import Page from "src/components/Page";
import {
    Container,
    Stack,
    Card,
    Typography,
    Chip,
    Avatar
} from '@mui/material';
import { VideoPlayer } from "src/sections/notifications";
import { useEffect, useState } from 'react' 
import useNotifications from "src/api/useNotifications";

export default function Notifications() {

    const { alldata } = useNotifications()   
    return (
        <Page title="User">
            <Container>
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>Notifications</Typography>
                </Stack> */}
                <Card sx={{maxWidth: 720 }}>
                    <Typography sx={{ p:2 }} variant="h5">{alldata.data.DeviceName}</Typography>
                    <VideoPlayer player={alldata.data} />
                    
                    {('DetectedType' in alldata.data) && (
                        <Stack direction="row" spacing={1} sx={{ m:2 }}>
                            {[...alldata.data.DetectedType.split(',')].map((value, key) => (
                                <Chip key={key} avatar={<Avatar>{value.charAt(0).toUpperCase() || ''}</Avatar>} label={value} />
                            ))}                            
                        </Stack>
                    )}

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ m:2, mb:1 }}>
                    <Typography variant="p" gutterBottom><Typography  variant="b">Audit No:</Typography> {alldata.data.Id}</Typography>
                    <Typography variant="p" gutterBottom color='gray'>{alldata.data.CreatedOn}</Typography>
                </Stack>

                </Card>
            </Container>
        </Page>
    )
}