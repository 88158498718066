import axios from 'src/axios';
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';

const getCamerasList = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('devices')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
      .catch((error) => console.error('API call failed:', error));
}

const getClientList = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('client-info/get-clients')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
      .catch((error) => console.error('API call failed:', error));
}

const trueEventsReport = (body, callback) => {
    const { month, year , client_id } = body; 

    axios({
        method: 'get',
        url: `${getURL('monthly-reports/monthly-total-events-report')}?month=${month}&year=${year}&client_id=${client_id}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
      .catch((error) => console.error('API call failed:', error));
}


const getIndividualEevnts = (data, callback) => {
    axios({
        method: 'get',
        url: `${getURL('monthly-reports/individual-events-report')}?${jsonToParam(data)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
      .catch((error) => console.error('API call failed:', error));
}


const getFalseEventsReport = (data, callback) => {
    axios({
        method: 'get',
        url: `${getURL('monthly-reports/monthly-false-events-report')}?${jsonToParam(data)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
      .catch((error) => console.error('API call failed:   for false ', error));
}

const getEvents = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('event-info/false-event-types')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
      .catch((error) => console.error('API call failed:   for events', error));
}

export { getCamerasList,  getClientList, trueEventsReport, getFalseEventsReport, getEvents ,getIndividualEevnts};