import axios from '../axios'
import { getURL } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';


// get cameras status data
const getCamerasStatus = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/dashboard/camera-status')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

export { getCamerasStatus }