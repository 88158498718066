import React, { useState,useEffect, useRef } from 'react';
import {
  InputAdornment,
  Grid,
  Box,
  Autocomplete,
  Button,
  TextField,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableContainer,TablePagination,Toolbar,OutlinedInput
} from '@mui/material';
import PropTypes from 'prop-types';
import Scrollbar from 'src/components/Scrollbar';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { YearCalendar } from '@mui/x-date-pickers/YearCalendar';
import { Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Page, Text, View, Document, StyleSheet, Image,pdf,PDFDownloadLink,PDFViewer,Font, Rect, Svg,Line } from '@react-pdf/renderer';
import { monthlyClientDashboard,getClientGroups } from 'src/api/reports';
import html2canvas from 'html2canvas';
import FormComponent from 'src/templates/MonthlyClientTemplate';

  const months = [
    { name: 'January', value: '1' },
    { name: 'February', value: '2' },
    { name: 'March', value: '3' },
    { name: 'April', value: '4' },
    { name: 'May', value: '5' },
    { name: 'June', value: '6' },
    { name: 'July', value: '7' },
    { name: 'August', value: '8' },
    { name: 'September', value: '9' },
    { name: 'October', value: '10' },
    { name: 'November', value: '11' },
    { name: 'December', value: '12' }
  ];

  const currentYear = new Date().getFullYear();
  
  const years = //[currentYear - 1,currentYear,currentYear + 1];
  [
    { name:(currentYear - 1).toString(),value:currentYear - 1 },
    { name:(currentYear).toString(),value:currentYear },
    { name:(currentYear + 1).toString(),value:currentYear + 1 }
  ]


const MonthlyClientDahBoard = ({clientList}) => {
  const contentRef = useRef();
  const options3 = clientList

  const [filterName, setFilterName] = useState('');  

  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const [value3, setValue3] = useState(null);
  const [value4, setValue4] = useState(null);
      
  const [data, setData] = useState([])
  const [row,rowChange] = useState([])
  const [page,pageChange]=useState(0)
  const [rowPerPage,rowPerPageChange]=useState(5)

  const [loadDocument,setLoadDocument]=useState(false)

  const [showChart,setShowChart]=useState(false)
  const [graphData,setGrapghData]=useState([])
  const [noData,setNoData] = useState(false)

  const handleButtonClick = () => {
    
    if(!value3){
      setError(true)
      setIsClientSelected(true)
    }

    if(!value1){
      setError(true)
      setIsMonthSelected(true)
    }

    if(!value2){
      setError(true)
      setIsYearSelected(true)
    }



    if(value1 && value2 && value3){
      let body={month:value1.value,year:value2.value,client_id:value3.ID}
      monthlyClientDashboard(body,(response)=>{
        debugger
         if(response.status===200){
            if(response.data.length>0){
              
              console.log(response.data)
              setData(response.data)
              //debugger
              let barGraphData = response.data[0].camera_wise_counts
              setGrapghData(barGraphData)
              setShowChart(true)
            }
           else{
            setData([])
             // setShowChart(false)
             setNoData(true)
           }
         }
      })
      
    }   
  };

// useEffect(() => {
//     setData(values)
// }, [])

const handleChangePage = (event,newpage)=>{
    if(newpage===0){
        pageChange(0);
    }
    else{
        pageChange(newpage)
    }
}

const handleRowsPerPage = (e)=>{
    rowPerPageChange(e.target.value)
    pageChange(0)
}

const handleFilterByName = (event) => {
    const pattern=event.target.value.trim()
    setFilterName(pattern);
  };

  useEffect(()=>{
    // console.log(filterName)
  },[filterName])

  const convertImageToBase64 = (file, callback) => {
    debugger
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  };


 const [pieChartData,setPieChartData]=useState([])

  const [error,setError]=useState(false)
  const [isMonthSelected,setIsMonthSelected]=useState(false)
  const [isYearSelected,setIsYearSelected]=useState(false)
  const [isClientSelected,setIsClientSelected]=useState(false)
  const [groups,setGroups]=useState([])
  const [deviceStats,setDeviceStats] = useState([]);

  const loadClientGroups = (newValue)=>{
    setIsClientSelected(false)
    setValue3(newValue)
    setShowChart(false)
    setNoData(false)
    //console.log(newValue)
    if(newValue){
      let body={client_id:newValue.ID}
      getClientGroups(body,(response)=>{
        if(response.status){
          setGroups(response.data)
        }
      })
    }
  }


  return (
    <Grid sx={{ marginLeft: '1rem' }}>
        <Grid container spacing={2} alignItems="center" sx={{marginTop:'0.2rem'}}>

        <Grid item xs={3}>
                <Typography variant="subtitle2" component="div">
                Client
                </Typography>
                <Autocomplete
                value={value3}
                onChange={(event, newValue) => {
                    if(newValue!==null){
                      loadClientGroups(newValue)
                    }
                }}
                options={clientList}
                getOptionLabel={(option)=>option.displayname}
                isOptionEqualToValue={(option, value) => option.ID === value.ID} 
                renderOption={(props, option) => (
                  <li {...props} key={option.ID}>  
                    {option.displayname} 
                  </li>
                )}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    placeholder="Select Client"
                    variant="outlined"
                    error={error && isClientSelected}
                    />
                )}
                />
                 {error && isClientSelected && <Typography variant="body2" color="error">Select Client</Typography>}
            </Grid>

            <Grid item xs={3}>
                <Typography variant='subtitle2' component="div">
                Month
                </Typography>

                <Autocomplete
                value={value1}
                onChange={(event, newValue) => {
                   if(newValue!==null){
                    setIsMonthSelected(false)
                    setLoadDocument(false)
                    setValue1(newValue);
                    setShowChart(false)
                    setNoData(false)
                   }
                }}
                options={months}
                getOptionLabel={(option)=>option.name}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    placeholder="Select Month"
                    variant="outlined"
                    error={error && isMonthSelected}
                    />
                )}
                />
                 {error && isMonthSelected && <Typography variant="body2" color="error">Select Month</Typography>}
            </Grid>
            
            <Grid item xs={3}>
                <Typography variant="subtitle2" component="div">
                Year
                </Typography>
                <Autocomplete
                value={value2}
                onChange={(event, newValue) => {
                   if(newValue!==null){
                    setLoadDocument(false)
                    setIsYearSelected(false)
                    setValue2(newValue);
                    setShowChart(false)
                    setNoData(false)
                   }
                }}
                options={years}
                getOptionLabel={(option)=>option.name}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    placeholder="Select Year"
                    variant="outlined"
                   error={error && isYearSelected}
                    />
                )}
                />
                {error && isYearSelected && <Typography variant="body2" color="error">Select Year</Typography>}
       
            </Grid>

            <Grid item xs={1} sx={{marginTop:2}}>
                    <Button variant="contained" onClick={handleButtonClick}>
                      Generate
                    </Button>           
            </Grid>

            <Grid item xs={3} sx={{marginBottom:2}}>
                <Typography variant="subtitle2" component="div">
                Group
                </Typography>
                <Autocomplete
                value={value4}
                onChange={(event, newValue) => {
                    if(newValue!==null){
                      setValue4(newValue);
                    }
                }}
                options={groups}
                getOptionLabel={(option)=>option.GroupName}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    placeholder="Select Group"
                    variant="outlined"
                    />
                )}
                />
               </Grid>

        
        </Grid>

        {showChart && <Grid container spacing={2} alignItems="center" sx={{ marginTop: '2rem' }}>
            {/* {disply html here} */}
            <FormComponent barGraphData={graphData} Data={data} Month={value1.name} Year={value2.value} clientName={value3.displayname}/>
        </Grid>}

        {noData && (
          <Grid 
            container 
            spacing={2} 
            alignItems="center" 
            justifyContent="center"  
            //sx={{ marginTop: '2rem'}}
          >
            <Typography align="center" sx={{fontWeight:'bold'}}>
              No Data
            </Typography>
          </Grid>
        )} 
    </Grid>
  );
};

export default MonthlyClientDahBoard;