import { amber,
    blue,
    blueGrey,
    brown,
    common,
    cyan,
    deepOrange,
    deepPurple,
    green, 
    grey, 
    indigo, 
    lightBlue, 
    lightGreen, 
    lime, 
    orange, 
    pink, 
    purple, 
    red, 
    teal, 
    yellow } from '@mui/material/colors'

const getColor = {
    a:deepOrange[500],
    b:deepOrange[400],
    c:deepOrange[300],
    d:deepPurple[500],
    e:deepPurple[400],
    f:deepPurple[300],
    g:amber[400],
    h:blueGrey[300],
    i:pink[400],
    j:blue[500],
    k:blueGrey[500],
    l:brown[500],
    m:common[500],
    n:cyan[500],
    o:green[500],
    p:grey[500],
    q:indigo[500],
    r:lightBlue[500],
    s:lightGreen[500],
    t:lime[500],
    u:orange[400],
    v:pink[500],
    w:purple[500],
    x:red[500],
    y:teal[500],
    z:yellow[500],
}

export { getColor }