import {
    Box,
    Typography,
    Stack,
    Chip,
    FormControl,
    InputLabel,CircularProgress,
    Select,
    MenuItem,
    IconButton,Grid,
    Avatar,
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, TextField,
    Card, 
    Table
} from "@mui/material";


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Share } from "@mui/icons-material";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Iconify from '../../../components/Iconify';
import useVideoPlayer from "src/hooks/useVideoPlayer";
import { useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types';

import { fDateTime } from "src/utils/formatTime"

import { LoadingButton } from '@mui/lab';
import { handleResponse } from "src/utils/responseHandler"
import axios from 'src/axios'
import { getURL } from 'src/utils/config';
import { getAudioByEvent } from "src/api/clientDetails";
import { CloudDownload } from "@mui/icons-material";
import { downloadVideo } from "src/api/analytics";
import { set } from "lodash";
import { getvideoURLMail } from "src/api/clientDetails";

const handleVideoPlay = (stream) => {

    try {
        window.JSBridge.showMessageInNative(stream)
    } catch (e) {
        console.info('Need to go on web view')
    }
}


const isWebView = () => {
    // Get the user agent string
    var userAgent = navigator.userAgent.toLowerCase();
    
    // Check if the user agent contains "wv" or "webview" (common indicators of a web view)
    return userAgent.includes("wv") || userAgent.includes("webview");    
      
}


export default function AuditPlayer({ player, setPlayer, updateToClient, isEscalating ,clientId}) {
    const videoElement = useRef(null)
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const IsAgentBased = localStorage.getItem('IsAgentBased');
    const [currentTime, setCurrentTime] = useState('00:00'); // State variable to store current time
    const [announcementFile,setAnnounceMentData] = useState([])
    const [loading, setLoading] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [emailText,setEmailText] = useState('')
    const [tokenName, setToken] = useState(localStorage.getItem('displayname'))
    const [emailError,setEmailError] = useState('');
    const sendMail = () => {
        setIsSubmitting(true)
        axios({
            method: 'get',
            url: getURL(`client-info/send-client-mail-consolidated?devicename=${player.devicename}&detectedtype=${player.DetectedType}&videourl=${player.VideoUrl}`),
            validateStatus: (status) => handleResponse(status),
        }).then(function (response) {
            setIsSubmitting(false)
            if (response.status === 200) {
            window.alert("Email has been sent successfully.")
            } else {
            window.alert("Something went wrong. Please try again later")
            }
        })
    }

    // const [isOpen, setOpen] = useState(false)
    const handleClose = () => {
        // setOpen(false)
        const isOpen = false
        setPlayer((plyr) => {return {...plyr, isOpen}})
        handleClosePlayer()
        setEmailText('')
      };
    
    // const [player, setPlayer] = useState(initValues)
    const {
        playerState,
        togglePlay,
        handleOnTimeUpdate,
        handleVideoSpeed,
        handlePause,
        handlePlay,
        handleInitPlay,
        handleClosePlayer
    } = useVideoPlayer(videoElement);


    useEffect(() => {
        if (player.VideoUrl)
            handleInitPlay()
    }, [player.VideoUrl])

    useEffect(() => {

        if(player.EventNo){

            let body = { event_no : player.EventNo }
            getAudioByEvent(body,(response)=> {
                if(response.status === 200){
                    // console.log("response Audio",response.data);
                    setAnnounceMentData(response.data)
                }
            })

        }   
    },[player.EventNo])

    const handleAudioTimeUpdate = (event) => {
        const minutes = Math.floor(event.target.currentTime / 60);
        const seconds = Math.floor(event.target.currentTime - minutes * 60);
        const currentTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
        setCurrentTime(currentTime);
    };
    const str_pad_left = (string, pad, length) => {
        return (new Array(length + 1).join(pad) + string).slice(-length);
    };

    const handleDownload = async () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
          }, 3000);
        if (videoElement.current) {
            const videoUrl = videoElement.current.src;
            downloadVideo(videoUrl, (response) => {

                console.log(response);
                if (response.status === 200) {
                    // Handle successful download if needed
                }
            });
        } else {
            setLoading(false); // Ensure loading state is reset if videoElement is not available
        }
    };


    const handleEmailAddress = (event) => {
        setEmailText(event.target.value);
        setEmailError('')
      };
    const handleShare = () => {
        if (emailText) {
      
            console.log("email",emailText,player.VideoUrl,tokenName)
            const body ={
                to : emailText,
                client_name :tokenName,
                video_url :player.VideoUrl
            }
            getvideoURLMail(body,(response) => {
                if(response.status === 200){
                    console.log("response email",response.data);
                    setEmailText('')
                }
            })
    
          } else {
            setEmailError('Please enter an email address.')
            // alert('Please enter an email address.');
          }


     
    }

    return (

        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={player.isOpen}
            onClose={handleClose}
        >
            <DialogTitle>
                {/* <Typography variant="b">Audit ID {player.Id}</Typography> */}
                <Typography color="gray">{player.DeviceName}</Typography>                
            </DialogTitle>
            <DialogContent>
                {/* <DialogContentText>
                    You can set my maximum width and whether to adapt or not.
                </DialogContentText> */}
                <Box>

{isWebView() ? (
                    (localStorage.getItem("client_id") === "855" || localStorage.getItem("client_id") === "863") ? (
                        <Box style={{ background: 'linear-gradient(to top, rgb(6, 6, 6), 20%, rgb(51 51 51))', maxHeight: 403}}>
                            <video
                                src={player.VideoUrl}
                                controls
                                ref={videoElement}
                                onTimeUpdate={handleOnTimeUpdate}
                                onPause={handlePause}
                                onPlay={handlePlay}
                                style={{ maxHeight: 403, width: '100%', display: player.display }}
                                preload="auto"
                                autoPlay
                            />
                        </Box>
                    ) : (
                        <Stack direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            onClick={() => handleVideoPlay(player.VideoUrl)}
                            style={{ background: 'linear-gradient(to top, rgb(6, 6, 6), 20%, rgb(51 51 51))', minHeight: 180 }}
                        >
                            <IconButton sx={{ color: "white" }}>
                                <Iconify sx={{ height: 40, width: 40 }} icon={"eva:play-circle-outline"} />
                            </IconButton>
                        </Stack>
                    )
                        // <Stack direction="row"
                        // justifyContent="center"
                        // alignItems="center"
                        // spacing={2} 
                        // onClick={() => handleVideoPlay(player.VideoUrl)}
                        
                        //  style={{ background: 'linear-gradient(to top, rgb(6, 6, 6), 20%, rgb(51 51 51))', minHeight: 180}}>
                        //  <IconButton sx={{ color: "white" }}>
                        //     <Iconify sx={{ height: 40, width: 40 }} icon={"eva:play-circle-outline"} />
                        //  </IconButton>
                        //  </Stack>

                        // <Box style={{ background: 'linear-gradient(to top, rgb(6, 6, 6), 20%, rgb(51 51 51))', maxHeight: 403}}>
                        // <video
                        //     src={player.VideoUrl}
                        //     controls
                        //     ref={videoElement}
                        //     onTimeUpdate={handleOnTimeUpdate}
                        //     onPause={handlePause}
                        //     onPlay={handlePlay}
                        //     style={{ maxHeight: 403, width: '100%', display: player.display }} />
                        // </Box>
                    ): (
                        <Box style={{ background: 'linear-gradient(to top, rgb(6, 6, 6), 20%, rgb(51 51 51))', maxHeight: 403}}>
                            <video
                                src={player.VideoUrl}
                                controls
                                ref={videoElement}
                                onTimeUpdate={handleOnTimeUpdate}
                                onPause={handlePause}
                                onPlay={handlePlay}
                                style={{ maxHeight: 403, width: '100%', display: player.display }}
                                preload="auto"
                                autoPlay
                                />
                        </Box>
                    )}
                    
                    <Stack direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2} sx={{ my: 2 }}>
                        {/* <Box>
                            <Chip
                                disabled={!player.src}
                                label={`Video ${playerState.playStatus}`}
                                color={playerState.isPlaying ? "primary" : "error"}
                                variant="outlined"
                                size="small" />
                            
                        </Box> */}
                        <FormControl>
                            <InputLabel id="video-simple-select-label">Speed</InputLabel>
                            <Select
                                labelId="video-simple-select-label"
                                id="video-simple-select"
                                label="Speed"
                                value={playerState.speed}
                                onChange={(e) => handleVideoSpeed(e)}
                                disabled={!player.VideoUrl}
                            >
                                <MenuItem value={1}>1x</MenuItem>
                                <MenuItem value={2}>2x</MenuItem>
                                <MenuItem value={3}>3x</MenuItem>
                                <MenuItem value={4}>4x</MenuItem>
                                <MenuItem value={5}>5x</MenuItem>
                            </Select>
                        </FormControl>
                        <IconButton aria-label="refresh" color="success" onClick={togglePlay} disabled={!player.VideoUrl}>
                            {!playerState.isPlaying ? (
                                <Iconify disabled={!player.VideoUrl} icon="bi:play-circle-fill" sx={{ height: 34, width: 34 }} />
                            ) : (
                                <Iconify icon="bi:pause-circle-fill" sx={{ height: 34, width: 34 }} />
                            )}
                        </IconButton>
                        {localStorage.getItem('ID') === '2' && (
                            <Button color="error" variant="outlined" onClick={() => updateToClient(`'${player.Id}'`)}>True Event</Button>
                        )}
                    </Stack>
                    {IsAgentBased === "0" ? (
                    <Box>
                        {(player.DetectedType) && [...player.DetectedType.split(',')].map((value, key) => (
                        <Chip sx={{ textTransform: "capitalize", mr: 1, mb: 1 }} key={key} avatar={<Avatar>{value.charAt(0).toUpperCase() || ''}</Avatar>} label={value} />
                        ))}
                    </Box>     
                      ) : (
                        <>  
                            <div>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={9}>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value = {emailText}
                                        onChange={handleEmailAddress}
                                    />
                                    {emailError && (
                                        <Typography color="error" variant="body2" align="center" style={{ marginTop: "0.5rem" }}>
                                        {emailError}
                                        </Typography>
                                    )}                                    
                                    </Grid>
                                    <Grid item xs={6} sm={1.5}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Share />}
                                        style={{ marginLeft: '0.5rem', width: '100%' }}
                                        onClick={handleShare}
                                    >
                                    </Button>
                                    </Grid>
                                    <Grid item xs={6} sm={1.5}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ marginRight: '0.5rem', marginLeft: '0.5rem', width: '100%' }}
                                    
                                        onClick={handleDownload}
                                        startIcon={!loading ? <CloudDownload /> : <CircularProgress size={22} color="inherit" /> }
                                        >
                                    </Button>
                                  
                                    </Grid>
                                </Grid>

                                <Typography sx={{ fontSize: '0.9rem', fontWeight: 800, marginTop: 2 }}>
                                    Announcement Audio
                                </Typography>

                                <Stack direction="column" spacing={2} marginTop={1}>
                                    <TableContainer>
                                    <Table>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'center' }}>FileName</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>Audio File</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>No of Times Announced</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {announcementFile.map((row, index) => (
                                            <TableRow key={index}>
                                            <TableCell style={{ width: '20%', textAlign: 'center' }}>{row['FileName']}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>
                                                <audio
                                                controls
                                                src={row["FilePath"]}
                                                type="audio/mpeg"
                                                onTimeUpdate={handleAudioTimeUpdate}
                                                style={{ width: '100%' }}
                                                >
                                                </audio>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row["NoOfTimesAnnounced"]}</TableCell>
                                            </TableRow>
                                        ))}
                                        {announcementFile.length === 0 && (
                                             <TableRow >
                                             <TableCell style={{ width: '20%', textAlign: 'center' }}></TableCell>
                                             <TableCell style={{ width: '20%', textAlign: 'center' ,fontWeight:"bold"}}>No Data</TableCell>
                                             <TableCell style={{ width: '20%', textAlign: 'center' }}></TableCell>

                                             </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                                </Stack>
                                </div>
                        </>
                    
                      )}

                    {Number(localStorage.getItem("client_id")) === 831 && (
                        <LoadingButton 
                        sx={{ mt: 2}}
                        fullWidth
                        variant="contained"
                        loading={isSubmitting}
                        onClick={sendMail}
                        >
                        Send Mail
                        </LoadingButton>
                        )}                   
                    
                </Box>

            </DialogContent>
            <Stack direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{ px: 2, py: 1 }}
                >
                {player.CreatedOn && (<Typography sx={{pl:1}} color={"gray"}>{ fDateTime(player.CreatedOn)}</Typography>) }               
                {/* <Button onClick={handleClose}>Close</Button>  */}
                {/* <Button color="error" variant="contained"  style={{ marginLeft: "0.5rem" }} onClick={handleClose}>Close</Button> */}
              
            </Stack>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    px: 2,
                    py: 2
                }}
            >
               <Button color="error" variant="contained" onClick={handleClose}>Close</Button>
           </Box>

            
        </Dialog>
    )
}

AuditPlayer.propTypes = {
    player: PropTypes.object.isRequired,
    setPlayer: PropTypes.func.isRequired
}
