import React, { useState , useEffect} from 'react';
import {
  Grid,
  Button,
  Typography,
  FormControl,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Container
} from '@mui/material';
import LineGraphMd from 'src/sections/reports/LineGraphMd';
import BarGraphMd from 'src/sections/reports/BarGraphMd';
import { getMonthlyPriceDetails ,getMonthlyActClientDetails} from 'src/api/apiMdReports';

export default function DashBoardMdPSIT() {
  const [year, setYear] = useState('');
  const [paymentData, setPaymentData] = useState([]);
  const [cameCount,setCamCount] = useState([]);

  const currentYear = new Date().getFullYear();
  const yearOptions = [
    { name: (currentYear - 1).toString(), value: currentYear - 1 },
    { name: currentYear.toString(), value: currentYear },
    { name: (currentYear + 1).toString(), value: currentYear + 1 }
  ];

  useEffect(()=>{
    const data = {
      year : currentYear
    }
    getMonthlyPriceDetails(data,(response)=>{
      if(response.status === 200 && response.data != null){
        // console.log("response",response.data)
        setPaymentData(response.data)
      }
    })

    getMonthlyActClientDetails(data,(response)=>{
      if(response.status === 200 && response.data != null){
        // console.log("response",response.data)
        setCamCount(response.data);
      }
    });


  },[])
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleButtonClick = () => {
    console.log("year",year);
    const data = {
      year : year
    }
    getMonthlyPriceDetails(data,(response)=>{
      if(response.status === 200 && response.data != null){
        console.log("response",response.data)
        setPaymentData(response.data)
      }
    })
    getMonthlyActClientDetails(data,(response)=>{
      if(response.status === 200 && response.data != null){
        setCamCount(response.data);
      }
    });
  };
  const handleClearButtonClick = () => {
    setYear('');
  };

const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const data = paymentData.map(item => ({
    month: monthNames[item.Month - 1],
    price: item.PaymentAmount
}));

console.log(cameCount);
const clientCounts = cameCount.map(item => ({
  month: monthNames[item.month - 1],
  clientCount: item.active_client_count,
  cameraCount: item.active_camera_count,
}))

console.log("test",clientCounts);
  const dummyData = [
    { month: 'January', clientCount: 120, cameraCount: 80 },
    { month: 'February', clientCount: 150, cameraCount: 90 },
    { month: 'March', clientCount: 200, cameraCount: 120 },
    { month: 'April', clientCount: 180, cameraCount: 100 },
    { month: 'May', clientCount: 220, cameraCount: 140 },
    { month: 'June', clientCount: 250, cameraCount: 160 },
    { month: 'July', clientCount: 300, cameraCount: 200 },
    { month: 'August', clientCount: 280, cameraCount: 180 },
    { month: 'September', clientCount: 320, cameraCount: 220 },
    { month: 'October', clientCount: 350, cameraCount: 250 },
    { month: 'November', clientCount: 280, cameraCount: 180 },
    { month: 'December', clientCount: 320, cameraCount: 200 }
  ];

  return (
    <>
      <div>
        <Typography variant="h4" gutterBottom>
          Dashboard
        </Typography>
        <Grid sx={{ marginLeft: '1rem' }}>
          <Grid container spacing={2} alignItems="center" sx={{ marginTop: '0.2rem' }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="year-label">Year</InputLabel>
                <Select
                  labelId="year-label"
                  id="year"
                  value={year ? year : currentYear}
                  onChange={handleYearChange}
                  label="Year"
                  placeholder="Select Year..."
                >
                  {yearOptions.map((yearOption) => (
                    <MenuItem key={yearOption.value} value={yearOption.value}>
                      {yearOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={3}> */}
              {/* Additional UI elements can be added here */}
            {/* </Grid> */}
            <DialogActions>
            <Button onClick={handleButtonClick} variant="contained" color="primary">
                Get Reports
              </Button>
              <Button color="error" variant="contained" onClick={handleClearButtonClick}>
                Cancel
              </Button>

            </DialogActions>
          </Grid>
        </Grid>

        <div style={{ marginTop: '30px' }}></div>

        <Container sx={{ marginLeft: '1rem' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ width: '50%', height: '400px' }}>
              <LineGraphMd data={data} />
            </Box>
            <Box sx={{ width: '50%', height: '800px' }}>
              <BarGraphMd data={clientCounts} />
            </Box>
          </Box>
        </Container>

        <div style={{ marginTop: '30px' }}></div>
      </div>
    </>
  );
}
