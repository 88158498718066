import { Box, Grid } from "@mui/material";
import CountBoard from "src/components/CountBoard";
// import ClientChart from "./ClientChart";

export default function ClientReport({ total, active, inactive, listHandler }) {
    return (
        <Box sx={{borderBottom: "1px solid #ebebeb", p: 2}}>
          <Grid container spacing={2}>             
            <Grid item md={2} xs={4} >
              <Box sx={{cursor: "pointer"}} onClick={() => listHandler('all')}>
                <CountBoard color="primary" count={total} title="Total Clients" />    
              </Box>                                    
            </Grid>
            <Grid item md={2} xs={4}>
                <Box sx={{cursor: "pointer"}} onClick={() => listHandler('active')}>
                  <CountBoard color="success" count={active} title="Active Clients"/>                         
                </Box>                
            </Grid>
            <Grid item md={2} xs={4}>
              <Box sx={{cursor: "pointer"}} onClick={() => listHandler('inactive')}>
                <CountBoard color="error" count={inactive} title="InActive Clients"/> 
              </Box>                
            </Grid>
          </Grid>
        </Box>
    )
}