import { 
  Container,
  Stack, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography, 
  Card, 
  Table, 
  FormControl, 
  Select, 
  InputLabel, 
  MenuItem
} from '@mui/material';
// components
import Page from '../components/Page';
import { useEffect, useState } from 'react';

import { getCamerasStatus } from '../api/camerasReport'

// ----------------------------------------------------------------------

const urlParams = new URLSearchParams(window.location.search);
let camStatus = urlParams.get('status') || "all";

export default function DevicesReport() {

  // console.log(camStatus)

  const [status, setStatus] = useState(camStatus)

  const [activeData, setActiveData] = useState([])

  const [data, setData] = useState({ all: [], active: [], inactive: []})


  const handleChange = (event) => {
    setStatus(event.target.value)

    // console.log(data, data[event.target.value])
  
    setActiveData([...data[event.target.value]])
  }

  useEffect(() => {
    getCamerasStatus((response) => {
      if (response.status === 200) {
        if (response.data && response.data.length > 0) {

          const result = {}
          result.all = response.data[0].hasOwnProperty("total_cameras") && response.data[0].total_cameras.hasOwnProperty("details") ? response.data[0].total_cameras.details : []
          result.active = response.data[0].hasOwnProperty("analytics_cameras") && response.data[0].analytics_cameras.hasOwnProperty("details") ? response.data[0].analytics_cameras.details : []
          result.inactive = response.data[0].hasOwnProperty("non_working_cameras") && response.data[0].non_working_cameras.hasOwnProperty("details") ? response.data[0].non_working_cameras.details : [] 
          
          setData({
            ...data,
            all: result.all,
            active: result.active,
            inactive: result.inactive

          })
          setActiveData([...result[status]])
        }
        
      }
    })
  }, [])

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography sx={{ py: 3 }} variant="h5">Cameras Status</Typography>
        <Card sx={{ p: 3 }}>

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <FormControl >
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value="all">Total Cameras</MenuItem>
                <MenuItem value="active">Working Cameras</MenuItem>
                <MenuItem value="inactive">Non Working Cameras</MenuItem>
              </Select>
            </FormControl>
          </Stack>


          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S No</TableCell>
                  <TableCell>Camera Name</TableCell>
                  <TableCell>RTSP Stream</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeData.map((row, index) => (
                   <TableRow key={index}>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{row['devicename']}</TableCell>
                    <TableCell>{row["substream"]}</TableCell>
                  </TableRow>
                ))}
               
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </Page>
  );
}
