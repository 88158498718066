import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Autocomplete,Grid,TextField,Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Dialog,DialogActions,DialogContent,DialogTitle,Button } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { UpdateClientGroup } from 'src/api/groups';


export default function GroupsMore({row,groups,handleGroupReload}) {

  const ref = useRef(null);
  const [fullWidth,setFullWidth]=useState(true);
  
  const [isOpen, setIsOpen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [updatedGroupId,setUpdatedGroupId]=useState({})

  const [openError,setOpenError]=useState(false)
  const [message,setMessage]=useState('')

  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleOpenDialog = () => {
   // debugger
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenError(false)
  };

  const editClientGroup = ()=>{
   // debugger
    handleOpenDialog()
  }

  const handleEdit = ()=>{
    
    let groupid=groups.filter(item=>item.GroupName === row.GroupName)        
    
    if(groupid.length===1){
            groupid=groupid[0].Id
    }

    if(updatedGroupId!==0 && updatedGroupId!==groupid){

        let newId=updatedGroupId.Id
        let newName=updatedGroupId.GroupName
        let body={GroupId:newId,DeviceId:row.deviceid,GroupName:newName}

        UpdateClientGroup(body,(response)=>{
                if(response.status===200){
                    setOpenError(true)
                    setMessage('Group Update Successfully')
                    handleGroupReload(true)
                }
                else{

                }
            })  
    } 
    else{
        setOpenError(true)
        setMessage('Select Group To Update')
    }    
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
       
        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={editClientGroup}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24}/>
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

    
        <MenuItem sx={{ color: 'text.secondary' }} >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

      </Menu>
    
        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>Edit Group</DialogTitle>
            <DialogContent>
               <Grid container spacing={2} sx={{marginTop:1}}>
                    <Grid item xs={6}>
                        <TextField fullWidth variant='outlined' label='Camera Name' value={row.devicename || ''}>
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                    <Autocomplete
                            fullWidth            
                            id="tags-outlined"
                            // options={groups}
                            options={(groups || []).map((group, groupIndex) => ({
                              ...group,
                              customKey: groupIndex, // Assuming customKey is a unique identifier
                            }))}
                            getOptionLabel={(option) => option.GroupName}
                            onChange={(event,value)=>setUpdatedGroupId(value)}
                            defaultValue={groups.find(option => option.GroupName === row.GroupName) || null}
                            filterSelectedOptions
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Groups"
                                placeholder="Select Group"
                            />
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option.customKey}>
                                {option.GroupName}
                              </li>
                            )}
                        />






                    </Grid>
               </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='error' onClick={handleCloseDialog}>Close</Button>
                <Button type="submit"  onClick={handleEdit}   variant="contained">Save</Button>

            </DialogActions>
        </Dialog>

        <Dialog open={openError} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
        <DialogTitle>Add Group</DialogTitle>
          <DialogContent>
                    {message}
          </DialogContent>
        <DialogActions>
          <Button color='error' variant='outlined' onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
     
    </>
  );
}