import React, { useEffect, useState } from 'react';
import {
  Card,
  Stack,
  Container,
  Typography,
  CardContent,
  Box,
  Divider,
  Grid,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import dayjs from "dayjs";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getMonitoringTimings, postMonitoringTimings } from 'src/api/analytics';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const cardColors = ['#FFE0E0', '#E0F5FF', '#E0FFE0', '#FFF5E0', '#F0E0FF', '#E0E8FF', '#FFE0F0'];

export default function MonTimings({ DeviceInfo, close }) {
  const [main, setOpen] = useState({ open: false, activeColoum: [], AnalyticName: '', activeCamIds: [] });
  const [timings, setTimings] = useState(
    daysOfWeek.map(() => ({
      fromTime: dayjs().startOf('day'),
      toTime: dayjs().endOf('day')
    }))
  );
    const [allDaysFromTime, setAllDaysFromTime] = useState(dayjs().startOf('day'));
  const [allDaysToTime, setAllDaysToTime] = useState(dayjs().endOf('day'));
  const [devicemonTimings, setDeviceMonTimings] = useState([]);
  const [status, setStatusBase] = useState(false);
  const [message, setMessage] = useState('');
  const [messageError, setErrorMessage] = useState('');
  const [messageErrorDate, setErrorMessageDate] = useState('');

  const formatTime = (time) => {
    return dayjs(time).format('HH:mm:ss');
  };

useEffect(() => {
  const selectedDevices = DeviceInfo;
  selectedDevices.forEach(value => {
    getMonitoringTimings(value, (response) => {
      if (response.status === 200) {
        const fetchedTimings = response.data;
        setTimings(prevTimings => {
          const updatedTimings = [...prevTimings];
          fetchedTimings.forEach(timing => {
            const { Dayno } = timing;
            const timingIndex = Dayno;
            if (timingIndex >= 0 && timingIndex < daysOfWeek.length) {
              console.log("updating",timing.FromHour,timing.ToHour)

              updatedTimings[timingIndex] = {
                fromTime: dayjs(timing.FromHour, 'HH:mm:ss'),
                toTime: dayjs(timing.ToHour, 'HH:mm:ss')
              };
            }
          });
          console.log("updating",updatedTimings)
          return updatedTimings;
        });
      }
    });
  });
}, []);

  

  useEffect(() => {
    if (messageError.length > 0) {
      const unqArr = [...new Set(messageError)];
      setErrorMessage(unqArr);
    }
  }, [messageError]);

  const handleClose = () => {
    close();
  };





  const findOverlappingDays = (data) => {
      const overlappingDays = [];
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  
      const parseTime = (time) => {
          const [hours, minutes, seconds] = time.split(':').map(Number);
          return { hours, minutes, seconds };
      };
  
      const isTimeGreater = (time1, time2) => {
          if (time1.hours > time2.hours) return true;
          if (time1.hours === time2.hours && time1.minutes > time2.minutes) return true;
          if (time1.hours === time2.hours && time1.minutes === time2.minutes && time1.seconds > time2.seconds) return true;
          return false;
      };

      const minutesSinceMidnight = (time) => {
        return time.hours * 3600 + time.minutes * 60 + time.seconds;
    };
      for (let i = 0; i < data.length; i++) {

          const currentDay = data[i];
          const nextDay = data[(i + 1) % data.length]; // Circular array logic to handle last day to first day comparison
  
          const fromTimeCurrent = parseTime(currentDay.FromHour);
          const toTimeCurrent = parseTime(currentDay.ToHour);
          const fromTimeNext = parseTime(nextDay.FromHour);
  
          // Case 1: All day scenario
          if (fromTimeCurrent.hours === 0 && fromTimeCurrent.minutes === 0 && fromTimeCurrent.seconds === 0 && toTimeCurrent.hours === 23 && toTimeCurrent.minutes === 59 && toTimeCurrent.seconds === 59) {
              continue;
          }
          
  
          // Case 2: Handle the scenario where current day's end time goes past midnight
          if (isTimeGreater(fromTimeCurrent, toTimeCurrent)) {
              // Current day goes past midnight
              const endOfDayMinutes = minutesSinceMidnight({ hours: 23, minutes: 59, seconds: 59 }) + 1;
              const timeAfterMidnight = minutesSinceMidnight(toTimeCurrent);
              const fromTimeCurrentSec = minutesSinceMidnight(fromTimeCurrent);
              // const remainingMinutesPastMidnight = endOfDayMinutes - fromTimeCurrentSec + timeAfterMidnight;
              console.log("endOfDayMinutes,timeAfterMidnight,remainingMinutesPastMidnight",fromTimeCurrentSec,timeAfterMidnight)
              // if (fromTimeCurrentSec === timeAfterMidnight) {
              //   console.log( "fromtime to time can't be same " )  
              // }

              if (timeAfterMidnight > minutesSinceMidnight(fromTimeNext)) {
                  overlappingDays.push(`Overlap between ${daysOfWeek[currentDay.Dayno]} and ${daysOfWeek[nextDay.Dayno]}`);
              }

          } else {
              // if (isTimeGreater(toTimeCurrent, fromTimeNext)) {
              //     overlappingDays.push(`Overlap between ${daysOfWeek[currentDay.Dayno]} and ${daysOfWeek[nextDay.Dayno]}`);
              // }
          }
      }
  
      return overlappingDays;
  };




const handleSaveTime = () => {
    const resultList = [ ];
    const selectedDevices = DeviceInfo;
    let previousTime = '';
    let previousFromTime = '';
    let extrahourIndex = '';
    let overlapDetected = false;

    setMessage('');
    setErrorMessage([]);
    setErrorMessageDate('');
    selectedDevices.forEach(value => {
        previousTime = '';
        timings.forEach((timing, index) => {
            const fromTimeformate = formatTime(timing.fromTime);
            const trimmedFromTime = fromTimeformate.split(":").slice(0, 3).join(":");
            const toTimeformate = formatTime(timing.toTime);
            const trimmedtoTime = toTimeformate.split(":").slice(0, 3).join(":");

            const currentDate = new Date();
            const currentDay = currentDate.getDay();
            const daysToAdd = index - currentDay;
            currentDate.setDate((currentDate.getDate() + daysToAdd) + 1);

            resultList.push({
                CameraId: value,
                Dayno: index,
                FromHour: trimmedFromTime,
                ToHour: trimmedtoTime
            });
        });
    });

    console.log("result report", resultList);
    postMonitoringTimings(resultList, (response) => {
      if (response.data) {
          setStatusBase(true);
          setMessage('Monitoring Timings Inserted Successfully!!');
      }
  });

    // const overlappingDays = findOverlappingDays(resultList);

    // for (let i = 0; i < resultList.length; i++) {
    //   if(resultList[i].FromHour === resultList[i].ToHour){
    //     setStatusBase(true)
    //     setErrorMessageDate("fromtime Totime can't be same ");
    //     return false
    //   }
    // }

    // if (overlappingDays.length > 0) {
    //     console.log("Overlap detected on the following days:");
    //     overlappingDays.forEach(day => {
    //         console.log("day...", day);
    //         setStatusBase(true);
    //         setErrorMessage([day]);
    //     });
    // } else {
    //     postMonitoringTimings(resultList, (response) => {
    //         if (response.data) {
    //             setStatusBase(true);
    //             setMessage('Monitoring Timings Inserted Successfully!!');
    //         }
    //     });
    // }
};


 



  
  

  const handleTimingChange = (index, field, value) => {
    setTimings(prevTimings => {
      const updatedTimings = [...prevTimings];
      updatedTimings[index][field] = value;
      return updatedTimings;
    });
  };

  const handleSetAllDaysTime = () => {
    setTimings(prevTimings => prevTimings.map(() => ({ fromTime: allDaysFromTime, toTime: allDaysToTime })));
  };

  return (
    <Page title="Monitoring Times">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>Monitoring Times</Typography>
        </Stack>
        <Card sx={{ padding: 3 }}>
          <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
            <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
              <Stack direction="row" spacing={2}>
                <Stack >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      views={['hours', 'minutes', 'seconds']}
                      label="From Time (All Days)"
                      value={allDaysFromTime}
                      onChange={(newValue) => setAllDaysFromTime(newValue)}
                      format="HH:mm:ss"
                      ampm={false}
                    />
                  </LocalizationProvider>
                  
                </Stack>
                <Stack>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      views={['hours', 'minutes', 'seconds']}
                      label="To Time (All Days)"
                      value={allDaysToTime}
                      onChange={(newValue) => setAllDaysToTime(newValue)}
                      format="HH:mm:ss"
                      ampm={false}
                      minutesStep={1}   // Set minutes step to 1 for every minute
                      secondsStep={1}   // Set seconds step to 1 for every second
                      getMinutesAriaText={(value) => `${value} minutes`}  // Optional: Aria text for minutes
                      getSecondsAriaText={(value) => `${value} seconds`}  // Optional: Aria text for seconds
                    />
                  </LocalizationProvider>
                </Stack>
                <LoadingButton variant="contained" onClick={handleSetAllDaysTime}>Apply to All Days</LoadingButton>
              </Stack>
            </Stack>

            <Grid container spacing={3}>
              {daysOfWeek.map((day, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card sx={{ py: 2, boxShadow: 0, textAlign: 'center', bgcolor: cardColors[index] }}>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {day}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        views={['hours', 'minutes', 'seconds']}
                        label="From Time"
                        value={timings[index].fromTime}
                        onChange={(newValue) => handleTimingChange(index, 'fromTime', newValue)}
                        format="HH:mm:ss"
                        ampm={false}
                      />
                    </LocalizationProvider>
                    <Box m={2} /> {/* Adjust margin as needed */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        views={['hours', 'minutes', 'seconds']}
                        label="To Time"
                        value={timings[index].toTime}
                        onChange={(newValue) => handleTimingChange(index, 'toTime', newValue)}
                        format="HH:mm:ss"
                        ampm={false}
                      />
                    </LocalizationProvider>
                  </CardContent>

                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Divider />

          <Stack direction="row" spacing={1} sx={{ paddingTop: 2 }}>
            <LoadingButton variant="contained" color='error' onClick={handleClose}>Close</LoadingButton>
            <LoadingButton variant="contained" onClick={handleSaveTime}>Save</LoadingButton>
          </Stack>

          {(status && message) && (
            <Stack direction="row" sx={{ paddingTop: 2 }}>
              <Alert severity="success">{message}</Alert>
            </Stack>
          )}

     {(status && messageErrorDate) && (
            <Stack direction="row" sx={{ paddingTop: 2 }}>
              <Alert severity="error">{messageErrorDate}</Alert>
            </Stack>
          )}

          {status && messageError.length > 0 && (
            <Stack direction="column-reverse" sx={{ paddingTop: 2, paddingBottom: 2 }}>
              {/* {messageError.map((error, index) => ( */}
                <Alert severity="error">{messageError}</Alert>
              {/* ))} */}
            </Stack>
          )}
        </Card>
      </Container>
    </Page>
  );
}
