import * as Yup from 'yup';
import qs from 'qs';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
// material
import {
  Grid,
  TextField,
  Alert,Table,styled,Paper,TableCell,TableHead,TableRow,TableBody,TableContainer,CardContent,
  Typography,
  Switch,
  Stack,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  FormHelperText,
  FormControlLabel,
  Card,
  colors,
  Icon
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, {Fragment } from 'react';

// component
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import client from 'src/api/clients';
import { getClientList , setAddDevice ,getServerList , getArchiveTimings, getMonitoringTimings, getTimezoneList } from 'src/api/analytics';
import { getProtocolsInfo } from 'src/api/protocol';
import axios from 'axios';
import { DeviceUnknown } from '@mui/icons-material';



export default function DeviceDetails({ dialogData, setOpen, reloadClientsData ,handleReloadPage}) {
 
     const [fullWidth, setFullWidth] = useState(true);

     // const [deviceDetails, setDialogIds] = useState([]);
     const [serverDetails,setServer] = useState('');
     const [archiveTimings,setArchiveTimings]=useState([]);
     const [monitoringtimings,setMonitoringTimings]=useState([]);
     // const [value, setValue] = useState([[2,11],[2,11],[2,11],[2,11],[2,11],[2,11],[2,11]]);
     const [selectedStream,setSelectedStream] = useState('');
     const [selectedDurationDays,setBackupdurationDays] = useState('');
     const [selectedCamId,setCamId] = useState('');
     const [protocolsData ,setDeviceProtocols] = useState([]);

     const getDayName = (dayValue) => {
          const daysOfWeek = ['Sunday' ,'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
          // const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
          return daysOfWeek[dayValue];
        }

     useEffect(() => {
          // setArchiveTimings([]);
          // setMonitoringTimings([]);

     // console.log("data",dialogData);

     if (dialogData.isOpen && dialogData.id) {

     // const valuesToAppend = dialogData.id.map(item => Object.values(item));
     // console.log("assigned data",valuesToAppend);

     // Appending values to deviceDetails
     // setDialogIds(dialogData);
     setSelectedStream(dialogData.id.substream)
     setBackupdurationDays(dialogData.id.BackupDurationDays)
     setCamId(dialogData.id.deviceid)
     setLocalDeviceName(dialogData.id.LocalDeviceName)
     setGroupName(dialogData.id.GroupName)
     setCameraType(dialogData.id.CameraView)
     setFootage(dialogData.id.Footage===0?'Image':dialogData.id.Footage===1?'Video':'')
     // setDialogIds(dialogData.id);

     // console.log("assigned data",deviceDetails);


     // server data 
     getServerList((response) => {
          if (response.status === 200) {
          //   console.log("servers ", response.data);

           const serverName = response.data.find(server => server.Id === dialogData.id.server_id)
          //  console.log("selected one",serverName.ServerName);

            setServer(serverName.ServerName)         
          }
        });


          if(dialogData.id.deviceid){
               getArchiveTimings(dialogData.id.deviceid,(response) => {
                    if (response.status === 200) {
                    //   console.log("archives Times ", response.data);
                    setArchiveTimings(response.data);
                         // const serverName = response.data.find(server => server.Id === deviceDetails.server_id)
                         // console.log("selected one",serverName.ServerName);
                         archiveTimings.sort((a, b) => a.Dayno - b.Dayno);
                         // console.log("selected one Archives",dialogData.id.deviceid,response.data);

                         // setServer(serverName.ServerName)         
                    }
                    });

               getMonitoringTimings(dialogData.id.deviceid,(response) => {
                    if (response.status === 200) {
                    // console.log("Monitoring Times ", response.data);
                    setMonitoringTimings(response.data);
                         // const serverName = response.data.find(server => server.Id === deviceDetails.server_id)
                         // console.log("selected one",serverName.ServerName);
                         monitoringtimings.sort((a, b) => a.Dayno - b.Dayno);
                         // console.log("selected one MonitoringTime",dialogData.id.deviceid,response.data);

                         // setServer(serverName.ServerName)         
                    }
                    });



               getProtocolsInfo({device_id: [dialogData.id.deviceid],intrusion_id: 0},(response) => {
                    if (response.status === 200) {
                         // console.log("protocos", response.data);
                         setDeviceProtocols(response.data)

                    }
                    })
               // getProtocolsInfo({device_id: [dialogData.id.deviceid],intrusion_id: ''},(response) => {
               //      if (response.status === 200) {
               //           console.log("protocos", response.data);

               //      }
               //    })

          }
          else {
               setArchiveTimings([]);
               setMonitoringTimings([]);
               // const serverName = response.
          }

     }

     }, [dialogData.isOpen, dialogData.id]);

     const onClose = () => {
         
          setArchiveTimings([]);
          setMonitoringTimings([]);
          setServer('');
          // setDialogIds('');
          setOpen(false);
          // setDialogIds('');
          // setServer('');
          // setArchiveTimings('');
          // setMonitoringTimings('');
          setLocalDeviceName('')
          setGroupName('')
          setCameraType('')
          setFootage('')
     }

     // console.log("test",dialogData.id)


// archives 


const [localDeviceName,setLocalDeviceName]=useState('')
const [groupName,setGroupName]=useState('')
const [cameraType,setCameraType]=useState('')
const [footage,setFootage]=useState('')




  return (
<Dialog  fullWidth={fullWidth}  maxWidth="md"    open={dialogData.isOpen} onClose={onClose}>
  <DialogContent >
   <Typography variant="h6" style={{ fontWeight: 'bold' }}>
   {selectedCamId}  Camera Details 
      </Typography>
               <Grid container spacing={2}>
                              <Grid item md={8}>
                              </Grid>
               </Grid>
                              

                        <Grid container spacing={2}>                           
                                      <Grid item md={6}>
                                       <InputLabel>RTSP stream</InputLabel> 
                                      <TextField 
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        value={selectedStream || ''}
                                        />

                                       </Grid>
                                       <Grid item md={6}>
                                      <InputLabel>Server</InputLabel> 
                                      <TextField 
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        value={serverDetails}
                                        />

                                       </Grid>
                         </Grid>

                         <Grid container spacing={2} marginTop={1}>                           
                                      <Grid item md={6}>
                                       <InputLabel>Local Camera Name</InputLabel> 
                                      <TextField 
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        value={localDeviceName || ''}
                                        />

                                       </Grid>
                                       <Grid item md={6}>
                                      <InputLabel>Camera Group Name</InputLabel> 
                                      <TextField 
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        value={groupName || ''}
                                        />

                                       </Grid>
                         </Grid>

                         <Grid container spacing={2} marginTop={1}>                           
                                      <Grid item md={6}>
                                       <InputLabel>Camera Type</InputLabel> 
                                      <TextField 
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        value={cameraType || ''}
                                        />

                                       </Grid>
                                       <Grid item md={6}>
                                      <InputLabel>Footage</InputLabel> 
                                      <TextField 
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        value={footage || ''}
                                        />

                                       </Grid>
                         </Grid>


                         <Box sx={{ my: 3 }}>
                         <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                         Camera Protocols
                         </Typography>
                         </Box>
                         <Grid container spacing={4} >                           
                                      <Grid item md={12}>
                                      <TableContainer component={Paper}>
                                             <Table>
                                             <TableHead>
                                                  <TableRow>
                                                  <TableCell>S.No</TableCell>
                                                  <TableCell>Protocol Name</TableCell>
                                                  <TableCell>Description</TableCell>
                                                  </TableRow>
                                             </TableHead>


                                             {protocolsData.length > 0 ? (
                                                            <TableBody>
                                                            {protocolsData.map((row, index) => (
                                                            <TableRow key={row.id}>
                                                            <TableCell component="th" scope="row">
                                                                 {index + 1}
                                                            </TableCell>
                                                            <TableCell>{row.IntrusionType}</TableCell>
                                                            <TableCell>{row.Message}</TableCell>
                                                            </TableRow>
                                                            ))}
                                                       </TableBody>
                                                  ) : (
                                                  <TableBody>
                                                  <TableRow>
                                                       <TableCell colSpan={3}>No Data</TableCell>
                                                  </TableRow>
                                                  </TableBody>
                                                  )}



                                             </Table>
                                        </TableContainer>

                                       </Grid>
                         </Grid>


                         <Box sx={{ my: 3 }}>
                         <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                              Monitoring Times
                         </Typography>
                         </Box>

                         <Grid container spacing={4}>                           
                                      <Grid item md={12}>
                                      <TableContainer component={Paper}>
                                             <Table>
                                             <TableHead>
                                                  <TableRow>
                                                  <TableCell>Day</TableCell>
                                                  <TableCell>From Time</TableCell>
                                                  <TableCell>To Time</TableCell>
                                                  </TableRow>
                                             </TableHead>

                                             {monitoringtimings.length > 0 ? (
                                                  <TableBody>
                                                  {monitoringtimings.map((row, index) => (
                                                       <TableRow key={index}>
                                                       {/* <TableCell component="th" scope="row">
                                                            {index + 1}
                                                       </TableCell> */}
                                                       <TableCell>{getDayName(row.Dayno)}</TableCell>
                                                       <TableCell>{row.FromHour}</TableCell>
                                                       <TableCell>{row.ToHour}</TableCell>
                                                       </TableRow>
                                                  ))}
                                                  </TableBody>
                                                  ) : (
                                                  <TableBody>
                                                  <TableRow>
                                                       <TableCell colSpan={3}>No Data</TableCell>
                                                  </TableRow>
                                                  </TableBody>
                                                  )}

                                             </Table>
                                        </TableContainer>

                                       </Grid>
                         </Grid>
                    
                         <Box sx={{ my: 3 }}>
                        
                                   <Grid container spacing={2}>                           
                                             <Grid item md={6}>
                                             <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                                  Archive Times  
                                                  </Typography>

                                             </Grid>
                                             <Grid item md={6}>
                                             <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                                                 Backup Duration Days : 
                                                 {selectedDurationDays || ''}
                                                  </Typography>
                                             </Grid>
                                   </Grid>

                         </Box>

                         <Grid container spacing={4}>                           
                                      <Grid item md={12}>
                                      <TableContainer component={Paper}>
                                             <Table>
                                             <TableHead>
                                                  <TableRow>
                                                  {/* <TableCell>S.No</TableCell> */}
                                                  <TableCell>Day</TableCell>
                                                  <TableCell>From Time</TableCell>
                                                  <TableCell>To Time</TableCell>
                                                  </TableRow>
                                             </TableHead>
                                             {archiveTimings.length > 0 ? (
                                                  <TableBody>
                                                  {archiveTimings.map((row, index) => (
                                                       <TableRow key={row.id}>
                                                       {/* <TableCell component="th" scope="row">
                                                            {index + 1}
                                                       </TableCell> */}
                                                       <TableCell>{getDayName(row.Dayno)}</TableCell>
                                                       <TableCell>{row.FromHour}</TableCell>
                                                       <TableCell>{row.ToHour}</TableCell>
                                                       </TableRow>
                                                  ))}
                                                  </TableBody>
                                                  ) : (
                                                  <TableBody>
                                                  <TableRow>
                                                       <TableCell colSpan={3}>No Data</TableCell>
                                                  </TableRow>
                                                  </TableBody>
                                                  )}

                                             </Table>
                                        </TableContainer>
                                       </Grid>
                         </Grid>
                       
                         <DialogActions>
                          <Button color='error' variant='outlined' onClick={onClose} >Close</Button>
                        </DialogActions>    
  </DialogContent>
</Dialog>

  )
}
DeviceDetails.propTypes = {
  setOpen: PropTypes.func.isRequired
} 