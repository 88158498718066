
import React, { createRef, useEffect, useRef, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { InputAdornment, ListItemText, OutlinedInput, TextareaAutosize, useRadioGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';
import axios from 'src/axios';
import {
  Checkbox,
  CardContent,
  Card,
  List,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  ListItem,
  TableContainer,
  FormControl,
  Paper,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableHead,
} from '@mui/material';
import Page from 'src/components/Page';
import { getCamerasList , getClientList} from 'src/api/analytics';

import { getProtocols , setAddProtocol ,getClientInfo , setSaveProtocol ,getProtocolsInfo ,setProtocolsInfoMessages ,deleteMessage ,setMultiDevicesProtocol,updateDeviceProtocolsInfo} from 'src/api/protocol';
import { Field } from 'formik';
import { CompressOutlined, Label, VpnLock } from '@mui/icons-material';
import { last, set } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import client from 'src/api/clients';
//import { getDevices } from 'src/api/devices';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Protocols= () => {


const [setClient,setSelectedClient] = useState([]);
const [clientName,selectedClientName] = useState([]);
const [cameraList,setCameras] = useState([]);
const [selectedCamesList,setCamsList] = useState([]);
const [selectedCamItems, setCamSelectedItems] = useState([]);


const [protocolList,setProtocols] = useState([]); 
const [open, setOpen] = useState(false);
const [newProtocolname,setNewProtoName] = useState('');
const [selectedProto,setSelectedProtocol] = useState([]);


const [clientInfo,setClientDetails] = useState([]);

// Form Fields

//console.log("selected Cam Items",selectedCamItems);

// police Number 
const [policeno, setPoliceNo] = useState('');

//mobile number 
const [mobileno, setMobileNo] = useState('');

//emil Change

const [email, setEmail] = useState('');

//Fire Number
const [fireNo, setFireNo] = useState('');

// address
const [address, setAddress] = useState('');

// Client ID mandatory 
const [isClientSelected, setIsClientSelected] = useState(false); // State variable to track client selection
const [saveError, setSaveError] = useState(false); // State to track save error

// Add new protocol Error
const [protocolNameError, setProtocolNameError] = useState(false);


// protocol Messages

const [introsionMessages,setIntrosionMessages] = useState([]);
const [message1,setMessage1] = useState('');
const [message2,setMessage2] = useState('');
const [message3,setMessage3] = useState('');


const [errorCams,setErrorCams]=useState('')
const [openError,setOpenError]=useState(false)

const [list,setList]=useState([])
const [header,setHeader]=useState('')

const [deviceHist,setDeviceHist]=useState({})

const [showConfirm,setShowConfirm]=useState(false)

const [protocolError,setProtocolError]=useState('')

const [policenoTextFields, setPolicenoTextFields] = useState(1);

const [activeCameras, setActiveCameras] = useState([])
const [inputValue, setInputValue] = useState([]);
const [checkValue,setCheckValue]=useState(false)

const handleCloseError = ()=>{
  setOpenError(false)
}



const [existingDeviceIntoID,setExistingDevicesIntoID] = useState([])

//console.log("Introsion Messages",existingDeviceIntoID);
//console.log("selectedProto",selectedProto);

const [deviceDataExist,setDeviceDataExist] = useState(false);


const handlePoliceNoChange = (event) => {
  setPoliceNo(event.target.value);
};


const handleMobileChange = (event) => {
  setMobileNo(event.target.value);
};

const handleEmailChange = (event) => {
  setEmail(event.target.value);
};

const handleFireChange = (event) => {
  setFireNo(event.target.value);
};

const handleAddressChange = (event) => {
  setAddress(event.target.value);
};

  useEffect(() => {

    getClientList((response) => {   
      if (response.status === 200) {
        //debugger
        setSelectedClient(response.data)
      }
    })

    getCamerasList((response) => {
      if (response.status === 200) {
        setCameras(response.data)
      }
    })

    getProtocols((response) => {
      if (response.status === 200) {
        setProtocols(response.data)
        //console.log('protocols',response.data);

      }
    })

  },[])

  
  
  useEffect(() => {
    const matchedCameras = cameraList.filter(camera => camera.clientid === clientName.ID);
    //console.log("cameras", matchedCameras);
    setCamsList(matchedCameras)

    if(clientName.ID!==undefined && clientName.ID!==null){
    getClientInfo(clientName.ID,(response) => {
      if (response.status === 200) {
        setClientDetails(response.data);
      }
    })
  }

  },[clientName.ID])

  const jsonToFormData = (data) => {
    let formdata = new FormData()
    for (let x in data) 
      formdata.append(x, data[x])
    return formdata
}

let listHist=[]
  
useEffect(() => {
  
 
    let params={}
  
    if(selectedProto.Id!==undefined && selectedProto.Id!==null && activeCameras.length===0){
       params = {
        device_id: [],
        intrusion_id: selectedProto.Id
      };
    }
    else if(activeCameras.length>0 && (selectedProto.Id!==undefined && selectedProto.Id!==null)){

      //let deviceIds = selectedCamItems.map(item => item.deviceid);
  
      params = {
        device_id: activeCameras,
        intrusion_id: selectedProto.Id
      };
    }
    else if(activeCameras.length>0 && (selectedProto.Id===undefined)){
    
    //  let deviceIds = selectedCamItems.map(item => item.deviceid);
  
    params = {
      device_id: activeCameras,
      intrusion_id: 0
    };
    }
  
    if(Object.keys(params).length){

    //setIntrosionMessages([])
    
    getProtocolsInfo(params,(response) => {
      //debugger
      if (response.status === 200) {
        
        if(response.data.length>0){

            // let devcieids=[...new Set(response.data.map(item=>item.DeviceId))]          
            
            // // logic for displaying messages for non integrated cameras
            // let notIntegrated=selectedCamItems.reduce((acc,current)=>{
            //   if(!devcieids.includes(current.deviceid)){
            //         acc.push(current.deviceid)
            //   }
            //   return acc
            // },[])
            
            // setList(notIntegrated)
            // if(notIntegrated.length>0){ // if cameras are non inegrated from selected camears
            //     if(list.length>0){
            //       //debugger
            //       const nonIntegrated=notIntegrated.filter(item=>list.includes(item)) // need to disply camera only once
            //       if(nonIntegrated.length>0 && nonIntegrated.length===list.length){
            //         setList([]) // if camera already displyed 
            //       }
            //       else if(nonIntegrated.length>0 && nonIntegrated.length!==list.length){
            //         const Integrated=notIntegrated.filter(item=>!list.includes(item)) // remove diplayed camera
            //         setList(Integrated) 
            //       }
            //       else{
            //         setList(notIntegrated) // add all non integrated cameras into list
            //       }
            //     }
            //      else{
            //        setList(notIntegrated) // add all non integrated cameras into list
            //      }
                
            //  }           
             setIntrosionMessages(response.data)
      }


      else{
        setIntrosionMessages([])
        setDeviceDataExist(false);
         
        if(selectedProto.Id){

          let params = {
            device_id: [],
            intrusion_id: selectedProto.Id
          };
        
        getProtocolsInfo(params,(response) => {
            if(response.status===200){
              setIntrosionMessages(response.data)
            }
        })
      }      
    }

      }
    })
    }
  
    if(existingDeviceIntoID.length>0 && (selectedProto.Id!==undefined && selectedProto.Id!==null)){
    if(existingDeviceIntoID[0] === selectedProto.Id){
      setDeviceDataExist(true);
     }
     else{
      setDeviceDataExist(false);
     }
    }

  },[activeCameras,selectedProto.Id])

  

useEffect(() => {
  
      if(introsionMessages.length === 0){
        setMessage1('');
        setMessage2('');
        setMessage3('');

        // const intrusionIds = introsionMessages.map(item => item.IntrusionId);
        // setExistingDevicesIntoID(intrusionIds)
      }
      else{
        if(introsionMessages.length===1){
                setMessage1(introsionMessages[0].Message1);
                setMessage2(introsionMessages[0].Message2);
                setMessage3(introsionMessages[0].Message3);
        }
        else{
          setDeviceDataExist(true)
        }
     }

  },[introsionMessages])

  
  
useEffect(() => {
 // console.log(clientInfo)
    // setFianlClientData([]);
   // debugger
   
    if(clientInfo.length == 1){
      //console.log("clientInfo Length" ,clientInfo)
      if(clientInfo[0].PoliceNo !== null){
        setPoliceNo(clientInfo[0].PoliceNo)
      }
      else{
        setPoliceNo('')
      }

      if(clientInfo[0].MobileNo !== null){
        setMobileNo(clientInfo[0].MobileNo)
      }
      else
      {
        setMobileNo('')
      }
      // setAddress(clientInfo[0].Address)
      if(clientInfo[0].FireNo !== null){
        setFireNo(clientInfo[0].FireNo)

      }
      else{
        setFireNo('')
      }

      if(clientInfo[0].Email !== null){
        setEmail(clientInfo[0].Email)
      }
      else{
        setEmail('')
      }
      if(clientInfo[0].Address !== null){
        setAddress(clientInfo[0].Address)
      }
      else{
        setAddress('')

      }
    }
    else{
      // setFianlClientData([]);

      setPoliceNo('')
      setMobileNo('')
      setAddress('')
      setFireNo('')
      setEmail('')
    }

  },[clientInfo])

//const [clientSelected,setClientSelected]=useState(false)
const handleClient = (_, value) => {
 // console.log(value)
 // debugger
  //let x=setClient.find((option) => option.ID === clientName.ID)

  setActiveCameras([])
  setCheckValue(false)
  if (!value) {
    selectedClientName([]);
    setCamSelectedItems([]);
    setCamsList([]); 
    setPoliceNo('')
    setMobileNo('')
    setAddress('')
    setFireNo('')
    setEmail('')
    setIsClientSelected(false);
    setSaveError(false);
    //setList([])
  } else {
    //setList([])
    setDeviceDataExist(false);
    setCamSelectedItems([]);
    setSelectedProtocol([])
    selectedClientName(value);
    setIsClientSelected(true);
    setLastEmail(false)
    setLastFireNumber(false)
    setLastNumber(false)
    setLastContact(false)
  }
};

const [openAuto,setOpenAuto]=useState(false)

const handleCamera = (event,value)=>{
  let actCams=[];
  if (value.some(obj => obj.hasOwnProperty('selectall'))){
    if(activeCameras.length===selectedCamesList.length){
      setActiveCameras([])
      setCheckValue(false)
    }
    else{
      selectedCamesList.forEach(item=>{
        actCams.push(item.deviceid)
      })
      setActiveCameras(actCams)
      setCheckValue(true)
    }
  }
  else{
    let id=value[0].deviceid;
    if(activeCameras.includes(id)){
      let filterArr = activeCameras.filter(divid => divid !== id);
      setActiveCameras(filterArr);
      if(checkValue){
        setCheckValue(false)
      }
    }
    else{
      setActiveCameras((prv)=>[...prv,id])
    }
  }
}

useEffect(()=>{
  if(activeCameras.length===selectedCamesList.length && activeCameras.length>0){
    setCheckValue(true)
  }
},[activeCameras])


const handleOpen = () => {
  setIsExisted(false)
  setOpen(true);
};
const handleClose = () => {
  setOpen(false);
  setNewProtoName('');
  setProtocolNameError(false);
};

const [isExisted,setIsExisted]=useState(false)

const handleAddNewProtocol = () => {


  if (newProtocolname.trim() === '') {
    setProtocolNameError(true);
  }
  else {
    
    // console.log(protocolList)
    // console.log(newProtocolname)

    let isExisted=protocolList.filter(item=>item.IntrusionType.toLowerCase()===newProtocolname.toLocaleLowerCase())

    //console.log(isExisted)

    if(isExisted.length===0){
    const data = {
      IntrusionType: newProtocolname,
    };
  
    const jsonData = JSON.stringify(data);
  
    setAddProtocol(data, (response) => {
      if(response.status === 200) {
        //console.log('Protocol Name:',response);
        setOpen(false);
        getProtocols((response) => {
          if (response.status === 200) {
            setProtocols(response.data)
            //console.log('protocols',response.data);
    
          }
        })
      }
    })
  
    setOpen(false);
    setProtocolNameError(false);
    }
    else{
      // setOpenError(true)
      // setErrorCams('Protocol Name Existed Already')
      
      //setProtocolError('Protocol Name Existed Already')
      setIsExisted(true)
      setProtocolNameError(true)
    }
  }

  
};

const handleProtocol = (event ,newValue) => {
  //debugger;
   if(newValue){
    setSelectedProtocol(newValue);

   }
   else {
    setSelectedProtocol([]);
    setIntrosionMessages([]);
    setDeviceDataExist(false)
   }
}

// complete Protocol Save 

const handleProtocolSave = () => {

  if (!isClientSelected) {
    setSaveError(true); // Set save error if client is not selected
    return;
  }

  // console.log('Email',email);
const data = {
  ClientId: clientName.ID,
  PoliceNo: policeno,
  FireNo: fireNo,
  Address:address,
  Email: email,
  MobileNo: mobileno
};
  
setSaveProtocol(data, (response) => {
    if(response.status === 200) {
      //console.log('Protocol Name:',response);
     // debugger
      setPoliceNo('')
      setMobileNo('')
      setAddress('')
      setFireNo('')
      setEmail('')

      if(clientName.ID!==undefined && clientName.ID!==null){
        getClientInfo(clientName.ID,(response) => {
          if (response.status === 200) {
            if(response.data !== null){
              setClientDetails(response.data);
            }
          }
        })
      }
    }
  })


}

//Handle Intosion Messages

const handleIntrusionMessageOneChange = (event) => {
    setMessage1(event.target.value);
}
const handleIntrusionMessageTwoChange = (event) => {
  setMessage2(event.target.value);

}
const handleIntrusionMessageThreeChange = (event) => {
  setMessage3(event.target.value);

}

// Handle protocol message Save
const handleProtocolMessageSave = () => {
  
  const deviceIds = selectedCamItems.map(item => item.deviceid);

  if(activeCameras.length !== 0 ){
    //console.log("Devices",deviceIds);
    const data = activeCameras.flatMap(DeviceId => {
        const messages = [message1, message2, message3];
        const filteredMessages = messages.filter(message => message !== null);
        return filteredMessages.map((message, index) => ({
          DeviceId: DeviceId,
          IntrusionId: selectedProto.Id,
          Message: message
        }));
      });
    

    setMultiDevicesProtocol(data,(response)=>{
      if(response.status === 200){
        // need to show alert box
        
        setHeader('')
        setErrorCams('Protocols Added Successfully')
        setOpenError(true)

        HandleProtocolInfo()
        
      }})
    }
  else {
   // debugger
    const body = {
      IntrusionId: selectedProto.Id ,
      Message1: message1,
      Message2: message2,
      Message3: message3
    }

    if (message1 === null || message1 === '') delete body.Message1; if (message2 === null || message2 === '') delete body.Message2; if (message3 === null || message3 === '') delete body.Message3;

    if(Object.keys(body).length){
    setProtocolsInfoMessages( body,(response) =>{
      
      if(response.status === 200 ){
        setOpenError(true)
        setHeader('Protocol Message Added Successfully!!')
        setErrorCams('')
        HandleProtocolInfo()
        // setMessage1('');
        // setMessage2('');
        // setMessage3('');

        //setSelectedProtocol([]);
      }
  
    })
  }
  }  
}
const [newText,setNewText]=useState('')
const addProtocolMessage = (values,deviceId)=>{
   // debugger
    let body=[{
      "DeviceId":parseInt(deviceId),
      "IntrusionId":selectedProto.Id,
      "Message":newText
    }]

    setMultiDevicesProtocol(body,(response) => {
      if (response.status === 200) {
          HandleProtocolInfo();
          //console.log(newMessage)
          setNewMessage(newMessage.filter(item => item.sno !== values))
          setNewText('');
      }
    })
}

const textFieldRefs = useRef([]);

const [editable,setEditable]=useState(false)
const handleEdit = (index, key,IntrusionId,message) => {
  
  const textFieldId = key + '_' + message;
  const textFieldElement = textFieldRefs.current[textFieldId];
  if (textFieldElement) {
    textFieldElement.focus();
    setEditable(true)
  }
};

const HandleProtocolInfo = ()=>{
  
 // let deviceIds = selectedCamItems.map(item => item.deviceid);

  let status=0;
  
  let params = {
    device_id: activeCameras,
    intrusion_id: selectedProto.Id?selectedProto.Id:0
  };
  getProtocolsInfo(params,(response) => {
    if (response.status === 200) {
      status=response.status
      setIntrosionMessages(response.data)
    }
  })
 // callback(status)
}

const deleteProtoMessage = () =>{
  deleteMessage(protoData,(response) => {
    if (response.status === 200) {
      // rendering 
        handleCloseError()
        setprotoData({})
        setShowConfirm(false)  
        HandleProtocolInfo()      
    }
  })
}

const [protoData,setprotoData]=useState({})

// Define handleRemove function
const handleRemove = (index, key) => {
  const data = {
    Id : key
  }
  setprotoData(data)
  setShowConfirm(true)
  setOpenError(true)
  setHeader('Do You Want to Delete Protocol Message?')
  setErrorCams('')

};

// Define handleSave function
const handleSave = (index,id, message,deviceId,intrusionId) => {
 
  if(editable){
    let body={Id:id,Message:message}
    updateDeviceProtocolsInfo(body,(response)=>{
      if(response.status===200){
        setOpenError(true)
        setHeader('Protocol Message Updated Successfully!!')
        setErrorCams('')
        setEditable(false)
      }
    })
  }
  else{
        setOpenError(true)
        setHeader('Add Updated Message to Save!!')
        setErrorCams('')
        //setEditable(false)
  }
};

const [newMessage,setNewMessage]=useState([])


const handleAdd = (DeviceId,messagesForDevice) => {

if(selectedProto.Id){
  //setNewMessage([]);
  const keys = Object.keys(groupMessagesByDeviceId(introsionMessages));
  let filteredMessages=[]
  if (keys.includes(DeviceId.toString())) {
    filteredMessages = introsionMessages.filter(message => message.DeviceId ===parseInt(DeviceId,10));
    // console.log(filteredMessages);
} 

let sno=0;
if (newMessage.length > 0 && newMessage[0].deviceid !== DeviceId) {
  setNewMessage([]);
  sno = filteredMessages.length + 1;
} else if (newMessage.length > 0) {
  sno = newMessage[newMessage.length - 1].sno + 1;
} else {
  sno = filteredMessages.length + 1;
}
  setNewMessage(prevMessages => [
    ...prevMessages,
    {
      deviceid:DeviceId,
      sno: sno,
      message: ''
    }
  ]);
  
  }
else{
    setOpenError(true)
    setHeader('')
    setErrorCams('Select Protocol Information')
}
};


const  groupMessagesByDeviceId= (messages) => {  
  const groupedMessages = {};
  messages.forEach(message => {
    if (!groupedMessages[message.DeviceId]) {
      groupedMessages[message.DeviceId] = [];
    }
    groupedMessages[message.DeviceId].push(message);
  });

  return groupedMessages;
}

const [messages, setMessages] = useState(introsionMessages);


const handleInputChange = (index, value,deviceId) => {
  //debugger

  if(editable){
    let messages=introsionMessages.filter(x=>x.DeviceId===deviceId)
    const indexToUpdate =messages.length===1?introsionMessages.findIndex(obj => obj.DeviceId === deviceId)
                        :messages.length>1?introsionMessages.findIndex(obj => obj === messages[index])
                        :''
  
    if (indexToUpdate !== -1) {
      const updatedIntrosionMessages = [...introsionMessages]; 
      updatedIntrosionMessages[indexToUpdate].Message = value; 
      setIntrosionMessages(updatedIntrosionMessages); // Set the state with the updated array
    }
  }
 
};

const [lastNumber,setLastNumber]=useState(false)
const [lastFireNumber,setLastFireNumber]=useState(false)
const [lastEmail,setLastEmail]=useState(false)
const [lastContact,setLastContact]=useState(false)



const removeNumber = (e,index,type) => {
  e.stopPropagation()
  if(type==='policeNumber'){
    const numbersArray = policeno.split(',');
    numbersArray.splice(index, 1); 
    const updatedNumbers = numbersArray.join(',');
    if(updatedNumbers){
      setPoliceNo(updatedNumbers);
    }
    else{
      setPoliceNo('');
      setLastNumber(true)
    }
   
  }
 else if(type==='fireNo'){
    const numbersArray = fireNo.split(',');
    numbersArray.splice(index, 1); 
    const updatedNumbers = numbersArray.join(',');
    if(updatedNumbers)
      setFireNo(updatedNumbers);
    else{
      setFireNo('')
      setLastFireNumber(true)
    }
 }
 else if(type==='email'){
  const numbersArray = email.split(',');
  numbersArray.splice(index, 1); 
  const updatedNumbers = numbersArray.join(',');
  if(updatedNumbers){
    setEmail(updatedNumbers);
  }
  else{
    setEmail('')
    setLastEmail(true)
  }
 
}
else if(type==='mobile'){
  const numbersArray = mobileno.split(',');
  numbersArray.splice(index, 1); 
  const updatedNumbers = numbersArray.join(',');
  if(updatedNumbers){
    setMobileNo(updatedNumbers);
  }
  else{
    setMobileNo('')
    setLastContact('')
  }
}
};

const [addEmptyBox,setEmptyBox]=useState([
  {
    police:false,
    fire:false,
    email:false,
    contact:false
  }
])

const handleBoxClick = (val)=>{
  //debugger
  if(val==='policeno'){
      if(policeno){
      setPoliceNo(policeno + ',')
      }
      else{
        setEmptyBox(prevState => [
          {
            ...prevState[0], 
            police: true 
          },
          ...prevState.slice(1)
        ]);
        if(lastNumber){
          setLastNumber(false)
        }
      }
  }
  else if(val==='fireno'){
    //setFireNo(fireNo + ',');
    if(fireNo){
      setFireNo(fireNo + ',')
      }
      else{
        setEmptyBox(prevState => [
          {
            ...prevState[0], 
            fire: true 
          },
          ...prevState.slice(1)
        ]);
        if(lastFireNumber){
          setLastFireNumber(false)
        }
      }
  }
  else if(val==='email'){
    //setEmail(email + ',')
    if(email){
      setEmail(email + ',')
      }
      else{
        setEmptyBox(prevState => [
          {
            ...prevState[0], 
            email: true 
          },
          ...prevState.slice(1)
        ]);
        if(lastEmail){
          setLastEmail(false)
        }
      }
  }
  else if(val==='mobileno'){
   // setMobileNo(mobileno + ',')
   if(email){
    setMobileNo(mobileno + ',')
    }
    else{
      setEmptyBox(prevState => [
        {
          ...prevState[0], 
          contact: true 
        },
        ...prevState.slice(1)
      ]);
      if(lastContact){
        setLastContact(false)
      }
    }
  }
}
const [newIndex, setNewIndex] = useState(-1);

const handleNumberChange = (event, index,type) => {
  if(type==='police'){
    const numbersArray = policeno.split(',');
    numbersArray[index] = event.target.value;
    const updatedNumbers = numbersArray.join(',');
    setPoliceNo(updatedNumbers);
  }
  else if(type==='fire'){
    const numbersArray = fireNo.split(',');
    numbersArray[index] = event.target.value;
    const updatedNumbers = numbersArray.join(',');
    setFireNo(updatedNumbers);
  }
  else if(type==='email'){
    const numbersArray = email.split(',');
    numbersArray[index] = event.target.value;
    const updatedNumbers = numbersArray.join(',');
    setEmail(updatedNumbers);
  }
  else if(type==='mobile'){
    const numbersArray = mobileno.split(',');
    numbersArray[index] = event.target.value;
    const updatedNumbers = numbersArray.join(',');
    setMobileNo(updatedNumbers);
  }
};

const handleFilterOptions = (options, params) => {
  const filter = createFilterOptions();
  const filtered = filter(options, params);
  
  // Separate selected and unselected options
  const selectedOptions = filtered.filter(option => activeCameras.includes(option.deviceid));
  const unselectedOptions = filtered.filter(option => !activeCameras.includes(option.deviceid));
  
  // Sort options: selected options first, then unselected
  const sortedOptions = [...selectedOptions, ...unselectedOptions];

  return [{ title: 'Select All', selectall: true }, ...sortedOptions];
};



  return (
<>
<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Protocols
          </Typography>
          <Button variant="contained" to="#" 
         onClick={() => handleOpen()} startIcon={<Iconify icon="eva:plus-fill" />}
          
          >
            Add Protocols
          </Button>

        </Stack>

<Card style={{ marginBottom: '5px' }}>


<CardContent style={{ marginBottom: '-25px' }}>
                 
            <Grid container spacing={2}>                           
                          <Grid item md={4}>
                              <FormControl fullWidth>
                                              
                              <Autocomplete
                                id="tags-outlined-client"
                                options={setClient.map((client, clientIndex) => ({
                                  ...client,
                                  customKey: clientIndex, // Assuming clientIndex is a unique identifier
                                }))}
                                isOptionEqualToValue={(option, value) =>
                                  option.customKey === value.customKey
                                }
                                // options={setClient}
                                getOptionLabel={(option) => `${option.displayname}`}
                                onChange={handleClient}
                                //value={clientName.ID ? setClient.find((option) => option.ID === clientName.ID) : null}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Client"
                                    placeholder="Search Client Name..."
                                    error={saveError && !isClientSelected} // Add error state based on saveError and isClientSelected
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <li {...props} key={option.customKey}>
                                    {option.displayname}
                                  </li>
                                )}
                              />


                                          {(saveError && !isClientSelected) && ( // Show error message if saveError and !isClientSelected
                                            <Typography variant="body2" color="error">
                                              Please select a client.
                                            </Typography>
                                          )}
                                                        
                              </FormControl>
                            </Grid>
                            <Grid item md={4}>
                                <FormControl fullWidth>
                                     
                                      {/* <Autocomplete
                                          multiple
                                          id="tags-outlined-client"
                                          options={selectedCamesList}
                                          getOptionLabel={(option) => `${option.deviceid} ${option.devicename}`}
                                          onChange={handleCamera}
                                          value={selectedCamItems}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Select Cameras"
                                              placeholder="Search Camera Name..."
                                            />
                                          )}
                                        /> */}
                                         <Autocomplete
                                          multiple
                                          id="tags-outlined-client"
                                          options={selectedCamesList}
                                          //getOptionLabel={(option) => `${option.deviceid} ${option.devicename}`}
                                          onChange={handleCamera}
                                          //value={selectedCamItems}
                                          
                                          //value={(checkValue) ? [{ title: 'Select All', all: true }] : inputValue.filter((camera) => activeCameras.includes(camera.deviceid))}
                                          value={[]}

                                          getOptionLabel={(option) => (option.selectall && selectedCamesList.length === activeCameras.length) ? 'Select All' : `${option.deviceid} ${option.devicename}`}

                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="Select Cameras"
                                              placeholder="Search Camera Name..."
                                            />
                                          )}
                                          
                                          disableCloseOnSelect={openAuto}
                                          open={openAuto}
                                          onOpen={() => setOpenAuto(true)}
                                          onClose={() => setOpenAuto(false)}

                                          // filterOptions={(options, params) => {
                                          //   const filter = createFilterOptions();
                                          //   const filtered = filter(options, params);
                                          //   return [{ title: 'Select All', selectall: true }, ...filtered];
                                          // }}

                                          filterOptions={handleFilterOptions}
                                  
                                          renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                              {option.selectall ? (
                                                <>
                                                  <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={checkValue}
                                                    //onChange={() => setCheckValue(!checkValue)}
                                                  />
                                                  {option.title}
                                                </>
                                              ) : (
                                                <>
                                                  <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={activeCameras.includes(option.deviceid)}
                                                    onChange={() => {
                                                      const isChecked = activeCameras.includes(option.deviceid);
                                                      const newActiveCameras = isChecked
                                                        ? activeCameras.filter((deviceId) => deviceId !== option.deviceid)
                                                        : [...activeCameras, option.deviceid];
                                                      setActiveCameras(newActiveCameras);
                                                    }}
                                                  />
                                                  {`${option.deviceid} ${option.devicename}`}
                                                </>
                                              )}
                                            </li>
                                          )}
                                          //getOptionKey={(option) => option.deviceid}
                                          isOptionEqualToValue={(option, value) => option.deviceid === value.deviceid}
                                        />

                                                  
                                                  {/* <FormHelperText style={{ color: 'red' }}>{formErrors[`${index}.CameraView`] || ''}</FormHelperText> */}
                                 </FormControl>

                            </Grid>
                            <Grid item md={4}>
                                <FormControl fullWidth>
                                <Autocomplete
                                    id="tags-outlined-client"
                                    options={protocolList.map((protocol, protocolIndex) => ({
                                      ...protocol,
                                      customKey: protocolIndex, // Assuming customKey is a unique identifier
                                    }))}
                                    isOptionEqualToValue={(option, value) => option.customKey === value.customKey}
                                    getOptionLabel={(option) => `${option.IntrusionType}`}
                                    onChange={handleProtocol}
                                    //value={selectedProto.Id ? protocolList.find((protocol) => protocol.Id === selectedProto.Id) : null}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select Protocol"
                                        placeholder="Search Protocol Name..."
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <li {...props} key={option.customKey}>
                                        {option.IntrusionType}
                                      </li>
                                    )}
                                  />
                                                  
                                                  {/* <FormHelperText style={{ color: 'red' }}>{formErrors[`${index}.CameraView`] || ''}</FormHelperText> */}
                                 </FormControl>

                            </Grid>
            </Grid>
</CardContent> 
<CardContent style={{ marginBottom: '-16px' }}>


    <Grid container spacing={2}>                           
            <Grid item md={3}>
                <InputLabel shrink>
                  Police Number
                </InputLabel>
                <Box
                   sx={{
                    border: '1px solid #b4b4b4',
                    padding: '8px',
                    borderRadius: '8px',
                    minHeight: isClientSelected && clientInfo.length == 1 && policeno && !lastNumber ? 'auto' : '50px', 
                  }}
                  onClick={() => handleBoxClick('policeno')}
                >
                  {!lastNumber && isClientSelected && policeno.split(',').map((policeNumber, index) => (                                      
                    <Box
                      key={index}
                      sx={{
                        marginBottom: 1,
                        width:'70%',
                        backgroundColor:'#E8E8E8',
                        position:'relative',
                        borderRadius: '100px',
                        border:'none',
                        borderColor:'none'
                      }}                   
                    >
                          <TextField
                            placeholder='Add Police Number'
                            value={policeNumber}
                            onClick={(e)=>{
                              e.stopPropagation()
                            }}
                            onChange={(e) => handleNumberChange(e, index,'police')}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                //borderRadius: '90px',
                                border:'none'
                              },
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <IconButton
                              onClick={(e) => removeNumber(e,index,'policeNumber')}                            
                            >
                            <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                          </IconButton>
                         </Box>                   
                    </Box>                                                     
                  ))}

                  
                  {/* {!policeno && isClientSelected && addEmptyBox[0].police && (
                    <Box
                      //key={index}
                      sx={{
                        marginBottom: 1,
                        width:'70%',
                        backgroundColor:'#E8E8E8',
                        position:'relative',
                        borderRadius: '100px',
                        border:'none',
                        borderColor:'none'
                      }}                   
                    >
                          <TextField
                            //className={classes.root}
                            //value={policeno}
                            onClick={(e)=>{
                              debugger
                              //e.preventDefault()
                              e.stopPropagation()
                            }}
                            placeholder='Add Police Number'
                            onChange={(e) => {
                              setPoliceNo(e.target.value)
                            }}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                //borderRadius: '90px',
                                border:'none'
                              },
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <IconButton
                              onClick={(e) => removeNumber(e,0,'policeNumber')}                            
                            >
                            <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                          </IconButton>
                         </Box>                   
                    </Box>
                  )} */}

             
                </Box>
            </Grid>
            
            <Grid item md={3}>
                <InputLabel shrink>
                      Fire Number
                </InputLabel>
                <Box
                  sx={{
                    border: '1px solid #b4b4b4',
                    padding: '8px',
                    borderRadius: '8px',
                    minHeight: isClientSelected && clientInfo.length == 1 && fireNo && !lastFireNumber ? 'auto' : '50px', 
                  }}
                  onClick={() => handleBoxClick('fireno')}
                >
                  {!lastFireNumber && isClientSelected && fireNo.split(',').map((fireNo, index) => (
                    <Box
                      key={index}
                      sx={{
                        marginBottom: 1,
                        width:'70%',
                        backgroundColor:'#E8E8E8',
                        borderRadius: '100px',
                        border:'none',
                        position:'relative',
                        borderColor:'none'
                      }}                   
                    >
                          <TextField
                            placeholder='Add Fire Number'
                            value={fireNo}
                            onClick={(e)=>e.stopPropagation()}
                            onChange={(e) => handleNumberChange(e, index,'fire')}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                borderRadius: 50,
                                border:'none'
                              }
                            }}

                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <IconButton
                              sx={{
                                  position: 'absolute',
                                  top: '-8px',
                                  right: '-8px',                                               
                                  }}
                              onClick={(e) => removeNumber(e,index,'fireNo')}
                            >
                              <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                            </IconButton> 
                          </Box>                     
                    </Box>
                  ))}

                  {/* {!fireNo && isClientSelected && addEmptyBox[0].fire && (
                    <Box
                      sx={{
                        marginBottom: 1,
                        width:'70%',
                        backgroundColor:'#E8E8E8',
                        position:'relative',
                        borderRadius: '100px',
                        border:'none',
                        borderColor:'none'
                      }}                   
                    >
                          <TextField
                            //className={classes.root}
                            //value={policeNumber}
                            onClick={(e)=>{
                              e.stopPropagation()
                            }}
                            placeholder='Add Fire Number'
                            onChange={(e) => {
                              setFireNo(e.target.value)
                            }}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                //borderRadius: '90px',
                                border:'none'
                              },
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <IconButton
                              onClick={(e) => removeNumber(e,0,'fireNo')}                            
                            >
                            <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                          </IconButton>
                         </Box>                   
                    </Box>
                  )} */}

                   
                </Box>
            </Grid>

            <Grid item md={3}>
            <InputLabel shrink>
                      Email
            </InputLabel>
            <Box
                  sx={{
                    border: '1px solid #b4b4b4',
                    padding: '8px',
                    borderRadius: '8px',
                    minHeight: isClientSelected && clientInfo.length == 1 && email && !lastEmail ? 'auto' : '50px'
                  }}
                  onClick={() => handleBoxClick('email')}
                >
                  {!lastEmail && isClientSelected && email.split(',').map((email, index) => (
                    <Box
                      key={index}
                      sx={{
                        marginBottom: 1,
                        width:'70%',
                        backgroundColor:'#E8E8E8',
                        borderRadius: '100px',
                        position:'relative',
                        border:'none'
                      }}                   
                    >
                          <TextField
                            placeholder='Add Email'
                            value={email}
                            onClick={(e)=>e.stopPropagation()}
                            onChange={(e) => handleNumberChange(e, index,'email')}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                borderRadius: 50,
                                border:'none'
                              }
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <IconButton
                              sx={{
                                  position: 'absolute',
                                  top: '-8px',
                                  right: '-8px',                                               
                                  }}
                              onClick={(e) => removeNumber(e,index,'email')}
                            >
                              <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                            </IconButton> 
                          </Box>                     
                    </Box>
                  ))}

                  {/* {!email && isClientSelected && addEmptyBox[0].email && (
                    <Box
                      sx={{
                        marginBottom: 1,
                        width:'70%',
                        backgroundColor:'#E8E8E8',
                        position:'relative',
                        borderRadius: '100px',
                        border:'none',
                        borderColor:'none'
                      }}                   
                    >
                          <TextField
                            onClick={(e)=>{
                              e.stopPropagation()
                            }}
                            placeholder='Add Email'
                            onChange={(e) => {
                              setEmail(e.target.value)
                            }}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                //borderRadius: '90px',
                                border:'none'
                              },
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <IconButton
                              onClick={(e) => removeNumber(e,0,'email')}                            
                            >
                            <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                          </IconButton>
                         </Box>                   
                    </Box>
                  )} */}

                   
            </Box>
            </Grid>

            <Grid item md={3}>
            <InputLabel shrink>
                      Contact Number
            </InputLabel>
            <Box
                  sx={{
                    border: '1px solid #b4b4b4',
                    padding: '8px',
                    borderRadius: '8px',
                    minHeight: isClientSelected && clientInfo.length == 1 && mobileno && !lastContact ? 'auto' : '50px', 
                  }}
                  onClick={() => handleBoxClick('mobileno')}
                >
                  {!lastContact && isClientSelected && mobileno.split(',').map((mobile, index) => (
                    <Box
                      key={index}
                      sx={{
                        marginBottom: 1,
                        width:'70%',
                        backgroundColor:'#E8E8E8',
                        borderRadius: '100px',
                        border:'none',
                        position:'relative',
                      }}                   
                    >
                          <TextField
                            placeholder='Add Contact Number'
                            value={mobile}
                            onClick={(e)=>e.stopPropagation()}
                            onChange={(e) => handleNumberChange(e, index,'mobile')}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                borderRadius: 50,
                                border:'none'
                              }
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                              <IconButton
                                sx={{
                                    position: 'absolute',
                                    top: '-8px',
                                    right: '-8px',                                               
                                    }}
                                onClick={(e) => removeNumber(e,index,'mobile')}
                              >
                                <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                              </IconButton>
                          </Box>                      
                    </Box>
                  ))}

                  {/* {!mobileno && isClientSelected && addEmptyBox[0].contact && (
                    <Box
                      sx={{
                        marginBottom: 1,
                        width:'70%',
                        backgroundColor:'#E8E8E8',
                        position:'relative',
                        borderRadius: '100px',
                        border:'none',
                        borderColor:'none'
                      }}                   
                    >
                          <TextField
                            onClick={(e)=>{
                              e.stopPropagation()
                            }}
                            placeholder='Add Contact Number'
                            onChange={(e) => {
                              setMobileNo(e.target.value)
                            }}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                //borderRadius: '90px',
                                border:'none'
                              },
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <IconButton
                              onClick={(e) => removeNumber(e,0,'mobile')}                            
                            >
                            <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                          </IconButton>
                         </Box>                   
                    </Box>
                  )} */}
                   
            </Box>
            </Grid>

            <Grid item md={3}>
            <InputLabel shrink>
                      Address
            </InputLabel>
                                <TextField
                                  fullWidth
                                  value={address}
                                  onChange={handleAddressChange}
                                  InputLabelProps={{
                                    shrink: !!address,
                                  }}
                                  sx={{
                                    '& input': {
                                      //height: '10px', 
                                      fontSize: '14px',
                                    }
                                  }}
                                />

            </Grid>
                       
    </Grid>

</CardContent> 
<DialogActions>
                          {/* <Button color='error' variant='outlined' >Clear</Button> */}
                          <LoadingButton type="submit"  onClick={handleProtocolSave} variant='outlined'>Save</LoadingButton>
</DialogActions>      


 
</Card>

<Card >



  <CardContent style={{ marginBottom: '-16px' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Messages
        </Typography>
        
        

      </Stack>

              { deviceDataExist && activeCameras.length !== 0  ? (
              
              Object.entries(groupMessagesByDeviceId(introsionMessages)).map(([deviceId, messagesForDevice]) => (
                <div key={deviceId}>
                  <Typography variant="h6">{deviceId}</Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>S.No</TableCell>
                          <TableCell>Message</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {messagesForDevice.map((message, index) => (
                          <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell style={{ width: '100%' }}>
                              <TextField
                                fullWidth
                                value={message.Message}                              
                                id={deviceId+'_'+message.Message}
                                inputRef={(el) => (textFieldRefs.current[message.DeviceId + '_' + message.Message] = el)}                             
                                onChange={(e) => handleInputChange(index, e.target.value,message.DeviceId)}
                              />
                            </TableCell>
                            <TableCell>
                              <Button variant='outlined' onClick={() => handleRemove(index, message.Id)}>Remove</Button>
                              <Button variant='outlined' onClick={() => handleEdit(index, message.DeviceId, message.IntrusionId,message.Message)}>Edit</Button>
                              <Button variant='outlined' onClick={() => handleSave(index,message.Id,message.Message, message.DeviceId, message.IntrusionId)}>Save</Button>
                            </TableCell>
                          </TableRow>
                        ))}
                        {newMessage.length>0?newMessage[0].deviceid===deviceId.toString() && newMessage.map((values,idx)=>(
                           <TableRow key={idx}>
                              <TableCell>{values.sno}</TableCell>
                              <TableCell style={{ width: '100%' }}>
                              <TextField
                                fullWidth                              
                                value={newText}
                                onChange={(e)=>setNewText(e.target.value)}
                              />
                              </TableCell>
                              <TableCell>
                              <Button variant='outlined' onClick={(prv)=>{
                                setNewMessage(prv =>
                                  prv.filter(entry => entry.sno !== values.sno)
                                )
                              }}>Remove</Button>
                              <Button variant='outlined' onClick={()=>addProtocolMessage(values.sno,deviceId)}>Save</Button>
                              </TableCell>
                           </TableRow>
                        )):''}
                      </TableBody>
                    </Table>
                    
                  </TableContainer>           
                    <div style={{ textAlign: 'right' }}>
                        <Button variant="contained"  onClick={() => handleAdd(deviceId,messagesForDevice)}>Add New Message</Button>
                    </div>
                    {/* <DialogActions>
                        <LoadingButton type="submit" onClick={handleProtocolMessageSave} variant='outlined'>Save</LoadingButton>
                    </DialogActions> */}
                </div>
              ))

              ) : (
                activeCameras.length !== 0 && selectedProto.Id ? (
                  <>
                  {/* {devicename?<Typography variant="h6">{devicename}</Typography>:''} */}
                  <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item md={10}>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          label="Message"
                          value={message1}
                          onChange={handleIntrusionMessageOneChange}
  
                        />
                      </FormControl>
                   
                    </Grid>
                  </Grid>
                    
                    <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item md={10}>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          label="Message"
                          value={message2}
                          onChange={handleIntrusionMessageTwoChange}
  
                        />
                      </FormControl>
                    </Grid>
                    </Grid>
                    
                    <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                    <Grid item md={10}>
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          label="Message"
                          value={message3}
                          onChange={handleIntrusionMessageThreeChange}
  
                        />
                      </FormControl>
                    </Grid>
                    </Grid>
  
                  <DialogActions>
                <LoadingButton type="submit" onClick={handleProtocolMessageSave} variant='outlined'>Save</LoadingButton>
                </DialogActions>
                </>
                ) : null 
              )} 

              {activeCameras.length === 0 && selectedProto.Id && (
                    <>
                      <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                        <Grid item md={10}>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              label="Message"
                              value={message1}
                              onChange={handleIntrusionMessageOneChange}

                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                        <Grid item md={10}>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              label="Message"
                              value={message2}
                              onChange={handleIntrusionMessageTwoChange}

                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" spacing={2} style={{ marginBottom: '10px' }}>
                        <Grid item md={10}>
                          <FormControl fullWidth>
                            <TextField
                              fullWidth
                              label="Message"
                              value={message3}
                              onChange={handleIntrusionMessageThreeChange}

                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  )}

              {activeCameras.length === 0 && selectedProto.Id && (
              <>
                          
              <DialogActions>
              <LoadingButton type="submit" onClick={handleProtocolMessageSave} variant='outlined'>Save</LoadingButton>
              </DialogActions>
              </>
                  )}
  </CardContent>
</Card>



{/* add protocol popup  */}
<Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
  <DialogTitle>Add protocol</DialogTitle>
  
    {/* Add camera form fields */}
    <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="protocolName"
                label="Protocol Name"
                type="text"
                fullWidth
                value={newProtocolname}
                onChange={(e) => {
                  setNewProtoName(e.target.value);
                  setProtocolNameError(false); // Reset error state when typing
                }}
                error={protocolNameError}
                helperText={protocolNameError && !isExisted ? 'Protocol Name cannot be empty' :protocolNameError && isExisted ? 'Protocol Name Already Existed':''}
                required
              />
              {protocolError && <Typography color="error" variant="body2" align="left" marginTop={1}>{protocolError}</Typography>}
  </DialogContent>
  <DialogActions>

            <Button color='error'  onClick={handleClose} variant='outlined' >Close</Button>
          <LoadingButton type="submit" onClick={handleAddNewProtocol}  variant='outlined'>Save</LoadingButton>
  </DialogActions>
</Dialog>


<Dialog open={openError} onClose={handleCloseError} maxWidth="xs" fullWidth>
  <DialogTitle>{header}</DialogTitle>
    <DialogContent>
             <Typography variant='h6'>{errorCams}</Typography>
  </DialogContent>
  <DialogActions>
            {showConfirm && <Button variant="contained" onClick={deleteProtoMessage}>Ok</Button>}
            <Button color='error' onClick={handleCloseError} variant='outlined' >Close</Button>
  </DialogActions>
</Dialog>

</>
  );
};

const ArrowBox = ({ field, entry, index, removeEntry }) => {
  const containerStyle = {
    position: 'relative',
    width: '100%',
    marginBottom: '8px', 
  };

  const boxStyle = {
    backgroundColor: '#90ee90',
    borderRadius: '4px',
    border: '0px solid #ccc',
    padding: '10px',
    boxSizing: 'border-box', 
    width: '100%',
  };

  const crossStyle = {
    position: 'absolute',
    right: '2px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: 'grey',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <div style={boxStyle}>
        <Typography variant="body1" style={{ textAlign: 'left', width: '100%' }}>
          {entry}
        </Typography>
        <IconButton
          onClick={() => removeEntry(field, index)}
          style={crossStyle}
        >
          <ClearIcon style={{ width: '15px' }} />
        </IconButton>
      </div>
    </div>
  );
};



export default Protocols;