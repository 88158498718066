import { forwardRef, useState } from 'react';
import { 
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    Stack
 } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

import DrawToolBar from './DrawToolBar';

import { useRef } from "react";
import useCanvas from "src/hooks/useCanvas";
import DrawCanvas from './DrawCanvas';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeviceDrawDialog() {
    const [open, setOpen] = useState(false);
    const [alignment, setAlignment] = useState('Polygon');

    // const inputElement = useRef(null)
    // const [
    //   handleOnMouseMove,
    //   handleMouseRelase,
    //   handleClick
    // ] = useCanvas(inputElement, "https://images.globalindustrial.com/images/enlarge/695511.jpg?t=1628284125430")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    


    return (
        <>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Sound
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </AppBar>


        <DrawCanvas/>
         

      </Dialog>
    </>
    )
}