import axios from 'src/axios';
import { getURL, jsonToFormData } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';

 


const getMonthlyPriceDetails = (data,callback) => {
    const { year } = data; 

    axios({
        method: 'get',
        url: `${getURL('/dashboard/monthly-bill')}?year=${year}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getMonthlyActClientDetails = (data,callback) => {
    const { year } = data; 

    axios({
        method: 'get',
        url: `${getURL('/dashboard/monthly-active-devices')}?year=${year}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getYearBilingReport= (data,callback) => {
    const { year } = data; 

    axios({
        method: 'get',
        url: `${getURL('/dashboard/monthly-bill')}?year=${year}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


export { getMonthlyPriceDetails ,getMonthlyActClientDetails ,getYearBilingReport}