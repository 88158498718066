import { useState, useEffect } from 'react';
import { getURL } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';
import axios from '../axios'

const useNotifications = () => {
    const [alldata, setData] = useState({
        isGetData: true,
        response: [],
        data: [{ VideoUrl:null, DeviceName:"" }]
    })

    const jsonToParam = (data) => {
        return Object.keys(data).map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
        }).join('&')
    }

    useEffect(() => {
        const httpURL = window.location.href
        const urlArray = httpURL.split('/')
        const auditId = urlArray[urlArray.length-1] != 'notifications' ? urlArray[urlArray.length-1] : 0
        if (alldata.isGetData) {
            let bodyContent = jsonToParam({
                "audits_id": auditId,
            });
            let url = `client-info/get_client_audits?${bodyContent}`
            if (alldata.isGetData) {
                axios({
                    method: 'get',
                    url: getURL(url, true),
                    validateStatus: (status) => handleResponse(status)
                }).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setData({
                            ...alldata,
                            response: response.data,
                            data: response.data.length ? response.data[0] : [{ VideoUrl:null }],
                            isGetData: false                         
                        })
                    }
                })
            }
        }
    }, [alldata.isGetData])

    return {
        alldata
    }
}

export default useNotifications