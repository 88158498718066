import { LoadingButton } from '@mui/lab';
import {
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Switch,
  FormControl,
  Typography,
  TextField,
  Card,
  Select,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { setNewDevice } from 'src/api/devices';
import Iconify from 'src/components/Iconify';

export default function ClientDeviceForm() {

  const [formCount,setFormCount]=useState(1)

  const [dialogOpen, setDialogOpen] = useState(false);
   
  const initialFormState = {
    CameraView: '',
    TimezoneId: '',
    devicename: '',
    MainStream: '',
    substream: '',
    ReStream: '',
    LocalDeviceName: '',
    fps: '',
    Footage: '',
    CameraMake: '',
    CameraModel: '',
    LocalHTTPURL: '',
    PublicHTTPURL: '',
    ServerUrl:'http://192.168.30.105:9002/',
    Serverport:'8080',
    Category:'', //  need to check
    IsAgentBased:0, //  need to check
    archieve:1,
    BackupDurationDays:30,
    HaveSpeaker: 1,
    clientid:0
  };

  const [formErrors, setFormErrors] = useState({});

  const FormData = (form, index) => {
    return (
      <Card sx={{ p: 2, mb: 2 }} key={index}>
        <Typography
          variant="subtitle2"
          sx={{
            my: 2, // margin top and bottom
            padding: '8px', // optional padding
            borderRadius: '4px', // optional border radius
            fontWeight: 'bold',
            fontSize: '1.2rem',
          }}
        >
          Basic Info
        </Typography>

        <Grid container spacing={2}>
          <Grid item md={2}>
            <FormControl fullWidth>
              <InputLabel id={`camera-view-label-${index}`}>Camera View</InputLabel>
              <Select
                label="Camera View"
                labelId={`camera-view-label-${index}`}
                id={`camera-view-${index}`}
                name="CameraView"
                value={form.CameraView}
                onChange={(e) => handleInputChange(index, e)}
              >
                <MenuItem value="In Door">In Door</MenuItem>
                <MenuItem value="Out Door">Out Door</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={2}>
            <TextField
              fullWidth
              label="Camera Name"
              name="devicename"
              value={form.devicename}
              onChange={(e) => handleInputChange(index, e)}
              error={!!formErrors[index]?.devicename}
              helperText={formErrors[index]?.devicename}
            />
          </Grid>

          <Grid item md={2}>
            <TextField
              fullWidth
              label="Main Stream"
              name="MainStream"
              value={form.MainStream}
              onChange={(e) => handleInputChange(index, e)}
            />
          </Grid>

          <Grid item md={2}>
            <TextField
              fullWidth
              label="Sub Stream"
              name="substream"
              value={form.substream}
              onChange={(e) => handleInputChange(index, e)}
              error={!!formErrors[index]?.substream}
              helperText={formErrors[index]?.substream}
            />
          </Grid>

          <Grid item md={2}>
            <TextField
              fullWidth
              label="ReStream"
              name="ReStream"
              value={form.ReStream}
              onChange={(e) => handleInputChange(index, e)}
            />
          </Grid>

          <Grid item md={2}>
            <TextField
              fullWidth
              label="Local Camera Name"
              name="LocalDeviceName"
              value={form.LocalDeviceName}
              onChange={(e) => handleInputChange(index, e)}
            />
          </Grid>

          <Grid item md={2}>
            <TextField
              fullWidth
              label="Frames Per Second (fps)"
              type="number"
              name="fps"
              value={form.fps}
              onChange={(e) => handleInputChange(index, e)}
            />
          </Grid>

          <Grid item md={2}>
            <FormControl fullWidth>
              <InputLabel id={`footage-label-${index}`}>Footage</InputLabel>
              <Select
                label="Footage"
                labelId={`footage-label-${index}`}
                id={`footage-${index}`}
                name="Footage"
                value={form.Footage}
                onChange={(e) => handleInputChange(index, e)}
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="0">Image</MenuItem>
                <MenuItem value="1">Video</MenuItem>
              </Select>
              {formErrors[index]?.Footage && (
                <Typography variant="caption" color="error">
                  {formErrors[index]?.Footage}
                </Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Typography variant="subtitle2" sx={{ my: 2 }}>
          Camera Configuration
        </Typography>

        <Grid container spacing={2}>
          <Grid item md={2}>
            <TextField
              fullWidth
              label="Camera Make"
              name="CameraMake"
              value={form.CameraMake}
              onChange={(e) => handleInputChange(index, e)}
            />
          </Grid>

          <Grid item md={2}>
            <TextField
              fullWidth
              label="Camera Model"
              name="CameraModel"
              value={form.CameraModel}
              onChange={(e) => handleInputChange(index, e)}
            />
          </Grid>

          <Grid item md={2}>
            <TextField
              fullWidth
              label="Local HTTP URL"
              name="LocalHTTPURL"
              value={form.LocalHTTPURL}
              onChange={(e) => handleInputChange(index, e)}
            />
          </Grid>

          <Grid item md={2}>
            <TextField
              fullWidth
              label="Public HTTP URL"
              name="PublicHTTPURL"
              value={form.PublicHTTPURL}
              onChange={(e) => handleInputChange(index, e)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item>
            <Button variant="outlined" color="error" onClick={(e)=>clearForm(e,index)}>
              Clear
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={(e)=>SaveCameraInfo(e,index)}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Card>
    );
  };
 
  const [forms, setForms] = useState([initialFormState]);

  const addNewForm = () => {
    setForms([...forms, initialFormState]);
    setFormCount(formCount+1)
  };

  const handleInputChange = (index, event) => {
    //debugger
    const { name, value } = event.target;

    const updatedForms = forms.map((form, i) =>
      i === index ? { ...form, [name]: value } : form
    );
    setForms(updatedForms);
  };

 

  const clearForm = (event,index)=>{
    // const UpdatedForm=forms.filter((_,i)=>i!==index)
    // if(UpdatedForm.length!==0){
    //   setForms(UpdatedForm);
    // }
    const updatedForms = forms.map((form, i) =>
      i === index ? initialFormState : form
    );
    setForms(updatedForms);
  }

  const SaveCameraInfo = (event,index)=>{
    //console.log(formErrors)
    event.preventDefault();

    let data = forms[index]
    let timezone_id=localStorage.getItem('timezone_id')
    let client_id=localStorage.getItem('client_id')
    if(timezone_id && client_id){
      data.TimezoneId=timezone_id
      data.clientid=client_id
      
      const newFormErrors = { ...formErrors };
      let hasErrors = false;
  
      if (!data.devicename) {
        newFormErrors[index] = newFormErrors[index] || {};
        newFormErrors[index].devicename = 'Camera Name is required';
        hasErrors = true;
      }
  
      if (!data.substream) {
        newFormErrors[index] = newFormErrors[index] || {};
        newFormErrors[index].substream = 'Sub Stream is required';
        hasErrors = true;
      }
  
      if (!data.Footage) {
        newFormErrors[index] = newFormErrors[index] || {};
        newFormErrors[index].Footage = 'Footage is required';
        hasErrors = true;
      }

      if(!hasErrors){
        debugger
        console.log([data])
        if(!data.fps){
          data.fps=0;
        }
        setFormErrors({})       
        setNewDevice((response)=>{
          console.log(response)
          if(response.status===200){
            setDialogOpen(true);

            let len = Object.keys(forms).length
             if(len===1){
              const updatedForms = forms.map((form, i) =>
                    i === index ? initialFormState : form
                  );
                  setForms(updatedForms);
             }
             else if(len!==0){
                const UpdatedForm=forms.filter((_,i)=>i!==index)
                if(UpdatedForm.length!==0){
                  setForms(UpdatedForm);
                  setFormCount(formCount-1)
                }
              }
           
            // if(forms.length===1 || index===0){
            //   // clear form
            //   const updatedForms = forms.map((form, i) =>
            //     i === index ? initialFormState : form
            //   );
            //   setForms(updatedForms);
            // }
            // else{
            //   let len = Object.keys(forms).length
            //   if(len!==0){
            //     let index=len-1
            //     const UpdatedForm=forms.filter((_,i)=>i!==index)
            //     if(UpdatedForm.length!==0){
            //       setForms(UpdatedForm);
            //       setFormCount(formCount-1)
            //     }
            //   }
            // }
          
          }
        },[data])
      
      }
      else{
        setFormErrors(newFormErrors);
        return;
      }
    }
  }

  const removeForm = ()=> {
      let len = Object.keys(forms).length
      console.log(len)
      if(len!==0){
        let index=len-1
        const UpdatedForm=forms.filter((_,i)=>i!==index)
        if(UpdatedForm.length!==0){
          setForms(UpdatedForm);
          setFormCount(formCount-1)
        }
      }
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Box sx={{ marginBottom: 2, display: 'flex', justifyContent: 'flex-end' }}>
      <Button variant='outlined' color='error' sx={{marginRight:2,fontSize: 20 }} onClick={removeForm}> - </Button>
        <Button variant="contained" onClick={addNewForm}>
        + Add Cameras {formCount > 1 && `${formCount > 1 ? ' : ' : ''}${formCount}`}
        </Button>
      </Box>
      {forms.map((form, index) => (
        <Box key={index}>{FormData(form, index)}</Box>
      ))}

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{"Devices Added Successfully"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
             device have been added successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}