import { 
    TableContainer, 
    Table,
    TableHead,
    TableRow,
    TableCell,
    Card,
    TableBody,
    Typography,
    Link
} from '@mui/material';
import Iconify from 'src/components/Iconify';



const columns = [
    { id: 'sno', label: 'S No', isLink: false},
    { id: 'ClientCreatedOn', label: 'Violation Time', isLink: false },
    { id: 'SnapshotUrl', label: 'Snapshot', isLink: true },
    { id: 'VideoUrl', label: 'Video', isLink: true },    
];  


export default function ReportDetailsTable ({title, rows}) {
    return (
        <Card>
          <Typography sx={{p: 2}} variant='h6'>{title}</Typography>
            <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow >
              {columns.map((column) => (
                <TableCell
                sx={{backgroundColor: (theme) => theme.palette.primary.main, color: "white"}}
                  key={column.id}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? rows.map((row, index) => {
                return (
                  <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                    {columns.map((column) => {
                      const value = column.id === "sno" ? index+1 : row[column.id];
                      return (
                        <TableCell key={column.id}>{column.isLink ? <Link target='blank' href={value}><Iconify  width={24} height={24} icon={column.id === "SnapshotUrl" ? "eva:image-outline" : 'eva:play-circle-outline'} /></Link> : value}</TableCell>
                      );
                    })}
                  </TableRow>
                );
              }) : (<TableRow hover tabIndex={-1}>
                  <TableCell colSpan={columns.length} align='center'>--No Data--</TableCell>
              </TableRow>) }
          </TableBody>
        </Table>
      </TableContainer>
        </Card>       
    )
}