import React from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { getEmailVerification } from 'src/api/addAgent';

class VerificationPage extends React.Component {
  state = {
    verificationMessage: ''
  };


  
  handleVerify = () => {
    const jsonToFormData = (data) => {
        let formdata = new FormData()
        for (let x in data)
          formdata.append(x, data[x])
        return formdata
      }

    const url = window.location.href;
    const params = new URLSearchParams(url.split('?')[1]);
    const token = params.get('token');

    if (!token) {
      console.error('Token not found in URL');
      return;
    }

    // const formData = new URLSearchParams();
    // formData.append('VerificationToken', token);
    // formData.append('VerificationStatus', 1);

    // const body = formData.toString();
    console.log(token)
    const body = { 
        VerificationToken : token,
        VerificationStatus : 1
      }
    console.log("before API",body)
    getEmailVerification(body,(response) => {
      if (response.status === 200) {
        this.setState({ verificationMessage: 'Your email was verified and redirecting to login page.' }, () => {
          setTimeout(() => {
            window.location.href = 'http://ai.vdointel.com:8997/login'; // Redirect after 3 seconds
          }, 2000); // Redirect after 3 seconds
        });
      } else {
        console.error('Verification failed:', response);
      }
    });
  };

  render() {
    const verifyBoxStyle = {
      border: '1px solid #ccc',
      padding: '50px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
      marginBottom: 'auto'
    };

    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div style={verifyBoxStyle}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={12} className="text-center">
              <Paper elevation={3} style={{ padding: '20px' }}>
                <div style={{ fontSize: '1.2rem', marginBottom: '20px' }}>
                  Click the button below to verify your email. 
                 
                </div>
                <Button
                  variant="contained"
                  
                  style={{ backgroundColor: '#9370db', borderColor: '#9370db' }}
                  onClick={this.handleVerify}
                >
                  Verify
                </Button>
               
                {this.state.verificationMessage && (
                  <Alert severity="success" style={{ marginTop: '20px' }}>
                    {this.state.verificationMessage}
                  </Alert>
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default VerificationPage;
