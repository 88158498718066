


import {  Container, Card, Stack, TextField, FormControl, InputLabel, Select, MenuItem, Button, Typography, CardContent, Box, Grid } from '@mui/material';
// components
import Page from '../components/Page';
import { ReportTable } from 'src/sections/reports';
import { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { getCameras, getEmployees, getLocations, getReportData } from 'src/api/jkoReports';
import { format } from "date-fns";


// ----------------------------------------------------------------------

const DETAILS_HEADS = ['Present', "Absent", "Sleeping"]

const camColumns = [
  {
    label: 'S No',
    dataKey: 'sno',
  },
  {
    label: 'Location Name',
    dataKey: 'locationname',
  },
  {
    label: 'Security Name',
    dataKey: 'security_name',
  },
  {
    label: 'Security Status',
    dataKey: 'security_status',
  },
  {
    label: 'From Time',
    dataKey: 'starttime',
  },
  {
    label: 'To Time',
    dataKey: 'endtime',
  },
  {
    label: 'Duration',
    dataKey: 'duration',
  },
  {
    label: 'Video',
    dataKey: 'videourl',
  }
];

const loColumns = [
  {
    label: 'S No',
    dataKey: 'sno',
  },
  {
    label: 'Camera',
    dataKey: 'devicename',
  },
  {
    label: 'Security Name',
    dataKey: 'securityname',
  },
  {
    label: 'Security Status',
    dataKey: 'security_status',
  },
  {
    label: 'From Time',
    dataKey: 'starttime',
  },
  {
    label: 'To Time',
    dataKey: 'endtime',
  },
  {
    label: 'Duration',
    dataKey: 'duration',
  },
  {
    label: 'Video',
    dataKey: 'videourl',
  }
];

const empColumns = [
  {
    label: 'S No',
    dataKey: 'sno',
  },
  {
    label: 'Location',
    dataKey: 'locationname',
  },
  {
    label: 'Camera',
    dataKey: 'devicename',
  },
  {
    label: 'Security Status',
    dataKey: 'security_status',
  },
  {
    label: 'From Time',
    dataKey: 'starttime',
  },
  {
    label: 'To Time',
    dataKey: 'endtime',
  },
  {
    label: 'Duration',
    dataKey: 'duration',
  },
  {
    label: 'Video',
    dataKey: 'videourl',
  }
];


export default function ReportsInfc() {

  const urlParams = new URLSearchParams(window.location.search);
  const rDate = urlParams.get('reportDate')
  const qShift = urlParams.get('shift')
  const pA = urlParams.get('attend')

  const [filter, setFilter] = useState({
    type: urlParams.get('type') ? urlParams.get('type') : "employee",
    employeeID: urlParams.get('employeeID') || "",
    locationId: urlParams.get('locationId') || "",
    cameraId: urlParams.get('cameraId') || "",
    reportDate: dayjs(rDate ? new Date(rDate) : new Date()),
    shift: qShift ? qShift : "night",
    attend: pA ? pA : "present"
  })

  const [employeesData, setEmpData] = useState([])
  const [locationData, setLocData] = useState([])
  const [camerasData, setCamData] = useState([])

  useEffect(() => {
    getEmployees((response) => {
      if (response.status === 200) {
        setEmpData([...response.data])
      }
    })

    getLocations((response) => {
      if (response.status === 200) {
        setLocData([...response.data])
      }
    })

    getCameras((response) => {
      if (response.status === 200) {
        setCamData([...response.data])
      }
    })

  }, [])

  // console.log(employeesData)

  const handleType = (event) => {
    setFilter({...filter, type: event.target.value})
  }

  const handleEmployee = (event) => {
    setFilter({...filter, employeeID: event.target.value})
  }

  const handleLocation = (event) => {
    setFilter({...filter, locationId: event.target.value})
  }
  
  const handleCamera = (event) => {
    setFilter({...filter, cameraId: event.target.value})
  }

  const handleReportDate = (date) => {
    setFilter({...filter, reportDate: date})
  }

  const handleShift = (event) => {
    setFilter({...filter, shift: event.target.value})
  }

  const handleAttend = (event) => {
    setFilter({...filter, attend: event.target.value})
  }

  const [filterDetails, setFilterDetails] = useState({
    presentList: {},
    absentList: {},
    bothList: {},
    type: null,
    attend: null,
    date: null,
    shift: null,
    securityName: null,
    locationName: null,
    cameraName: null,
    productivity: null,
    totalPresent: "00:00:00",
    totalAbsent: "00:00:00",
    totalSleep: "00:00:00",
  })

  const resetData = () => {
    setFilterDetails({
      ...filterDetails, 
      presentList: [],
      absentList: [],
      bothList: [],
      type: filter.type,
      attend: filter.attend,
      date: format(new Date(filter.reportDate), 'dd-MM-yyyy'),
      shift: filter.shift,
      securityName: filter.employeeID,
      locationName: null,
      cameraName: null,
      productivity: 0,
      totalPresent: "00:00:00",
      totalAbsent: "00:00:00",
      totalSleep: "00:00:00",
    })
  }

  const getFilterData = () => {


    const date = new Date(filter.reportDate)
    const wd = date.getDay()
    
    let f_time = '08:00:00'
    let t_time = '08:00:00'
    if (filter.shift === 'day') {
        f_time = '08:00:00'
        t_time = '20:00:00'
    } else if (filter.shift === 'night') {  
        f_time = '20:00:00'
        t_time = '08:00:00'
    }


    const from_date = `${format(date, 'yyyy-MM-dd')} ${f_time}`
    if (filter.shift !== 'day')
      date.setDate(date.getDate() + 1);
    const to_date = `${format(date, 'yyyy-MM-dd')} ${t_time}`
    const nonMonDays = [1, 2, 3, 4, 5]
    
    // if (nonMonDays.includes(wd) && filter.shift === 'day') {
    //   resetData()

      // setFilterDetails({
      //   ...filterDetails, 
      //   presentList: [],
      //   absentList: [],
      //   bothList: [],
      //   type: filter.type,
      //   attend: filter.attend,
      //   date: format(new Date(filter.reportDate), 'dd-MM-yyyy'),
      //   shift: filter.shift,
      //   securityName: filter.employeeID,
      //   locationName: null,
      //   productivity: 0,
      //   totalPresent: "00:00:00",
      //   totalAbsent: "00:00:00",
      //   totalSleep: "00:00:00",
      // })
      
    // }
    //  else {
      // console.log('filter', filter)
      getReportData({
        type: filter.type,
        Id: filter.type === "employee" ? filter.employeeID : (filter.type === "location" ? filter.locationId : filter.cameraId),
        startime: from_date,
        endtime: to_date
      }, (response) => {
        if (response.status === 200) {

          if (response.data) {
            let presentData = {}
            let absentData = {}
            let bothData = {}
            let sleepingData = {}

            if (filter.type === "location") {
              if (Object.keys(response.data).includes('present_list')) {
                  Object.keys(response.data.present_list).map(item => {
                    presentData[item] = response.data.present_list[item].details
                  })
              }
              if (Object.keys(response.data).includes('absent_list')) {
                  Object.keys(response.data.absent_list).map(item => {
                    absentData[item] = response.data.absent_list[item].details
                  })
              }
              if (Object.keys(response.data).includes('sleeping_list')) {
                  Object.keys(response.data.sleeping_list).map(item => {
                    sleepingData[item] = response.data.sleeping_list[item].details
                  })
              }
              if (Object.keys(response.data).includes('both_list')) {
                  Object.keys(response.data.both_list).map(item => {
                    bothData[item] = response.data.both_list[item].details
                  })
              }
            } else if (filter.type === "camera") {
              if (Object.keys(response.data).includes('present_list')) {
                presentData['camera'] = response.data.present_list
              }
              if (Object.keys(response.data).includes('absent_list')) {
                absentData['camera'] = response.data.absent_list
              }
              if (Object.keys(response.data).includes('sleeping_list')) {
                sleepingData['camera'] = response.data.sleeping_list
              }
              if (Object.keys(response.data).includes('both_list')) {                
                bothData['camera'] = response.data.both_list              
              }
            }else {
                presentData['employee'] = Object.keys(response.data).includes('present_list') ? response.data.present_list: presentData
                absentData['employee'] = Object.keys(response.data).includes('absent_list') ? response.data.absent_list: absentData
                sleepingData['employee'] = Object.keys(response.data).includes('sleeping_list') ? response.data.sleeping_list: sleepingData
                bothData['employee'] = Object.keys(response.data).includes('both_list') ? response.data.both_list: bothData
            }

            // console.log('absentData', absentData)
            setFilterDetails({
              ...filterDetails, 
              presentList: presentData,
              absentList: absentData,
              sleepingList: sleepingData,
              bothList: bothData,
              type: filter.type,
              attend: filter.attend,
              date: format(new Date(filter.reportDate), 'dd-MM-yyyy'),
              shift: filter.shift,
              securityName: filter.employeeID,
              locationName: Object.keys(response.data).includes('location_name') && response.data['location_name'],
              cameraName: Object.keys(response.data).includes('camera_name') && response.data['camera_name'],              
              productivity: Object.keys(response.data).includes('location_percent') ? response.data.location_percent : Object.keys(response.data).includes('security_percent') ? response.data.security_percent: Object.keys(response.data).includes('camera_percent') && response.data.camera_percent,
              totalPresent: Object.keys(response.data).includes('total_present_duration') && response.data['total_present_duration'],
              totalAbsent: Object.keys(response.data).includes('total_absent_duration') && response.data['total_absent_duration'],
              totalSleep: Object.keys(response.data).includes('total_sleeping_duration') && response.data['total_sleeping_duration'],
              profileImage: Object.keys(response.data).includes('security_image') ? response.data.security_image : ""

            })
          } else {
            resetData()
          }          
        }
      })
    // }   
  }

  useEffect(() => {
    if (rDate) {
      getFilterData()
    }
  }, [rDate])

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
          <Typography sx={{ pb: 3, pl: 1 }} variant="h4" gutterBottom>
            Employee Report
          </Typography>
        <Card sx={{ p: 3 }}>
          <Grid container spacing={2}>
            <Grid item md={2} xs={12}>
              <FormControl fullWidth>
                    <InputLabel id="report-type">Type</InputLabel>
                    <Select 
                      labelId="report-type"
                      label="Type"
                      value={filter.type} 
                      onChange={handleType} >
                        <MenuItem value="employee">Employee Wise</MenuItem>
                        <MenuItem value="location">Location Wise</MenuItem>
                        <MenuItem value="camera">Camera Wise</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            
            {filter.type === "employee" && (
              <Grid item md={2} xs={12} >
                <FormControl fullWidth>
                    <InputLabel id="emp-name">Employee Name</InputLabel>
                    <Select 
                      labelId="emp-name"
                      label="Employee Name"
                      value={filter.employeeID} 
                      onChange={handleEmployee} >
                        {employeesData.map((row, idx) => 
                        (<MenuItem key={idx} value={row.SecurityName}>{row.SecurityName}</MenuItem>)
                        )}                        
                    </Select>
                </FormControl>
                </Grid>
              )}
            
            
            {filter.type === "location" && (
              <Grid item md={2} xs={12}>
                <FormControl fullWidth >
                    <InputLabel id="report-location">Location</InputLabel>
                    <Select 
                      labelId="report-location"
                      label="Location"
                      value={filter.locationId} 
                      onChange={handleLocation} >
                        {locationData.map((row, idx) => 
                          (<MenuItem key={idx} value={row.Id}>{row.GroupName}</MenuItem>)
                        )}
                    </Select>
                </FormControl>
                </Grid>
              )}
            
            
            {filter.type === "camera" && (
                <Grid item md={2} xs={12}>
                  <FormControl fullWidth>
                      <InputLabel id="report-camera">Cameras</InputLabel>
                      <Select 
                        labelId="report-camera"
                        label="Cameras"
                        value={filter.cameraId} 
                        onChange={handleCamera} >
                          {camerasData.map((row, idx) => 
                            (<MenuItem key={idx} value={row.deviceid}>{row.devicename}</MenuItem>)
                          )}
                      </Select>
                  </FormControl>
                </Grid>
              )}  
            
            <Grid item md={2} xs={12}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                  <DesktopDatePicker
                    label="Report Date"
                    inputFormat="DD/MM/YYYY"
                    value={filter.reportDate}
                    onChange={(e) => handleReportDate(e)}
                    name="reportDate"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
              <FormControl fullWidth>
                    <InputLabel id="report-shift">Shift</InputLabel>
                    <Select 
                      labelId="report-shift"
                      label="Shift"
                      value={filter.shift} 
                      onChange={handleShift} >
                        <MenuItem value="day">Day</MenuItem>
                        <MenuItem value="night">Night</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={2}  xs={12}>
              <FormControl fullWidth>
                    <InputLabel id="report-attend">Present/Absent</InputLabel>
                    <Select 
                      labelId="report-attend"
                      label="Present/Absent"
                      value={filter.attend} 
                      onChange={handleAttend} >
                        <MenuItem value="present">Present</MenuItem>
                        <MenuItem value="absent">Absent</MenuItem>
                        <MenuItem value="sleep">Sleep</MenuItem>
                        <MenuItem value="both">All</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={2}  xs={12}>
              <Button size="large" variant='contained' onClick={getFilterData}>Get Report</Button>
            </Grid>    
          </Grid>
          {filterDetails.type && (
            <Card sx={{ my: 2, backgroundColor: (theme) => theme.palette['primary'].lighter, color: '#004166', boxShadow: "none" }}>
            <CardContent>
              <Stack 
              direction="row" 
              alignItems="center"
              spacing={3}>
                <Box>
                    {filterDetails.type == "employee" ? (
                    <Typography><Typography sx={{ fontWeight: 800, pr: 1 }} variant='span'>Security Name:</Typography> {filterDetails.securityName}</Typography>
                  ) : filterDetails.type == "location" ?  (
                    <Typography><Typography sx={{ fontWeight: 800, pr: 1 }} variant='span'>Location Name:</Typography>{filterDetails.locationName}</Typography>
                  ) : (
                    <Typography><Typography sx={{ fontWeight: 800, pr: 1 }} variant='span'>Camera Name:</Typography>{filterDetails.cameraName}</Typography>
                  )}
                  
                  <Typography><Typography sx={{ fontWeight: 800, pr: 1 }} variant='span'>Date:</Typography>{filterDetails.date}</Typography>
                  <Typography style={{textTransform: "capitalize"}}><Typography sx={{ fontWeight: 800, pr: 1 }} variant='span'>Shift:</Typography>{filterDetails.shift}</Typography>
                  <Typography><Typography sx={{ fontWeight: 800, pr: 1 }} variant='span'>Productivity:</Typography> {filterDetails.productivity && `${filterDetails.productivity} %`}</Typography>
                  <Typography><Typography sx={{ fontWeight: 800, pr: 1 }} variant='span'>Total Present Duration:</Typography>{filterDetails.totalPresent}</Typography>
                  <Typography><Typography sx={{ fontWeight: 800, pr: 1 }} variant='span'>Total Absent Duration:</Typography>{filterDetails.totalAbsent}</Typography>
                  <Typography><Typography sx={{ fontWeight: 800, pr: 1 }} variant='span'>Total Sleep Duration:</Typography>{filterDetails.totalSleep}</Typography>
                </Box>
                {filterDetails.type === "employee" && (
                  <Box component="img" src={filterDetails.profileImage} sx={{ borderRadius: 50, p: 1, backgroundColor: "#efefef" }}  width={150} height={150}/>
                )}
              </Stack>
                
                
            </CardContent>
          </Card>
          )}
          {filterDetails.type &&
          filterDetails.attend === 'present' ? (
            <ReportTable title={DETAILS_HEADS[0]} columns={filterDetails.type === "location" ? loColumns: filterDetails.type === 'camera' ? camColumns: empColumns} rows={filterDetails.presentList} type={filterDetails.type}/>
          ) : filterDetails.attend === 'absent' ? (
            <ReportTable title={DETAILS_HEADS[1]}  columns={filterDetails.type === "location" ? loColumns: filterDetails.type === 'camera' ? camColumns: empColumns} rows={filterDetails.absentList} type={filterDetails.type}/>
          ) : filterDetails.attend === 'sleep' ? (
            <ReportTable title={DETAILS_HEADS[2]}  columns={filterDetails.type === "location" ? loColumns:  filterDetails.type === 'camera' ? camColumns: empColumns} rows={filterDetails.sleepingList} type={filterDetails.type}/>
          )  : filterDetails.attend === 'both' && [filterDetails.presentList, filterDetails.absentList, filterDetails.sleepingList].map((item, idx) => {
            return(
            <ReportTable key={idx} title={DETAILS_HEADS[idx]} columns={filterDetails.type === "location" ? loColumns: filterDetails.type === 'camera' ? camColumns: empColumns} rows={item} type={filterDetails.type} />
          
          )} )
          
            
          }
          
        </Card>
      </Container>
    </Page>
  );
}
