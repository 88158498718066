import { useRef } from "react";
import useCanvas from "src/hooks/useCanvas";
import DrawToolBar from "./DrawToolBar";
import { Stack } from "@mui/material";

const DrawCanvas = () => {

  const inputElement = useRef(null)
  const [
    handleOnMouseMove,
    handleMouseRelase,
    handleClick,
    tool,
    handleToolBar
  ] = useCanvas(inputElement, "https://images.globalindustrial.com/images/enlarge/695511.jpg?t=1628284125430")


  return (
    <>
    <Stack
         direction="row"
         justifyContent="center"
         alignItems="center"
         spacing={2}
         >
          <DrawToolBar tool={tool} handleTool={handleToolBar} />
         </Stack>

         <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
         >
            
            <canvas 
      ref={inputElement} 
      width={300} 
      height={300} 
      style={{ background: 'gray' }}
      onMouseMove={handleOnMouseMove}
      onMouseUp={handleMouseRelase}
      onMouseDown={handleClick}
      ></canvas>
         </Stack>
    </>
      
  );
};

export default DrawCanvas;
