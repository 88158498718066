import {
  Card,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Tooltip,
  IconButton,
  CardHeader
} from '@mui/material';
// components
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import { getCounts } from 'src/api/headCountAPI';
import InfoRounded from '@mui/icons-material/InfoRounded';
import { InfoOutlined } from '@mui/icons-material';


// ----------------------------------------------------------------------

const RUN_FREQ_TIME = 5
const INFOS = {
  "Civilian": "Without Uniform and Others",
  "Regular Roll": "Regular Roll and Contractors",
}


export default function HeadCountDashboard() {

  const [reportData, setReportData] = useState({ totalEntry: 0, totalExit: 0, total: 0, details: []})
  const [isReload, setReload] = useState(true)

  useEffect(() => {
    getCounts((response) => {
      if (response.status === 200) {
        // console.log(response.data)
        setReportData({
          ...reportData, 
          totalEntry: response.data.hasOwnProperty('totalEntry') ? response.data.totalEntry: 0,
          totalExit: response.data.hasOwnProperty('totalExit') ? response.data.totalExit: 0,
          total: response.data.hasOwnProperty('total') ? response.data.total: 0,
          details: response.data.hasOwnProperty('details') ? response.data.details: 0,
        })
      }
    })
    const intervalId = setInterval(() => {
      setReload(!isReload)
    }, 1000*RUN_FREQ_TIME)
    return () => clearInterval(intervalId)
  }, [isReload])

  return (

        <Grid sx={{pb: 2}} container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>            
            <Card            
            sx={{p: 2, pt: 0 }}>
              <CardHeader title="Head Count Overview" subheader="Overview"/>
              {/* <Card sx={{ textAlign: "center", p: 1, mt: 2 }}> */}
                {/* <Typography variant='h1'>25</Typography>
                <Typography>Total</Typography> */}
                {/* <Stack
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={2}
                  sx={{ p: 3}}
                >
                  <Box>
                    <Typography variant='h1' sx={{color: (theme) => theme.palette['success'].darker}}>{reportData.totalEntry}</Typography>
                    <Typography sx={{color: (theme) => theme.palette['success'].darker}}>Entry</Typography>
                  </Box>
                  <Box>
                    <Typography variant='h1' sx={{color: (theme) => theme.palette['error'].darker}}>{reportData.totalExit}</Typography>
                    <Typography sx={{color: (theme) => theme.palette['error'].darker}}>Exit</Typography>
                  </Box>                  
                </Stack> */}
                <Card sx={{ backgroundColor: (theme) => theme.palette['primary'].lighter, py: 1, p: 2, textAlign: "center", mt: 2 }}>
                  <Typography sx={{ color: (theme) => theme.palette['primary'].darker }} variant='h2'>{reportData.totalEntry}</Typography>
                  <Typography sx={{ color: (theme) => theme.palette['primary'].darker }}>Total</Typography>
                </Card>                
              {/* </Card> */}
              <TableContainer sx={{ pt: 2 }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell>In</TableCell>
                      {/* <TableCell>Exit</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.details.length > 0 ? reportData.details.map((item, index) => 
                      (<TableRow key={index}>
                        <TableCell>{item.type} 
                          <Tooltip title={INFOS.hasOwnProperty(item.type) ? INFOS[item.type] : item.type} placement="right-start">
                            <IconButton>
                              <InfoOutlined sx={{ fontSize: 15 }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{item.entry_count}</TableCell>
                        {/* <TableCell>{item.exit_count}</TableCell> */}
                      </TableRow>)
                    ) : (
                      <TableRow>
                        <TableCell align='center' colSpan={3}>--No Data--</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
  );
}
