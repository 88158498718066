import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  TextField,
  Alert,
  Box,
  Typography,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import axios from '../../axios';
import { getURL, jsonToFormData } from 'src/utils/config';
import { getServers, addNewServer,updateServer } from 'src/api/servers';
import { useEffect } from 'react';

export default function AddEditForm({ onClose,Message,Action,Name,Type,Serverid,loadServersList }) {
  //console.log(onClose)
  const [isError, setErrorMsg] = useState({ status: false, msg: "" });
  const [serverTypeVal, setServerType] = useState('');
  const [name,setName]=useState('')

 

  const handleServerType = (event) => {
    setServerType(event.target.value);
  };

  const validationSchema = Yup.object().shape({
    serverName: Yup.string().required('Server Name is required'),

  });

  const formik = useFormik({
    initialValues: {
      serverName: name,
      serverType: serverTypeVal
    },
    validationSchema,
    onSubmit: (values) => {
      //debugger
      setErrorMsg({ status: false, msg: "" });
      handleSave(values);
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting,setFieldValue } = formik;

  const handleServerName = (event) =>{
    setName(event.target.value)
    setFieldValue('serverName', event.target.value);
  }


  useEffect(()=>{
    if(Action === 'Edit'){
      setName(Name)
      setFieldValue('serverName', Name);
      if(Type){
        setServerType(Type)
      }
      else{
        setServerType('testServer')
      }
    }
  },[Action, Name, Type, setFieldValue])

  const handleSave = (values) => {
    //debugger
   // console.log("values",values);
    const insertData = {
      ServerName: values.serverName,
      ServerType: serverTypeVal
      }
    const data = {
      
    }
    console.log(`ServerName: ${insertData.ServerName}`);
    console.log(`ServerType: ${insertData.ServerType}`);

    console.log("insertData",insertData);

    if(Action==='Add'){
      addNewServer(insertData,(response)=>{
        if(response.status === 200) {
          // getServers((response) => {
          //   if (response.status === 200) {
          //     // setData(response.data);
              
          //   }})
          onClose("right")(false);
          loadServersList();         
          console.log("posted server details ",response.data)
        }
      })
    }
    else if(Action==='Edit'){
      //let id=Serverid;
      const UpdatedData = {
        ServerName:name,
        ServerType:serverTypeVal,
        ServerId:Serverid
      }
      console.log(UpdatedData)
      updateServer(UpdatedData,(response)=>{
        if(response.status===200){
          //debugger
          console.log(response)
          loadServersList(); 
          
          //onClose("right")(false); 
          
          if (typeof onClose === 'function') {
            onClose("right", false);
          }
        }
      })
    }

  };

 


const handleClose = (event) => {
 // debugger
  //console.log("handleSubmit");
 // console.log(onClose)
 
 // onClose("right")(false);
  if (typeof onClose === 'function' && Action==='Add') {
    onClose("right", false)(event);
  }
  else if (typeof onClose === 'function' && Action === 'Edit') {
    onClose(event); 
  }
}

  return (
    <Box sx={{ p: 3, position: "relative", height: "100%", width: 350 }}>
      <Stack direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        spacing={1} sx={{ position: 'absolute', top: 0, left: 0, p: 2, backgroundColor: 'white' }}>
        <Typography variant='h5'>{Message}</Typography>
      </Stack>
      <Paper sx={{ position: 'absolute', top: 80, right: 0, width: "100%", px: 2 }}>
        <FormikProvider value={formik}>
          {isError.status && (
            <Alert severity="error" sx={{ mb: 3 }}>{isError.msg}</Alert>
          )}
          <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ height: "100%" }}>
            <Stack spacing={3} sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Server Name"
                {...getFieldProps('serverName')}
                error={Boolean(touched.serverName && errors.serverName)}
                helperText={touched.serverName && errors.serverName}
                value={name}
                onChange={handleServerName}
              />
        
              <FormControl fullWidth>
              <InputLabel id="server-type-label">Server Type</InputLabel>
                <Select
                  labelId="server-type-label"
                  id="server-type"
                  value={serverTypeVal}
                  //value={serverType?serverType:'testServer'}
                  label="Server Type"
                  onChange={handleServerType}
                >
                                    <MenuItem value='localVirtualMachine'>Local Virtual Machine</MenuItem>
                                    <MenuItem value='thirdPartyServer'>Third Party Server</MenuItem>
                                    <MenuItem value='clientDevices'>Client Devices</MenuItem>
                                    <MenuItem value='iotDevices'>IOT Devices</MenuItem>
                                    <MenuItem value='testServer'>Test Server</MenuItem>

                  {/* <MenuItem value='live'>Live</MenuItem>
                  <MenuItem value='local'>Local</MenuItem> */}
                </Select>
              </FormControl>
            </Stack>

            
            <Stack direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              width="100%"
              sx={{ position: 'absolute', bottom: -45, right: 0, p: 2 }}>
              <Button variant='contained' color='error' fullWidth onClick={handleClose} >Cancel</Button>
              <LoadingButton
                fullWidth
                // size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                Save
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Paper>
    </Box>
  );
}