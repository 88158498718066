
const jsonToFormData = (data) => {
    let formdata = new FormData()
    for (let x in data) 
      formdata.append(x, data[x])
    return formdata
}

const jsonToParam = (data) => Object.keys(data).map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(data[k])).join('&')

const getURL = (path, isCacheURL=false) => {

  console.log(path)
  let _ORIGIN_ = ''
  if (process.env.NODE_ENV === 'production')
    _ORIGIN_ = isCacheURL ? process.env.REACT_APP_PROD_AUDITS_API : process.env.REACT_APP_PROD_API
  if ( path === '/file-upload')
     _ORIGIN_ = process.env.REACT_APP_DEV_IMAGE_API 
  else
    _ORIGIN_ = isCacheURL ? process.env.REACT_APP_AUDITS_API : process.env.REACT_APP_API

  _ORIGIN_ = (_ORIGIN_.charAt(_ORIGIN_.length-1) === '/') ? _ORIGIN_.slice(0, _ORIGIN_.length-1): _ORIGIN_

  path = (path.charAt(0) === '/') ? path.slice(1, path.length): path
  return `${_ORIGIN_}/${path}`
}

export { jsonToFormData, getURL, jsonToParam }