import axios from '../axios'
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';

const monEscalationReport = (data,callback) => {
    axios({
        method: 'get',
        url: `${getURL('/monthly-reports/monthly-escalation-report')}?${jsonToParam(data)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const allClientMonthlyReport = (data,callback) => {
    axios({
        method: 'get',
        url: `${getURL('/monthly-reports/all-client-monthly-report')}?${jsonToParam(data)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const monthlyClientDashboard = (data,callback) => {
    axios({
        method: 'get',
        url: `${getURL('/monthly-reports/monthly-client-dashboard-report')}?${jsonToParam(data)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getClientGroups = (data,callback) => {
    axios({
        method: 'get',
        url: `${getURL('/camera-groups/get-groups-by-client')}?${jsonToParam(data)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

export { monEscalationReport,allClientMonthlyReport,monthlyClientDashboard,getClientGroups }