import axios from '../axios'
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';

// get cameras data
const getClientContact = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/protocols/get-client-contacts')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}




const setClientContacts = (body,callback) => {
    axios({
        method: 'post',
        url: getURL('/protocols/add-client-contacts'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


const postClientContacts = (body,callback) => {
    axios({
        method: 'put',
        url: getURL('/protocols/update-client-contact'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}



const deleteClientContacts = (Id, callback) => {
    axios({
        method: 'delete',
        url: getURL('/protocols/delete-client-contact'),
        data: Id,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


const getClientEscalatedVideos = (data,callback) => {
    const { camera_id, start_time , end_time } = data; 
    axios({
        method: 'get',
        url: `${getURL('/recordings')}?camera_id=${camera_id}&start_time=${start_time}&end_time=${end_time}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getAudioByEvent = (body,callback) => {
    axios({
        method: 'get',
        // url: `${getURL('devices')}?${jsonToParam(body)}`,
        url: `${getURL('/client-info/get-announcements-by-eventno')}?${jsonToParam(body)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))

    // const { eventNumber } = data; 
    // axios({
    //     method: 'get',
    //     url: `${getURL('/client-info/get-announcements-by-eventno')}?event_no=${eventNumber}`,
    //     validateStatus: (status) => handleResponse(status)
    // }).then((response) => callback(response))
}

const getvideoURLMail = (data,callback) => {
    const { to, client_name , video_url } = data; 
    axios({
        method: 'get',
        url: `${getURL('/client-reports/send-video-detail')}?to=${to}&client_name=${client_name}&video_url=${video_url}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


export { getClientContact ,setClientContacts ,postClientContacts ,deleteClientContacts ,getClientEscalatedVideos ,getAudioByEvent ,getvideoURLMail}
