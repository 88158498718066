import {
  Grid,
  Box,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PieChart } from './modules';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


export default function ProGraph({ setFilter,
  reportdate,
  handleDate,
  high,
  medium,
  low,
  reportShift,
  setShift }) {

  const theme = useTheme();
  // console.log(high, medium, low)
  const handleChange = (event) => {
    setShift(event.target.value);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={4} lg={3}>
        <PieChart
          chartData={[
            { label: 'High', value: Number(high) },
            { label: 'Medium', value: Number(medium) },
            { label: 'Low', value: Number(low) },
          ]}
          chartColors={[
            theme.palette.success.dark,
            theme.palette.chart.orange[0],
            theme.palette.chart.red[0],
          ]}
          setFilter={setFilter}
        />
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'right',
              '& > :not(style)': { m: 2 },
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DesktopDatePicker
                label="Report Date"
                inputFormat="DD/MM/YYYY"
                value={reportdate}
                onChange={(e) => handleDate(e)}
                name="reportDate"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Shift</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={reportShift}
                label="Shift"
                onChange={handleChange}
              >
                <MenuItem value='day'>Day</MenuItem>
                <MenuItem value='night'>Night</MenuItem>
                {/* <MenuItem value='full'>Full Day</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
        </Stack>

      </Grid>
    </Grid>
  )
}