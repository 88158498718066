import React from 'react'
import axios from 'src/axios';
import { getURL,jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';


const getGroups= (callback) => {
    axios({
        method: 'get',
        url: `${getURL('camera-groups')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch((error)=>{
        console.log(error)
    })
}

const getClientInfo= (body,callback) => {
    axios({
        method: 'get',
        url: `${getURL('camera-groups/camera-group-info')}?${jsonToParam(body)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch((error)=>{
        console.log(error)
    })
}

const AddClientGroupName = (body,callback) => {

    axios({
        method: 'post',
        url: `${getURL('camera-groups/add')}`,
        data:body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch((error)=>{
        console.log(error)
    })
}

const UpdateClientGroup = (body,callback) => {

    axios({
        method: 'put',
        url: `${getURL('camera-groups/update')}`,
        data:body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch((error)=>{
        console.log(error)
    })
}

const AddClientGroup = (body,callback) => {

    axios({
        method: 'post',
        url: `${getURL('camera-groups/add-camera-group-cams')}`,
        data:body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch((error)=>{
        console.log(error)
    })
}


export {getGroups,getClientInfo,AddClientGroupName,UpdateClientGroup,AddClientGroup}