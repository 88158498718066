import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';


export default function LocationAlert({
    alert,
    handleAlertClose,
    title,
    content,
    locationId,
    status,
    handleStatus }) {
    return (
        <Dialog
            open={alert}
            onClose={handleAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAlertClose}>Disagree</Button>
                <Button onClick={() => handleStatus(locationId, status)} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    )
}