import React, { useEffect, useState } from "react";
import axios from "src/axios";
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,Switch,
  TableContainer,
  Checkbox,
  useStepContext,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ListItemText } from "@mui/material";
import Iconify from "src/components/Iconify";
import {
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,Form,
  DialogTitle,
  TableHead,
} from "@mui/material";
import {
  getGroups,
  getClientInfo,
  AddClientGroupName,
  AddClientGroup,
} from "src/api/groups";
import {
  getPlanDetails,
  AddPlanCam,
  getClientPlanInfo,
  AddNewPlan,
} from "src/api/plan";
import { getClientList } from "src/api/analytics";
import { getDevices } from "src/api/devices";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PlansMore from "./PlansMore";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ClientAddPlan = () => {
  const [clientInfo, setClientInfo] = useState([]); // State for storing client data
  const [client, setClient] = useState("");
  const [plan, setPlan] = useState([]);
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [devices, setDevices] = useState([]);
  const [clientCameras, setClientCameras] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [activeCameras, setActiveCameras] = useState([]);
  const [inputValue, setInputValue] = useState([]);

  // add group to Devices
  const [errorDevicesMessage, setDeviceErrorMessage] = useState("");
  const [errorClientMessage, setClientErrorMessage] = useState("");
  const [errorGroupMessage, setGroupErrorMessage] = useState("");

  const [checkValue, setCheckValue] = useState(false);
  const [openAuto, setOpenAuto] = useState(false);


  const handleGroupReload = (val) => {
    let body = { client_id: client };
    getClientPlanInfo(body, (response) => {
      setClientData(response.data);
      // console.log("plans for Cams", response.data);
    });
  };

  useEffect(() => {
    getClientList((response) => {
      if (response.status === 200) {
        setClientInfo(response.data);
      }
    });

    getPlanDetails((response) => {
      setPlan(response.data);
    });

    let _body = { status: "1", limit: 0 };
    getDevices(_body, (response) => {
      let totalCams = response.data;
      if (response.status == 200) {
        setDevices(response.data);
      }
    });
  }, []);

  const onClientChange = (event, value) => {
    debugger;
    setDeviceErrorMessage("");
    setClientErrorMessage("");
    setGroupErrorMessage("");
    //setClientCameras([])
    setActiveCameras([]);
    setOpenAuto(false);

    if (!value || value.length === 0) {
      setActiveCameras([]);
      setInputValue([]);
      setClientData([]);
    }
    setCheckValue(false);
    let id = "";
    // setSelectedCameras('')
    // setSelectedGroup(0)
    setSelectedCameras([]);

    if (!value) {
      setClient("");
      setClientCameras([]);
      setSelectedCameras([]);
    } else {
      setClientErrorMessage("");
      id = value.ID;
      setClient(id);
      const body = { client_id: id };
      getClientPlanInfo(body, (response) => {
        setClientData(response.data);
        // console.log(response.data);
      });

      const clientCams = devices.filter((item) => item.clientid === id);

      // console.log("Cameras List", clientCams);
      setClientCameras(clientCams);
    }
  };

  useEffect(() => {
    // console.log("selected cameras", selectedCameras);
  }, [selectedCameras]);

  const handleCameraChange = (event, value) => {
    debugger;
    if (value.some((obj) => obj.hasOwnProperty("all"))) {
      if (value[0].all) {
        //let x=value[0].all
        setOpenAuto(true);
      } else {
        setOpenAuto(false);
      }
    } else {
      setOpenAuto(true);
    }

    if (
      value.length > 1 &&
      value.every(
        (entry, index, arr) => JSON.stringify(entry) === JSON.stringify(arr[0])
      )
    ) {
      value = [];
    }

    const containsAllProperty = value.some((obj) => obj.hasOwnProperty("all"));

    if (value.length === 0 || !containsAllProperty) {
      setCheckValue(false);
    }

    let actCameras = [];
    setDeviceErrorMessage("");

    if (value.length > 0 && !containsAllProperty) {
      value.forEach((row) => {
        actCameras.push(row.deviceid);
      });

      if (clientCameras.length === actCameras.length) {
        setCheckValue(true);
        setOpenAuto(false);
      }

      setActiveCameras([...actCameras]);
      setInputValue([...value]);
    }

    if (value.length > 0 && containsAllProperty) {
      let Cameras = clientCameras;

      actCameras = [];

      if (activeCameras && activeCameras.length === clientCameras.length) {
        setCheckValue(false);
        const deviceIds = value
          .filter((obj) => !obj.hasOwnProperty("all"))
          .map((obj) => obj.deviceid);
        Cameras = Cameras.filter((item) => !deviceIds.includes(item.deviceid));
        Cameras.forEach((row) => {
          actCameras.push(row.deviceid);
        });
        setActiveCameras([...actCameras]);
        setInputValue(clientCameras);
        //setOpenAuto(false);
        return;
      }
      if (!checkValue) {
        Cameras.forEach((row) => {
          actCameras.push(row.deviceid);
        });
        setActiveCameras([...actCameras]);
        setInputValue(clientCameras);
        setCheckValue(true);
      } else {
        setActiveCameras([]);
        setInputValue([]);
      }
    } else if (value.length === 0) {
      setActiveCameras([]);
      setCheckValue(false);
      setInputValue([]);
    }
  };

  const handleSave = () => {
    debugger;
    // if (!activeCameras || !client || !selectedGroup) {
    //   setErrorMessageOpen(true);
    // }
    if (activeCameras.length === 0 || !client || !selectedGroup) {
      if (client === "") {
        setClientErrorMessage("Please Select client");
      }
      if (activeCameras.length === 0) {
        setDeviceErrorMessage("Please Select Atleast one Camera");
      }
      if (selectedGroup === 0) {
        setGroupErrorMessage("Please Select the Group");
      }
      setErrorMessageOpen(true);
    } else {
      let body = activeCameras.map((item) => {
        return {
          PlanId: selectedGroup,
          CameraId: item,
        };
      });

      AddPlanCam(body, (response) => {
        if (response.status === 200) {
          const body = { client_id: client };

          getClientPlanInfo(body, (response) => {
            setClientData(response.data);
            setClient("");
            setSelectedCameras([]);

            console.log(response.data);
          });
        }
      });
    }

    // else {
    //   // console.log(selectedCameras)
    //   // console.log(clientData)

    //   let presentValues = activeCameras.filter(id => clientData.map(item => item.deviceid).includes(id));

    //   if(presentValues.length>0){
    //         // groups are not editable from here
    //         setMessage('Groups cannot be edited from here')
    //         setOpenError(true)
    //   }
    //   else{
    //     let body=activeCameras.map(item=>{
    //       return {
    //         GroupId: selectedGroup,
    //         DeviceId: item,
    //         ClientId: client
    //       }
    //     })

    //     AddClientGroup(body,(response)=>{
    //       if(response.status===200){
    //         const body={group_id:0,client_id:client}

    //         getClientInfo(body,(response)=>{

    //           setClientData(response.data)
    //           // setClient('');
    //           // setSelectedCameras([]);

    //           console.log(response.data)
    //         })
    //       }
    //     })

    //     //setClient('');
    //     //setClientCameras([]);
    //     //setSelectedCameras([]);
    //     //setClientData([])
    //     // setSelectedGroup()
    //   }

    // }
  };



  const onGroupChange = (event, value) => {
    if (value) {
      setSelectedGroup(value.Id);
      setGroupErrorMessage("");
    } else {
      setSelectedGroup(0);
    }
  };


  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
        ml={5}
      >
        <Typography variant="h4" gutterBottom>
          Add Plan To the Client
        </Typography>

      </Stack>

      <Card sx={{ width: "95%", p: 2, margin: "auto" }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                {/* <Autocomplete
                  fullWidth
                  id="tags-outlined"
                  options={clientInfo}
                  getOptionLabel={(option) => option.displayname}
                  onChange={onClientChange}
                  value={client.ID}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Clients"
                      placeholder="Enter Client Name"
                    />
                  )}
                /> */}

        <Autocomplete
          options={clientInfo.map((client, clientIndex) => ({
            ...client,
            customKey: clientIndex, // Assuming clientIndex is a unique identifier
          }))}
          isOptionEqualToValue={(option, value) =>
            option.customKey === value.customKey
          }
          getOptionLabel={(option) => `${option.displayname}`}
          onChange={onClientChange}
          value={client.ID}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              id="select-cameras"
              label="Select client"
              variant="outlined"
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.customKey}>
              {option.displayname}
            </li>
          )}
        />

              </FormControl>
              {errorClientMessage && (
                <Typography color="error" variant="body2" align="left">
                  {errorClientMessage}
                </Typography>
              )}
            </Grid>

            <Grid item xs={3}>
              <Autocomplete
                multiple
                fullWidth
                id="camera"
                options={clientCameras}
                value={
                  checkValue
                    ? [{ title: "Select All", all: true }]
                    : inputValue.filter((camera) =>
                        activeCameras.includes(camera.deviceid)
                      )
                }
                getOptionLabel={(option) =>
                  option.all && clientCameras.length === activeCameras.length
                    ? "Select All"
                    : `${option.deviceid} ${option.devicename}`
                }
                onChange={handleCameraChange}
                disableCloseOnSelect={openAuto}
                open={openAuto}
                onOpen={() => setOpenAuto(true)}
                onClose={() => setOpenAuto(false)}
                filterOptions={(options, params) => {
                  const filter = createFilterOptions();
                  const filtered = filter(options, params);
                  return [{ title: "Select All", all: true }, ...filtered];
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    {option.all ? (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={checkValue}
                          onChange={() => setCheckValue(!checkValue)}
                        />
                        {option.title}
                      </>
                    ) : (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={activeCameras.includes(option.deviceid)}
                          onChange={() => {
                            const isChecked = activeCameras.includes(
                              option.deviceid
                            );
                            const newActiveCameras = isChecked
                              ? activeCameras.filter(
                                  (deviceId) => deviceId !== option.deviceid
                                )
                              : [...activeCameras, option.deviceid];
                            setActiveCameras(newActiveCameras);
                          }}
                        />
                        {`${option.deviceid} ${option.devicename}`}
                      </>
                    )}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Camera"
                    onFocus={() => setOpenAuto(true)}
                  />
                )}
              />

              {errorDevicesMessage && (
                <Typography color="error" variant="body2" align="left">
                  {errorDevicesMessage}
                </Typography>
              )}
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth>
              <Autocomplete
                fullWidth
                options={plan || []}
                getOptionLabel={(option) => option.PlanName}
                onChange={onGroupChange}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Plan"
                    placeholder="Select Plan"
                  />
                )}
                // getOptionSelected={(option, value) => option.Id === value.Id} // Specify the logic to determine if an option is selected
              />


              </FormControl>
              {errorGroupMessage && (
                <Typography color="error" variant="body2" align="left">
                  {errorGroupMessage}
                </Typography>
              )}
            </Grid>

            <Grid item xs={3}>
              <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>
                Save
              </Button>
            </Grid>
          </Grid>
        </CardContent>

        <TableContainer>
          <Table>
            <TableHead sx={{ background: "#f3f3f3" }}>
              <TableRow>
                <TableCell sx={{ background: "#f3f3f3" }}>S.No</TableCell>
                <TableCell sx={{ background: "#f3f3f3" }}>
                  Client Name
                </TableCell>
                <TableCell sx={{ background: "#f3f3f3" }}>
                  Camera Name
                </TableCell>
                <TableCell sx={{ background: "#f3f3f3" }}>Plan Name</TableCell>
                <TableCell sx={{ background: "#f3f3f3" }}>Price</TableCell>
                <TableCell sx={{ background: "#f3f3f3" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.displayname}</TableCell>
                  <TableCell>{row.devicename}</TableCell>
                  <TableCell>{row.PlanName}</TableCell>
                  <TableCell>{row.Pricing}</TableCell>
                  <TableCell>
                    <PlansMore
                      row={row}
                      groups={plan}
                      handleGroupReload={handleGroupReload}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default ClientAddPlan;
