import { Card, CardContent, Grid, Avatar, Box, CardMedia, Chip } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import axios from '../../../axios'
import useVideoPlayer from "src/hooks/useVideoPlayer";
import { format } from 'date-fns';
import { getURL } from 'src/utils/config';


// import { borderRadius } from '@mui/system';

const setArray = (count, data) => [...Array(parseInt(count))].map((_, index) => (data))
const initalcolor = {
    backgroundColor: 'white',
    padding: 3
}
const updatedcolor = {
    backgroundColor: 'red',
    padding: 3,
    // transition: 'background-color 3s'
}

const RUN_FREQ_TIME = 5 //sec
const last_datetime = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
let lastPlayedBox = null

const CameraList = ({ camlst, gridview, idx, setCamStatus, camstatus }) => {

    const { VideoUrl, CameraId, DetectedType, Id } = camlst
    let g = parseInt(gridview) / 2 >= 3 ? 12 / 3 : 12 / 2
    const detectes = DetectedType ? DetectedType.split(',') : []   

    const [fadeclour, setColor] = useState(initalcolor)

    const videoElement = useRef(null)
    const {
        playerState,
        togglePlay,
        handleOnTimeUpdate,
        handleVideoSpeed,
        handlePause,
        handlePlay,
        handleInitPlay
    } = useVideoPlayer(videoElement);

    useEffect(() => {
        if (VideoUrl) {
            handleInitPlay()
            setColor(updatedcolor)
        }
        // playerState.playStatus
    }, [VideoUrl, Id])

    useEffect(() => {
        if (playerState.playStatus === 'Completed' && camstatus[idx]) {
            lastPlayedBox = Number(idx)
            setCamStatus((auditstatus) => {
                auditstatus[idx] = false                
                return [...auditstatus]
            })
            setColor(initalcolor)
        }
        // setCamStatus[idx] = false
        // console.log('Completed', idx)
    })

    return (
        <Grid item xs={12} sm={g} md={g}>


            <Card style={{ borderRadius: '0' }}>
                <CardMedia
                    component="video"
                    image={VideoUrl}
                    autoPlay={true}
                    ref={videoElement}
                    onTimeUpdate={handleOnTimeUpdate}
                    onPause={handlePause}
                    onPlay={handlePlay}
                    style={{ height: 300, width: '100%', backgroundColor: 'black' }}
                />
                <CardContent sx={{ height: 40, border: '1px solid #bdbdbd' }} style={fadeclour}>
                    {detectes.map((det, k) => (
                        <Chip key={k} sx={{ mr: 1, backgroundColor: "white", textTransform: "capitalize" }}  avatar={<Avatar>{det.charAt(0).toUpperCase() || ''}</Avatar>} label={det} />
                    ))} 
                </CardContent>

            </Card>
        </Grid>
    )
}

export default function AuditLiveGrid({ gridview }) {

    // {
    //     "Id": 118,
    //     "CameraId": "6158",
    //     "EventTime": null,
    //     "EventType": null,
    //     "DetectedType": "person,Accident",
    //     "AnalyticsId": "person,Accident",
    //     "VideoUrl": "http://14.97.207.197:8080/videos/video_2022_08_04_18_44_17.mp4",
    //     "UpdatedUrl": null,
    //     "SnapshotUrl": null,
    //     "Status": 1,
    //     "Remarks": "Accident",
    //     "Comments": null,
    //     "CreatedOn": "2022-08-04 18:44:17",
    //     "CreatedBy": null,
    //     "UpdatedOn": null,
    //     "UpdatedBy": null
    // },

    const CAM_LIST = setArray(gridview, {
        VideoUrl: null,
        CameraId: '',
        DetectedType: ''
    })
    const [audits, setAudits] = useState(CAM_LIST)
    const [holdaudits, setHoldAudits] = useState([])
    const [lastid, setLastId] = useState(0)
    const INIT_CAM_STATUS = setArray(gridview, false)
    const [camstatus, setCamStatus] = useState(INIT_CAM_STATUS)
    const [reload, setReload] = useState(true)



    useEffect(() => {
        
        if (holdaudits.length < 1) {
            // let id = audits.length > 0 ? audits
            axios({
                method: 'post',
                url: getURL('cacheaudits/get_clientaudits/', true),
                data: {
                    "limit": gridview,
                    "Id": lastid,
                    "LastDateTime": last_datetime
                },
                validateStatus: function (status) {
                    return status >= 200 || status === 400;
                }
            }).then(function (response) {
                if (response.status === 200 || response.status === 201) {
                    // let res = Object.values(response.data)
                    let res = response.data
                    if (res.length > 0) {
                        let lstvalue = res[res.length-1].Id
                        setLastId(lstvalue)
                        setHoldAudits(res)
                    }
                }
            })
        }

        if ([...camstatus.filter((isPlay) => !isPlay)].length && holdaudits.length) {
            let hdaudits = holdaudits
            let adts = audits
            let waitingGrids = [...camstatus.map((value, key) => value && key)].filter((value) => value !== false)
            // if (lastPlayedBox !== null && )
            let cstatus = [...camstatus.map((value, key) => {
                if (!value && hdaudits.length) {
                    adts[key] = hdaudits[0]
                    hdaudits.shift()
                    value = true
                }
                return value
            })]    

            setAudits(adts)
            setHoldAudits(hdaudits)
            setCamStatus(cstatus)
            // console.log(camstatus, cstatus)

        }

        const intervalId = setInterval(() => {
            setReload((r) => !r)
        }, 1000*RUN_FREQ_TIME)
        return () => clearInterval(intervalId)
        // , [holdaudits, setHoldAudits, setCamStatus, camstatus]
    }, [reload])

    return (
        <Box overflow="auto" flex={1} flexDirection="column" display="flex" p={1}>
            {/* <Grid container spacing={3} sx={{ maxHeight:500 }} > */}
            <Grid container spacing={1} >
                {audits.map((camlst, ckey) => (
                    <CameraList key={ckey} camlst={camlst} gridview={gridview} idx={ckey} setCamStatus={setCamStatus} camstatus={camstatus}/>
                ))}
            </Grid>
        </Box>
    )
}