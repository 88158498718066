import {
  Container,
  Typography,
  Card,
  Stack,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  TextField,
  Button,
  Tab,
  Box
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';

// components
import Page from '../components/Page';
import { useState } from 'react';
import {   
  TabContext,
  TabList,

  TabPanel 
} from '@mui/lab';
import { getHeadCountReportData } from 'src/api/headCountReport';
import { format } from "date-fns";
import { ReportTable } from 'src/sections/headCountReports';


// ----------------------------------------------------------------------


// Regular Roll (employee,tshirt,white_uniform)
// Trainee (trainee)
// Forbes (forbes)
// Security(security_guard,lady_person)
// Civilian(Others

const objectTypes = [
  {title: "All", value: "all"},
  {title: "Regular Roll", value: "employee,tshirt,white_uniform"},
  {title: "Trainee", value: "trainee"},
  {title: "Forbes", value: "forbes"},
  {title: "Security", value: "security_guard,lady_person"},
  {title: "Civilian", value: "others"},

]


export default function HeadCountReport() {

  const [objectType, setObjectType] = useState('all')
  const [reportDate, setReportDate] = useState(dayjs(new Date()))
  const [timeRange, setTimeRange] = useState('full-day')

  const [fromHour, setFromHour] = useState(1)
  const [toHour, setToHour] = useState(1)

  const [activeDays, setActiveDays] = useState([])
  const [activeTab, setActiveTab] = useState("all")
  const [headCountData, setHeadCountData] = useState({})

  const handleObjectType = (event) => {
    setObjectType(event.target.value)
  }

  const handleReportDate = (value) => {
    setReportDate(value)
  }

  const handleTimeRange = (event) => {
    setTimeRange(event.target.value)
    setFromHour(1)
    setToHour(1)
  }

  const handleFromHour = (event) => { 
    setFromHour(event.target.value)
  }

  const handleToHour = (event) => { 
    setToHour(event.target.value)
  }

  const handlerTabs = (_, value) => {
   setActiveTab(value)
  }

  const getReportData = () => {
    let days = []
    if (timeRange === "full-day") {
      days = [...Array(24)].map((_, i) => i+1)
    } else if (timeRange === "custom" && fromHour <= toHour) {
      days = [...Array((toHour-fromHour)+1)].map((_, i) => i+fromHour)
    }
    setActiveDays([...days])   
    const body = { date: format(new Date(reportDate), 'yyyy-MM-dd') }
    if (objectType !== "all") {
      body.type = objectType
    }
    if (timeRange === "custom" && fromHour <= toHour) {
      body.fromhour = fromHour
      body.tohour = toHour
    }
    getHeadCountReportData(body, (response) => {
      if (response.status === 200) {
          setHeadCountData(response.data)
      }
    })
  }


  return (
    <Page title="Head Count Report">
      <Container maxWidth="xl">
        <Typography sx={{ pb: 3, pl: 1 }} variant="h4" gutterBottom>Head Count Report</Typography>
        <Card sx={{p: 2}}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <FormControl fullWidth>
              <InputLabel id="obj-type-select-label">Type</InputLabel>
              <Select
                labelId="obj-type-select-label"
                id="obj-type-select"
                value={objectType}
                label="Type"
                onChange={handleObjectType}
              >
                {objectTypes.map((row, idx) => (<MenuItem key={idx} value={row.value}>{row.title}</MenuItem>))}  
                
              </Select>
            </FormControl>
            <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                  <DesktopDatePicker
                    label="Date"
                    inputFormat="DD/MM/YYYY"
                    value={reportDate}
                    onChange={(e) => handleReportDate(e)}
                    name="reportDate"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="time-range-select-label">Time Range</InputLabel>
              <Select
                labelId="time-range-select-label"
                id="time-range-select"
                value={timeRange}
                label="Time Range"
                onChange={handleTimeRange}
              >
                <MenuItem value="full-day">Full Day</MenuItem> 
                <MenuItem value="custom">Custom</MenuItem>
              </Select>
            </FormControl>

            {timeRange === "custom" && (
              <>
                <FormControl fullWidth>
                  <InputLabel id="from-hour-select-label">From Hour</InputLabel>
                  <Select
                    labelId="from-hour-select-label"
                    id="from-hour-select"
                    value={fromHour}
                    label="From Hour"
                    onChange={handleFromHour}
                  >
                    {[...Array(23)].map((_, hour) => (<MenuItem key={(hour)} value={hour+1}>{hour+1}</MenuItem>))}                
                  </Select>
                </FormControl>
    
                <FormControl fullWidth>
                  <InputLabel id="to-hour-select-label">To Hour</InputLabel>
                  <Select
                    labelId="to-hour-select-label"
                    id="to-hour-select"
                    value={toHour}
                    label="To Hour"
                    onChange={handleToHour}
                  >
                    {[...Array(23)].map((_, hour) => (<MenuItem key={(hour)} value={hour+1}>{hour+1}</MenuItem>))}                
                  </Select>
                </FormControl>
                </>  
            )} 
            <Button fullWidth variant='contained' size="large" onClick={getReportData}>Get Report</Button>
          </Stack>


          {activeDays.length > 0 && (
              <Box sx={{ pt: 2, width: '100%' }}>
                <TabContext sx={{ padding: 0}}  value={String(activeTab)}>
                  
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList variant="scrollable"
        scrollButtons="auto" onChange={handlerTabs} aria-label="lab API tabs example">
                          <Tab label="All" value="all" />
                          {activeDays.map((row, key) => (
                            <Tab key={key} label={row} value={String(row)} />
                          ))}
                      </TabList>
                    </Box>
                        <TabPanel sx={{p:0}} value="all">
                            {activeDays.map((row, key) => (
                              <ReportTable key={key} title={`Hour - ${row}`} tableData={headCountData.hasOwnProperty(row) ? headCountData[row]: {}}/>
                            ))}
                            
                        </TabPanel>
                        {activeDays.map((row, key) => (
                          <TabPanel sx={{p:0}} key={key} value={String(row)}>
                            <ReportTable title={`Hour - ${row}`} tableData={headCountData.hasOwnProperty(row) ? headCountData[row]: {}}/>
                          </TabPanel>
                        ))}
                </TabContext>
            </Box>
          )}
        </Card>
      </Container>
    </Page>
  );
}
