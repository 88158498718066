import React, { useState } from "react";
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,TableFooter,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,Paper,
  Grid,
  TableHead,
} from "@mui/material";

import { getYearBilingReport } from "src/api/apiMdReports";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import * as XLSX from 'xlsx';

const MdReports = () => {
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [reportData,setReportData] = useState([]);

  const currentYear = new Date().getFullYear();
  const yearOptions = [
    { name: (currentYear - 1).toString(), value: currentYear - 1 },
    { name: currentYear.toString(), value: currentYear },
    { name: (currentYear + 1).toString(), value: currentYear + 1 }
  ];

  const monthOptions = [
    { name: "January", value: 1 },
    { name: "February", value: 2 },
    { name: "March", value: 3 },
    { name: "April", value: 4 },
    { name: "May", value: 5 },
    { name: "June", value: 6 },
    { name: "July", value: 7 },
    { name: "August", value: 8 },
    { name: "September", value: 9 },
    { name: "October", value: 10 },
    { name: "November", value: 11 },
    { name: "December", value: 12 }
  ];

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleSave = () => {
    console.log("year and month ",year,month);

    const data = {
      year: year,
      month: month,
    }
    getYearBilingReport(data, (response) => {

      if (response.status === 200 && response.data !== null) { 
           console.log(response.data);
           setReportData(response.data);
       }

    });

    // Your save logic here
  };

  const handleCancel = () => {
    setMonth('');
    setYear('');
  }

  const handleExportExcel = () => {
    if (!reportData1 || reportData1.length === 0) {
      alert('No data to Export.');
      return;
    }

    const sheetName = `Billing_Report_${year}_${month}`;
    const headers = ['S.No', 'Client Name', 'Camera Count', 'Price'];

    const formattedData = reportData1.map((item, index) => ({
      'S.No': (index + 1).toString(),
      'Client Name': item.displayname,
      'Camera Count': item.active_camera_count.toString(),
      'Price': item.billed_amount.toString(),
    }));

    const totalBilledAmount = reportData1.reduce((acc, item) => acc + item.billed_amount, 0);
    const additionalRows = [
      {
        'S.No': '',
        'Client Name': 'Total',
        'Camera Count': '',
        'Price': totalBilledAmount.toString(),
      },
    ];

    const allRows = [...formattedData, ...additionalRows];

    const ws = XLSX.utils.json_to_sheet(allRows, {
      header: headers,
      cellStyles: true,
    });

    const filename = sheetName + '.xlsx';
    const maxColumnWidths = {};
    headers.forEach(header => {
      maxColumnWidths[header] = Math.max(
        20,
        ...allRows.map(row => (row[header] || '').toString().length)
      );
    });
    const columnWidths = headers.map(header => ({
      wch: maxColumnWidths[header],
    }));
    ws['!cols'] = columnWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName || 'Sheet 1');
    XLSX.writeFile(wb, filename);
  };


  const reportData2 = [];
  const reportData1 = [
    {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },
    {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },  {
      "clientid": 655,
      "displayname": "krandsons",
      "active_camera_count": 14,
      "billed_amount": 4500
    },
    {
      "clientid": 768,
      "displayname": "Precistat",
      "active_camera_count": 16,
      "billed_amount": 3000
    }
  ]
  const totalBilledAmount = reportData1.reduce((acc, row) => acc + row.billed_amount, 0);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
        ml={5}
      >
        <Typography variant="h4" gutterBottom>
          Billing Report
        </Typography>
      </Stack>

      <Card sx={{ width: "95%", p: 2, margin: "auto" }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel id="year-label">Year</InputLabel>
                <Select
                  labelId="year-label"
                  id="year"
                  value={year}
                  onChange={handleYearChange}
                  label="Year"
                >
                  {yearOptions.map((yearOption) => (
                    <MenuItem key={yearOption.value} value={yearOption.value}>
                      {yearOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel id="month-label">Month</InputLabel>
                <Select
                  labelId="month-label"
                  id="month"
                  value={month}
                  onChange={handleMonthChange}
                  label="Month"
                >
                  {monthOptions.map((monthOption) => (
                    <MenuItem key={monthOption.value} value={monthOption.value}>
                      {monthOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={2} md={1}>
              <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>
                GetDetails
              </Button>
            </Grid>

            <Grid item xs={6} sm={3} md={1}>

              <Button
                  sx={{ mt: 2 ,ml: -2}}
                  variant="contained"
                  color="primary"
                  startIcon={<InsertDriveFileIcon />}
                  onClick={handleExportExcel}
                >
                  Excel <SaveAltIcon />
                </Button>

            </Grid>

            <Grid item xs={6} sm={3} md={1}>
              <Button variant="contained" color="error" onClick={handleCancel} sx={{ mt: 2, ml: -2 }}>
                Cancel
              </Button>
            </Grid>


          </Grid>
        </CardContent>
        <Grid container sx={{ marginTop: 0.5 }}>
            <Card sx={{ height: "100%", display: "flex", width: "100%" }}>
              <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
                <Grid item xs={12}>
                  <TableContainer component={Paper} style={{ maxHeight: 500 }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                        <TableCell sx={{ background: "#f3f3f3" }}>S.No</TableCell>
                        <TableCell sx={{ background: "#f3f3f3" }}>Client Name</TableCell>
                        <TableCell sx={{ background: "#f3f3f3" }}>Camera Count</TableCell>
                        <TableCell sx={{ background: "#f3f3f3" }}>Price</TableCell>
                        </TableRow>
                      </TableHead>
                    <TableBody>
                      {reportData1.length > 0 ? (
                            reportData1.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.displayname}</TableCell>
                                <TableCell>{row.active_camera_count}</TableCell>
                                <TableCell>{row.billed_amount}</TableCell>
                              </TableRow>
                                
                            ))
                            ) : (
                              <TableRow>
                                <TableCell colSpan={12}>                              
                                  <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                                        No Data 
                                      </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                      </TableBody>
                      {reportData1.length > 0 ?(
                      <TableFooter>
                        <TableRow>
                          <TableCell colSpan={3} sx={{ background: "#f3f3f3", position: 'sticky', bottom: 0, fontWeight: 'bold',color:"black" }}>
                            Total
                          </TableCell>
                          <TableCell sx={{ background: "#f3f3f3", position: 'sticky', bottom: 0, fontWeight: 'bold' ,color:"black"}}>
                            {totalBilledAmount}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                      ) : null}
                    </Table>
                  </TableContainer>
                </Grid>
              </CardContent>
            </Card>
          </Grid>


      </Card>
    </>
  );
};

export default MdReports;
