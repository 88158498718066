import React from 'react';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const LineGraphMd = ({ data }) => {
  const months = data.map(item => item.month);
  const prices = data.map(item => item.price);

  // Find the maximum price
  const maxPrice = Math.max(...prices);
  const nearestMultipleOf50 = Math.ceil(maxPrice / 50) * 50 + 1000 ;

  const chartData = {
    labels: months,
    datasets: [
      {
        label: 'Price',
        backgroundColor: 'rgba(57, 144, 229 , 0.2)',
        borderColor: 'rgba(57, 144, 229 , 1)',
        pointBackgroundColor: 'rgba(57, 144, 229 , 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(57, 144, 229 , 1)',
        data: prices,
        fill: true, // Fill the area under the line
      }
    ]
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Months'
        }
      },
      y: {
        beginAtZero: true,
        max: nearestMultipleOf50, // Add a buffer for better visualization
        title: {
          display: true,
          text: 'Price'
        }
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: Math.round,
        font: {
          weight: 'bold'
        }
      }
    }
  };

  return (
    <div>
      <Line data={chartData} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default LineGraphMd;
