import axios from '../axios'
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';

const newSubLogins = (body,callback) => {
    axios({
        method: 'post',
        url: getURL('/client/add-client-users'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


const getSubLoginDetails = (data,callback) => {
    const { type } = data; 

    axios({
        method: 'get',
        url: `${getURL('/client/client-users')}?type=${type}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const updateLoginDetails = (data, callback) => {
    axios({
        method: 'put',
        url : getURL('/client/update-client-users'),
        data : data,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}
export { newSubLogins ,getSubLoginDetails ,updateLoginDetails}