import axios from '../axios'
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';


// get report data
const getReportData = (callback, fromTime, toTime) => {
    axios({
        method: 'get',
        url: `${getURL(`/dashboard/category-wise-report?fromtime=${fromTime}&totime=${toTime}`)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

export { getReportData }