import axios from '../axios'
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';

// get cameras data
const getHeadCountReportData = (body, callback) => {
    axios({
        method: 'get',
        url: `${getURL('employee-information')}?${jsonToParam(body)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

export { getHeadCountReportData }