import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Tabs,
  Tab,
  Badge,
  Chip
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import client from 'src/api/clients';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Label from 'src/components/Label';
import { DeviceForm, DeviceList } from 'src/sections/devices';
import { getDevices } from 'src/api/devices'
import { getClientList } from 'src/api/analytics';
import DeviceDetails from 'src/sections/devices/DeviceDetails';

// ----------------------------------------------------------------------

export default function Devices() {
  const [devices, setDevices] = useState({
    isLoad: false,
    all: [],
    active: [],
    inActive: [],
    lastId: null
  })

  const [totalData,setTotalData]=useState({})

  const [clientId,setClientId]=useState([])

  const getClientId = (clientid) =>{    
    if(clientid.selectedClients){
      let id=clientid.selectedClients.map(item=>item.ID)
      setClientId(id)
    }
  }

  useEffect(() => {    
      
      const body = { status: "0,1", limit: 0 }
      // if (devices.lastId !== null) {
      //   body.lastid = devices.lastId
      // }
  
      getDevices(body, (response) => {
        let totalCams=response.data;
        //console.log(clientId)
        if (response.status == 200) { 
        
          //console.log(totalCams)
          const active = []
          const inActive = []
          let lastId = body.lastid
          if (totalCams) {
            if(clientId.length===0){
             // debugger
             setTotalData(totalCams)
              totalCams.forEach((row) => {
                if (row.Status === 1)
                  active.push(row)
                else if (row.Status === 0)
                  inActive.push(row)
              })
              lastId = totalCams[totalCams.length-1].uid
            }
            else{
             // debugger
              totalCams=totalCams.filter(item=>clientId.includes(item.clientid))
              totalCams.forEach((row) => {
                if (row.Status === 1)
                  active.push(row)
                else if (row.Status === 0)
                  inActive.push(row)
              })
              lastId =totalCams.length>0?totalCams[totalCams.length-1].uid:0
            }
           
          }       
  //debugger
          setDevices({ 
            ...devices,
            all: totalCams ? totalCams : [],
            active: active,
            inActive: inActive,
            lastId: lastId,
            isLoad: totalCams.length === body.limit ? !devices.isLoad: devices.isLoad
            //isLoad:true
          })

          //setTotalData(devices)
  
        }
      })
    
   
  }, [])

  const handleReloadPage = () => {
    debugger
    console.log(selectedClient)
    
    const body = { status: "0,1", limit: 0 }
    getDevices(body, (response) => {
      let totalCams=response.data;
      //console.log(clientId)
      if (response.status == 200) { 

        const active = []
        const inActive = []
        let lastId = body.lastid
        if (totalCams) {
          if(selectedClient.length===0){
           // debugger
           setTotalData(totalCams)
            totalCams.forEach((row) => {
              if (row.Status === 1)
                active.push(row)
              else if (row.Status === 0)
                inActive.push(row)
            })
            lastId = totalCams[totalCams.length-1].uid
          }
          else{
           //debugger
           //totalCams = totalCams.filter(item => item.ID.some(id => selectedClient.includes(id)));
           totalCams = totalCams.filter(item=>selectedClient.some(item2=>item2.ID===item.clientid));
            console.log(totalCams)
           totalCams.forEach((row) => {
              if (row.Status === 1)
                active.push(row)
              else if (row.Status === 0)
                inActive.push(row)
            })
            lastId =totalCams.length>0?totalCams[totalCams.length-1].uid:0
          }
         
        }       
        setDevices({ 
          ...devices,
          all: totalCams ? totalCams : [],
          active: active,
          inActive: inActive,
          lastId: lastId,
          isLoad: totalCams.length === body.limit ? !devices.isLoad: devices.isLoad
          //isLoad:true
        })

        setDisplayAll(true)
      }
    })
  }

  const reloadClientsData = () => {
    //debugger
    setDevices({...devices, isLoad: !devices.isLoad})
  }

  const [selectedClient, setSelectedClient] = useState([]);

  const [clientList,setClientList]=useState([]) 

  const [displayAll,setDisplayAll] = useState(false)

  const handleDisplay = () =>{
    setDisplayAll(false)
  }

  useEffect(() => {
    getClientList((response) => {
      if (response.status === 200) {
         setClientList(response.data)
      }
    })
    },[])

    useEffect(()=>{
      //debugger
      console.log(devices.inActive)
    },[devices])
 
  const updateClientDevices=(devices)=>{   
    //debugger
    console.log(devices)
    setDevices(devices)
    //setSelectedClient(selectedClient)
  }
  
  const handleClientChange = (value) => {         
    debugger
    console.log(value)
    if(value.length>0){
     
      if(value.length===clientList.length){
        setSelectedClient(value);
        if(Object.keys(totalData).length>0){
          loadFillDevices(totalData)
        }
      }
      else{
        setSelectedClient(value);
        console.log(devices)
        let id=value.map(item=>item.ID)
        if(!displayAll){
          debugger
          let clientDevices=totalData.filter(item => id.includes(item.clientid))
          loadFillDevices(clientDevices)
        }
        else{
          console.log(devices)
          let updatedDevices=devices.all.filter(item => id.includes(item.clientid))
          loadFillDevices(updatedDevices)
        }
       
        
      }
    }
      else{
        if(Object.keys(totalData).length>0){
          loadFillDevices(totalData)
          setSelectedClient([])
        }
      }
  };

  const loadFillDevices = (data)=>{
    //debugger
      let totalCams=data
      const active = []
      const inActive = []
      let lastId = 0
      if(totalCams.length!==0){
        totalCams.forEach((row) => {
          if (row.Status === 1)
            active.push(row)
          else if (row.Status === 0)
            inActive.push(row)
        })
      }

      let obj = {
        isLoad: true,
        all: totalCams,
        active: active,
        inActive: inActive,
        lastId: lastId
      }
      updateClientDevices(obj)
  }  

  const [dialog, setOpen] = useState({ isOpen: false, id: null });
  const [moremenu, setOpenMenu] = useState({ isOpen: false, id: null });

  function handleDialog(id=null) {
   
    setOpen({...dialog, isOpen: true, id: id})
  }

  function handleMoremenu(id=null) {
   
    setOpenMenu({...moremenu, isOpen: true, id: id})
  }

  

  const [value, setValue] = useState('one');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };




  return (
    <Page title="User">
      <Container maxWidth="xl">
    
        <DeviceForm key={dialog} dialogData={dialog} setOpen={setOpen} reloadClientsData={reloadClientsData} handleReloadPage={handleReloadPage}/>
        <DeviceDetails dialogData={moremenu} setOpen={setOpenMenu} reloadClientsData={reloadClientsData} handleReloadPage={handleReloadPage}/>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Cameras
          </Typography>
          <Button variant="contained" onClick={() => handleDialog()} component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Camera
          </Button>
        </Stack>
        <Card>
          <TabContext value={value}>
            <TabList
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                sx={{ borderBottom: "1px solid #ebebeb", backgroundColor: "#f3f3f3" }}
                
              >
                <Tab
                  key="all"
                  value="one"
                  icon={<Label sx={{ mr: 1 }} color='primary'>{devices.all.length}</Label>} 
                  style={{ minHeight: "auto" }}
                  iconPosition="start"
                  label="All"                  
                />
                <Tab 
                key="active"
                value="two"
                label="Active"
                icon={<Label sx={{ mr: 1 }} color='success'>{devices.active.length}</Label>} 
                style={{ minHeight: "auto" }}
                iconPosition="start"
                
                />
                <Tab 
                key="inactive"
                value="three" 
                label="In Active"
                icon={<Label sx={{ mr: 1 }} color='error'>{devices.inActive.length}</Label>} 
                style={{ minHeight: "auto" }}
                iconPosition="start"
                   />
              </TabList>

              <TabPanel kay="one" value="one" index={0} sx={{ p: 0}}>
                
                <DeviceList key="one" clientInfo={clientList} totalData={totalData} total={devices.all} handleDialog={handleDialog} handleMoremenu={handleMoremenu} reloadClientsData={reloadClientsData} getClientid={getClientId} updateClientDevices={updateClientDevices} selectedClient={selectedClient} onClientChange={handleClientChange} handleReloadPage={handleReloadPage} handleDisplay={handleDisplay}/>
              </TabPanel>

              <TabPanel kay="Two" value="two" index={1} sx={{ p: 0}}>
                <DeviceList key="two" clientInfo={clientList} totalData={totalData} total={devices.active} handleDialog={handleDialog} handleMoremenu={handleMoremenu} reloadClientsData={reloadClientsData} getClientid={getClientId} updateClientDevices={updateClientDevices} selectedClient={selectedClient} onClientChange={handleClientChange} handleReloadPage={handleReloadPage} handleDisplay={handleDisplay}/>
              </TabPanel>

              <TabPanel  kay="three" value="three" index={2} sx={{ p: 0}}>
                <DeviceList key="three" clientInfo={clientList} totalData={totalData} total={devices.inActive} handleDialog={handleDialog}  handleMoremenu={handleMoremenu} reloadClientsData={reloadClientsData} getClientid={getClientId}  updateClientDevices={updateClientDevices} selectedClient={selectedClient} onClientChange={handleClientChange} handleReloadPage={handleReloadPage} handleDisplay={handleDisplay}/>
              </TabPanel>
              
          </TabContext>
        </Card>
      </Container>
    </Page>
  );
}