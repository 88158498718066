import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Link
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu, UserForm } from '../sections/@dashboard/user';
// mock
import { getLicensePlateData } from 'src/api/vehicles';
import ImageIcon from '@mui/icons-material/Image';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sno', label: 'S No', alignRight: false },
  { id: 'SnapshotUrl', label: 'Snapshot', alignRight: false },
  { id: 'LicensePlateNumber', label: 'Vehicle Number', alignRight: false },
  { id: 'Direction', label: 'Entry/Exit', alignRight: false },
  { id: 'devicename', label: 'Camera', alignRight: false },
  { id: 'VehicleTime', label: 'Vehicle Time', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (row) => `${row.Direction.toLowerCase()} ${row.LicensePlateNumber.toLowerCase()} ${row.VehicleTime.toLowerCase()} ${row.devicename.toLowerCase()}`.indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function LicensePlate() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [vehicleData, setVehicleData] = useState([]);

  const [timer, setTimer] = useState(false);
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleTimer = () => {
    setTimer((timer) => !timer)
  }
  
  useEffect(() => {
    getLicensePlateData((response) => {
      if (response.status === 200) {
        setVehicleData(response.data)
      }
      setTimeout(() => handleTimer(), 2000)
    })
  }, [timer])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = vehicleData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vehicleData.length) : 0;

  const filteredUsers = applySortFilter(vehicleData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;


  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            License Plates info
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={vehicleData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                   
                    const { Id, SnapshotUrl, LicensePlateNumber, Direction, devicename, VehicleTime } = row;
                    const isItemSelected = selected.indexOf(LicensePlateNumber) !== -1;
                    const sno = ((page * rowsPerPage) + index) + 1

                    return (
                      <TableRow
                        hover
                        key={Id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left">{sno}</TableCell>
                        <TableCell align="left">
                          <Link target='blank' href={SnapshotUrl}>
                            <ImageIcon></ImageIcon>
                          </Link>
                        </TableCell>
                        <TableCell sx={{ textTransform: "uppercase" }} align="left">{LicensePlateNumber}</TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }} align="left">{Direction}</TableCell>
                        <TableCell align="left">{devicename}</TableCell>
                        <TableCell align="left">{VehicleTime}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={vehicleData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
