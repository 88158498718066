import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
 } from '@mui/material';
import { useState } from 'react';
import VideoPlayerDialog from './VideoPlayerDialog';
import Iconify from 'src/components/Iconify';



export default function ReportTable({columns, rows, title, type}) {

  // console.log(rows[type])

  const [open, setOpen] = useState({ isOpen: false, url: null});


  const handleClickOpen = (url) => {
    setOpen({...open, url: url, isOpen: true});
  };

  const handleClose = () => {
    setOpen({...open, url: null, isOpen: false});
  };

  let isValid = false


  return (
    <Box>
      <VideoPlayerDialog
       open={open.isOpen}
      handleClose={handleClose}
      url={open.url}/>
      <Typography sx={{py: 3, pl: 1}} variant='h5'>{`${title} Details`}</Typography>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.dataKey}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {type === 'location' ? Object.keys(rows).map((cameraName, main_idx) => rows[cameraName].map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  {columns.map((column) => {
                    return column.label === "Video" ? (
                      <TableCell key={column.dataKey} onClick={() => handleClickOpen(row[column.dataKey])}><IconButton><Iconify width={24} height={24} icon="eva:play-circle-outline" /> </IconButton></TableCell>
                    ) : column.dataKey === "devicename" && idx === 0 ? (
                      <TableCell rowSpan={rows[cameraName].length} key={column.dataKey}>{column.dataKey == "sno" ? idx+1 : column.dataKey === 'security_status' ? title: row[column.dataKey]}</TableCell>
                    ): column.dataKey === "sno" && idx === 0 ? (
                      <TableCell rowSpan={rows[cameraName].length} key={column.dataKey}>{main_idx+1}</TableCell>
                    ): !["sno", "devicename"].includes(column.dataKey) && (
                      <TableCell key={column.dataKey}>{column.dataKey == "sno" ? idx+1 : column.dataKey === 'security_status' ? title: row[column.dataKey]}</TableCell>
                    );
                  })}
                </TableRow>
              ))) : Object.keys(rows).includes(type) && rows[type].length > 0 ? rows[type].map((row, idx) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                      {columns.map((column) => {
                        return column.label === "Video" ? (
                          <TableCell key={column.dataKey} onClick={() => handleClickOpen(row[column.dataKey])}><IconButton><Iconify width={24} height={24} icon="eva:play-circle-outline" /> </IconButton></TableCell>
                        ) : (
                          <TableCell key={column.dataKey}>{column.dataKey == "sno" ? idx+1 : column.dataKey === 'security_status' ? title: row[column.dataKey]}</TableCell>
                        );
                      })}
                    </TableRow>
                  );
                }): (
                  <TableRow>
                    <TableCell colSpan={columns.length} align='center'>--No Data--</TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}