import axios from '../axios'
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';
import { jsonToFormData } from 'src/utils/config';

// get cameras data
const getCamerasList_Live = (device_id,callback) => {
    axios({
        method: 'get',
        url: `${getURL('/client/cameras-live')}?camera_id=${device_id}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}



const getGroupsByClient = (body,callback) => {
    //debugger
    let url = `${getURL('/camera-groups/get-groups-by-client')}`;

    if(body && body.client_id){
        url+=`?client_id=${body.client_id}`;
    }

    axios({
        method: 'get',
       // url: `${getURL('/camera-groups/get-groups-by-client')}`,
        url:url,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const InsertUpdateEscStatus = (body,callback)=>{
    axios({
        method: 'post',
        url: getURL('client-info/add-escalation-settings'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getEscStatus = (client_id,callback) =>{
    axios({
        method: 'get',
        url: `${getURL('/client-info/escalation-settings')}?client_id=${client_id}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getCamerasByGroupID = (group_id,callback) => {
     axios({
        method: 'get',
        url:  `${getURL('/camera-groups/get-cameras-by-group')}?group_id=${group_id}`,
        validateStatus: (status) => handleResponse(status)
     }).then((response) => callback(response))
}


const getCamerasList = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('devices')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

export { getCamerasList_Live ,getGroupsByClient ,getCamerasByGroupID ,getCamerasList,InsertUpdateEscStatus,getEscStatus }