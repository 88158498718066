import React, { useState, useEffect } from 'react';
import {
  Grid, Button, TextField, Typography, TableCell, TableRow,Box,
  TableBody, Table, TableHead, TableContainer, TablePagination, OutlinedInput,Checkbox,  createFilterOptions,

  FormControl, InputLabel, MenuItem, Select, Autocomplete, InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getClientList, getCamerasList, getEvents, getFalseEventsReport } from 'src/api/EventReports/EventReports';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Iconify from 'src/components/Iconify';
import SearchNotFound from 'src/components/SearchNotFound';
import * as XLSX from 'xlsx';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';




const iconCamera = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIconCamera = <CheckBoxIcon fontSize="small" />;

const iconClient = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIconClient = <CheckBoxIcon fontSize="small" />;



// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  marginLeft: 15,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const months = [
  { name: 'January', value: '1' },
  { name: 'February', value: '2' },
  { name: 'March', value: '3' },
  { name: 'April', value: '4' },
  { name: 'May', value: '5' },
  { name: 'June', value: '6' },
  { name: 'July', value: '7' },
  { name: 'August', value: '8' },
  { name: 'September', value: '9' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' },
];

const FalseEvents = () => {
  const [filterName, setFilterName] = useState('');
  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const [inputValue, setInputValue] = useState([]);
  const [error, setError] = useState(false);
  const [isMonthSelected, setIsMonthSelected] = useState(false);
  const [isYearSelected, setIsYearSelected] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectEvent, setSelectEvent] = useState('');
  const [clientInfo, setClientInfo] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [selectedClientCameras, setSelectedClientCameras] = useState([]);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [events, setEvents] = useState([]);
 

  const currentYear = new Date().getFullYear();
  const years = [(currentYear - 1).toString(), currentYear.toString(), (currentYear + 1).toString()];

  const [checkClientValue,setCheckClientValue]=useState(false)
  const [activeClients, setActiveClients] = useState([])
  const [activeClientsValue,setActiveClientsValue]=useState([])
  const [openClientAuto,setOpenClientAuto]=useState(false)

  const [checkCameraValue,setCheckCameraValue]=useState(false)
  const [activeCameras, setActiveCameras] = useState([])
  const [openCameraAuto,setOpenCameraAuto]=useState(false)


  useEffect(() => {
    getClientList((response) => {
      if (response.status === 200) {
        setClientInfo(response.data);
      }
    });
    getCamerasList((response) => {
      if (response.status === 200) {
        setCameras(response.data);
      }
    });
    getEvents((response) => {
      if (response.status === 200) {
        setEvents(response.data.filter(event => event.EventTypeStatus === 0));
        // console.log("events", response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (activeClientsValue.length>0) {
      const clientIds = activeClientsValue.map(client => client.ID);
      const filteredCameras = cameras.filter(camera => clientIds.includes(camera.clientid));
      setSelectedClientCameras(filteredCameras);
    } else {
      setSelectedClientCameras([]);
    }
  }, [activeClientsValue]);

  const handleButtonClick = () => {
    if (!value1) {
      setError(true);
      setIsMonthSelected(true);
    }
    if (!value2) {
      setError(true);
      setIsYearSelected(true);
    }
    if(!selectEvent){
      setError(true);

    }


    if (value1 && value2 && selectEvent) {
      const data = {
        month: value1.value,
        year: value2,
        client_id: activeClients ? `${activeClients}` : "",
        event_type: parseInt(selectEvent, 10),
        //camera_id: selectedCameras.map(camera => camera.deviceid).length > 0 ?  selectedCameras.map(camera => camera.deviceid) : "", // Send selected camera IDs
        camera_id:activeCameras ? `${activeCameras}` : "",
      };
      // console.log('Button clicked with data:', data); // Log the data being used
      // debugger
      getFalseEventsReport(data, (response) => {
        // console.log('API response received in button click handler:', response); // Log the response received
        if (response.status === 200 && response.data != null) {
            setData(response.data);
        }
      });
    }
  };

  // const handleClientChange = (event, value) =>{
  //   debugger

  //   setClientMatchedCameras([])
  //   setCheckValue(false)
  //   setActiveCameras([])
  //   if (value.some(obj => obj.hasOwnProperty('selectall'))) {
  //     if(value[0].selectall){
  //       setOpenClientAuto(true);
  //     }
  //     else{
  //       setOpenClientAuto(false);
  //     }
  //   } else {
  //     setOpenClientAuto(true);
  //   }
  
  //   if (value.length > 1 && value.every((entry, index, arr) => JSON.stringify(entry) === JSON.stringify(arr[0]))) {
  //     value = [];
  //   }
    
  //   const containsAllProperty = value.some(obj => obj.hasOwnProperty('selectall'));
  
  //   if (value.length === 0 || !containsAllProperty) {
  //     setCheckClientValue(false);
  //   }
  
  //   let actClients = [];
  //   //setDeviceErrorMessage('');
  
  //   if (value.length > 0 && !containsAllProperty) {
  //     value.forEach((row) => {
  //       actClients.push(row.ID);
  //     });
  
  //     if (clientMatchedCameras.length === actClients.length) {
  //       setCheckClientValue(true);
  //       setOpenClientAuto(false);
  //     }
  
  //     setActiveClients(actClients);
  //     setInputClientValue([...value]);
  //   }
  
  //   if (value.length > 0 && containsAllProperty) {
  //     let Clients = clientInfo;
  
  //     actClients = [];
  
  //     if (activeClients && activeClients.length === isClientList.length) {
  //       setCheckClientValue(false);
  //       // const selectedDisplayName = value.filter(obj => obj.hasOwnProperty('selectall'))[0].displayname;
  //       // const selectedClients = Clients.filter(client => client.displayname === selectedDisplayName);
  //       const Ids = value.filter(obj => !obj.hasOwnProperty('selectall')).map(obj => obj.ID);
        
  //       Clients = Clients.filter(item => !Ids.includes(item.ID));
  //       Clients.forEach((row) => {
  //         actClients.push(row.ID);
  //       });
  //       setActiveClients([...actClients]);
  //       setInputClientValue(isClientList);

  //       // selectedClients.forEach((row) => {
  //       //   actClients.push(row.ID);
  //       // });
  //       // setActiveClients(actClients);
  //       // setInputClientValue(isClientList);
  //       return;

  //       //return;
  //     }
  //     if (!checkClientValue) {
  //       Clients.forEach((row) => {
  //         actClients.push(row.ID);
  //       });
  //       setActiveClients(actClients);
  //       setInputClientValue(isClientList);
  //       setCheckClientValue(true);
  //     } else {
  //       setActiveClients([]);
  //       setInputClientValue([]);
  //     }
  //   } else if (value.length === 0) {
  //     setActiveClients([]);
  //     setCheckClientValue(false);
  //     setInputClientValue([]);
  //   }
  // }

  const handleClientChange = (event,value) =>{
    //debugger
    let actClients=[];
    if (value.some(obj => obj.hasOwnProperty('selectall'))){
      if(activeClients.length===clientInfo.length){
        setActiveClients([])
        setActiveClientsValue([])
        setCheckClientValue(false)
      }
      else{
        clientInfo.forEach(item=>{
          actClients.push(item.ID)
        })
        setActiveClients(actClients)
        setActiveClientsValue(clientInfo)
        setCheckClientValue(true)
      }
    }
    else{
      let id=value[0].ID;
      if(activeClients.includes(id)){
        let filterArr = activeClients.filter(client => client !== id);
        let filterArrNew=activeClientsValue.filter(cid => cid.ID !== id);
        // console.log(typeof activeClients[0]);
        setActiveClients(filterArr);
        setActiveClientsValue(filterArrNew)
        if(checkClientValue){
          setCheckClientValue(false)
        }
      }
      else{
        setActiveClients((prv)=>[...prv,id])
        setActiveClientsValue((prv)=>[...prv,value[0]])
      }
    }
  }

  useEffect(()=>{
    //debugger
    if(activeClients.length===clientInfo.length && activeClients.length>0){
      setCheckClientValue(true)
    }
  },[activeClients])


  const handleCameraChange = (event,value)=>{
    // debugger
    // console.log(activeClients)
    let actCameras=[];
    if (value.some(obj => obj.hasOwnProperty('selectall'))){
      if(activeCameras.length===selectedClientCameras.length){
        setActiveCameras([])
        setCheckCameraValue(false)
      }
      else{
        selectedClientCameras.forEach(item=>{
          actCameras.push(item.deviceid)
        })
        setActiveCameras(actCameras)
        setCheckCameraValue(true)
      }
    }
    else{
      let id=value[0].deviceid;
      if(activeCameras.includes(id)){
        let filterArr = activeCameras.filter(client => client !== id);
        setActiveCameras(filterArr);
        if(checkCameraValue){
          setCheckCameraValue(false)
        }
      }
      else{
        setActiveCameras((prv)=>[...prv,id])
      }
    }
  }

  
  useEffect(()=>{
    // debugger
    // console.log(activeClients)
    if(activeCameras.length===selectedClientCameras.length && activeCameras.length>0){
      setCheckCameraValue(true)
    }
  },[activeCameras])

  const handleEventChange = (event) => {
    const eventId = event.target.value;
    setSelectEvent(eventId); // Ensure this sets the correct event ID
    // console.log('Selected Event ID:', eventId); // Log selected event ID
  
    // Reset error if an event is selected
    if (eventId) {
      setError(false);
    }
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value.trim());
  };

  const columns = [
    { id: 'Sno', name: 'Sno' },
    { id: 'displayname', name: 'Client Name' },
    { id: 'devicename', name: 'Camera Name' },
    { id: 'false_event_count', name: 'False Events' },
  ];

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = data.filter((item) => (

    (item.displayname && item.displayname.toLowerCase().includes(filterName.toLowerCase())) ||
    (item.devicename && item.devicename.toLowerCase().includes(filterName.toLowerCase()))
  ));

  const isDataNotFound = filteredData.length === 0;


  const exportToExcel = () => {


    if (filteredData.length) {
      const newColumnOrder = [
        'S No',
        'Client Name',
        'Camera Name',
        'False Events',
        // 'Agent', 
      ];
      
      const headers = newColumnOrder;
      
      const formatEventRaised = (eventRaised) => {
        try {
          const date = new Date(eventRaised);
          if (isNaN(date.getTime())) {
            throw new Error('Invalid date');
          }
          return date.toISOString().slice(0, 19).replace('T', ' ');
        } catch (error) {
          console.error('Error parsing date:', error);
          return ''; // Or handle the error according to your application's needs
        }
      };
      
      const formatLatency = (latency) => {
        const hours = Math.floor(latency / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((latency % 3600) / 60).toString().padStart(2, '0');
        const seconds = (latency % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
      };
  
      
      const sortedData = filteredData.sort((a, b) => {
        return a.event_hour - b.event_hour;
    });
    
    // Then, construct the data array
    const data = [headers, ...sortedData.map((item, index) =>
        [index + 1, ...headers.slice(1).map(key => {
          if (key === 'Client Name') return item['displayname'];
          if (key === 'Camera Name') return item['devicename'];
          if (key === 'False Events') return item['false_event_count'];

          return item[key];
        })]
    )];
      
      // setHeaders(headers);
      // setData(data);



      const sheetName = "False Events";
      // const headers = Object.keys(filteredData[0]);
      // const data = [
      //   headers,
      //   ...filteredData.map((item) => headers.map((key) => item[key])),
      // ];

      const ws = XLSX.utils.aoa_to_sheet(data);
      const filename = sheetName + ".xlsx";

      const maxColumnWidths = {};
      headers.forEach((header) => {
        maxColumnWidths[header] = Math.max(
          20,
          ...data.map((row) => (row[header] || "").toString().length)
        );
      });
      const columnWidths = headers.map((header) => ({
        wch: maxColumnWidths[header],
      }));

      ws["!cols"] = columnWidths;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName || "Sheet 1");

      XLSX.writeFile(wb, filename);
    } else {
      alert("No data to Export.");
    }
  };

  const handleCameraFilterOptions = (options,params) =>{
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    
    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeCameras.includes(option.deviceid));
    const unselectedOptions = filtered.filter(option => !activeCameras.includes(option.deviceid));
    
    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];
  
    return [{ title: 'Select All', selectall: true }, ...sortedOptions];
  }

  const handleFilterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    
    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeClients.includes(option.ID));
    const unselectedOptions = filtered.filter(option => !activeClients.includes(option.ID));
    
    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];
  
    return [{ title: 'Select All', selectall: true }, ...sortedOptions];
  };


  return (
    <Grid sx={{ marginLeft: '1rem' }}>
      <Grid container spacing={2} alignItems="center" sx={{ marginTop: '0.2rem' }}>
        <Grid item xs={3}>
          <Autocomplete
            value={value1}
            onChange={(event, newValue) => {
              setIsMonthSelected(false);
              setValue1(newValue);
            }}
            options={months}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Month"
                label="Month"
                variant="outlined"
                error={error && isMonthSelected}
              />
            )}
          />
          {error && isMonthSelected && <Typography variant="body2" color="error">Select Month</Typography>}
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            value={value2}
            onChange={(event, newValue) => {
              setIsYearSelected(false);
              setValue2(newValue);
            }}
            options={years}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Year"
                label="Year"
                variant="outlined"
                error={error && isYearSelected}
              />
            )}
          />
          {error && isYearSelected && <Typography variant="body2" color="error">Select Year</Typography>}
        </Grid>
        <Grid item xs={3} sx={{ height: '100%' }}>

            <Autocomplete
              multiple
              fullWidth
              id="client"
              options={clientInfo}
              
              //value={(checkClientValue) ? [{ title: 'Select All', selectall: true }] : inputClientValue.filter((client) => activeClients.includes(client.ID))}
              value={[]}

              getOptionLabel={(option) => {
                return (option.selectall && clientInfo.length === activeClients.length) ? 'Select All' : `${option.displayname}`;
              }}
              onChange={handleClientChange}
              disableCloseOnSelect={openClientAuto}
              open={openClientAuto}
              onOpen={() => setOpenClientAuto(true)}
              onClose={() => setOpenClientAuto(false)}
              
              
              // filterOptions={(options, params) => {
              //   const filter = createFilterOptions();
              //   const filtered = filter(options, params);
              //   //console.log('test',options)
              //   return [{ title: 'Select All', selectall: true }, ...filtered];
              // }}

              filterOptions={handleFilterOptions}


              renderOption={(props, option, { selected }) => {
                const key = `camid-${option.ID}`
                return (<li {...props} key={key}>
                  
                  {option.selectall ? (
                    <>
                      <Checkbox
                        icon={iconClient}
                        checkedIcon={checkedIconClient}
                        style={{ marginRight: 8 }}
                        checked={checkClientValue}
                        //onChange={() => setCheckClientValue(!checkClientValue)}
                      />
                      {option.title}
                    </>
                  ) : (
                    <>
                      <Checkbox
                        icon={iconClient}
                        checkedIcon={checkedIconClient}
                        style={{ marginRight: 8 }}
                        checked={activeClients.includes(option.ID)}
                        onChange={() => {
                          const isChecked = activeClients.includes(option.ID);
                          const newActiveClients = isChecked
                            ? activeClients.filter((clientId) => clientId !== option.ID)
                            : [...activeClients, option.ID];
                            setActiveClients(newActiveClients);
                        }}
                      />
                      {`${option.displayname}`}
                    </>
                  )}
                
                </li>)
              }
              }
              renderInput=
              {(params) => 
                <TextField {...params} label="Client" onFocus={() => setOpenClientAuto(true)}/>
              }
            />

        </Grid>
        <Grid item xs={3}>

        <Autocomplete
              multiple
              fullWidth
              id="camera"
              options={selectedClientCameras}
              value={[]}
              getOptionLabel={(option) => {
                return (option.selectall && cameras.length === activeCameras.length) ? 'Select All' : `${option.deviceid} ${option.devicename}`;
              }}
              onChange={handleCameraChange}
              disableCloseOnSelect={openCameraAuto}
              open={openCameraAuto}
              onOpen={() => setOpenCameraAuto(true)}
              onClose={() => setOpenCameraAuto(false)}
              
              // filterOptions={(options, params) => {
              //   const filter = createFilterOptions();
              //   const filtered = filter(options, params);
              //   //console.log('test',options)
              //   return [{ title: 'Select All', selectall: true }, ...filtered];
              // }}

              filterOptions={handleCameraFilterOptions}


              renderOption={(props, option, { selected }) => {
                const key = `camid-${option.deviceid}`
                return (<li {...props} key={key}>
                  
                  {option.selectall && selectedClientCameras.length>0 ? (
                    <>
                      <Checkbox
                        icon={iconCamera}
                        checkedIcon={checkedIconCamera}
                        style={{ marginRight: 8 }}
                        checked={checkCameraValue}
                      />
                      {option.title}
                    </>
                  ) : option.deviceid ? (
                    <>
                      <Checkbox
                        icon={iconCamera}
                        checkedIcon={checkedIconCamera}
                        style={{ marginRight: 8 }}
                        checked={activeCameras.includes(option.deviceid)}
                        onChange={() => {
                          const isChecked = activeCameras.includes(option.deviceid);
                          const newActiveCameras = isChecked
                            ? activeCameras.filter((devid) => devid !== option.deviceid)
                            : [...activeCameras, option.deviceid];
                            setActiveCameras(newActiveCameras);
                        }}
                      />
                      {`${option.deviceid} ${option.devicename}`}
                    </>
                  ) : ""}
                
                </li>)
              }
              }
              renderInput=
              {(params) => 
                <TextField {...params} label="Camera" onFocus={() => setOpenCameraAuto(true)}/>
              }
        />

          {/* <Autocomplete
            multiple
            id="tags-outlined-cameras"
            options={selectedClientCameras}
            getOptionLabel={(option) => `${option.deviceid} ${option.devicename}`}
            value={selectedCameras}
            onChange={(event, newValue) => {
              setSelectedCameras(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Camera"
                placeholder="Search Camera ID Or Name..."
              />
            )}
          /> */}

        </Grid>
        <Grid item xs={3} sx={{ height: '100%' }}>
          <FormControl fullWidth>
            <InputLabel id="event-label">Event</InputLabel>
            <Select
              labelId="event-label"
              value={selectEvent}
              onChange={handleEventChange}
              label="Event"
              error={error && !selectEvent}

            >
              {events.map((event) => (
                <MenuItem key={event.Id} value={event.Id}>
                  {event.EventType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {error && !selectEvent && (
            <Typography variant="body2" color="error">
              Select Event
            </Typography>
          )}
        </Grid>
        <Grid item sx={{ marginTop: 2 }}>
          <Button variant="contained" onClick={handleButtonClick}>
            Submit
          </Button>
          <Button variant="contained" onClick={exportToExcel} sx={{ marginLeft: 1 }}>
            <InsertDriveFileIcon sx={{marginRight:'5px'}}/> Export <SaveAltIcon sx={{paddingLeft:'5px'}}/>
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <SearchStyle
          value={filterName}
          onChange={handleFilterByName}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
      </Grid>

      <Grid container spacing={2} alignItems="center" sx={{ marginTop: '1rem' }}>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f2f2f2' }}>
                {columns.map((item) => (
                  <TableCell key={item.id}>
                    {item.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell align="left">{row.displayname}</TableCell>
                  <TableCell align="left">{row.devicename}</TableCell>
                  <TableCell align="left">{row.false_event_count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            {(isDataNotFound && filterName) ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={11}>
                          <Box sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ):null}
              {(filteredData.length === 0 && !filterName) && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={11}>
                        <Box sx={{ py: 3 }}>
                        <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                            No Data
                          </Typography>
                          </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} 
          </Table>
        </TableContainer>
      </Grid>

      <Grid>
        <TablePagination
          rowsPerPageOptions={[100, 300, 500]}
          page={page}
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleRowsPerPageChange}
          sx={{ backgroundColor: '#f2f2f2' }}
        />
      </Grid>
    </Grid>
  );
};

export default FalseEvents;