import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Box,
    Typography,
    LinearProgress,
    Button,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    DialogActions,
    Divider,
    IconButton,
    TableContainer
} from "@mui/material";
import { useState } from "react";
import Iconify from "src/components/Iconify";


function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

export default function DetailsBox({dialogHandler, dialogConfig}){
    const [ video, setVideo ] = useState({open: false, url: null})
    const handleVideo = (isOpen, url=null) => {
        if (!isOpen) {
            setVideo({...video, open: false})
        } else {
            setVideo({...video, open: true, url: url})
        }
    }
    // absent_duration, details, securityname, shiftname, short_image, snapshoturl, present_duration, progress_percent
    const progress_percent = Number(dialogConfig.data.hasOwnProperty('progress_percent') ? dialogConfig.data.progress_percent: 0)
    const details = dialogConfig.detailsType === 'present' ? (dialogConfig.data.hasOwnProperty('details') ? dialogConfig.data.details: []) : dialogConfig.data.hasOwnProperty('absent_details') ? dialogConfig.data.absent_details: []
    const total_duration = dialogConfig.detailsType === 'present' ? (dialogConfig.data.hasOwnProperty('present_duration') ? dialogConfig.data.present_duration: []) : dialogConfig.data.hasOwnProperty('absent_duration') ? dialogConfig.data.absent_duration: []


    return (
        <Dialog onClose={dialogHandler} open={dialogConfig.open} fullWidth={true}
        maxWidth={'sm'}>
            <DialogContent sx={{pb: 0}}>
                {!video.open ? (<>
                    <Grid container spacing={1} sx={{pb: 2}}>
                    <Grid item md={4}>
                        <Box component="img" src={dialogConfig.data.hasOwnProperty('short_image') ? dialogConfig.data.short_image: ''} 
                        
                        sx={{ borderRadius: 50, p: 1, backgroundColor: "#efefef" }}
                        width={150} height={150}/>
                    </Grid>
                    <Grid item md={8} sx={{justifyContent:"center",  alignItems:"center", margin: "auto 0px"}}>                 
                        <Box width={"100%"} sx ={{p: 2}}>
                            <Typography variant="h6">{dialogConfig.data.hasOwnProperty('locationname') ? dialogConfig.data.locationname: ''}</Typography>      
                            <Typography color={"gray"}>{total_duration}</Typography>                      
                            
                            {dialogConfig.detailsType === 'present' && <LinearProgressWithLabel value={progress_percent} color={progress_percent > 90 ? 'success': (progress_percent > 80 ? 'warning': 'error')} />}                          
                        </Box>                     
                    </Grid>
                </Grid>
                {/* <Divider/> */}
                <TableContainer sx={{ maxHeight: 340 }}>
                    <Table stickyHeader aria-label="sticky table" sx={{mt: 2}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Camera</TableCell>
                                <TableCell>From Time</TableCell>
                                <TableCell>To Time</TableCell>
                                <TableCell>Duration</TableCell>
                                <TableCell>Video</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {details.map((row, idx) => {
                                const {cameraname, duration, fromtime, totime, videourl} = row
                                return (
                                    <TableRow key={idx}>
                                        <TableCell>{cameraname}</TableCell>
                                        <TableCell>{fromtime}</TableCell>
                                        <TableCell>{totime}</TableCell>
                                        <TableCell>{duration}</TableCell>                            
                                        <TableCell>
                                            <IconButton onClick={() => handleVideo(true, videourl)}>
                                                <Iconify icon={'eva:play-circle-outline'} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}                        
                        </TableBody>
                    </Table>
                </TableContainer>
                </>): <>
                    <IconButton onClick={() => handleVideo(false)}>
                        <Iconify icon={'eva:arrow-back-outline'} />
                    </IconButton>
                    <Box component="video"
                    sx={{ borderRadius: 1, backgroundColor: "black", mt: 1 }}
                     width="100%"
                     src={video.url} 
                     controls
                     />
                </>}                
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dialogHandler(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}