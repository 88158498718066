import React, { useState, useEffect } from "react";
import Iconify from "src/components/Iconify";
import SearchNotFound from "src/components/SearchNotFound";
import {
  Grid,
  Box,
  Autocomplete,
  Button,
  TextField,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import * as XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import { allClientMonthlyReport } from "src/api/reports";
import PropTypes from "prop-types";

// const months = [
//     'January', 'February', 'March', 'April', 'May', 'June',
//     'July', 'August', 'September', 'October', 'November', 'December'
//   ];

const months = [
  { name: "January", value: 1 },
  { name: "February", value: 2 },
  { name: "March", value: 3 },
  { name: "April", value: 4 },
  { name: "May", value: 5 },
  { name: "June", value: 6 },
  { name: "July", value: 7 },
  { name: "August", value: 8 },
  { name: "September", value: 9 },
  { name: "October", value: 10 },
  { name: "November", value: 11 },
  { name: "December", value: 12 },
];
const currentYear = new Date().getFullYear();
const years =
  //[currentYear - 1,currentYear,currentYear + 1];
  [
    { name: (currentYear - 1).toString(), value: currentYear - 1 },
    { name: currentYear.toString(), value: currentYear },
    { name: (currentYear + 1).toString(), value: currentYear + 1 },
  ];
const options3 = ["Choice X", "Choice Y", "Choice Z"];

const AllClientMonthlyReport = () => {
  const [filterName, setFilterName] = useState("");
  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const [value3, setValue3] = useState(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [isMonthSelected, setIsMonthSelected] = useState(false);
  const [isYearSelected, setIsYearSelected] = useState(false);
  const [row, rowChange] = useState([]);
  const [page, pageChange] = useState(0);
  const [rowPerPage, rowPerPageChange] = useState(100);

  const [headers, setHeaders] = useState([]);
  const [dataReport, setDataReport] = useState([]);

  useEffect(() => {
    if (dataReport.length > 0 && headers.length > 0) {
      const ws = XLSX.utils.aoa_to_sheet(dataReport);
      const sheetName = "All Client Monthly Report";
      const filename = sheetName + ".xlsx";

      const maxColumnWidths = {};
      headers.forEach((header) => {
        maxColumnWidths[header] = Math.max(
          20,
          ...data.map((row) => (row[header] || "").toString().length)
        );
      });
      const columnWidths = headers.map((header) => ({
        wch: maxColumnWidths[header],
      }));

      ws["!cols"] = columnWidths;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName || "Sheet 1");

      XLSX.writeFile(wb, filename);
    }
  }, [dataReport, headers]);

  const filteredData = data.filter((item) => {
    // console.log(item)
    return (
      item.Name && item.Name.toLowerCase().includes(filterName.toLowerCase())
    );
  });

  const isDataNotFound = filteredData.length === 0;

  allClientMonthlyReport.prototype = {
    numSelected: PropTypes.number,
    filterName: PropTypes.string,
    onFilterName: PropTypes.func,
  };

  //const [allClientMonReport, setAllClientMonRep] = useState([]);

  // useEffect(()=>{
  //     console.log(page,rowPerPage)
  // },[page,rowPerPage])

  const handleButtonClick = () => {
    // console.log('Dropdown 1 value:', value1);
    // console.log('Dropdown 2 value:', value2);

    if (!value1) {
      setError(true);
      setIsMonthSelected(true);
    }

    if (!value2) {
      setError(true);
      setIsYearSelected(true);
    }

    if (value1 && value2) {
      // console.log(value1.value,value2.value)
      let body = { month: value1.value, year: value2.value };

      allClientMonthlyReport(body, (response) => {
        if (response.status === 200) {
          setData(response.data);
        }
      });
    }
  };

  const columns = [
    { id: "Sno", name: "Sno" },
    { id: "Name", name: "Client Name" },
    { id: "cameras", name: "Total Cameras" },
    { id: "total_event_count", name: "Total Events" },
    { id: "true_event_count", name: "True Events" },
    { id: "false_event_count", name: "False Events" },
    { id: "escalated_event_count", name: "Escalated Events" },
  ];

  const handleChangePage = (event, newpage) => {
    pageChange(newpage);
  };

  const handleFilterByName = (event) => {
    debugger;
    const pattern = event.target.value.trim();
    setFilterName(pattern);
    //event.stopPropagation();
  };

  const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    marginLeft: 15,
    transition: theme.transitions.create(["box-shadow", "width"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
    "& fieldset": {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  }));

  const handleRowsPerPage = (e) => {
    rowPerPageChange(e.target.value);
    pageChange(0);
  };

  const exportToExcel = () => {
    console.log("reportdata", filteredData);
    debugger;
    if (filteredData.length > 0) {
      debugger;
      const newColumnOrder = [
        "S No",
        "Client Name",
        "Total Cameras",
        "Total Events",
        "True Events",
        "False Events",
        "Escalated Events",
      ];

      const headers = newColumnOrder;

      const dataReport = [
        headers,
        ...filteredData.map((item, index) => [
          index + 1,
          ...headers.slice(1).map((key) => {
            if (key === "Client Name") return item["Name"];
            if (key === "Total Cameras") return item["cameras"];
            if (key === "Total Events") return item["total_event_count"];
            if (key === "True Events") return item["true_event_count"];
            if (key === "False Events") return item["false_event_count"];
            if (key === "Escalated Events")
              return item["escalated_event_count"];
            return item[key];
          }),
        ]),
      ];

      setHeaders(headers);
      setDataReport(dataReport);
    } else {
      // setHeaders([]);
      // setDataReport([]);
      alert("No data to Export.");
      return;
    }
  };

  return (
    <Grid sx={{ marginLeft: "1rem" }}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ marginTop: "0.2rem" }}
      >
        <Grid item xs={3}>
          <Typography variant="subtitle2" component="div">
            Month
          </Typography>
          <Autocomplete
            value={value1}
            onChange={(event, newValue) => {
              setIsMonthSelected(false);
              setValue1(newValue);
            }}
            options={months}
            getOptionLabel={(option) => option.name} // Display month names
            //getOptionSelected={(option, value) => option.name === value.value} // Ensure proper comparison for selection
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Month"
                variant="outlined"
                error={error && isMonthSelected}
              />
            )}
          />
          {error && isMonthSelected && (
            <Typography variant="body2" color="error">
              Select Month
            </Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" component="div">
            Year
          </Typography>
          <Autocomplete
            value={value2}
            onChange={(event, newValue) => {
              setIsYearSelected(false);
              setValue2(newValue);
            }}
            options={years}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Year"
                variant="outlined"
                error={error && isYearSelected}
              />
            )}
          />
          {error && isYearSelected && (
            <Typography variant="body2" color="error">
              Select Year
            </Typography>
          )}
        </Grid>
        <Grid item xs={3} sx={{ marginTop: 2 }}>
          <Button variant="contained" onClick={handleButtonClick}>
            Submit
          </Button>
          <Button
            variant="contained"
            sx={{ marginLeft: 1 }}
            onClick={exportToExcel}
          >
            Export To Excel
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <SearchStyle
          value={filterName}
          onChange={handleFilterByName}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
      </Grid>

      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{ marginTop: "1rem" }}
      >
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#f2f2f2" }}>
                  {columns.map((item) => {
                    return <TableCell key={item.id}>{item.name}</TableCell>;
                  })}
                </TableRow>
              </TableHead>

              <TableBody>

              {filteredData.length === 0 ? (
                  <TableRow>
                    {/* <TableCell colSpan={5} align="center">No Data</TableCell> */}
                    <TableCell colSpan={7} >
                    <Typography  variant="body1" align="center" sx={{ fontWeight: 'bold' }}>
               No Data
              </Typography>
                    </TableCell>

                  </TableRow>
                ) : (
                filteredData
                  .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow key={`${item.EventNo}-${index}`}>
                        {columns.map((column, i) => {
                          if (column.id === "Sno") {
                            return (
                              <TableCell key={i}>
                                {page * rowPerPage + index + 1}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={i}>{item[column.id]}</TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })
                )}
              </TableBody>


              {/* {isDataNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )} */}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Grid>

      <Grid>
        <TablePagination
          rowsPerPageOptions={[100, 300, 500]}
          page={page}
          count={filteredData.length}
          rowsPerPage={rowPerPage}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleRowsPerPage}
          sx={{ backgroundColor: "#f2f2f2" }}
        />
      </Grid>
    </Grid>
  );
};

export default AllClientMonthlyReport;
