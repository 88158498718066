import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  FormControl,
  DialogActions,
  InputLabel,
  Select,
  MenuItem,
  Container,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { getURL } from 'src/utils/config';
import axios from "axios";
import dayjs from "dayjs";
import MohiniBarGraph from "./MohiniBarGraph";
import { getMohiniDahboard } from "src/api/ShiftMaster";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

export default function MohiniDashBoard() {
  const [fromDate, setFromDate] = useState(dayjs().startOf("month").hour(0).minute(0));
  const [toDate, setToDate] = useState(dayjs().endOf("day"));
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [apiData, setApiData] = useState(null);
  const [locationNames, setLocationNames] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [shiftTypesNames, setShiftTypesNames] = useState([]); // Initialize as an empty array
  const [selectedShiftTypes, setSelectedShiftTypes] = useState([]); // Initialize as an empty array
  const [dashboardData,setDashboardData]= useState([]); // Initialize as an empty array
  const [errorMessage,setErrorMessage] = useState(false); // Initial

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = getURL("employee-shifts-info/location-names?status=1");
      const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: AUTH_TOKEN,
          },
        });

        const extractedLocationNames = response.data.map(item => item.LocationName);
        setLocationNames(extractedLocationNames);
        setApiData(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedLocationId !== null ) {
      //   console.log("selected ID",selectedLocationId);
        const dynamicUrl = getURL(`shift-mon-timings?Status=1&LocationId=${selectedLocationId}`)
        const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
  
        try {
          const response = await axios.get(dynamicUrl, {
            headers: {
              Authorization: AUTH_TOKEN,
            },
          });

          console.log("data for selected Location ",response.data)
          const extractedShiftTypes = response.data.map(item => ({
            ID:item.Id,
            ShiftName: item.ShiftName,
            // EntryFromTime: item.EntryFromTime, // Add the actual property name from your API response
            // EntryToTime: item.EntryToTime, 
            ExitFromTime: item.ExitFromTime, // Add the actual property name from your API response
            ExitToTime: item.ExitToTime     // Add the actual property name from your API response
          }));
          // const extractedShiftTypes = response.data.map(item => item.ShiftName);
          setShiftTypesNames(extractedShiftTypes);
          // console.log("location and shifts",extractedShiftTypes)
          // console.log("location and shifts",extractedShiftTypes.toTime)

          setApiData(prevData => {
            // Make sure to merge previous data with the new data
            const newData = [...prevData, ...response.data];
            return newData;
          });

          // setApiData(response.data);  // Update apiData after setShiftTypes
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };
  
    // Use data from the first useEffect
    fetchData();
  }, [selectedLocationId]);



  const handleShiftType = (event, value) => {
    setSelectedShiftTypes(value);
  
    //   const selectedShiftData = apiData.find(item => item.ShiftName === value);
    
    //   if (selectedShiftData) {
    //   } else {
    //     console.error('Selected shift not found in data.');
    //   }
  
    };


  const handleDetectedType = (event, value) => {
    setSelectedLocation(value);
    // setFromDate(null);
    // setToDate(null);
    const selectedLocationObject = apiData.find((location) => location.LocationName === value);
    setSelectedLocationId(selectedLocationObject ? selectedLocationObject.Id : null);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (newDate) => {
    setToDate(newDate);
  };

  const handleButtonClick = () => {
    const formateFromDate = dayjs(fromDate).format('YYYY-MM-DD HH:mm:ss');
    const formateToDate = dayjs(toDate).format('YYYY-MM-DD HH:mm:ss');

    if(!fromDate || !toDate){
        setErrorMessage(true)
        return false
    }

    const data ={
    from_date: formateFromDate,
    to_date: formateToDate,
    screen_id: selectedLocationId ? selectedLocationId : 0,
    show_id: selectedShiftTypes.ID ?selectedShiftTypes.ID : 0,
    }
    console.log("data",data);
    getMohiniDahboard(data,(response)=>{
        if(response.status === 200 && response.status != null) {
            console.log(response.data);
            setDashboardData(response.data);
        }
    })
    // Implement your logic here
  };

  const handleClearButtonClick = () => {
    setFromDate(null);
    setToDate(null);
    setSelectedLocation([]);
    setSelectedShiftTypes([]);
    setApiData(null);
    setShiftTypesNames([]);
    
  };






  return (
    <>

      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="From Date"
                    id="from-date"
                    // ampm={false}
                    format="YYYY-MM-DD HH:mm"
                    value={fromDate}
                    // viewRenderers={{
                    //     hours: renderTimeViewClock,
                    //     minutes: renderTimeViewClock,
                    //     seconds: renderTimeViewClock,
                    //   }}
                    ampm={false}
                    required={true}
                    onChange={handleFromDateChange}
                    sx={{ border: "none" }}
                    maxDate={dayjs()}
                  />
                </LocalizationProvider>
                { errorMessage && !fromDate &&  <Typography color="error" variant="body2" align="left" marginTop={1}>Please Select FromDate</Typography>} 
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  sx={{width: "100%"}}
                  label="To Date"
                  id="to-date"
                  format="YYYY-MM-DD HH:mm"
                  value={toDate}
                  // viewRenderers={{
                  //   hours: renderTimeViewClock,
                  //   minutes: renderTimeViewClock,
                  //   seconds: renderTimeViewClock,
                  // }}
                  ampm={false}
                  onChange={handleToDateChange}
                  maxDate={dayjs()}
                />
              </LocalizationProvider>
              { errorMessage && !toDate &&  <Typography color="error" variant="body2" align="left" marginTop={1}>Please Select ToDate</Typography>} 

          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel id="location-name-label">Screen Name</InputLabel>
            <Select
              labelId="location-name-label"
              label="Screen Name"
              value={selectedLocation}
              onChange={(e) => handleDetectedType(e, e.target.value)}
            >
              {locationNames.sort((a, b) => a.localeCompare(b)).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
        <FormControl fullWidth>

                <InputLabel id="shift-type-label" 
                // sx={{ color: shiftTypeError ? 'red' : 'gray' }}
                >Show Name</InputLabel>
                    <Select
                        labelId="shift-type-label"
                        label="Show Type"
                        value={selectedShiftTypes}
                        onChange={(e) => handleShiftType(e, e.target.value)}
                        // error={shiftTypeError}
                    >
                    
                    {
                        shiftTypesNames
                        .slice()
                        .sort((a, b) => a.ShiftName.localeCompare(b.ShiftName))
                        .map((option) => {
                            // Convert '00:00' to '12:00' if needed
                            const extfromTime = option.ExitFromTime === '00:00:00' ? '12:00:00' : option.ExitFromTime;
                            const exttoTime = option.ExitToTime === '00:00:00' ? '12:00:00' : option.ExitToTime;
                    
                    
                            const formatTimes = (time) => {
                            const [hours, minutes] = time.split(':');
                            const date = new Date(2000, 0, 1, hours, minutes);
                            return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                        };

                            const extfromTimeFormatted = option.ExitFromTime === '00:00:00' ? '12:00 AM' : formatTimes(extfromTime);
                            const exttoTimeFormatted = option.ExitToTime === '00:00:00' ? '12:00 AM' : formatTimes(exttoTime);
                        
                            return (
                            <MenuItem key={option.ShiftName} value={option}>
                            {`${option.ShiftName} (${extfromTimeFormatted} - ${exttoTimeFormatted})` }
                            </MenuItem>
                            );
                        })
                    }
                    
                    </Select>
        </FormControl>
          
        </Grid>
        <Grid item xs={12} sm={6} md={2} container justifyContent="flex-start" alignItems="center">
          <Button
            onClick={handleButtonClick}
            variant="contained"
            color="primary"
            sx={{ marginRight: "1rem" }}
          >
            Get Reports
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={handleClearButtonClick}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>

      <div style={{ marginTop: "30px" }}></div>

      <Container>
        <MohiniBarGraph data={dashboardData} ></MohiniBarGraph>
      </Container>
    </>
  );
}
