import React, { useState,useEffect } from 'react';
import { filter } from 'lodash';
import Iconify from 'src/components/Iconify';
import {
  InputAdornment,
  Grid,
  Box,
  Autocomplete,
  Button,
  TextField,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableContainer,TablePagination,Toolbar,OutlinedInput, Stack
} from '@mui/material';
import PropTypes from 'prop-types';
import Scrollbar from 'src/components/Scrollbar';
import { styled } from '@mui/material/styles';
import { Construction } from '@mui/icons-material';
//import OutlinedInput from '@mui/material';
import { monEscalationReport } from 'src/api/reports';
import SearchNotFound from 'src/components/SearchNotFound';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';


const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    // justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
  }));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    marginLeft: 15,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  }));

  const months = [
    { name: 'January', value: '1' },
    { name: 'February', value: '2' },
    { name: 'March', value: '3' },
    { name: 'April', value: '4' },
    { name: 'May', value: '5' },
    { name: 'June', value: '6' },
    { name: 'July', value: '7' },
    { name: 'August', value: '8' },
    { name: 'September', value: '9' },
    { name: 'October', value: '10' },
    { name: 'November', value: '11' },
    { name: 'December', value: '12' }
  ];


const MonthlyEscalationReport = ({clientList}) => {

  const options3 =clientList //['Choice X', 'Choice Y', 'Choice Z'];

  const [filterName, setFilterName] = useState('');  

  const [value1, setValue1] = useState(null);
  
  const [value3, setValue3] = useState(null);

  const [value2, setValue2] = useState(null);
  const currentYear = new Date().getFullYear();
  const years = [(currentYear - 1).toString(),currentYear.toString(),(currentYear+1).toString()];

  //console.log(years)

  const [error,setError]=useState(false)
  const [isMonthSelected,setIsMonthSelected]=useState(false)
  const [isYearSelected,setIsYearSelected]=useState(false)
  const [isClientSelected,setIsClientSelected]=useState(false)



const [data, setData] = useState([])
const [row,rowChange] = useState([])
const [page,pageChange]=useState(0)
const [rowPerPage,rowPerPageChange]=useState(100)

const [headers, setHeaders] = useState([]);
const [dataReport, setDataReport] = useState([]);
// useEffect(() => {
//     setData(values)
// }, [])

useEffect(() => {
    if (dataReport.length > 0 && headers.length > 0) {
      const ws = XLSX.utils.aoa_to_sheet(dataReport);
      const sheetName = "Monthly Escalation Report";
      const filename = sheetName + '.xlsx';

      const maxColumnWidths = {};
      headers.forEach(header => {
        maxColumnWidths[header] = Math.max(
          20,
          ...data.map(row => (row[header] || '').toString().length)
        );
      });
      const columnWidths = headers.map(header => ({
        wch: maxColumnWidths[header]
      }));

      ws['!cols'] = columnWidths;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName || 'Sheet 1');

      XLSX.writeFile(wb, filename);
    }
  }, [dataReport, headers]); 


const handleButtonClick = () => {
    // console.log(value1,value2)
    pageChange(0)
    if(!value1){
        setError(true)
        setIsMonthSelected(true)
    }

    if(!value2){
        setError(true)
        setIsYearSelected(true)
    }

    //let clientList=value3.map(item=>item.ID)

    if(!value3){
        setError(true)
        setIsClientSelected(true)
    }

    if(value1 && value2 && value3){
        let month=value1.value
        let year=value2
        let clientId=value3.ID
        let data={month:month,year:year,client_id:clientId}
        monEscalationReport(data,(result)=>{
           if(result.status===200){
             setData(result.data)
           }
        })
        //console.log(value1.value,value2,value3.ID)
        
    }

    //  let month=value1?(value1.value,setError(false),setIsMonthSelected(false)):(setError(true),setIsMonthSelected(true))   
    //  let year = value2 ? (value2, setError(false), setIsYearSelected(false)) : (setError(true), setIsYearSelected(true));
    
     // let clientList=value3.map(item=>item.ID)

    //console.log(month,year,clientList)
  };

    const columns = //clientList
    [
        { id: 'Sno', name: 'Sno' },
        { id: 'EventNo', name: 'Event Number' },
        { id: 'devicename', name: 'Camera Name' },
        { id: 'VideoUrl', name: 'Escalation Url' }
    ]

    const values = [
        { EventNo: '123', CameraName: 'test1', EscalationUrl: 'abc.com' },
        { EventNo: '456', CameraName: 'test2', EscalationUrl: 'def.com' },
        { EventNo: '789', CameraName: 'test3', EscalationUrl: 'ghi.com' },
        { EventNo: '101112', CameraName: 'test4', EscalationUrl: 'jkl.com' },
        { EventNo: '131415', CameraName: 'test5', EscalationUrl: 'mno.com' },
        { EventNo: '161718', CameraName: 'test6', EscalationUrl: 'pqr.com' },
        { EventNo: '192021', CameraName: 'test7', EscalationUrl: 'stu.com' },
        { EventNo: '222324', CameraName: 'test8', EscalationUrl: 'vwx.com' },
        { EventNo: '252627', CameraName: 'test9', EscalationUrl: 'yz.com' },
        { EventNo: '282930', CameraName: 'test10', EscalationUrl: 'xyz.com' }
    ];




const handleChangePage = (event,newpage)=>{
    if(newpage===0){
        pageChange(0);
    }
    else{
        pageChange(newpage)
    }
}

const handleRowsPerPage = (e)=>{
    rowPerPageChange(e.target.value)
    pageChange(0)
}

const handleFilterByName = (event) => {
    debugger
    const pattern=event.target.value.trim()
    setFilterName(pattern);
  };

  const filteredData = data.filter((item) => {
    //console.log(item)
    return (
      (item.EventNo && item.EventNo.toLowerCase().includes(filterName.toLowerCase())) ||
      (item.devicename && item.devicename.toLowerCase().includes(filterName.toLowerCase())) ||
      (item.VideoUrl && item.VideoUrl.toLowerCase().includes(filterName.toLowerCase()))
    );
  });
  
  const isDataNotFound = filteredData.length === 0;

//   useEffect(()=>{
//     console.log(filterName)
//   },[filterName])

  MonthlyEscalationReport.prototype = {
    numSelected: PropTypes.number,
    filterName: PropTypes.string,
    onFilterName: PropTypes.func,
  }

  const exportToExcel = () => {
    console.log("reportdata",data.length)
    debugger
        if (data.length > 0) {
            debugger
            const newColumnOrder = [
                'S No',
                'Event No',
                'Camera Name',
                'Escalation Url'
            ];
            
            const headers = newColumnOrder;
                
            const dataReport = [headers, ...data.map((item, index) =>
                [index + 1, ...headers.slice(1).map(key => {
                    if (key === 'Event No') return item['EventNo'];
                    if (key === 'Camera Name') return item['devicename'];
                    if (key === 'Escalation Url') return item['VideoUrl'];
                    return item[key];
                })]
            )];
            
            setHeaders(headers);
            setDataReport(dataReport);
            }   
        else {
            setHeaders([]);
            setDataReport([]);
        alert('No data to Export.');
        return;
        }
  };


  return (
    <Grid sx={{ marginLeft: '1rem' }}>
        <Grid container spacing={2} alignItems="center" sx={{marginTop:'0.2rem'}}>
        
            <Grid item xs={3}>
                <Typography variant='subtitle2' component="div">
                Month
                </Typography>

                <Autocomplete
                value={value1}
                onChange={(event, newValue) => {  
                    setIsMonthSelected(false)                  
                    setValue1(newValue);
                }}
                options={months}
                getOptionLabel={(option)=>option.name}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    placeholder="Select Month"
                    variant="outlined"
                    error={error && isMonthSelected}
                    />
                )}
                />
                 {error && isMonthSelected && <Typography variant="body2" color="error">Select Month</Typography>}
            </Grid>
            
            <Grid item xs={3}>
                <Typography variant="subtitle2" component="div">
                Year
                </Typography>
                <Autocomplete
                value={value2}
                onChange={(event, newValue) => {
                    setIsYearSelected(false)
                    setValue2(newValue);
                }}
                options={years}
                getOptionLabel={(option)=>option}
                renderInput={(params) => (
                    <TextField
                    {...params}
                    placeholder="Select Year"
                    variant="outlined"
                   error={error && isYearSelected}
                    />
                )}
                />
                {error && isYearSelected && <Typography variant="body2" color="error">Select Year</Typography>} 
            </Grid>
            
            <Grid item xs={3}>
                <Typography variant="subtitle2" component="div">
                Client
                </Typography>
                  <Autocomplete                 
                  value={value3}
                  onChange={(event, newValue) => {
                      setIsClientSelected(false)
                      setValue3(newValue);
                  }}
                  options={clientList}
                  getOptionLabel={(option)=>option.displayname}
                  isOptionEqualToValue={(option, value) => option.ID === value.ID} 
                  renderOption={(props, option) => (
                    <li {...props} key={option.ID}>  
                      {option.displayname} 
                    </li>
                  )}
                  renderInput={(params) => (
                      <TextField
                      {...params}
                      placeholder="Select Client"
                      variant="outlined"
                      error={error && isClientSelected}
                      />
                  )}
                  />
                {error && isClientSelected && <Typography variant="body2" color="error">Select Client</Typography>}
            </Grid>
            
            <Grid item xs={3} sx={{marginTop:2}}>
                    <Button variant="contained" onClick={handleButtonClick}>
                    Submit
                    </Button>     
                    <Button variant="contained" sx={{marginLeft:1}} onClick={exportToExcel}>
                            Export To Excel
                    </Button>               
            </Grid>
        
        </Grid>

        <Grid container spacing={2} sx={{marginTop:2}}>
            <SearchStyle
            value={filterName}
            onChange={handleFilterByName}
            placeholder="Search"
            startAdornment={
                <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
            }
            /> 
        </Grid>        
   
        <Grid container spacing={2} alignItems="center" sx={{ marginTop: '1rem' }}>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table stickyHeader>
                <TableHead>
                            <TableRow sx={{backgroundColor: '#f2f2f2'}}>
                                {columns.map(item => {
                                    return <TableCell key={item.id}>
                                        {item.name}
                                    </TableCell>
                                })}
                            </TableRow>
                </TableHead>
                <TableBody>

                {data.length === 0 ? (
                  <TableRow>
                    {/* <TableCell colSpan={5} align="center">No Data</TableCell> */}
                    <TableCell colSpan={5} >
                    <Typography  variant="body1" align="center" sx={{ fontWeight: 'bold' }}>
               No Data
              </Typography>
                    </TableCell>

                  </TableRow>
                ) : (
                  data
                    .slice(page * rowPerPage, (page * rowPerPage) + rowPerPage)
                    .map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{row.EventNo}</TableCell>
                        <TableCell align="left">{row.devicename}</TableCell>
                        <TableCell align="left">
                          <a href={row.VideoUrl} target="_blank" rel="noopener noreferrer">{row.VideoUrl}</a>
                        </TableCell>
                        {/* <TableCell align="left">{row.EventType}</TableCell>
                        <TableCell align="left">{row.SupervisorEscalatedNotes}</TableCell>
                        <TableCell align="left">{row.EventTime}</TableCell>
                        <TableCell align="left">{row.is_supervisor_escalated}</TableCell> */}
                      </TableRow>
                    ))
                )}



                {/* {filteredData
                    .slice(page * rowPerPage, (page * rowPerPage) + rowPerPage)
                    .map((item, index) => {
                    return (
                        <TableRow key={`${item.EventNo}-${index}`}>
                        {columns.map((column, i) => {
                            if (column.id === 'Sno') {
                            return <TableCell key={i}>{page * rowPerPage + index + 1}</TableCell>;
                            } else if (column.id === 'VideoUrl') {
                            return (
                                <TableCell key={i}>
                                    <Link to={item[column.id]} target={column.id}>
                                    {item[column.id]}
                                    </Link>

                                </TableCell>
                            );
                            } else {
                            return <TableCell key={i}>{item[column.id]}</TableCell>;
                            }
                        })}
                        </TableRow>
                    );
                    })} */}
                </TableBody>

                {/* {isDataNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                )} */}
            </Table>
          </TableContainer>
        </Scrollbar>
        </Grid>

        <Grid>
            <TablePagination
                rowsPerPageOptions={[100, 300, 500]}
                page={page}
                count={filteredData.length} 
                rowsPerPage={rowPerPage}
                component="div"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleRowsPerPage}
                sx={{ backgroundColor: '#f2f2f2' }}
            />
        </Grid>
    </Grid>
  );
};

export default MonthlyEscalationReport;