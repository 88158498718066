


import React, { useEffect, useState } from 'react';
import axios from 'src/axios';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Checkbox,
  useStepContext,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ListItemText } from '@mui/material';
import Iconify from 'src/components/Iconify';
import {
  CardContent,  
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableHead,
} from '@mui/material';
import { getGroups,getClientInfo,AddClientGroupName,AddClientGroup } from 'src/api/groups';
import { getClientList } from 'src/api/analytics';
import { getDevices } from 'src/api/devices';
import GroupsMore from './GroupsMore';
import { CameraEnhance } from '@mui/icons-material';
import { iteratee } from 'lodash';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { tr } from 'date-fns/locale';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Groupsdetails= () => {
  const [data, setData] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [selectedClient, setSelectedClient] = useState(null); // State for selected client
  const [clientInfo, setClientInfo] = useState([]); // State for storing client data
  const [clientOptions, setClientOptions] = useState([]); // State for autocomplete options
  const [client, setClient] = useState('');
  const [camera, setCamera] = useState('');
  const [group, setGroup] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [open, setOpen] = useState(false);
  const [openError,setOpenError]=useState(false)
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [clientData,setClientData]=useState([])
  const [devices,setDevices]=useState([])
  const [clientCameras,setClientCameras]=useState([])
  const [selectedGroup,setSelectedGroup]=useState(null)
  const [reloadGroups,setReloadGroups]=useState(false)
  const [message,setMessage]=useState('')
  const [groupNameError, setGroupNameError] = useState(false);
  const [helperText,setHelperText] = useState('');
  const [activeCameras, setActiveCameras] = useState([])
  const [inputValue, setInputValue] = useState([]);

  // add group to Devices
  const [errorDevicesMessage, setDeviceErrorMessage] = useState('');
  const [errorClientMessage, setClientErrorMessage] = useState('');
  const [errorGroupMessage, setGroupErrorMessage] = useState('');

  const [errorMessage,setErrorMessage]=useState('')

  
  const [checkValue,setCheckValue]=useState(false)

  const [openAuto,setOpenAuto]=useState(false)

  const handleGroupReload = (val)=>{
    
    let body={group_id:0,client_id:client}
    getClientInfo(body,(response)=>{
      setClientData(response.data)
      // console.log(response.data)
    })
  }


  useEffect (()=>{

    getClientList((response) => {
      if (response.status === 200) {
         setClientInfo(response.data)
      }
    })

    getGroups((response)=>{
      setGroup(response.data)
    })


    let _body = { status: "1", limit: 0 }
    getDevices(_body, (response) => {
      let totalCams=response.data;
      if (response.status == 200) { 
        setDevices(response.data)      
      }
    })

  },[])

  const onClientChange = (event,value)=>{
    debugger
    console.log(selectedGroup)
    setDeviceErrorMessage('')
    setClientErrorMessage('')
    setGroupErrorMessage('')
    //setClientCameras([])
    setActiveCameras([])
    setOpenAuto(false)

    setSelectedGroup(null)

    if(!value || value.length===0){
      setActiveCameras([])
      setInputValue([])
      setClientData([])
    }
    setCheckValue(false)
    let id=''
    // setSelectedCameras('')
    // setSelectedGroup(0)
    setSelectedCameras([]);
    
    if(!value)
      {
        setClient('');
        setClientCameras([]);
        setSelectedCameras([]);

    }
    else{
      setClientErrorMessage('')
      id=value.ID
        setClient(id)
        const body={group_id:0,client_id:id}
        getClientInfo(body,(response)=>{
          setClientData(response.data)
          // console.log(response.data)
        })

      const clientCams=devices.filter(item=>item.clientid===id)

      // console.log("Cameras List",clientCams)
      setClientCameras(clientCams)
    }
  }

  const handleFilterByName = event => {
    setFilterName(event.target.value);
    // Perform additional filtering based on the selected client if needed
  };

  const handleOpen = () => {
    setGroupName("");
    setOpen(true);
  };
  // const handleCameraChange = (event, newValue) => {

  //   // setClientCameras([])

  //     let cameras=newValue.map(item=>item.deviceid)

      
  //     setSelectedCameras(cameras);
  // };

  useEffect(()=>{
    // console.log('selected cameras',selectedCameras)
  },[selectedCameras])

  const handleCameraChange = (event,value) =>{
    let actCams=[];
    if (value.some(obj => obj.hasOwnProperty('selectall'))){
      if(activeCameras.length===clientCameras.length){
        setActiveCameras([])
        setCheckValue(false)
      }
      else{
        clientCameras.forEach(item=>{
          actCams.push(item.deviceid)
        })
        setActiveCameras(actCams)
        setCheckValue(true)
      }
    }
    else{
      let id=value[0].deviceid;
      if(activeCameras.includes(id)){
        let filterArr = activeCameras.filter(divid => divid !== id);
        setActiveCameras(filterArr);
        if(checkValue){
          setCheckValue(false)
        }
      }
      else{
        setActiveCameras((prv)=>[...prv,id])
      }
    }
  }

  useEffect(()=>{
    debugger
    if(activeCameras.length===clientCameras.length && activeCameras.length>0){
      setCheckValue(true)
    }
  },[activeCameras])
  
  // const handleCameraChange = (event, value) => {
  //   debugger
  //   if (value.some(obj => obj.hasOwnProperty('all'))) {
  //     if(value[0].all){
  //       //let x=value[0].all
  //       setOpenAuto(true);
  //     }
  //     else{
  //       setOpenAuto(false);
  //     }
  //   } else {
  //     setOpenAuto(true);
  //   }
  
  //   if (value.length > 1 && value.every((entry, index, arr) => JSON.stringify(entry) === JSON.stringify(arr[0]))) {
  //     value = [];
  //   }
    
  //   const containsAllProperty = value.some(obj => obj.hasOwnProperty('all'));
  
  //   if (value.length === 0 || !containsAllProperty) {
  //     setCheckValue(false);
  //   }
  
  //   let actCameras = [];
  //   setDeviceErrorMessage('');
  
  //   if (value.length > 0 && !containsAllProperty) {
  //     value.forEach((row) => {
  //       actCameras.push(row.deviceid);
  //     });
  
  //     if (clientCameras.length === actCameras.length) {
  //       setCheckValue(true);
  //       setOpenAuto(false);
  //     }
  
  //     setActiveCameras([...actCameras]);
  //     setInputValue([...value]);
  //   }
  
  //   if (value.length > 0 && containsAllProperty) {
  //     let Cameras = clientCameras;
  
  //     actCameras = [];
  
  //     if (activeCameras && activeCameras.length === clientCameras.length) {
  //       setCheckValue(false);
  //       const deviceIds = value.filter(obj => !obj.hasOwnProperty('all')).map(obj => obj.deviceid);
  //       Cameras = Cameras.filter(item => !deviceIds.includes(item.deviceid));
  //       Cameras.forEach((row) => {
  //         actCameras.push(row.deviceid);
  //       });
  //       setActiveCameras([...actCameras]);
  //       setInputValue(clientCameras);
  //       //setOpenAuto(false);
  //       return;
  //     }
  //     if (!checkValue) {
  //       Cameras.forEach((row) => {
  //         actCameras.push(row.deviceid);
  //       });
  //       setActiveCameras([...actCameras]);
  //       setInputValue(clientCameras);
  //       setCheckValue(true);
  //     } else {
  //       setActiveCameras([]);
  //       setInputValue([]);
  //     }
  //   } else if (value.length === 0) {
  //     setActiveCameras([]);
  //     setCheckValue(false);
  //     setInputValue([]);
  //   }
  // };
  
  // const handleCameraChange = (event, value) => {   
  //   debugger
  //   if(value.length===clientCameras.length || value.some(obj => obj.hasOwnProperty('all'))){
  //     setOpenAuto(false)
  //   }
  //   else{
  //     setOpenAuto(true)
  //   }

  //   if(value.length>1 && value.every((entry, index, arr) => JSON.stringify(entry) === JSON.stringify(arr[0]))){
  //     value=[];
  //   }
  //     const containsAllProperty = value.some(obj => obj.hasOwnProperty('all'));

  //     if (value.length === 0 || !containsAllProperty) {
  //       setCheckValue(false);
  //     }
      
  //     let actCameras = [];
  //     setDeviceErrorMessage('');
      
  //       if (value.length > 0 && !containsAllProperty) {
        
  //         value.forEach((row) => {
  //         actCameras.push(row.deviceid);
  //       });
  
  //       if(clientCameras.length===actCameras.length){
  //         setCheckValue(true)
  //         //setOpenAuto(false)
  //       }
       
  //       setActiveCameras([...actCameras]);
  //       setInputValue([...value]);
  //     }
  
  //     if(value.length>0 && containsAllProperty){  // checked select all
  //       let Cameras=clientCameras
        
  //       actCameras = []; 
        
  //       if(activeCameras && activeCameras.length===clientCameras.length){ // uncheck select all and camera
          
  //         setCheckValue(false)
  //         const deviceIds = value.filter(obj => !obj.hasOwnProperty('all')).map(obj => obj.deviceid);
  //         Cameras=Cameras.filter(item=>!deviceIds.includes(item.deviceid))
  //         Cameras.forEach((row) => {
  //           actCameras.push(row.deviceid);
  //         });
  //         setActiveCameras([...actCameras]);
  //         setInputValue(clientCameras);
  //         //setOpenAuto(false)
  //         return
  //       }
  //       if(!checkValue){       // if select all is true (false prv) need to load all cameras
  //         Cameras.forEach((row) => {
  //           actCameras.push(row.deviceid);
  //         });
  //         setActiveCameras([...actCameras]);
  //         setInputValue(clientCameras);
  //         setCheckValue(true)
  //         //setOpenAuto(false)
  //       }
  //       else{                 // if select all is false need to empty all state variables
  //         setActiveCameras([]);
  //         setInputValue([]);
  //         //handleReload()
  //       }
  //     }
  //     else if(value.length === 0){ 
  //       setActiveCameras([]);
  //       setCheckValue(false)
  //       setInputValue([]);
  //     }  
  // };
  
  const handleClose = () => {
    setOpen(false);
    setOpenError(false);
    setGroupNameError(false);
    setHelperText('')
  };

  const saveGroupName1 = ()=>{


    if (groupName.trim() === '') {
      setGroupNameError(true);
    }
    else {



    let body = { GroupName: groupName };

    AddClientGroupName(body,(response)=>{
       // console.log(response)
      
        setGroupName("");
        setOpen(false);

        if(response.status===200){
          getGroups((response)=>{
            setGroup(response.data)
            setOpenError(true)
            setMessage('Group Name Added Successfully')
          })
        }   
    })
    setGroupNameError(false);
  }
  }


  const saveGroupName = () => {
debugger
    if (groupName.trim() === '') {
      setGroupNameError(true);
      setMessage('Group Name cannot be empty.')
    } else {
      // Check if the group name already exists
      const isGroupNameExists =group?group.some(group => group.GroupName.toLowerCase() === groupName.toLowerCase()):'';
  
      if (isGroupNameExists) {
        setGroupNameError(true);
        //setErrorMessage()
        setMessage('Group Name already exists');
      } else {
        let body = { GroupName: groupName };
  
        AddClientGroupName(body, (response) => {
          setGroupName("");
          setOpen(false);
  
          if (response.status === 200) {
            getGroups((response) => {
              setGroup(response.data);
              setOpenError(true);
              setMessage('Group Name Added Successfully');
            });
          }
        });
        setGroupNameError(false);
      }
    }
  };
  

  const handleSave = () => {
    debugger
    // if (!activeCameras || !client || !selectedGroup) {
    //   setErrorMessageOpen(true);
    // } 
    if (activeCameras.length === 0 || !client || !selectedGroup) {
      if(client === ''){
        setClientErrorMessage("Please Select client")
      }
      if(activeCameras.length === 0){
        setDeviceErrorMessage("Please Select Atleast one Camera")
      }
      if(selectedGroup === 0){
        setGroupErrorMessage("Please Select the Group")
      }
    setErrorMessageOpen(true);
  }
    else {
      // console.log(selectedCameras)
      // console.log(clientData)

    
      let presentValues = activeCameras.filter(id => clientData.map(item => item.deviceid).includes(id));
      
      if(presentValues.length>0){
            // groups are not editable from here
            setMessage('Groups cannot be edited from here')
            setOpenError(true)
      }
      else{
        let body=activeCameras.map(item=>{
          return {
            GroupId: selectedGroup.Id,
            DeviceId: item,
            ClientId: client
          }
        })
  
        AddClientGroup(body,(response)=>{
          if(response.status===200){
            const body={group_id:0,client_id:client}
            
            getClientInfo(body,(response)=>{

              setClientData(response.data)
              // setClient('');
              // setSelectedCameras([]);


              // console.log(response.data)
            })
          }
        })

        //setClient('');
        //setClientCameras([]);
        //setSelectedCameras([]);
        //setClientData([])
        // setSelectedGroup()
      }
         
    }
  };

  const handleErrorMessageClose = () => {
    setErrorMessageOpen(false);
  };

  // const handleGroupNameChange = event => {
  //   setGroupName(event.target.value);
  // };

  const onGroupChange = (event,value) =>{
    if(value){
      setSelectedGroup(value)
      setGroupErrorMessage('')
    }
    else{
      setSelectedGroup(null)
    }
  }

  const handleFilterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    
    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeCameras.includes(option.deviceid));
    const unselectedOptions = filtered.filter(option => !activeCameras.includes(option.deviceid));
    
    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];

    return [{ title: 'Select All', selectall: true }, ...sortedOptions];
  };

  

  return (
    <>
<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} ml={5}>
       
        <Typography variant="h4" gutterBottom>
          Groups
        </Typography>
        
          <Button
            variant="contained"
            onClick={() => handleOpen()}
            startIcon={<Iconify icon="eva:plus-fill" />}
            sx={{marginRight:'3%'}}
          >
            Add Group
          </Button>
          
          <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
                            <DialogTitle>Add Group</DialogTitle>
                
                  {/* Add camera form fields */}
                  <DialogContent>

                  <TextField
                        margin="dense"
                        id="groupName"
                        label="Group Name"
                        type="text"
                        fullWidth
                        value={groupName}
                        onChange={(e) => {
                            const newName = e.target.value;
                            setGroupName(newName);
                            setGroupNameError(false); // Reset error state when typing
                            // Check if the new name exists in the list
                            // if (groupName.includes(newName)) {
                            //     setGroupNameError(true);
                            //     setHelperText("Group name already exists.");
                            // } else 
                            if (newName.trim() === "") { // Check if the field is empty
                                setGroupNameError(true);
                                setHelperText("Group Name cannot be empty.");
                                setMessage('')
                            } else {
                                setGroupNameError(false);
                                setHelperText(""); // Clear helper text if no error
                            }
                        }}
                        error={groupNameError}
                        helperText={helperText}
                        required
                    />

                    {groupNameError && (<Typography color='red' fontSize={12}>{message}</Typography>)}
                    
                </DialogContent>
                <DialogActions>
                  <Button color='error' variant='outlined' onClick={handleClose}>Close</Button>
                  <Button type="submit"  onClick={saveGroupName} variant="contained">Save</Button>
                </DialogActions>
          </Dialog>        
</Stack>

     
      <Card sx={{ width: '95%', p: 2, margin: 'auto' }}>
  <CardContent>
    <Grid container spacing={3}>
      
      <Grid item xs={3}>
        <FormControl fullWidth>
          <Autocomplete
            fullWidth
            id="tags-outlined"
            options={clientInfo.map((client, clientIndex) => ({
              ...client,
              customKey: clientIndex, // Assuming clientId is a unique identifier
            }))}
            isOptionEqualToValue={(option, value) =>
              option.customKey === value.customKey
            }
            getOptionLabel={(option) => option.displayname}
            onChange={onClientChange}
            value={client.ID}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Clients"
                placeholder="Enter Client Name"
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.customKey}>
                {option.displayname}
              </li>
            )}
          />
       </FormControl>
        {errorClientMessage && <Typography color="error" variant="body2" align="left">{errorClientMessage}</Typography>}
      </Grid>
      
      <Grid item xs={3}>
     
      <Autocomplete
        multiple
        fullWidth
        id="camera"
        options={clientCameras}
        
        //value={(checkValue) ? [{ title: 'Select All', all: true }] : inputValue.filter((camera) => activeCameras.includes(camera.deviceid))}
        value={[]}

        getOptionLabel={(option) => (option.selectall && clientCameras.length === activeCameras.length) ? 'Select All' : `${option.deviceid} ${option.devicename}`}
        onChange={handleCameraChange}
        disableCloseOnSelect={openAuto}
        open={openAuto}
        onOpen={() => setOpenAuto(true)}
        onClose={() => setOpenAuto(false)}
        
        // filterOptions={(options, params) => {
        //   const filter = createFilterOptions();
        //   const filtered = filter(options, params);
        //   return [{ title: 'Select All', selectall: true }, ...filtered];
        // }}

        filterOptions={handleFilterOptions}
        
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            {option.selectall ? (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={checkValue}
                  //onChange={() => setCheckValue(!checkValue)}
                />
                {option.title}
              </>
            ) : (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={activeCameras.includes(option.deviceid)}
                  onChange={() => {
                    const isChecked = activeCameras.includes(option.deviceid);
                    const newActiveCameras = isChecked
                      ? activeCameras.filter((deviceId) => deviceId !== option.deviceid)
                      : [...activeCameras, option.deviceid];
                    setActiveCameras(newActiveCameras);
                  }}
                />
                {`${option.deviceid} ${option.devicename}`}
              </>
            )}
          </li>
        )}
        renderInput=
        {(params) => 
           <TextField {...params} label="Camera" onFocus={() => setOpenAuto(true)}/>
        }
      />

      
      
      {errorDevicesMessage && <Typography color="error" variant="body2" align="left">{errorDevicesMessage}</Typography>}
      </Grid>
      
      <Grid item xs={3}>
        <FormControl fullWidth>
         
        <Autocomplete
            fullWidth
            // options={group || []}     
            options={(group || []).map((group, groupIndex) => ({
              ...group,
              customKey: groupIndex, // Assuming customKey is a unique identifier
            }))}
            isOptionEqualToValue={(option, value) => option.customKey === value.customKey}
            getOptionLabel={(option) => option.GroupName}
            value={selectedGroup}
            onChange={onGroupChange}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Groups"
                placeholder="Select Group"
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.customKey}>
                {option.GroupName}
              </li>
            )}
          />
        </FormControl>
        {errorGroupMessage && <Typography color="error" variant="body2" align="left">{errorGroupMessage}</Typography>}
      </Grid>
      
      <Grid item xs={3}>
        <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>
          Save
        </Button>
      </Grid>
    
    </Grid>
  </CardContent>
 
  <TableContainer>
  <Table>
    <TableHead sx={{ background: '#f3f3f3' }}>
      <TableRow>
        <TableCell sx={{ background: '#f3f3f3' }}>S.No</TableCell>
        <TableCell sx={{ background: '#f3f3f3' }}>Client Name</TableCell>
        <TableCell sx={{ background: '#f3f3f3' }}>Camera Name</TableCell>
        <TableCell sx={{ background: '#f3f3f3' }}>Group Name</TableCell>
        <TableCell sx={{ background: '#f3f3f3' }}></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
  {clientData.map((row, index) => (
    <TableRow key={index}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{row.DisplayName}</TableCell>
      <TableCell>{row.devicename}</TableCell>
      <TableCell>{row.GroupName}</TableCell>
      <TableCell><GroupsMore row={row} groups={group} handleGroupReload={handleGroupReload}/></TableCell>
      
    </TableRow>
  ))}
</TableBody>

  </Table>
</TableContainer>

      </Card>


      <Dialog open={openError} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Add Group</DialogTitle>
          <DialogContent>
                    {message}
          </DialogContent>
        <DialogActions>
          <Button color='error' variant='outlined' onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>



</>
  );
};

export default Groupsdetails;
// in onchange user makes Empty Client and then automatically clear the camera Fields