import axios from 'src/axios'
import { getURL, jsonToParam ,jsonToFormData} from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';

const getProtocols = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/protocols/get-introsion-types')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const addIntrusion =(body,callback) =>{
    axios({
        method: 'post',
        url: getURL('/protocols/add-introsion-type'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const setAddProtocol = (body ,callback) => {
    axios({
        method: 'post',
        url: getURL('/protocols/add-introsion-type'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getClientInfo = (clientId ,callback) => {
    axios({
        method: 'get',
        url: `${getURL('/protocols/get-introsion-info')}?client_id=${clientId}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


const setSaveProtocol = (body ,callback) => {
    axios({
        method: 'post',
        url: getURL('/protocols/add-introsion-contacts'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getProtocolsInfo = (params ,callback) => {
    const { intrusion_id, device_id } = params; 
    axios({
        method: 'get',
        url: `${getURL('/protocols/get-introsion-messages')}?intrusion_id=${intrusion_id}&device_id=${device_id}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

// const getProtocolsInfo = (deviceid ,callback) => {
//     //const { intrusion_id, device_id } = params; 
//     axios({
//         method: 'get',
//         url: `${getURL('/protocols/get-introsion-messages')}?device_id=${deviceid}`,
//         validateStatus: (status) => handleResponse(status)
//     }).then((response) => callback(response))
// }


const getInrusionInfo = (intrusion_id ,callback) => {
    //const { intrusion_id, device_id } = params; 
    axios({
        method: 'get',
        url: `${getURL('/protocols/get-protocols-info')}?intrusion_id=${intrusion_id}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

// const addDevicesProtocol = (body ,callback) => {
//     axios({
//         method: 'post',
//         url: getURL('/protocols/add-introsion-message'),
//         data: body,
//         validateStatus: (status) => handleResponse(status)
//     }).then((response) => callback(response))
// }

const updateDeviceProtocolsInfo = (params ,callback) => {
   // debugger
   // console.log(params.id)
    axios({
        method: 'put',
        url: `${getURL('/protocols/update-introsion-message')}`,
        data:params,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

// const updateIntrusionType = (body,callback) =>{
//     axios({
//         method: 'put',
//         url: `${getURL('/protocols/update-protocol-message')}`,
//         data:body,
//         validateStatus: (status) => handleResponse(status)
//     }).then((response) => callback(response))
// }

const setProtocolsInfoMessages = (body,callback) => {
    //console.log(callback)
    axios({
        method: 'put',
        url: getURL('/protocols/update-protocol-message'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const deleteMessage = (Id, callback) => {
    axios({
        method: 'delete',
        url: getURL('/protocols/delete-intrusion-message'),
        data: Id,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const deleteIntrusionType = (body, callback) => {
    axios({
        method: 'delete',
        url: getURL('/protocols/delete-protocol-info'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const UpdateMessage = (body,callback) =>{
    axios({
        method: 'put',
        url: getURL('/protocols/update-message'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const DeleteDeviceMapping = (body,callback) =>{
    axios({
        method: 'delete',
        url: getURL('/protocols/delete-intrusion-message'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


const setMultiDevicesProtocol = (body ,callback) => {
    axios({
        method: 'post',
        url: getURL('/protocols/add-introsion-message'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const addUpdateIntrusion = (body ,callback) => {
    axios({
        method: 'put',
        url: getURL('/protocols/add-or-update-protocols-devices'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


// const getDevices = (body, callback) => {
//     axios({
//         method: 'get',
//         url: `${getURL('devices')}?`,
//         validateStatus: (status) => handleResponse(status)
//     }).then((response) => callback(response))
// }



export { UpdateMessage,getInrusionInfo,getProtocols , setAddProtocol ,getClientInfo ,setSaveProtocol ,getProtocolsInfo ,setProtocolsInfoMessages ,deleteMessage ,setMultiDevicesProtocol,updateDeviceProtocolsInfo,addIntrusion,deleteIntrusionType,addUpdateIntrusion,DeleteDeviceMapping} 