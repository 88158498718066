import {
  Container,
  Stack,
  Typography,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  Toolbar,
  Box,
  Card,
  Autocomplete,
  TextField,
  Chip,
  Button,
} from '@mui/material';
import {
  
  Table,
 
 
  TableRow,
  TableBody,
  TableCell,
TableFooter,
  TableContainer,
  TablePagination,
  Link,
} from '@mui/material';
// components
import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';

import Page from '../components/Page';
// mock
// import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import * as XLSX from 'xlsx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { UserListHead  } from '../sections/@dashboard/user';
import { DateTimePicker } from '@mui/x-date-pickers';

import IconButton from '@mui/material/IconButton';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// import Loader from 'react-loader-spinner';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import axios from "axios";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ClearIcon from '@mui/icons-material/Clear';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grade } from '@mui/icons-material';
import { Grid } from 'react-loader-spinner';
import { getURL } from 'src/utils/config';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';


// import { common } from '@mui/material/colors';
// import { values } from 'lodash';
// import { useRef } from 'react';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';


// const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
//   maxWidth: 200,
//   transition: theme.transitions.create(['box-shadow', 'width'], {
//     easing: theme.transitions.easing.easeInOut,
//     duration: theme.transitions.duration.shorter,
//   }),
//   '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
//   '& fieldset': {
//     borderWidth: `1px !important`,
//     borderColor: `${theme.palette.grey[500_32]} !important`,
//   },
// }));



// const EmployeeStatus = ['Entry','Exit'];
// const formatTime = (timeString) => {
//   const time = new Date(`1970-01-01T${timeString}`);
//   return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
// };




export default function NetrawalaEntryMonthly() {
  const [locationNames, setLocationNames] = useState([]); // Initialize as an empty array
  const [selectedLocation, setSelectedLocation] = useState([]); // Initial value set to null
  const [selectedLocationId, setSelectedLocationId] = useState(null); // New state to stor
  const [apiData, setApiData] = useState(null); // New state for storing API data
  const [selectedShiftTypes, setSelectedShiftTypes] = useState([]); // Initialize as an empty array
  const [shiftTypesNames, setShiftTypesNames] = useState([]); // Initialize as an empty array
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [responseData, setResponseData] = useState([]);

  const [selectedGetLocation, setSelectedGetLocation] = useState([]); // Initial value set to null
  const [selectedShift, setSelectedGetShift] = useState([]); // New state to stor


  const [displayDate,setDisplayDate] = useState(null);
  const [displaytoTime,setDisplayDateTo]= useState(null);

  const [locationError, setLocationError] = useState(false);
  const [shiftTypeError, setShiftTypeError] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [dateValidate,setValidate] = useState(false);
  const [totalEntryCount, setTotalEntryCount] = useState(0);
  const [totalExitCount, setTotalExitCount] = useState(0);
  const [entryExitDifference, setEntryExitDifference] = useState(0);

  let rowSpanApplied = false;
  let rowSpanAppliedExit =false;

  const handleFromDateChange = (date) => {
    setFromDate(date);
    setFromDateError(false);
    setSelectedLocation([]);
  };
  
  const handleToDateChange = (date) => {
    setToDate(date);
    setToDateError(false);
    setSelectedLocation([]);

  };


const exportToExcel = () => {
if (!responseData || responseData.length === 0) {
  alert('No data to Export.');
  return;
}

const sheetName = selectedGetLocation + '_' + selectedShift;
const headers = ['S.No', 'From Date' ,'To Date', 'Location Name', 'Shift Type', 'Vest Type', 'Entry Count', 'Exit Count'];

const formattedHeaders = headers.map(header =>
  header
    .replace(/_/g, ' ')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase())
);
const indexWithData = responseData.findIndex((item) => item.entry_video !== undefined && item.exit_video !== undefined);

const formattedData = responseData.map((item, index) => {
  const isLastRow = index === responseData.length - 1;


  return {
    'S.No': (index + 1).toString(),
    'From Date': displayDate,
    'To Date': displaytoTime,
    'Location Name': selectedGetLocation,
    'Shift Type': selectedShift,
    'Vest Type': item.Type,
    'Entry Count': (item.entry).toString(),
    'Exit Count': (item.exit).toString()
  };
});

// Additional rows
const additionalRows = [
  {
    'S.No': '',
    'From Date': '',
    'To Date': '',
    'Location Name': '',
    'Shift Type': '',
    'Vest Type': '',
    'Entry Count': '',
    'Exit Count': ''
  },
  {
    'S.No': '',
    'From Date': '',
    'To Date': '',
    'Location Name': '',
    'Shift Type': '',
    'Vest Type': '',
    'Entry Count': `Total Entry Count: ${totalEntryCount}`,
    'Exit Count': `Total Exit Count: ${totalExitCount}`,

  },
];

const allRows = [...formattedData, ...additionalRows];

const ws = XLSX.utils.json_to_sheet(allRows, {
  header: formattedHeaders,
  cellStyles: true,
});

const filename = sheetName + '.xlsx';
const maxColumnWidths = {};
headers.forEach(header => {
  maxColumnWidths[header] = Math.max(
    20,
    ...allRows.map(row => (row[header] || '').toString().length)
  );
});
const columnWidths = headers.map(header => ({
  wch: maxColumnWidths[header],
}));
ws['!cols'] = columnWidths;

const wb = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(wb, ws, sheetName || 'Sheet 1');
XLSX.writeFile(wb, filename);
};






const handleButtonClick = (exportType) => {
  switch (exportType) {
    case 'excel':
    
      exportToExcel();
      break;
    default:
      break;
  }
};

useEffect(() => {
  if (Array.isArray(responseData) && responseData.length > 0) {
    const newTotalEntryCount = responseData.reduce((sum, entry) => sum + entry.entry, 0);
    const newTotalExitCount = responseData.reduce((sum, entry) => sum + entry.exit, 0);
    const newEntryExitDifference = newTotalEntryCount - newTotalExitCount;

    // Update state values
    setTotalEntryCount(newTotalEntryCount);
    setTotalExitCount(newTotalExitCount);
    setEntryExitDifference(newEntryExitDifference);
  }
}, [responseData]);

  useEffect(() => {
    const fetchData = async () => {
      
      const apiUrl = getURL("employee-shifts-info/location-names?status=1");
      const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
      
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: AUTH_TOKEN,
          },
        });

        const extractedLocationNames = response.data.map(item => item.LocationName);
        setLocationNames(extractedLocationNames);
       
        setApiData(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };
 

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedLocationId !== null ) {
      //   console.log("selected ID",selectedLocationId);
        const dynamicUrl = getURL(`shift-mon-timings?Status=1&LocationId=${selectedLocationId}`)
        const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
  
        try {
          const response = await axios.get(dynamicUrl, {
            headers: {
              Authorization: AUTH_TOKEN,
            },
          });

          console.log("data for selected Location ",response.data)
          const extractedShiftTypes = response.data.map(item => ({
            ID : item.Id,
            ShiftName: item.ShiftName,
            EntryFromTime: item.EntryFromTime, // Add the actual property name from your API response
            EntryToTime: item.EntryToTime, 
            ExitFromTime: item.ExitFromTime, // Add the actual property name from your API response
            ExitToTime: item.ExitToTime     // Add the actual property name from your API response
          }));
          // const extractedShiftTypes = response.data.map(item => item.ShiftName);
          setShiftTypesNames(extractedShiftTypes);
          // console.log("location and shifts",extractedShiftTypes)
          // console.log("location and shifts",extractedShiftTypes.toTime)

          setApiData(prevData => {
            // Make sure to merge previous data with the new data
            const newData = [...prevData, ...response.data];
            return newData;
          });

          // setApiData(response.data);  // Update apiData after setShiftTypes
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };
  
    // Use data from the first useEffect
    fetchData();
  }, [selectedLocationId]);

         

  const handleShiftType = (event, value) => {
    
  //   console.log("shift", value);
    setSelectedShiftTypes(value);

    const selectedShiftData = apiData.find(item => item.ShiftName === value);
  
    if (selectedShiftData) {
      const selectedShiftFromTime = selectedShiftData.FromTime;
      const selectedShiftToTime = selectedShiftData.ToTime;
      setFromTime(selectedShiftFromTime)  
      setToTime(selectedShiftToTime)

    } else {
      console.error('Selected shift not found in data.');
    }
;
  };


  const handleDetectedType = (event, value) => {
    
    setSelectedLocation(value);
    setSelectedShiftTypes('');

    setLocationError(false)
  const selectedLocationObject = apiData.find((location) => location.LocationName === value);
    setSelectedLocationId(selectedLocationObject ? selectedLocationObject.Id : null);

  };


    const handleSubmit = () => {

      setLoading(true);
      setResponseData([]);
      setLocationError(false);
      setShiftTypeError(false);
      setFromDateError(false);
      setToDateError(false);
      setTotalEntryCount(0);
      setTotalExitCount(0);
      setEntryExitDifference(0);
      if (selectedLocation.length === 0 || fromDate === null || toDate === null) {
        if(selectedLocation.length === 0) {
          setLocationError(true)
        }

        if(fromDate === null) {
          setFromDateError(true)
        }

        if(toDate === null) {
          setToDateError(true)
        }

      setLoading(false);

        return;
      }




      setSelectedGetLocation(selectedLocation);

      console.log("from and to date",fromDate,toDate);

      const combinedFromDateTime = dayjs(fromDate).format('YYYY-MM-DD HH:mm:ss');
      const combinedToDateTime = dayjs(toDate).format('YYYY-MM-DD HH:mm:ss');
      
      


      const fromDateTime = dayjs(combinedFromDateTime).format('YYYY-MM-DD HH:mm');
      const toDateTime = dayjs(combinedToDateTime).format('YYYY-MM-DD HH:mm'); 

      console.log("from and to date with time", fromDateTime, toDateTime);


      setValidate(false);

      setDisplayDate(fromDateTime);
      setDisplayDateTo(toDateTime);
      // const shiftID = selectedShiftTypes.ID ? selectedShiftTypes.ID : 0
      // console.log("shift Id",shiftID , selectedShiftTypes);
      const apiUrl = getURL(`/employee-shifts-info/head-count-monthly-report?` +
        // `shift_id=${encodeURIComponent(String(shiftID))}&` +
        `location_id=${encodeURIComponent( String(selectedLocationId))}&` +
        `fromtime=${encodeURIComponent( String(combinedFromDateTime))}&` +
        `totime=${encodeURIComponent( String(combinedToDateTime))}`);
  
      const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;

      axios.get(apiUrl, {
        headers: {
          Authorization: AUTH_TOKEN,
        },
      })
      .then(response => {


        const modifiedData = response.data.map(item => ({
          ...item,
          Type: item.Type.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()),
        }));

        if (modifiedData == []){
          }
        else{
          setResponseData(modifiedData);        
          setSelectedGetShift(selectedShiftTypes.ID);   
        }
        setLoading(false);
      })
      .catch(error => {
        // Handle API request errors
        console.error('API Error:', error);
        setLoading(false);
        
      });
    };



    const TABLE_HEAD = [
      { id: 'Id', label: 'S.No' },
      { id: 'From Date', label: 'From Date' },
      { id: 'To Date', label: 'To Date' },
      { id: 'Location Name', label: 'Location Name' },
      { id: 'Shift Name', label: 'Shift Type' },
      { id: 'From Time', label: 'Vest Type' },
      { id: 'To Time', label: 'Entry Count' },
      { id: 'Status', label: 'Exit Count' }
    ];
   

  return (
    <Page title="Neterwala">
      <Container maxWidth="xl">   
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                      Monthly Report
                    </Typography>
                    
        </Stack>   
        <Card sx={{ mt: 2}}>

      <Stack direction="row" spacing={2} sx={{ p: 2 }}>
 



{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
  <FormControl fullWidth sx={{ width: '75%', '& fieldset': { borderColor: fromDateError ? 'red' : 'initial' } }}>
    <DateTimePicker
      label="From Date & Time"
      value={fromDate}
      onChange={handleFromDateChange}
      maxDateTime={dayjs()}
      renderInput={(props) => (
        <TextField
          {...props}
          InputProps={{
            style: {
              '&:hover': {
                borderColor: fromDateError ? 'red' : 'initial',
              },
              '&.Mui-focused': {
                borderColor: fromDateError ? 'red' : 'initial',
              },
            },
          }}
        />
      )}
    />
    {fromDateError && <Typography color="error" variant="body2">Please select From Date & Time</Typography>}
  </FormControl>
</LocalizationProvider> */}

<LocalizationProvider dateAdapter={AdapterDayjs}>
<FormControl fullWidth sx={{ width: '75%', '& fieldset': { borderColor: fromDateError ? 'red' : 'initial' } }}>

                <DateTimePicker
                  sx={{width: "100%"}}
                  label="From Date"
                  id="from-date"
                  // viewRenderers={{
                  //   hours: renderTimeViewClock,
                  //   minutes: renderTimeViewClock,
                  //   seconds: renderTimeViewClock,
                  // }}
                  ampm={true}
                  format="YYYY-MM-DD HH:mm"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  maxDate={dayjs()}
                />
    {fromDateError && <Typography color="error" variant="body2">Please select From Date & Time</Typography>}
  </FormControl>
</LocalizationProvider>


<LocalizationProvider dateAdapter={AdapterDayjs}>
<FormControl fullWidth sx={{ width: '75%', '& fieldset': { borderColor: fromDateError ? 'red' : 'initial' } }}>
                <DateTimePicker
                  sx={{width: "100%"}}
                  label="To Date"
                  id="to-date"
                  // viewRenderers={{
                  //   hours: renderTimeViewClock,
                  //   minutes: renderTimeViewClock,
                  //   seconds: renderTimeViewClock,
                  // }}
                  ampm={true}
                  format="YYYY-MM-DD HH:mm"
                  value={toDate}
                  onChange={handleToDateChange}
                  minDate={fromDate}
                  maxDate={dayjs()}
                />
           {toDateError && <Typography color="error" variant="body2">Please Select To Date</Typography>}
           </FormControl>
           
</LocalizationProvider>


{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl fullWidth sx={{ width: '75%' }}>
        <DatePicker
          label="To Date"
          value={toDate}
          onChange={handleToDateChange}
          minDate={fromDate}
          maxDate={dayjs()}
          renderInput={(props) => (
            <TextField
              {...props}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: toDateError ? 'red' : 'initial',
                  },
                  '&:hover fieldset': {
                    borderColor: toDateError ? 'red' : 'initial',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: toDateError ? 'red' : 'initial',
                  },
                },
              }}
            />
          )}
        />
        {toDateError && <Typography color="error" variant="body2">Please Select To Date</Typography>}
      </FormControl>
    </LocalizationProvider> */}

<FormControl fullWidth sx={{ minWidth: 300 }}>
  <InputLabel id="location-name-label">Location Name</InputLabel>
  <Select
    labelId="location-name-label"
    label="Location Name"
    value={selectedLocation}
    onChange={(e) => handleDetectedType(e, e.target.value)}
    // error={locationError  ? true : false }
    >
   {locationNames
.sort((a, b) => a.localeCompare(b))
.map((option) => (

  <MenuItem key={option} value={option}>
    {option}
  </MenuItem>
))}

  </Select>
  {locationError && <Typography color="error" variant="body2"  >Please Select Location</Typography>}

</FormControl>

{/* <FormControl fullWidth sx={{ minWidth: 200 }}>
<InputLabel id="shift-type-label" sx={{ color: shiftTypeError ? 'red' : 'gray' }}>Shift Type</InputLabel>
<Select
  labelId="shift-type-label"
  label="Shift Type"
  value={selectedShiftTypes}
  onChange={(e) => handleShiftType(e, e.target.value)}
  error={shiftTypeError}
>

{
  shiftTypesNames
  .slice()
  .sort((a, b) => a.ShiftName.localeCompare(b.ShiftName))
  .map((option) => {
      // Convert '00:00' to '12:00' if needed
      const entfromTime = option.EntryFromTime === '00:00:00' ? '12:00:00' : option.EntryFromTime;
      const enttoTime = option.EntryToTime === '00:00:00' ? '12:00:00' : option.EntryToTime;
      const extfromTime = option.ExitFromTime === '00:00:00' ? '12:00:00' : option.ExitFromTime;
      const exttoTime = option.ExitToTime === '00:00:00' ? '12:00:00' : option.ExitToTime;

      // console.log(`FromTime: ${option.FromTime} | ToTime: ${option.ToTime} | Converted FromTime: ${fromTime} | Converted ToTime: ${toTime}`);

      const formatTimes = (time) => {
        const [hours, minutes] = time.split(':');
        const date = new Date(2000, 0, 1, hours, minutes);
        return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    };
      const entfromTimeFormatted = option.EntryFromTime === '00:00:00' ? '12:00 AM' : formatTimes(entfromTime);
      const enttoTimeFormatted = option.EntryToTime === '00:00:00' ? '12:00 AM' : formatTimes(enttoTime);
      const extfromTimeFormatted = option.ExitFromTime === '00:00:00' ? '12:00 AM' : formatTimes(extfromTime);
      const exttoTimeFormatted = option.ExitToTime === '00:00:00' ? '12:00 AM' : formatTimes(exttoTime);
      return (

      <MenuItem key={option.ShiftName} value={option}>
      {`${option.ShiftName} (${entfromTimeFormatted} - ${enttoTimeFormatted})   (${extfromTimeFormatted} - ${exttoTimeFormatted})` }
      </MenuItem>
      );

  })
}

        
</Select>
</FormControl> */}

<LoadingButton  sx={{ width: '25%' }}  type="submit" variant="contained" onClick={handleSubmit} loading={loading} >
          Get Reports
</LoadingButton>
        {/* disabled={selectedLocation.length === 0 || selectedShiftTypes.length === 0 || fromDate === null || toDate === null} */}
          </Stack>
        </Card>


{loading && (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </div>
    )}

    {!loading && (
      <Card sx={{ mt: 4, p: 6 }}>

 
      <Button
        sx={{ position: 'absolute', top: 0, right: 15 ,mt : 1}}
        variant="contained"
        color="primary"
        startIcon={<InsertDriveFileIcon />}
        onClick={() => handleButtonClick('excel')}
      >
        Excel <SaveAltIcon />
      </Button>
      
            <TableContainer sx={{ width: '100%' }}>
            <Table>
      
              <React.Fragment>
              <UserListHead 
                headers={TABLE_HEAD} 
              
                headLabel={TABLE_HEAD}
                // rowCount={clients.length}
                // numSelected={selected.length}
                />
      
                {/* No Data has to show only when shift from time and date is in the current time and data */}
                <TableBody>
      
    
      
            { dateValidate ? (
            <TableRow>
            <TableCell colSpan={9} align="center">
            <Typography variant="h6" color="textSecondary">
            No Data
            </Typography>
            </TableCell>
            </TableRow>
            ) : (
          responseData
            .sort((a, b) => a.Type.localeCompare(b.Type))
            .map((item, index) => {
              let displayRowSpan = !rowSpanApplied ? 8 : 4;
              rowSpanApplied = true; // Once the first row with rowspan is applied, set it to true
              
              return (
                <TableRow key={index}>
                  <TableCell align="left">{index + 1}</TableCell>
                  
                  {index === 0 && (
                    <TableCell align="left" rowSpan={displayRowSpan}>
                      {displayDate}
                    </TableCell>
                  )}
                  
                  {index === 0 && (
                    <TableCell align="left" rowSpan={displayRowSpan}>
                      {displaytoTime}
                    </TableCell>
                  )}
        
                  {index === 0 && (
                    <TableCell align="left" rowSpan={displayRowSpan}>
                      {selectedGetLocation}
                    </TableCell>
                  )}
        
                  {index === 0 && (
                    <TableCell align="left" rowSpan={displayRowSpan}>
                      {selectedShift ?  selectedShiftTypes.ShiftName : "All Shifts"}
                    </TableCell>
                  )}
        
                  <TableCell align="left">{item.Type}</TableCell>
                  <TableCell align="left">{item.entry}</TableCell>
                  <TableCell align="left">{item.exit}</TableCell>
                </TableRow>
              );
            })
        
            )}
                </TableBody>
                
                {/* if item.entry_video has a value then append the value for this handlePlay(item.entry_video, 'Entry Video') check the entire records */}
              </React.Fragment>
      
      
            </Table>
            </TableContainer>
      
            <Table>
            {responseData.length > 0 && (
            <TableFooter>
    
    
            <TableRow>
                <TableCell></TableCell>
                <TableCell align="right" sx={{  marginLeft: '-100px', color: 'black' }}> </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>  <Typography   sx={{ color: 'black' }}>
            Total Entry:  {totalEntryCount}  
            </Typography></TableCell>
                
                <TableCell>  <Typography  align="left" sx={{ color: 'black' }}>
         Total Exit: {totalExitCount}
    
            </Typography></TableCell>

    
    
    
    {/*   
              <TableCell align="left" sx={{ textAlign: 'left', color: 'black' }}>
      <Typography>
        Total Exit: {totalExitCount}
      </Typography>
    </TableCell> */}
    
      
      
            </TableRow>
            </TableFooter>
            )}
            </Table>
            </Card>
    )}


      </Container>
    </Page>
  )
}

