import React from 'react'
import Page from 'src/components/Page';
import { Container,Alert,Stack,Box,Divider,Dialog,DialogTitle,DialogContent,
    TextField,Typography,Card,Autocomplete,Button,DialogActions,Tab} from '@mui/material';
import { LoadingButton,TabPanel,TabContext,TabList } from '@mui/lab';
import { useState,useEffect } from 'react';
import MonthlyEscalationReport from 'src/sections/reports/MonthlyEscalationReport';
import AllClientMonthlyReport from 'src/sections/reports/AllClientMonthlyReport';
import SupervisorQcReport from 'src/sections/reports/SupervisorQcReport';
import MonthlyClientDahBoard from 'src/sections/reports/MonthlyClientDahBoard';
import DailyEmailReports from 'src/sections/reports/DailyEmailReports';
import { getClientList } from 'src/api/analytics';
import BarChart from 'src/sections/reports/BarChart';
import BarChartTest from 'src/sections/reports/BarChartTest';
export default function DeploymentReports() {
  const [clientList,setClientList]=useState([]) 
  // useEffect(() => {
  //   getClientList((response) => {
  //     if (response.status === 200) {
  //        setClientList(response.data)
  //     }
  //   })
  //   },[])

  useEffect(() => {
    getClientList((response) => {
      if (response.status === 200) {
        // const usClients = response.data.filter(client => client.ClientType === "US");
        setClientList(response.data);
      }
    });
  }, []);
  
    const [value, setValue] = useState('one');
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <Page title="Reports">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Reports
            </Typography>
          </Stack>
          
          <Card>
            <TabContext value={value}>
              <TabList
                  onChange={handleChange}
                  aria-label="wrapped label tabs example"
                  sx={{ borderBottom: "1px solid #ebebeb", backgroundColor: "#f3f3f3" }}
                >
                  <Tab
                    value="one"
                    label="Monthly Escalation Report"
                  />
                  <Tab 
                    value="two" 
                    label="All Client Monthly Report" />
                  <Tab 
                    value="three" 
                    label="Monthly Client Dashboad Report" />
                  <Tab 
                    value="four" 
                    label="Daily Email Report" />
                   {/* <Tab 
                    value="four" 
                    label="Supervisor QC Report" />
                    <Tab 
                    value="five" 
                    label="DashBords QC Report" /> */}
                </TabList>


                <TabPanel value="one" index={0} sx={{ p: 0}}>                
                    <MonthlyEscalationReport
                     clientList={clientList}
                    />
                </TabPanel>
  
                <TabPanel value="two" index={1} sx={{ p: 0}}>
                    <AllClientMonthlyReport
                    />
                </TabPanel>
  
                <TabPanel value="three" index={2} sx={{ p: 0}}>
                    <MonthlyClientDahBoard
                    clientList={clientList}
                    />
                </TabPanel>
                <TabPanel value="four" index={2} sx={{ p: 0}}>
                    <DailyEmailReports
                    // clientList={clientList}
                    />
                </TabPanel>

                {/* <TabPanel value="four" index={2} sx={{ p: 0}}>
                    <SupervisorQcReport
                    />
                </TabPanel>
                <TabPanel value="five" index={2} sx={{ p: 0}}>
                    <BarChartTest
                    />
                </TabPanel> */}
                
            </TabContext>
          </Card>
        </Container>
      </Page>
      );
}