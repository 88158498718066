import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  FormControl,
  InputLabel,
  locationError,
  Select,
  CircularProgress,
  Container,
  Typography,
  TableContainer,
  IconButton,
  Grid,
  MenuItem,
  TablePagination,
  Link,
  Icon,
  colors,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

import Iconify from "../../src/components/Iconify.js";
import { useEffect, useState } from "react";
import {
  UserListHead,
  UserListToolbar,
} from "../../src/sections/@dashboard/user";
import Label from "../../src/components/Label.js";
import ShiftTimeEdit from "src/sections/shifttime/shiftTimeEdit.js";
import ShiftTimeAdd from "src/sections/shifttime/shiftTimeAdd.js";

import TimeLapsEdit from "../sections/timelaps/TimeLapsEdit.js";
import TimelapsAdd from "src/sections/timelaps/TimelapsAdd.js";
import axios from "src/axios";

// mock
import { ListItemText } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React from "react";
import { LoadingButton } from "@mui/lab";
import { Delete } from "@mui/icons-material";
import { deleteShiftById, getAllShifts } from "src/api/shiftsMaster.js";

export default function AddShiftsNeterwala() {
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [locationNames, setLocationNames] = useState([]); // Initialize as an empty array
  const [selectedLocationId, setSelectedLocationId] = useState(null); // New state to stor
  const [apiData, setApiData] = useState(null); // New state for storing API data
  const [locationData, setLocationData] = useState([]); // Initialize as an empty array
  const [toTime, setToTime] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState([]); // Initial value set to null
  const [isNewShiftAdd, setNewShift] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openDelete, setOpenDelete] = useState(false); // State to manage dialog visibility

  const TABLE_HEAD = [
    { id: "Id", label: "S.No" },
    { id: "locationname", label: "Location Name" },
    { id: "Shiftname", label: "Shift Name" },
    { id: "EntryFromtime", label: "Entry From Time" },
    { id: "Entrytotime", label: "Entry To Time" },
    { id: "ExitFromtime", label: "Exit From Time" },
    { id: "Exittotime", label: "Exit To Time" },
    { id: "Action", label: "Action" },
  ];


  const handleGetShifts = () => {
    setLoading(true);
    getAllShifts((response) => {
      setLoading(false)
        if (response.status === 200) {
            setLocationData(response.data)
        }
    })
  };


  useEffect(() => handleGetShifts(), [])
  
  const handleDetectedType = (event, value) => {
    setSelectedLocation(value);
    const selectedLocationObject = apiData.find(
      (location) => location.LocationName === value
    );
    setSelectedLocationId(
      selectedLocationObject ? selectedLocationObject.Id : null
    );
  };

  const filteredData = locationData.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterName.toLowerCase())
    )
  );

  const handleUpdateData = async () => {
    try {
      handleGetShifts();
      console.log("yes ");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const [data, setData] = useState([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [editData, setEditData] = useState({}); // State to store the data for editing

  const addNewShift = () => {
    setNewShift((prevVisibility) => !prevVisibility);
  };
  const handleAddTimelaps = (timelapsValue) => {
    // Handle the logic for adding timelaps here
    console.log("Adding Timelaps:", timelapsValue);
  };

  const handleEditClick = (row) => {
    setCurrentId(row.Id);
    setEditData(row);
    setIsDialogOpen(true);
  };

  const handleDeleteClick = (row) => {
    setOpenDelete(true);
    console.log("row id", row.ShiftId);
  };

  const handleClose = () => {
    setOpenDelete(false); // Close the dialog
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setCurrentId(null);
  };
  const jsonToFormData = (data) => {
    let formdata = new FormData();
    for (let x in data) formdata.append(x, data[x]);
    return formdata;
  };

  const deleteShift = (row) => {    

    deleteShiftById({
      Id: row.Id,
    }, (response) => {
      setOpenDelete(false);
      if ([200, 201].includes(response.status))
        handleGetShifts();
    })


  };

  // const handleEditClick = (id) => {
  //   alert(`Edit icon clicked for ID: ${id}`);
  // };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Shifts
        </Typography>
        <Button
          variant="contained"
          to="#"
          onClick={() => addNewShift()}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Add Shifts
        </Button>
      </Stack>
      {/* <Card sx={{ mt: 2 }}>
        <Stack direction="row" spacing={2} sx={{ p: 2 }}>
          <FormControl sx={{ width: 300 }}>
            <InputLabel
              id="location-name-label"
              // sx={{ color: locationError ? 'red' : 'gray' }}
            >
              Location Name
            </InputLabel>
            <Select
              labelId="location-name-label"
              label="Location Name"
              value={selectedLocation}
              onChange={(e) => handleDetectedType(e, e.target.value)}
              // error={locationError}
            >
              {locationNames
                .sort((a, b) => a.localeCompare(b))
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <LoadingButton
            sx={{
              position: "absolute",

              right: 10,
              padding: "10px",
              width: "8%",
            }}
            type="submit"
            variant="contained"
            onClick={handleGetShifts}
            // loading={loading}
          >
            Get Shifts
          </LoadingButton>
        </Stack>
      </Card> */}
      <UserListToolbar
        sx={{ backgroundColor: "#f3f3f3" }}
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
      />

      <Card>
        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <React.Fragment>
              <UserListHead
                headers={TABLE_HEAD}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                // rowCount={clients.length}
                numSelected={selected.length}
              />

              {/* Render rows with dummy data */}

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={TABLE_HEAD.length} align="center">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "20vh",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">{row.LocationName}</TableCell>
                      <TableCell align="left">{row.ShiftName}</TableCell>
                      <TableCell align="left">{row.EntryFromTime}</TableCell>
                      <TableCell align="left">{row.EntryToTime}</TableCell>
                      <TableCell align="left">{row.ExitFromTime}</TableCell>
                      <TableCell align="left">{row.ExitToTime}</TableCell>

                      <TableCell>
                        {/* <Button onClick={() => handleEditClick(row)}>
                          <Iconify
                            icon="eva:edit-fill"
                            width={24}
                            height={24}
                          />
                          <ListItemText primary="Edit" />
                        </Button> */}
                        {/* <Button
                    color='error' 
                      onClick={() => handleDeleteClick(row)}
                     >
                      <Iconify icon="eva:trash-2-outline" width={24} height={24}/>
                      <ListItemText primary="Delete" />
                    </Button> */}

                        <Button
                          color="error"
                          onClick={() => handleDeleteClick(row)}
                        >
                          {/* <Icon
                            icon="eva:trash-2-outline"
                            width={24}
                            height={24}
                          /> */}
                          <Iconify icon="eva:trash-2-outline" width={15} height={15}/>
                        </Button>
                        <Dialog open={openDelete} onClose={handleClose}>
                          <DialogTitle>Delete Confirmation</DialogTitle>
                          <DialogContent>
                            <WarningIcon
                              color="warning"
                              sx={{ fontSize: 48 }}
                            />
                            <p>Are you sure you want to delete this shift ?</p>
                            {row.ShiftName}
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                              onClick={() => {
                                // Add delete logic here
                                deleteShift(row); // Close the dialog after deletion
                              }}
                              color="error"
                            >
                              Delete
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </React.Fragment>
          </Table>
        </TableContainer>
      </Card>
      <ShiftTimeEdit
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        rowData={editData}
        updateDataCallback={handleUpdateData}
      ></ShiftTimeEdit>
      {/* <TimeLapsEdit isOpen={isDialogOpen} onClose={handleCloseDialog} currentId={currentId} rowData={editData} updateDataCallback={handleUpdateData} /> */}

      <ShiftTimeAdd
        isOpen={isNewShiftAdd}
        onClose={() => setNewShift(false)}
        onAddTimelaps={handleAddTimelaps}
        updateDataCallback={handleUpdateData}
      />
    </>
  );
}
