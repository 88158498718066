import React, { useState } from 'react';
import {
    Card,
    CardHeader,
    IconButton,
    CardContent,
    Stack,
    Button,
    Box,
    CircularProgress,
    Typography,
    Link,
    LinearProgress,
    TextField,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Tabs,
    Tab,
    TableHead,
    TableRow,
    Paper,
    Menu,
    MenuItem,
    Drawer
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MemoryIcon from '@mui/icons-material/Memory';
import StorageIcon from '@mui/icons-material/Storage';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { format } from 'date-fns';
import Iconify from 'src/components/Iconify';
import Label from '../../components/Label';
import { serverDetails, changeServerStatus ,deleteServer} from 'src/api/servers';
import { circularProgressClasses } from '@mui/material/CircularProgress';
import AddEditForm from './AddEditForm';

const anchor = 'right'

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
        </Box>
    );
}

function CircularProgressWithLabel(props) {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ px: 1, pt: 1 }}
        >
            <Box sx={{ position: 'relative' }}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                    }}
                    {...props}
                    value={100}
                />
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: (theme) => theme.palette[props.color].main,
                        position: 'absolute',
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                    }}
                    {...props}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${parseFloat(props.value).toFixed(2)}%`}
                    </Typography>
                </Box>
            </Box>
        </Stack>
    );
}

const getPercentageVal = (usedValue, value) => parseFloat(value) > 0 ? (parseFloat(usedValue) / parseFloat(value)) * 100 : 0;

const isOnline = (lastUpdateTime) => {
    if (!lastUpdateTime) {
        return false;
    }
    const today = new Date();
    const lastDateTime = new Date(lastUpdateTime);
    const diff = today - lastDateTime;
    const minutes = Math.floor(Math.floor(diff / 1000) / 60);
    return minutes <= 11;
};

const setDateTimeFormat = (lastupdate, updatedon) => lastupdate ? format(new Date(lastupdate), 'yyyy-MM-dd H:m:s') : (updatedon ? format(new Date(updatedon), 'yyyy-MM-dd H:m:s') : '');

export default function DetailsBox({ row,loadServersList }) {
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
      const toggleDrawer = (anchor, open) => (event) => {
        debugger
        console.log('toggleDrawer',event);
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };
    const { ServerIp, ServerName, ServerId, Storage, RAM, Id, usedRAMgb, usedCPU, usedStorageGB, Status, LastUpdate, UpdatedOn, devices_count, ServerType } = row;
    const RAMval = getPercentageVal(usedRAMgb, RAM);
    const storageVal = getPercentageVal(usedStorageGB, Storage);
    const islive = isOnline(LastUpdate);

    const [copied, setCopied] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [cameraData, setCameraData] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null); // For menu
    const [selectedAction, setSelectedAction] = useState(false);

    // Form Data 
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [serverData, setServerData] = useState({ ServerName: '', ServerType: '',ID:'' });

    // State for confirmation dialog
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleMenuClose();
        setServerData({
            ServerName: ServerName,
            ServerType: ServerType,
            ID:ServerId
        });
        setIsFormVisible(true);
    };

    const handleChangeStatus = () => {
        setSelectedAction(true);
        handleMenuClose();
        setConfirmAction(() => () => {
            const serverStatus = Status === 0 ? 1 : 0;
            const data = {
                ServerId: ServerId,
                Status: serverStatus
            };
            changeServerStatus(data, (response) => {
                if (response.status === 200 && response.data !== null) {
                    console.log("response data for the server is " + response.data);
                    loadServersList();
                }
            });
            console.log('Current status of', Id, 'is:', Status);
        });
        setOpenConfirmDialog(true);
    };

    const handleDelete = () => {
        setSelectedAction(false);

        handleMenuClose();
        setConfirmAction(() => () => {
            const data = {
                ServerId: ServerId
            };
            deleteServer(data, (response) => {
                if (response.status === 200 && response.data !== null) {
                    console.log("response data for the server delete is " + response.data);
                    loadServersList();
                }
            });
            console.log('Delete action clicked for:', ServerName);
        });
        setOpenConfirmDialog(true);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(ServerId).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    const handleOpenDialog = (ID) => {
        serverDetails(ID, (response) => {
            if (response.status === 200) {
                setCameraData(response.data);
                console.log('response based on server ID : ', response.data);
            }
        });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => setOpenDialog(false);

    const open = Boolean(anchorEl);

    const activeCameras = cameraData.filter(camera => camera.Status === 1);
    const inactiveCameras = cameraData.filter(camera => camera.Status === 0);

    // Handlers for confirmation dialog
    const handleConfirmDialogClose = (confirmed) => {
        setOpenConfirmDialog(false);
        if (confirmed && confirmAction) {
            confirmAction();
        }
    };

    return (
        <>
            <Card sx={{ backgroundColor: 'rgb(244, 246, 248)' }}>
                <CardHeader
                    title={ServerName}
                    subheader={islive ? 'Online' : 'Offline'}
                    action={
                        <IconButton aria-label="settings" onClick={handleMenuClick}>
                            <MoreVertIcon />
                        </IconButton>
                    }
                    avatar={<OnlinePredictionIcon color={islive ? "success" : "error"} />}
                />
                <CardContent sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                        <Card sx={{ p: 2 }}>
                            <CircularProgressWithLabel size={110} thickness={6} value={parseFloat(usedCPU)} color='info' />
                            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} color="gray">
                                <MemoryIcon />
                                <Typography align='center' sx={{ fontSize: 12 }}>CPU</Typography>
                            </Stack>
                        </Card>
                        <Card sx={{ p: 2 }}>
                            <CircularProgressWithLabel size={110} thickness={6} value={RAMval} color='warning' />
                            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} color="gray">
                                <StorageIcon size={12} />
                                <Typography align='center' sx={{ fontSize: 12 }}>RAM</Typography>
                            </Stack>
                        </Card>
                    </Stack>

                    <Box sx={{ pt: 2 }}>
                        <Typography sx={{ fontWeight: 800 }}>Storage</Typography>
                        <Typography align='right' color="gray" sx={{ fontSize: 14, pb: 0.6, px: 1 }}>{usedStorageGB} GB of {Storage} GB Used</Typography>
                        <LinearProgressWithLabel value={storageVal} color="info" />
                    </Box>

                    <Stack direction='row' sx={{ pt: 2 }}>
                        <Typography sx={{ fontWeight: 800, pr: 1 }}>Ip Address: </Typography>
                        <Typography>{ServerIp}</Typography>
                    </Stack>

                    <Stack direction='row' sx={{ pt: 2 }}>
                        <Typography sx={{ fontWeight: 800, pr: 1 }}>Status: </Typography>
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5}>
                            {Number(Status) === 1 ? (
                                <Iconify icon="eva:checkmark-circle-2-outline" sx={{ color: "green", fontSize: 20 }} />
                            ) : (
                                <Iconify icon="eva:close-circle-outline" sx={{ color: "red", fontSize: 20 }} />
                            )}
                            <Typography>{Number(Status) === 1 ? 'Active' : 'InActive'}</Typography>
                        </Stack>
                    </Stack>

                    <Stack direction='row' sx={{ pt: 2, alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 800, pr: 1 }}>Server ID:</Typography>
                        <TextField
                            value={ServerId || ''}
                            variant="outlined"
                            size="small"
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleCopy} edge="end" size="small">
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                sx: { pr: 0.5, width: 'auto' }
                            }}
                            sx={{ pr: 1 }}
                        />
                    </Stack>

                    <Stack direction='row' sx={{ pt: 2 }}>
                        {copied && <Typography variant='body2' color='success.main' sx={{ pl: 1 }}>Copied!</Typography>}
                    </Stack>

                    <Stack direction='row' sx={{ pt: 2, alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 800, pr: 1 }}>Cameras: <Link onClick={() => handleOpenDialog(Id)} >
                            {devices_count}
                        </Link></Typography>
                    </Stack>

                </CardContent>

                <Typography sx={{ px: 3, py: 1 }} align="right" color="gray">{setDateTimeFormat(LastUpdate, UpdatedOn)}</Typography>
            </Card>
            
            {isFormVisible && (                                             
            //    <AddEditForm
            //         serverName={serverData.ServerName}
            //         serverType={serverData.ServerType}
            //     />
            <Drawer
                anchor={anchor}
                open={isFormVisible}             
                //onClose={toggleDrawer(anchor, false)}
                onClose={() => {
                    setIsFormVisible(false);
                    setState({ ...state, [anchor]: false });
                }}
                >
                <Box>
                    <AddEditForm 
                      onClose={() => {
                        setIsFormVisible(false);
                        setState({ ...state, [anchor]: false });
                        }}
                        //onClose={toggleDrawer}
                        Message='Edit Server' 
                        Action='Edit' 
                        Name={serverData.ServerName} 
                        Type={serverData.ServerType} 
                        Serverid={serverData.ID}
                        loadServersList={loadServersList}/>
                </Box>
            </Drawer>

            )}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem onClick={handleChangeStatus}>Change Status</MenuItem>
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>

            <Dialog open={openDialog} maxWidth="md" fullWidth>
                <DialogTitle>Camera Details</DialogTitle>
                <DialogContent>
                    <Tabs
                        value={selectedTab}
                        onChange={(event, newValue) => setSelectedTab(newValue)}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="camera status tabs"
                    >
                        <Tab label="Active Cameras" />
                        <Tab label="Inactive Cameras" />
                    </Tabs>

                    <TableContainer component={Paper} style={{ marginTop: 16 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>S.No</TableCell>
                                    <TableCell>Camera Name</TableCell>
                                    <TableCell>Device ID</TableCell>
                                    <TableCell>Client Name</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedTab === 0 ? (
                                    activeCameras.length > 0 ? (
                                        activeCameras.map((camera, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{camera.devicename}</TableCell>
                                                <TableCell>{camera.deviceid}</TableCell>
                                                <TableCell>{camera.displayname}</TableCell>
                                                <TableCell>{camera.Category}</TableCell>
                                                <TableCell>
                                                    <Label variant="ghost" color="success">
                                                        Active
                                                    </Label>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">No active cameras available</TableCell>
                                        </TableRow>
                                    )
                                ) : (
                                    inactiveCameras.length > 0 ? (
                                        inactiveCameras.map((camera, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{camera.devicename}</TableCell>
                                                <TableCell>{camera.deviceid}</TableCell>
                                                <TableCell>{camera.displayname}</TableCell>
                                                <TableCell>{camera.Category}</TableCell>
                                                <TableCell>
                                                    <Label variant="ghost" color="error">
                                                        Inactive
                                                    </Label>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">No inactive cameras available</TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions sx={{ padding: '16px', justifyContent: 'flex-end' }}>
                    <Button color='error' variant="contained" onClick={handleCloseDialog}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to {selectedAction ? 'change the status' : 'delete the server'}?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmDialogClose(false)} color="primary">No</Button>
                    <Button onClick={() => handleConfirmDialogClose(true)} color="error">Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}