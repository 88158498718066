import { useRef } from "react";
// @mui
import { styled } from '@mui/material/styles';
import { Link, Typography, Container, Box, Alert } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// components
import Page from '../components/Page';

import useLicence from 'src/api/useLicence';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function LicenceUpgrade() {

  const ref = useRef();

  const { data, fileUpload } = useLicence()

  const handleChange = (event) => {
    const files = Array.from(event.target.files);
    const formData = new FormData();
    formData.append("file", files[0], files[0].name);
    fileUpload (formData)
  };

  return (
    <Page title="Licenece Expired" sx={{ backgroundColor: "#fff6f3" }}>
      <Container>
       
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        {/* <Card sx={{p:5}}> */}
          <Typography variant="h3" paragraph>
            Licence Expired!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, your licence is expired please upload valid licence file
          </Typography>

          <Box
            component="img"
            src="/static/illustrations/illustration_login.png"
            sx={{ height: 260, mx: 'auto', my: { xs: 3, sm: 5 } }}
          />
          {data.showAlert && (
              <Alert severity={data.status ? 'success': 'error'}>{data.message}</Alert>
          )}
          <LoadingButton 
            loading={data.isLoading} 
            size="large" 
            variant="contained"  
            color="error"
            onClick={(e) => ref.current?.click()}>
            Choose Licence File 
            <input ref={ref} type="file" hidden onChange={handleChange}/>
          </LoadingButton>

          <Typography sx={{ pt: 2 }}>Or go to <Link href="/login">Login</Link></Typography>
          {/* </Card> */}
        </ContentStyle>
        
      </Container>
    </Page>
  );
}
