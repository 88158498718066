import { Card, Typography } from "@mui/material";

export default function CountBoard({ color, count, title, others }) {

    return (
        <Card sx={{
            textAlign: "center",
            p: 3,
            color: (theme) => theme.palette[color].darker,
            bgcolor: (theme) => theme.palette[color].lighter
        }} 
        {...others}
        >
            <Typography variant="h4">{count}</Typography>
            <Typography sx={{ fontSize: '0.8rem' }}>{title}</Typography>
        </Card>
    )

}