import { 
  Container, 
  Typography,
  Stack,
  Card,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  FormGroup,
  FormControlLabel,
  Grid,
  TextField,
  Autocomplete,
  styled,
  Snackbar,
  Alert,Checkbox
} from '@mui/material';
// components
import Page from '../components/Page';
import { getAnalyticsMain, getAnalyticsSub, getCamerasList, getSavedAnalytics, setAnalytics ,getClientList } from 'src/api/analytics';
import Iconify from 'src/components/Iconify';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import axios from "axios";
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';
import { createFilterOptions } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


// ----------------------------------------------------------------------

const AnalyticsMainBox = styled(Card)(() => ({
  border: '1px solid rgba(145, 158, 171, 0.24)',
  boxShadow: 'none'
}))

const AnalyticsMediaBox = styled(Card)(({theme}) => ({
  boxShadow: 'none',
  textAlign: "center", 
  padding: theme.spacing(1.5),
  width: 60,
  height: 60,
}))


const manageAnalyticsMain = (response) => {
  let results = {}
  response.map((row) => {
    row.isActive = false
    results[row.Id] = row
  })
  return results
}



const manageAnalyticSub = (response) => {
  let results = {}
  response.map((row) => {
    row.isActive = false
    if (!(row.AnalyticId in results)) {
      results[row.AnalyticId] = []
    }
    results[row.AnalyticId].push(row)
  })
  return results
}


const manageAnalyticsSettings = (response) => {
  //debugger
  let results = {}
  if(response){
  response.forEach((row) => {
    if (!(row.CameraId in results))
      results[row.CameraId] = { mainIds: [], subIds: [] }
    results[row.CameraId].mainIds.push(row.analyticsmainid)
    results[row.CameraId].subIds.push(row.analyticssubid)
  })}
  return results
}


export default function Analytics() {

  const [cameras, setCameras] = useState([])
  const [activeCameras, setActiveCameras] = useState([])
  const [analyticsMain, setAnalyticsMain] = useState({})
  const [analyticSub, setAnalyticSub] = useState({})
  const [activeAnalyticSub, setActiveAnalyticSub] = useState({ isOpen: false, activeId: [], title: null })
  const [activeSubIDs, setActiveSubIDs] = useState([])
  const [activeMainIDs, setActiveMainIDs] = useState([])
  const [isResting, setRestLoadStatus] = useState(false)
  const [isSaving, setSaveLoadStatus] = useState(false)
  const [settingAlert, setSettingAlert] = useState(false)
  const [analyticsSettings, setAnalyticsSettings] = useState({})
  const [isLoadSettings, setLoadSettingsStatus] = useState(false)
  const [savedResponse, setSavedResponse] = useState({ status: false, content: null})
  const [client, setClient] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedClienCameras, setSelectedClientCamers] = useState([])
  const [inputValue, setInputValue] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [camSelect,setCamSelected] = useState(true);

  const [isClient,setIsClient] = useState(false)

  const [openAuto,setOpenAuto]=useState(false)


  useEffect(() => {

    let userType=localStorage.getItem('user_type')

    if(userType==='2')
    {
      setIsClient(true)

      let id=localStorage.getItem('ID')
      getSavedAnalytics(id, (response) => {
        if (response.status === 200) {
          const managedData = manageAnalyticsSettings(response.data);
          setAnalyticsSettings({ ...managedData });
        }
      });

    }

    getCamerasList((response) => {
      if (response.status === 200) {
        setCameras(response.data)
      }
    })

    getClientList((response) => {
      if (response.status === 200) {
        setClient(response.data)
        //console.log("clients",client);
      }
    })
  
    getAnalyticsMain((response) => {
      if (response.status === 200) {
        const managedData = manageAnalyticsMain(response.data)
        setAnalyticsMain({...managedData})
      } 
    })
    getAnalyticsSub((response) => {
      if (response.status === 200) {
        const managedData = manageAnalyticSub(response.data)
        setAnalyticSub({...managedData})
      }
    })    

  }, [])

  useEffect(()=>{
    if(isClient){
      console.log(cameras)
      setSelectedClientCamers(cameras)
    }
  },[cameras])

  // useEffect(() => {
  //   //console.log("clients to get analy",client);
  //   getSavedAnalytics((response) => {
  //     if (response.status === 200) { 
  //       const managedData = manageAnalyticsSettings(response.data)
  //       setAnalyticsSettings({...managedData})
  //     }
  //   })

  // }, [isLoadSettings])

  useEffect(() => {
    //console.log("clients to get analy", selectedClients);
  
    // Assuming you have a way to get clientId, replace 'yourClientId' with the actual clientId
    if(localStorage.getItem('user_type')!=='2'){
      getSavedAnalytics(selectedClients, (response) => {
        if (response.status === 200) {
          const managedData = manageAnalyticsSettings(response.data);
          setAnalyticsSettings({ ...managedData });
        }
      });
    }
    
  
  }, [selectedClients]);



  // here api is calling but getting null value

  // const handleCameras = (_, value) => {

  //   //debugger

  //   let actIDs = [...activeSubIDs]
  //   let activeMains = [...activeMainIDs]
    

  //   if (value.length === 0) {
  //     actIDs = []
  //     activeMains = []
  //   }
  //   const actCameras = []
  //   if (value.length > 0) {
  //     value.forEach((row) => {
  //       actCameras.push(row.deviceid)
  //       if (row.deviceid in analyticsSettings) {
  //         actIDs = [...actIDs, ...analyticsSettings[row.deviceid].subIds]
  //         activeMains = [...activeMains, ...analyticsSettings[row.deviceid].mainIds]
  //       }
        
  //     })
  //   }
  //   // //console.log(actCameras)
  //   setActiveCameras([...actCameras])
  //   setActiveSubIDs([...new Set(actIDs)])
  //   setActiveMainIDs([...new Set(activeMains)])
  //   setInputValue([...value]);



  // }
  useEffect(()=>{
    //debugger
    // console.log(activeCameras)
  },[activeCameras])

  const [checkValue,setCheckValue]=useState(false)
  
  // const handleCameras = (_, value) => {
  //   debugger
  //   //console.log(value)
  //   if(value.length>1 && value.every((entry, index, arr) => JSON.stringify(entry) === JSON.stringify(arr[0]))){
  //     value=[];
  //   }
  //   //console.log(selectedClienCameras)
  //   //console.log(activeCameras)

  //   let actIDs = [...activeSubIDs];
  //   let activeMains = [...activeMainIDs];
  //   //const allCameras = selectedClienCameras.map((camera) => camera.deviceid);
  
  //   const containsAllProperty = value.some(obj => obj.hasOwnProperty('all'));

  //   if (value.length === 0 || !containsAllProperty) {
  //     actIDs = [];
  //     activeMains = [];
  //     setCheckValue(false);
  //   }
  
  //   let actCameras = [];
  //   if (value.length > 0 && !containsAllProperty) {
  //     //setCheckValue(false)
  //     value.forEach((row) => {
  //       actCameras.push(row.deviceid);
  //       if (row.deviceid in analyticsSettings) {
  //         actIDs = [...actIDs, ...analyticsSettings[row.deviceid].subIds];
  //         activeMains = [...activeMains, ...analyticsSettings[row.deviceid].mainIds];
  //       }
  //     });
  //     setActiveSubIDs([...new Set(actIDs)]);
  //     setActiveMainIDs([...new Set(activeMains)]);
  //     setInputValue([...value]);
  //     setActiveCameras([...actCameras]);
  //   }

  //   if(value.length>0 && containsAllProperty){  // checked select all
  //     let clienCameras=selectedClienCameras
  //     actCameras = []; actIDs = [...activeSubIDs];activeMains = [...activeMainIDs];
  //     if(activeCameras && activeCameras.length===selectedClienCameras.length){ // uncheck select all and camera
  //       setCheckValue(false)
  //       const deviceIds = value.filter(obj => !obj.hasOwnProperty('all')).map(obj => obj.id);
  //       clienCameras=clienCameras.filter(item=>!deviceIds.includes(item.deviceid))
  //       clienCameras.forEach((row) => {
  //         actCameras.push(row.deviceid);
  //         if (row.deviceid in analyticsSettings) {
  //           actIDs = [...actIDs, ...analyticsSettings[row.deviceid].subIds];
  //           activeMains = [...activeMains, ...analyticsSettings[row.deviceid].mainIds];
  //         }
  //       });
  //       setActiveSubIDs([...new Set(actIDs)]);
  //       setActiveMainIDs([...new Set(activeMains)]);
  //       setInputValue(selectedClienCameras);
  //       setActiveCameras([...actCameras]);

  //       return
  //     }
  //     if(!checkValue){       // if select all is true (false prv) need to load all cameras
  //       clienCameras.forEach((row) => {
  //         actCameras.push(row.deviceid);
  //         if (row.deviceid in analyticsSettings) {
  //           actIDs = [...actIDs, ...analyticsSettings[row.deviceid].subIds];
  //           activeMains = [...activeMains, ...analyticsSettings[row.deviceid].mainIds];
  //         }
  //       });
  //       setActiveSubIDs([...new Set(actIDs)]);
  //       setActiveMainIDs([...new Set(activeMains)]);
  //       setInputValue(selectedClienCameras);
  //       setActiveCameras([...actCameras]);
  //     }
  //     else{                 // if select all is false need to empty all state variables
  //       setActiveSubIDs([]);
  //       setActiveMainIDs([]);
  //       setInputValue([]);
  //       setActiveCameras([]);
  //       //handleReload()
  //     }
  //   }
  //   else if(value.length === 0){ 
  //     //debugger 
  //     setActiveSubIDs([]);
  //     setActiveMainIDs([]);
  //     setInputValue([]);
  //     setActiveCameras([]);
  //     setCheckValue(false)
  //   }
  // };
  

  const handleCameras = (_, value) => {
    //debugger

    if (value.some(obj => obj.hasOwnProperty('all'))) {
      if(value[0].all){
        setOpenAuto(true);
      }
      else{
        setOpenAuto(false);
      }
    } 
    else {
      setOpenAuto(true);
    } 

    if(value.length>1 && value.every((entry, index, arr) => JSON.stringify(entry) === JSON.stringify(arr[0]))){
      value=[];
    }
    

    let actIDs = [...activeSubIDs];
    let activeMains = [...activeMainIDs];
    //const allCameras = selectedClienCameras.map((camera) => camera.deviceid);
  
    const containsAllProperty = value.some(obj => obj.hasOwnProperty('all'));

    if (value.length === 0 || !containsAllProperty) {
      actIDs = [];
      activeMains = [];
      setCheckValue(false);
    }
  
    let actCameras = [];
    if (value.length > 0 && !containsAllProperty) {
      //setCheckValue(false)
      value.forEach((row) => {
        actCameras.push(row.deviceid);
        if (row.deviceid in analyticsSettings) {
          actIDs = [...actIDs, ...analyticsSettings[row.deviceid].subIds];
          activeMains = [...activeMains, ...analyticsSettings[row.deviceid].mainIds];
        }
      });

      if(selectedClienCameras.length===actCameras.length){
        setCheckValue(true)
      }
      setActiveSubIDs([...new Set(actIDs)]);
      setActiveMainIDs([...new Set(activeMains)]);
      setInputValue([...value]);
      setActiveCameras([...actCameras]);
    }

    if(value.length>0 && containsAllProperty){  // checked select all
      let clienCameras=selectedClienCameras
      actCameras = []; actIDs = [...activeSubIDs];activeMains = [...activeMainIDs];
      if(activeCameras && activeCameras.length===selectedClienCameras.length){ // uncheck select all and camera
        setCheckValue(false)
        const deviceIds = value.filter(obj => !obj.hasOwnProperty('all')).map(obj => obj.deviceid);
        clienCameras=clienCameras.filter(item=>!deviceIds.includes(item.deviceid))
        clienCameras.forEach((row) => {
          actCameras.push(row.deviceid);
          if (row.deviceid in analyticsSettings) {
            actIDs = [...actIDs, ...analyticsSettings[row.deviceid].subIds];
            activeMains = [...activeMains, ...analyticsSettings[row.deviceid].mainIds];
          }
        });
        setActiveSubIDs([...new Set(actIDs)]);
        setActiveMainIDs([...new Set(activeMains)]);
        setInputValue(selectedClienCameras);
        setActiveCameras([...actCameras]);

        return
      }
      if(!checkValue){       // if select all is true (false prv) need to load all cameras
        clienCameras.forEach((row) => {
          actCameras.push(row.deviceid);
          if (row.deviceid in analyticsSettings) {
            actIDs = [...actIDs, ...analyticsSettings[row.deviceid].subIds];
            activeMains = [...activeMains, ...analyticsSettings[row.deviceid].mainIds];
          }
        });
        setActiveSubIDs([...new Set(actIDs)]);
        setActiveMainIDs([...new Set(activeMains)]);
        setInputValue(selectedClienCameras);
        setActiveCameras([...actCameras]);
        setCheckValue(true)
      }
      else{                 // if select all is false need to empty all state variables
        setActiveSubIDs([]);
        setActiveMainIDs([]);
        setInputValue([]);
        setActiveCameras([]);
        //handleReload()
      }
    }
    else if(value.length === 0){ 
      //debugger 
      setActiveSubIDs([]);
      setActiveMainIDs([]);
      setInputValue([]);
      setActiveCameras([]);
      setCheckValue(false)
    }
  };



  const handleMainAnalytics = (id, displayName) => {
    setActiveAnalyticSub({...activeAnalyticSub, isOpen: true, activeId: analyticSub[id], title: displayName})
  }

  const handleClose = () => {
    setActiveAnalyticSub({...activeAnalyticSub, isOpen: false, activeId: [], title: null})
  }

  const handleSwitchBar = (row) => {

  
    
    if (row && activeCameras.length > 0) {

      const actIDs = [...activeSubIDs]
      if (!actIDs.includes(row.Id)) 
        actIDs.push(row.Id)
      else
        actIDs.splice(actIDs.indexOf(row.Id), 1)
      
   
      setActiveSubIDs([...actIDs])  
      let isAdd = false
      activeAnalyticSub.activeId.forEach((item) => {
        if (actIDs.includes(item.Id))
          isAdd = true
      })
      const activeMains = [...activeMainIDs]
      if (isAdd && !activeMains.includes(row.AnalyticId)) {
        activeMains.push(row.AnalyticId)
        setActiveMainIDs([...activeMains])
      } else if (!isAdd && activeMains.includes(row.AnalyticId)){
        activeMains.splice(activeMains.indexOf(row.AnalyticId), 1)
        setActiveMainIDs([...activeMains])
      } 
      setSettingAlert(false)
     
    } else {
      setSettingAlert(true)
    }
  }

  const resetData = () => {
    console.log("activeCameras",activeCameras.length)
    if(activeCameras.length === 0){
       console.log("please select atleast one Camera");
       setSavedResponse({...savedResponse, status: true, title: "please select atleast one Camera!"})
       setCamSelected(false)
       setTimeout(() => setSavedResponse({...savedResponse, status: false, title: null}), 1000)


    }
    else{
      setCamSelected(true)
      const body = {
        "CameraId": activeCameras.toString(),
        "AnalyticsSubId": ""
      }
      setRestLoadStatus(true)
      setAnalytics((response) => {
        if (response.status === 200 || response.status === 201) {
          setRestLoadStatus(false)
          setSavedResponse({...savedResponse, status: true, title: "Setup reset success!"})
          setActiveSubIDs([])
          setActiveMainIDs([])
          setActiveCameras([])
          setLoadSettingsStatus(!isLoadSettings)
          setTimeout(() => setSavedResponse({...savedResponse, status: false, title: null}), 1000)
        }
      }, body)
    }

  }
  const saveData = () => {
    if (activeCameras.length > 0) {
      // //console.log(activeCameras)
      const body = {
        "CameraId": activeCameras.toString(),
        "AnalyticsSubId": activeSubIDs.toString()
      }
      setSaveLoadStatus(true)
      setAnalytics((response) => {
        if (response.status === 200 || response.status === 201) {
          //setSaveLoadStatus(false)
          
          setSavedResponse({...savedResponse, status: true, title: "Setup Completed!"})
          handleReload()
          setTimeout(() => setSavedResponse({...savedResponse, status: false, title: null}), 1000)

          // setLoadSettingsStatus(!isLoadSettings)
          // setActiveSubIDs([])
          // setActiveMainIDs([])
          // setActiveCameras([])
          // setTimeout(() => setSavedResponse({...savedResponse, status: false, title: null}), 1000)
        
        }
      }, body)
      //handleReload()
    }    
  }

  const handleReload = () => {
    //debugger
    getSavedAnalytics(selectedClients, (response) => {
      if (response.status === 200) {
        const managedData = manageAnalyticsSettings(response.data);
        setAnalyticsSettings({ ...managedData });
      }
    });
  }


  const handleClient = (_, value) => {

    setCheckValue(false)

    if (value.length === 0) {
      setSelectedClientCamers([]);
      setInputValue([]);
      setActiveCameras([]);
      setActiveSubIDs([]);
      setActiveMainIDs([]);

    } else {
      const selectedClientIds = value.map(client => client.ID);   
      const selectedCameras = cameras.filter(camera => selectedClientIds.includes(camera.clientid));
      //console.log("Selected Clients:", selectedClientIds);
      const selected_Cam_clientIds = inputValue.map(camera => camera.clientid);
      //console.log("Selected Clients:", selected_Cam_clientIds);
      setSelectedClientCamers(selectedCameras);
      setSelectedClients(selectedClientIds)      
      

    }
  };

  useEffect(() => {
    const updatedInputValues = inputValue.filter(camera => selectedClients.includes(camera.clientid));
    setInputValue([...updatedInputValues]);
  }, [selectedClients.length]);



  useEffect(() => {
    if (settingAlert)
      setTimeout(() => setSettingAlert(false), 2000)
  }, [settingAlert])

  const handleFilterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    
    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeCameras.includes(option.deviceid));
    const unselectedOptions = filtered.filter(option => !activeCameras.includes(option.deviceid));
    
    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];

    return [{ title: 'Select All', all: true }, ...sortedOptions];
  };



  return (
    <Page title="Analytics">
      <Container  maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>Analytics</Typography>          
        </Stack>
          <Grid container spacing={2}>
      {!isClient && ( <Grid item xs={3}>
          <Autocomplete
            id="tags-outlined-client"
            // options={client}
            options={client.map((client, clientIndex) => ({
              ...client,
              customKey: clientIndex, // Assuming clientIndex is a unique identifier
            }))}
            isOptionEqualToValue={(option, value) =>
              option.customKey === value.customKey
            }

            getOptionLabel={(option) => `${option.displayname}`}
            onChange={(event, value) => handleClient(event, value ? [value] : [])}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Client"
                placeholder="Search Client Name..."
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.customKey}>
                {option.displayname}
              </li>
            )}   
          />
      </Grid>)}

        <Grid item xs={3}>
      
            <Autocomplete
              multiple
              id="tags-outlined-cameras"
              options={selectedClienCameras}
              getOptionLabel={(option) =>(option.all && selectedClienCameras.length===activeCameras.length)?'Select All':`${option.deviceid} ${option.devicename}`}
              onChange={handleCameras}
              //value={inputValue.filter((camera) => activeCameras.includes(camera.deviceid))}
              value={(checkValue) ? [{ title: 'Select All', all: true }] : inputValue.filter((camera) => activeCameras.includes(camera.deviceid))}
              //inputValue={checkValue ? 'Select+All' : ''}
              disableCloseOnSelect={openAuto}
              open={openAuto}
              onOpen={() => setOpenAuto(true)}
              onClose={() => setOpenAuto(false)}

              filterOptions = {handleFilterOptions}

              // filterOptions={(options, params) => {
              //   const filter = createFilterOptions();
              //   const filtered = filter(options, params);
              //   return [{ title: 'Select All', all: true }, ...filtered];
              // }}

              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  {option.all ? (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={checkValue}
                        onChange={() => setCheckValue(!checkValue)}
                      />
                      {option.title}
                    </>
                  ) : (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={activeCameras.includes(option.deviceid)}
                        onChange={() => {
                          const isChecked = activeCameras.includes(option.deviceid);
                          const newActiveCameras = isChecked
                            ? activeCameras.filter((deviceId) => deviceId !== option.deviceid)
                            : [...activeCameras, option.deviceid];
                          setActiveCameras(newActiveCameras);
                        }}
                      />
                      {`${option.deviceid} ${option.devicename}`}
                    </>
                  )}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Camera"
                  placeholder="Search Camera ID Or Name..."
                  
                />
              )}
              renderTags={() => null} 
            />

    
        </Grid>
          </Grid>



        <Grid container spacing={2} sx={{ pt: 2 }}>
            {Object.values(analyticsMain).map((row, key) => (
              <Grid item md={2} key={key}>
                  <AnalyticsMainBox sx={{ p: 2, color: activeMainIDs.includes(row.Id) ? 'green': 'black', borderColor: activeMainIDs.includes(row.Id) ? 'green': 'rgba(145, 158, 171, 0.24)' }} onClick={() => handleMainAnalytics(row.Id, row.DisplayName)}>                  
                    <AnalyticsMediaBox sx={{ color: (theme) => theme.palette[activeMainIDs.includes(row.Id) ? 'success': 'primary'].darker}}>
                      <Iconify icon={row.Icon || 'mdi:head-snowflake-outline'} width={35} height={35} />
                    </AnalyticsMediaBox>     
                    <Typography sx={{ pt:2 }} variant="h5">{row.DisplayName}</Typography>   
                    <Typography color={activeMainIDs.includes(row.Id) ? '#3a783a': 'gray'} sx={{pt:1}}>Small description about analytics.</Typography> 
                </AnalyticsMainBox>
              </Grid>
            ))}        
        </Grid> 

        <Dialog
            open={activeAnalyticSub.isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {activeAnalyticSub.title}
            </DialogTitle>
            <DialogContent>
                <FormGroup>
                    {(activeAnalyticSub.activeId) && activeAnalyticSub.activeId.map((row, key) => (
                        <FormControlLabel key={key} control={<Switch color="success" checked={activeSubIDs.includes(row.Id)} onChange={() => handleSwitchBar(row)} />} label={row.DisplayName} />
                    ))}
                </FormGroup>
                        {/* <Snackbar open={settingAlert} autoHideDuration={1000} > */}

                        {settingAlert === true && (
                        <Stack open={settingAlert} autoHideDuration={1000}>
                                          <Alert severity="error" sx={{ width: "100%" }}>Please select atleast one device!</Alert>
                        </Stack> 
                      )}  

                        {/* </Snackbar> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>Done</Button>
            </DialogActions>    
        </Dialog>


        {(savedResponse.status === true && camSelect === true)&& (
          <Stack direction="row" sx={{ paddingTop: 2 }}>
            <Alert severity="success">{savedResponse.title}</Alert>
          </Stack> 
        )}  
        
        {(savedResponse.status === true && camSelect === false) && (
          <Stack direction="row" sx={{ paddingTop: 2 }}>
            <Alert severity="error" sx={{ width: "100%" }}>{savedResponse.title}</Alert>
          </Stack> 
        )}  

        <Stack direction="row" spacing={1} sx={{ paddingTop: 2 }}>
            <LoadingButton variant="contained" loading={isResting} color="error" onClick={resetData}>Reset</LoadingButton>
            {/* <LoadingButton disabled={activeCameras.length === 0} loading={isSaving} variant="contained" onClick={saveData} >Save</LoadingButton> */}
            <LoadingButton variant="contained" onClick={saveData} >Save</LoadingButton>
        </Stack>




        
      </Container>
    </Page>
  );
}