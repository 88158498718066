import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const BarGraphMd = ({ data }) => {
  const months = data.map(item => item.month);
  const clientCounts = data.map(item => item.clientCount);
  const cameraCounts = data.map(item => item.cameraCount);

  // Find the maximum value for both datasets
  const maxClientCount = Math.max(...clientCounts);
  const maxCameraCount = Math.max(...cameraCounts);
  const maxCount = Math.max(maxClientCount, maxCameraCount);
  const nearestMultipleOf50 = Math.ceil(maxCount / 50) * 50 + 100;

  const chartData = {
    labels: months,
    datasets: [
      {
        label: 'Client Count',
        backgroundColor: 'rgba(57, 144, 229 , 1)',
        data: clientCounts
      },
      {
        label: 'Camera Count',
        backgroundColor: 'rgba(75, 192, 192, 1)',
        data: cameraCounts
      }
    ]
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months'
        }
      },
      y: {
        beginAtZero: true,
        max: nearestMultipleOf50, // Add a buffer for better visualization
        title: {
          display: true,
          text: 'Count'
        }
      }
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: Math.round,
        font: {
          weight: 'bold'
        }
      }
    }
  };

  return (
    <div>
      <Bar data={chartData} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default BarGraphMd;
