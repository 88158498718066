import React from 'react';
import { Bar } from 'react-chartjs-2';
import { PDFViewer, PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';


// Chart data
const chartData = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

// Styles for PDF
const styles = StyleSheet.create({
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});



// React component to render the chart
const ChartComponent = () => {
  return (
    <div style={{ width: 400, margin: 'auto' }}>
      <h2>Bar Chart</h2>
      <Bar data={chartData} />
    </div>
  );
};

// React component to generate PDF
const PDFDocument = () => {
  return (
    <Document>
      <Page size="A4">
        <View style={styles.section}>
          <Text>Bar Chart Data Display in PDF</Text>
        </View>
        <View style={styles.section}>
          <ChartComponent />
        </View>
      </Page>
    </Document>
  );
};


// React component to render PDF
const PDFViewerComponent = () => {
  return (
    <PDFViewer style={{ width: '100%', height: '100vh' }}>
      <PDFDocument />
      
    </PDFViewer>
  );
};

// React component to download PDF
const DownloadPDF = () => {
  return (
    <PDFDownloadLink document={<PDFDocument />} fileName="bar_chart.pdf">
      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
      <ChartComponent />

    </PDFDownloadLink>
  );
};







// Main App component
const BarChartTest = () => {
  return (
    <div>
      <ChartComponent />
      <PDFViewerComponent />
      {/* <DownloadPDF /> */}
    </div>
  );
};

export default BarChartTest;