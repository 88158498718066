import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Grid,
  TextField,
  Alert,
  Typography,
  Switch,
  Stack,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import client from 'src/api/clients';

// ----------------------------------------------------------------------

export default function ClientForm({ total, dialogData, setOpen, reloadClientsData }) {



  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');

  // console.log(id)
  // const handleClickOpen = () => {
  //     setOpen(true);
  // };

  const handleClose = () => {
    setOpen({...dialogData, isOpen: false, id: null});
  };

  const [isError, setErrorMsg] = useState({ status: false, msg: "" })


  const [timezones, setTimezone] = useState([])
  const [timezone, setZone] = useState('')

  const [clientType, setClientType] = useState('')
  const [clientNameExits,setClientNameExit] = useState('')

  useEffect(() => {
    client.getTimeZones((response) => {
      if (response.status === 200) {
        setTimezone(response.data)
      }
    })

  }, []) 
  
  const handleTimeZone = (event) => {
    setZone(event.target.value)
  }

  const handleClientType = (event) =>{
    // console.log("client type",event.target.value)
    setClientType(event.target.value)
  }


  const LoginSchema = Yup.object().shape({
    clientName: Yup.string().required('Client/Company name is required'),
    timezone: Yup.string().required('Timezone is required'),
    userId: Yup.string().required('Username is required'),
    email: Yup.string().required('Email is required'),
    userPwd: Yup.string().required('Password is required'),
    ClientType: Yup.string().required('Please select Client Type')
  });

  const jsonToParam = (data) => {
    return Object.keys(data).map(function (k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
    }).join('&')
  }
  

  const handelFormResponse = (response) => {
        setSubmitting(false)
        if (response.status === 200) {
          // console.log(response.data)
          resetForm()
          handleClose()
          reloadClientsData ()
        }
  }

  const formik = useFormik({
    initialValues: {
      id: null,
      clientName: "",
      email: "",
      address: "",
      timezone: "",
      userId: "",
      userPwd: "",
      ClientType: "",
      IsAgentBased: 0
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
     
      setErrorMsg((er) => {
        // console.log('er',er)
        er.status = false
        return er
      })

      const insertData = { 
        Name: values.userId,
        Password: values.userPwd,
        Email: values.email,
        Address: values.address,
        UserType: 2,
        displayname: values.clientName,
        TimezoneId: values.timezone,
        IsAgentBased: values.IsAgentBased,
        ClientType: values.ClientType
      }

      // console.log('Inserted Values', insertData)
      if (values.id) {
        insertData.ID = values.id
        client.updateClient(insertData, (response) => handelFormResponse(response))
      } else
        client.addClient(insertData, (response) => handelFormResponse(response))

      // axios({
      //   method: 'post',
      //   url: '/token',
      //   data: jsonToParam(values),
      //   validateStatus: function (status) {
      //     return status >= 200 || status === 400;
      //   }
      // }).then(function (response) {
      //   setSubmitting(false)
      //   if (response.status === 200 || response.status === 201) {

      //     navigate('/', { replace: true })
      //   } else
      //     setErrorMsg({ status: true, msg: (response.data.error_description ?? 'The user name or password is incorrect') })
      // })

    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting, setFieldValue, resetForm } = formik;
  
  const handleSwitch = (event) => {
    setFieldValue('IsAgentBased', parseInt(event.target.value) === 0 ? 1 : 0)
  }
  const handleSelect = (event) => {
    // console.log(event.target.value);
  };

  useEffect(() => {
    const keys = ['id','clientName','email','address','timezone','userId','userPwd','IsAgentBased','ClientType']
    const values = ['ID','displayname','Email','Address','TimezoneId','Name','Password','IsAgentBased','ClientType']
    // console.log('dialog.id', dialogData)
    if (dialogData.id) {
      keys.forEach((key, idx) => {
        // console.log(key, dialogData.id[values[idx]] === null ? '' : dialogData.id[values[idx]])
        setFieldValue(key, dialogData.id[values[idx]] === null ? '' : dialogData.id[values[idx]])
      })
    } else 
      resetForm()
  }, [dialogData.id])

//   console.log("total",total)

// function checkIfNameExists(textFieldValue) {
//     for (let i = 0; i < total.length; i++) {
//         if (total[i].displayname === textFieldValue) {
//             return true; // Name exists in the records
//         }
//     }
//     return false; // Name doesn't exist in the records
// }

// function checkIfUNameExists(textFieldValue) {
//   for (let i = 0; i < total.length; i++) {
//       if (total[i].Name === textFieldValue) {
//           return true; // Name exists in the records
//       }
//   }
//   return false; // Name doesn't exist in the records
// }

// const textFieldValue = getFieldProps('clientName').value; // Assuming this is how you get the value from the text field
// const userNameFieldValue = getFieldProps('userId').value;
// const userNameExist = checkIfNameExists(userNameFieldValue)
// const nameExists = checkIfUNameExists(textFieldValue);

// if (nameExists) {
//     // Handle case where name already exists
//     // console.log("Client Name already exists in records");
//     setClientNameExit("Client Name already exists in records")
// } else {
//     // Proceed with saving
//     console.log("client Name is unique. Proceed with saving.");
// }

// if (userNameExist) {
//   // Handle case where name already exists
//   console.log("User Name already exists in records");
// } else {
//   // Proceed with saving
//   console.log("User Name is unique. Proceed with saving.");
// }



  return (


    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={dialogData.isOpen}
      onClose={handleClose}
    >
      <DialogTitle>Add/Edit Client</DialogTitle>
      <DialogContent>        
          <FormikProvider value={formik}>
            {(isError.status) && (
              <Alert severity="error" sx={{ mb: 3 }}>{isError.msg}</Alert>
            )}
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Typography variant='subtitle2' sx={{ my: 2 }}>Basic Info</Typography>
              <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      autoComplete="clientName"
                      type="text"
                      label="Client/Company Name"
                      {...getFieldProps('clientName')}
                      error={Boolean(touched.clientName && errors.clientName)}
                      // helperText={touched.clientName && errors.clientName}
                    />
                    {touched.clientName && errors.clientName && <Typography color="error" variant="body2"  >{touched.clientName && errors.clientName}</Typography>}
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      autoComplete="email"
                      type="text"
                      label="Email"
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      // helperText={touched.email && errors.email}
                    />
                    {touched.email && errors.email && <Typography color="error" variant="body2"  >{touched.email && errors.email}</Typography>}

                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      fullWidth
                      autoComplete="address"
                      type="text"
                      label="Address"
                      {...getFieldProps('address')}
                      error={Boolean(touched.address && errors.address)}
                      // helperText={touched.address && errors.address}
                    />
                  {touched.address && errors.address && <Typography color="error" variant="body2"  >{touched.address && errors.address}</Typography>}

                  </Grid>
                  <Grid item md={6}>
                  <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-labell">Timezone</InputLabel>
                  <Select
                    
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={timezone}
                    label="Timezone"
                    onChange={handleTimeZone}
                    {...getFieldProps('timezone')}
                    error={Boolean(touched.timezone && errors.timezone)}
                    // helperText={touched.timezone && errors.timezone}
                  >
                    {timezones.map((item, key) => (
                      <MenuItem key={key} value={item.Id}>{item.TimeZone}</MenuItem>
                    ))}
                    
                    {/* <FormHelperText>{touched.timezone && errors.timezone}</FormHelperText> */}
                  </Select>
                  {touched.timezone && errors.timezone && <Typography color="error" variant="body2"  >{touched.timezone && errors.timezone}</Typography>}

                  </FormControl>
                  
                 
                  </Grid>
                  <Grid item md={6}>
                  <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-labell">Client Type</InputLabel>
                  <Select
                    
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={clientType}
                    label="Client Type"
                    onChange={handleClientType}
                    {...getFieldProps('ClientType')}
                    error={Boolean(touched.ClientType && errors.ClientType)}
                    // helperText={touched.ClientType && errors.ClientType}
                  >
                    
                    <MenuItem value="INDIA">INDIA</MenuItem>
                    <MenuItem value="US">US</MenuItem>

                    {/* <FormHelperText>{touched.timezone && errors.timezone}</FormHelperText> */}
                  </Select>
                  {touched.ClientType && errors.ClientType && <Typography color="error" variant="body2"  >{touched.ClientType && errors.ClientType}</Typography>}

                  </FormControl>
                  
                 
                  </Grid>
              </Grid>              
              <Typography variant='subtitle2' sx={{ my: 2 }}>Login Info</Typography>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    autoComplete="userId"
                    type="text"
                    label="Username"
                    {...getFieldProps('userId')}
                    error={Boolean(touched.userId && errors.userId)}
                    // helperText={touched.userId && errors.userId}
                  />
                {touched.userId && errors.userId && <Typography color="error" variant="body2"  >{touched.userId && errors.userId}</Typography>}

                </Grid>
                <Grid item md={6}>
                  <TextField
                      fullWidth
                      autoComplete="userPwd"
                      type="text"
                      label="Password"
                      {...getFieldProps('userPwd')}
                      error={Boolean(touched.userPwd && errors.userPwd)}
                      // helperText={touched.userPwd && errors.userPwd}
                    />
                {touched.userPwd && errors.userPwd && <Typography color="error" variant="body2"  >{touched.userPwd && errors.userPwd}</Typography>}

                </Grid>
              </Grid>
              <Stack direction={"row"} spacing={2} sx={{ my: 2 }}>
                  <Box>
                    <Typography variant='subtitle2'>Event Send To</Typography>
                    <Typography>
                      {values.IsAgentBased === 0
                        ? "Events will be sent to Client"
                        : "Events will be sent to Tier 1"}

                    </Typography>
                  </Box>
                  { values.IsAgentBased ?
                    <Switch color="success" value={values.IsAgentBased} checked={true} onChange={handleSwitch} inputProps={{ 'aria-label': 'controlled' }}/> :
                    <Switch value={values.IsAgentBased} checked={false} onChange={handleSwitch} inputProps={{ 'aria-label': 'controlled' }}/>
                  }            
                  
                  </Stack>


              <DialogActions>
              <Button color='error' variant='outlined' onClick={handleClose}>Close</Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Save 
              </LoadingButton>
              </DialogActions>
              
            </Form>
          </FormikProvider>


    
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions> */}
    </Dialog>





  );
}

ClientForm.propTypes = {
  // dialogData: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired
}