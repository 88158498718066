import {
    Stack,
    Button,
    Container,
    Typography,
} from '@mui/material';
import Page from '../components/Page';

export default function AuditLive() {

    const handelePopup = (url) => {
        window.open(url,'name','width=1200,height=800')
    }

    return (
        <Page title="Live Audits">
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>Live Audits</Typography>
                </Stack>

                <Stack spacing={2} direction="row">
                    <Button onClick={() => handelePopup('/audit-live/4')} variant="outlined">2X2</Button>
                    <Button onClick={() => handelePopup('/audit-live/6')} variant="outlined">3X2</Button>
                    <Button onClick={() => handelePopup('/audit-live/9')} variant="outlined">3X3</Button>
                </Stack>
               
            </Container>
        </Page>
    )
}