import { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Switch, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';

export default function TimelapsAdd({ isOpen, onClose ,updateDataCallback }) {
  const [selectedCameraIds, setSelectedCameraIds] = useState([]);
  const [dataDevicesId, setDataDevicesId] = useState([]);
  const [ProcessFps, setProcessFps] = useState( );
  const [VideoFps, setVideoFps] = useState();
  const [SegmentTime, setSegmentTime] = useState();
  const [IsActive, setIsActive] = useState(false);

  const handleNumericChange = (setter, value) => {
    // Use parseInt to convert the value to an integer
    const numericValue = parseInt(value, 10);
    
    // Check if the numericValue is NaN, if so, set it to null
    setter(isNaN(numericValue) ? null : numericValue);
  };

  // const handleCameraIdChange = (_, newValue) => {
  //   handleNumericChange(setCameraId, newValue);
  // };

  const handleProcessFpsChange = (e) => {
    handleNumericChange(setProcessFps, e.target.value);
  };

  const handleVideoFpsChange = (e) => {
    handleNumericChange(setVideoFps, e.target.value);
  };

  const handleSegmentTimeChange = (e) => {
    handleNumericChange(setSegmentTime, e.target.value);
  };

  const handleAddTimelaps = async () => {
    try {
      // Construct the data object

      // const data = temId.map((cameraIdOption) => ({
      //   CameraId: selectedCameraIds,
      //   ProcessFps,
      //   VideoFps,
      //   SegmentTime,
      //   IsActive: IsActive ? 1 : 0,
      // }));
      const selectedItems = temId.filter((item) => selectedCameraIds.includes(item));
      // console.log("selected Items",selectedItems)

      const data = selectedCameraIds.map((cameraId) => ({
        CameraId: cameraId,
        ProcessFps,
        VideoFps,
        SegmentTime,
        IsActive: IsActive ? 1 : 0,
      }));

      // const data = [{
      //   CameraId : selectedCameraIds,
      //   ProcessFps,
      //   VideoFps,
      //   SegmentTime,
      //   IsActive: IsActive ? 1 : 0,
      // }];

      // Make a POST request to your API endpoint
      const response = await axios.post('http://192.168.30.68:8080/time-laps/add-time-laps-settings', data);

      // You can handle the response data here if needed
      // console.log('API Response:', response.data);
      updateDataCallback();
      // Clear the input fields and close the dialog
      setSelectedCameraIds([]);
      setProcessFps('');
      setVideoFps('');
      setSegmentTime('');
      setIsActive(false);
      onClose();
    } catch (error) {
      console.error('Error adding Timelaps:', error);
      // Handle error logic here
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = 'http://192.168.30.68:8080/devices/cameras';
      const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
      // console.log('AUTH_TOKEN',AUTH_TOKEN);
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `${AUTH_TOKEN}`,
          },
        });

        // Handle successful response
        setDataDevicesId(response.data);
        // console.log("Token get api", response.data);
      } catch (error) {
        // Handle error
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  
  const temId = dataDevicesId.map(item => item.deviceid);

  // const temId = ['1','2','3','4']

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add TimeLaps</DialogTitle>
      <DialogContent>

      <Autocomplete
          multiple
          options={temId}
          getOptionLabel={(option) => option}
          value={selectedCameraIds}
          onChange={(event, newValue) => setSelectedCameraIds(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="CameraId"
              fullWidth
              margin="normal"
            />
          )}
        />

        <TextField
          label="ProcessFps"
          value={ProcessFps}
          onChange={handleProcessFpsChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="VideoFps"
          value={VideoFps}
          onChange={handleVideoFpsChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="SegmentTime"
          value={SegmentTime}
          onChange={handleSegmentTimeChange}
          fullWidth
          margin="normal"
        />
        <Box>
          <Typography variant='subtitle2'>Active state</Typography>
          <Typography>Disabling this will automatically set to InActive</Typography>
        </Box>
        <Switch
          color="success"
          checked={IsActive}
          onChange={() => setIsActive(!IsActive)}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' variant='outlined' onClick={onClose}>
          Close
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleAddTimelaps}
        >
          Add Time Laps
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

