import axios from '../axios'
import { getURL, jsonToFormData } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';

const client = new Object()

// Get client data
client.getClients = (callback) => {
    axios({
        method: 'get',
        url: getURL('client'),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

// get All timezones
client.getTimeZones = (callback) => {
    axios({
        method: 'get',
        url: getURL('timezone'),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

// Add new client
client.addClient = (formdata, callback) => {
    axios({
        method: 'post',
        url: getURL('client/add'),
        data: jsonToFormData(formdata),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

client.updateClient = (formdata, callback) => {
    axios({
        method: 'put',
        url: getURL('client/update'),
        data: jsonToFormData(formdata),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

client.updateSataus = (id, status, callback) => {
    axios({
        method: 'put',
        url: getURL('client/update-status'),
        data: jsonToFormData({
            "ID": id,
            "Status": status
        }),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

client.deleteClient = (id, callback) => {
    axios({
        method: 'delete',
        url: getURL('client/delete'),
        data: jsonToFormData({"ID": id}),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

export default client