// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const deployNavConfig = [
  // {
  //   title: 'Dashboard',
  //   path: '/DashboardDep',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  //   isHideInXs: false,
  //   positiveAccess: [],
  //   positiveUserRoles: [],
  //   negativeUserRoles: [],
  //   negativeAccess: [],
  // },
  {
    title: 'Clients',
    path: 'clients',
    icon: getIcon('eva:people-outline'),
    isHideInXs: true,
    positiveAccess: [],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  {
    title: 'Cameras',
    path: '/devices',
    icon: getIcon('eva:camera-outline'),
    isHideInXs: false,
    positiveAccess: [],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
  },


  // {
  //   title: 'Groups',
  //   path: '/group-settings',
  //   icon: getIcon('eva:color-palette-outline'),
  //   isHideInXs: false,
  //   positiveAccess: [],
  //   positiveUserRoles: [9],
  //   negativeUserRoles: [],
  //   negativeAccess: [],
  // },
  // {
  //   title: 'Monitoring Times',
  //   path: '/mon-timings',
  //   icon: getIcon('eva:clock-outline'),
  //   isHideInXs: false,
  //   positiveAccess: [],
  //   positiveUserRoles: [],
  //   negativeUserRoles: [],
  //   negativeAccess: [],
  // },
  // {
  //   title: 'Time Laps',
  //   path: 'timelaps',
  //   icon: getIcon('quill:stopwatch'),
  //   isHideInXs: true,
  //   positiveAccess: [],
  //   positiveUserRoles: [],
  //   negativeUserRoles: [],
  //   negativeAccess: [],
  // },
  // {
  //   title: 'Shift Timings',
  //   path: 'shifttime',
  //   icon: getIcon('eva:people-outline'),
  //   isHideInXs: true,
  //   positiveAccess: [],
  //   positiveUserRoles: [],
  //   negativeUserRoles: [],
  //   negativeAccess: [],
  // }, 
  {
    title: 'Neterwala',
    path: 'neterwala',
    icon: getIcon('eva:people-outline'),
    isHideInXs: true,
    positiveAccess: [765],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  {
    title: 'Reports',
    path: '/deployment-reports',
    icon: getIcon('eva:file-text-outline'),
    isHideInXs: false,
    positiveAccess: [],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  {
    title: 'Live',
    path: '/cameras-live',
    icon: getIcon('eva:video-outline'),
    isHideInXs: false,
    positiveAccess: [],
    positiveUserRoles: [],
   // negativeUserRoles: [9],
    negativeUserRoles: [],
    negativeAccess: [],
  },
  // {
  //   title: 'Escalated Events',
  //   path: '/Escalated-Events',
  //   icon: getIcon('eva:video-off-outline'),
  //   isHideInXs: false,
  //   positiveAccess: [],
  //   positiveUserRoles: [],
  //   negativeUserRoles: [9],
  //   negativeAccess: [],
  // },
  {
    title: 'Settings',
    path: '/settings', // Adjust the path as needed
    icon: getIcon('eva:settings-outline'),
    isHideInXs: false, // Adjust visibility as needed
    positiveAccess: [],
    positiveUserRoles: [],
    negativeUserRoles: [],
    negativeAccess: [],
    children: [
      {
        title: 'Groups',
        path: '/groups', // Adjust the path as needed
        icon: getIcon('eva:people-outline'),
        isHideInXs: false, // Adjust visibility as needed
        positiveAccess: [],
        positiveUserRoles: [],
        negativeUserRoles: [],
        negativeAccess: [],
      },
      {
        title: 'Protocols',
        path: 'device_protocols', // Adjust the path as needed
        icon: getIcon('eva:settings-outline'),
        isHideInXs: false, // Adjust visibility as needed
        positiveAccess: [],
        positiveUserRoles: [],
        negativeUserRoles: [],
        negativeAccess: [],
      },
      {
        title: 'Add Agent',
        path: 'Add-Agent', // Adjust the path as needed
        icon: getIcon('eva:settings-outline'),
        isHideInXs: false, // Adjust visibility as needed
        positiveAccess: [],
        positiveUserRoles: [],
        negativeUserRoles: [],
        negativeAccess: [],
      },
      {
        title: 'Servers',
        path: 'servers',
        icon: getIcon('eva:hard-drive-outline'),
        isHideInXs: true,
        positiveAccess: [],
        positiveUserRoles: [],
        negativeUserRoles: [],
        negativeAccess: [],
      },
      {
        title: 'Analytics',
        path: '/analytics',
        icon: getIcon('eva:color-palette-outline'),
        isHideInXs: false,
        positiveAccess: [],
        positiveUserRoles: [9],
        negativeUserRoles: [],
        negativeAccess: [],
      },
      {
        title: 'Login Creations',
        path: '/AddNewlogin',
        icon: getIcon('eva:color-palette-outline'),
        isHideInXs: false,
        positiveAccess: [],
        positiveUserRoles: [9],
        negativeUserRoles: [],
        negativeAccess: [],
      },
      {
        title: 'Client Consolidation Login',
        path: '/clientConsolidationlogin',
        icon: getIcon('eva:color-palette-outline'),
        isHideInXs: false,
        positiveAccess: [],
        positiveUserRoles: [9],
        negativeUserRoles: [],
        negativeAccess: [],
      },

      // {
      //   title: 'Daily Email Reports',
      //   path: 'dailyEmailReports', // Adjust the path as needed
      //   icon: getIcon('eva:settings-outline'),
      //   isHideInXs: false, // Adjust visibility as needed
      //   positiveAccess: [],
      //   positiveUserRoles: [],
      //   negativeUserRoles: [],
      //   negativeAccess: [],
      // }
    ]
    },
  {
      title: 'Billing',
      path: '/settings', // Adjust the path as needed
      icon: getIcon('mdi:currency-inr'), // Use the rupee icon here
      isHideInXs: false, // Adjust visibility as needed
      positiveAccess: [],
      positiveUserRoles: [],
      negativeUserRoles: [],
      negativeAccess: [],
      children: [
        {
          title: 'View Plans',
          path: '/viewPLans', // Adjust the path as needed
          icon: getIcon('mdi:currency-inr'), // Use the rupee icon here
          isHideInXs: false, // Adjust visibility as needed
          positiveAccess: [],
          positiveUserRoles: [],
          negativeUserRoles: [],
          negativeAccess: [],
        },
        {
          title: 'Add Plan To the Client',
          path: '/clientADDbilling', // Adjust the path as needed
          icon: getIcon('mdi:currency-inr'), // Use the rupee icon here
          isHideInXs: false, // Adjust visibility as needed
          positiveAccess: [],
          positiveUserRoles: [],
          negativeUserRoles: [],
          negativeAccess: [],
        },
        {
          title: 'Generate Bill For Client',
          path: '/clientbilling', // Adjust the path as needed
          icon: getIcon('eva:people-outline'),
          isHideInXs: false, // Adjust visibility as needed
          positiveAccess: [],
          positiveUserRoles: [],
          negativeUserRoles: [],
          negativeAccess: [],
        }

        // {
        //   title: 'Protocols',
        //   path: 'device_protocols', // Adjust the path as needed
        //   icon: getIcon('eva:settings-outline'),
        //   isHideInXs: false, // Adjust visibility as needed
        //   positiveAccess: [],
        //   positiveUserRoles: [],
        //   negativeUserRoles: [],
        //   negativeAccess: [],
        // },
        // {
        //   title: 'Add Agent',
        //   path: 'Add-Agent', // Adjust the path as needed
        //   icon: getIcon('eva:settings-outline'),
        //   isHideInXs: false, // Adjust visibility as needed
        //   positiveAccess: [],
        //   positiveUserRoles: [],
        //   negativeUserRoles: [],
        //   negativeAccess: [],
        // }
      ]
      },
  // {
  //   title: 'VerificationPage',
  //   path: '/',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  //   isHideInXs: false,
  //   positiveAccess: [],
  //   positiveUserRoles: [],
  //   negativeUserRoles: [],
  //   negativeAccess: [],
  // }


];

export default deployNavConfig;