import {
    Stack,
    Box,
    Select,
    MenuItem,
    IconButton,
    FormControl,
    InputLabel,
    Alert
} from '@mui/material';
import { useRef, useEffect } from "react";
import Iconify from 'src/components/Iconify';
import useVideoPlayer from "src/hooks/useVideoPlayer";

export default function VideoPlayer ({player}) {

    // console.log(player)

    const videoElement = useRef(null)
    const {
            playerState,
            togglePlay,
            handleOnTimeUpdate,
            handleVideoSpeed,
            handlePause,
            handlePlay,
            handleInitPlay
        } = useVideoPlayer(videoElement);
        
    useEffect(() => {
            if (player.VideoUrl)
                handleInitPlay()
            // console.log(typeof(JSBridge))
            var JSBridge = (typeof JSBridge === "undefined") ? false : JSBridge
            if (JSBridge)
                JSBridge.showMessageInNative(player.VideoUrl);
            
        }, [player.VideoUrl])   

    return (
        <Box>
            <Box style={{ background: 'linear-gradient(to top, rgb(6, 6, 6), 20%, rgb(51 51 51))', maxHeight: 403, maxWidth: 720 }}>
                <video
                    src={player.VideoUrl}
                    controls
                    ref={videoElement}
                    onTimeUpdate={handleOnTimeUpdate}
                    onPause={handlePause}
                    onPlay={handlePlay}
                    style={{ maxHeight: 403, maxWidth: 720, width: '100%', display: player.display }} />
            </Box>
            <Stack direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2} sx={{ m: 2 }}>
                {/* <Box>
                <Chip
                    disabled={!player.src}
                    label={`Video ${playerState.playStatus}`}
                    color={playerState.isPlaying ? "primary" : "error"}
                    variant="outlined"
                    size="small" />
                
            </Box> */}
                <FormControl>
                    <InputLabel id="video-simple-select-label">Speed</InputLabel>
                    <Select
                        labelId="video-simple-select-label"
                        id="video-simple-select"
                        label="Speed"
                        value={playerState.speed}
                        onChange={(e) => handleVideoSpeed(e)}
                        disabled={!player.VideoUrl}
                    >
                        <MenuItem value={1}>1x</MenuItem>
                        <MenuItem value={2}>2x</MenuItem>
                        <MenuItem value={3}>3x</MenuItem>
                        <MenuItem value={4}>4x</MenuItem>
                        <MenuItem value={5}>5x</MenuItem>
                    </Select>
                </FormControl>
                <IconButton aria-label="refresh" color="success" onClick={togglePlay} disabled={!player.VideoUrl}>
                    {!playerState.isPlaying ? (
                        <Iconify disabled={!player.VideoUrl} icon="bi:play-circle-fill" sx={{ height: 34, width: 34 }} />
                    ) : (
                        <Iconify icon="bi:pause-circle-fill" sx={{ height: 34, width: 34 }} />
                    )}
                </IconButton>
                
                
            </Stack>

        </Box>
    )
}