import React, { useEffect, useState } from 'react';
import axios from 'src/axios';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Checkbox,
  useStepContext,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ListItemText } from '@mui/material';
import Iconify from 'src/components/Iconify';
import {
  CardContent,  Toolbar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableHead,
} from '@mui/material';
import { getGroups,getClientInfo,AddClientGroupName,AddClientGroup } from 'src/api/groups';
import { getClientList } from 'src/api/analytics';
import { getDevices } from 'src/api/devices';
// import GroupsMore from './GroupsMore';
import GroupsMore from '../settings/GroupsMore';
import { CameraEnhance } from '@mui/icons-material';
import { iteratee } from 'lodash';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { tr } from 'date-fns/locale';
import { getClientPlanInfo ,AddclientInvoice} from 'src/api/plan';
import { styled } from '@mui/material/styles';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Image from 'src/components/Image';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import dayjs from 'dayjs';


const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  // justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ClientBilling= () => {
  const [data, setData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null); // State for selected client
  const [clientInfo, setClientInfo] = useState([]); // State for storing client data
  const [clientData,setClientData]=useState([])

  // add group to Devices
  const [errorClientMessage, setClientErrorMessage] = useState('');
  const [errorMessageOpen, setErrorMessageOpen] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().startOf('month').hour(0).minute(0))
  const [discountAmount, setDiscountAmount] = useState('');
  const [formatedDate,setFormatedDate]= useState("")
  const [unitsPrice,setUnitsPrice] = useState("");

  useEffect(()=> {
    if(selectedClient != null){
      if(selectedClient.ClientType != null){
        setUnitsPrice(selectedClient.ClientType === "INDIA" ? "INR" :"USD")
      }

    }

    
  },[selectedClient])

  // console.log("selcted Clien",selectedClient);
  useEffect (()=>{

    getClientList((response) => {
      if (response.status === 200) {
         setClientInfo(response.data)
      }
    })
  },[])

  const onClientChange = (event,value)=>{

    // console.log("Client",value);
    setSelectedClient(value);
  }


 const handleSave = () => {

  const fromDatefor = fromDate;

  const isValidDate = (value) => {
    const date = new Date(value);
    return !isNaN(date.getTime());
  };

  const parseDate = (dateString) => {
    if (!isValidDate(dateString)) {
      console.error("Invalid date:", dateString);
      return null; // Return null if the date string is invalid
    }
    return new Date(dateString);
  };

  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const fromDateObj = parseDate(fromDatefor);
  const formattedFromDate = formatDateTime(fromDateObj);

    if (!selectedClient) {
     
        setClientErrorMessage("Please Select client")
        setErrorMessageOpen(true);
     }
    else {

      const body={client_id:selectedClient.ID}
      // console.log("formattedFromDate",formattedFromDate)
      setFormatedDate(formattedFromDate);
      getClientPlanInfo(body,(response)=>{

            setClientData(response.data)

            let body = [{
              ClientId: selectedClient.ID,
              NoOfCameras: clientData.length,
              InvoiceDate:formattedFromDate,
              PaymentAmount: 2000
            }]

            AddclientInvoice(body, (response) => {    
              // if (response.status === 200) {
              //   getAllPlanDetails((response) => {
              //     setPlan(response.data);
              //     setOpenError(true);
              //     setMessage("Plan Added Successfully");
              //   });
              // }
            });
        // get invoice
            })
         
    }
  };
 
  const handleDownloadPDF1 = () => {
    setClientErrorMessage("")
 if (!selectedClient) {
     
      setClientErrorMessage("Please Select client")
      setErrorMessageOpen(true);
   }
  else if (clientData.length === 0){
    setClientErrorMessage("Can't Downlaod Due to Empty Records")
    setErrorMessageOpen(true);
  } 
   else if(clientData.lenght != 0){
    const doc = new jsPDF();

    doc.text(`${selectedClient.displayname}`, 20, 20);

    const tableColumn = ["S.No", "Client Name", "Camera Name", "Plan Name", "Price"];
    const tableRows = [];

    clientData.forEach((row, index) => {
      const tableRow = [
        index + 1,
        row.displayname,
        row.devicename,
        row.PlanName,
        row.Pricing,
      ];
      tableRows.push(tableRow);
    });

    // Add total row
    const totalRow = [
      "",
      "",
      "",
      "Total",
      clientData.reduce((total, row) => total + row.Pricing, 0),
    ];
    tableRows.push(totalRow);


    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
      styles: { halign: 'center' }, // horizontally center align text
      headStyles: { fillColor: '#5e92c7' },
      didParseCell: function (data) {
        // Apply color to the total row
        if (data.row.index === tableRows.length - 1) {
          data.cell.styles.fillColor = [94, 146, 199];
          data.cell.styles.textColor = [0, 0, 0];
          data.cell.styles.fontStyle = 'bold';
        }
      }
    });

    doc.save(`${selectedClient.displayname} client_data_report.pdf`);
  }

  };

  const handleDownloadPDF = () => {


    setClientErrorMessage("");
    
    if (!selectedClient) {
      setClientErrorMessage("Please Select client");
      setErrorMessageOpen(true);
    } else if (clientData.length === 0) {
      setClientErrorMessage("Can't Download Due to Empty Records");
      setErrorMessageOpen(true);
    } else {
      const doc = new jsPDF();
      const discountAmountpdf = discountAmount ? discountAmount : 0; // Example discount amount, replace with your actual value if needed
  
      doc.text(`${selectedClient.displayname}`, 20, 20);
      doc.text(`${formatedDate}`, 120, 20);


      const tableColumn = ["S.No", "Client Name", "Camera Name", "Plan Name", "Price"];
      const tableRows = [];
  
      clientData.forEach((row, index) => {
        const tableRow = [
          index + 1,
          row.displayname,
          row.devicename,
          row.PlanName,
          row.Pricing,
        ];
        tableRows.push(tableRow);
      });
  
      // Add total row
      const totalAmount = clientData.reduce((total, row) => total + row.Pricing, 0);
      const totalRow = [
        "",
        "",
        "",
        "Total Amount",
        totalAmount,
      ];
      tableRows.push(totalRow);
      
      // Add discount amount row
      const discountRow = [
        "",
        "",
        "",
        "Discount Amount",
        discountAmountpdf
      ];
      tableRows.push(discountRow);
  

  
      // Add final amount row
      const finalRow = [
        "",
        "",
        "",
        "Final Amount",
        totalAmount - discountAmount
      ];
      tableRows.push(finalRow);
  
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 30,
        styles: { halign: 'center' }, // horizontally center align text
        headStyles: { fillColor: '#5e92c7' },
        didParseCell: function (data) {
          // Apply color to the total and final amount rows
          if (data.row.index === tableRows.length - 3 || data.row.index === tableRows.length - 1) {
            data.cell.styles.fillColor = [94, 146, 199];
            data.cell.styles.textColor = [0, 0, 0];
            data.cell.styles.fontStyle = 'bold';
          }
        }
      });
  
      doc.save(`${selectedClient.displayname}_client_data_report.pdf`);
    }
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handlediscountAmount = (event) => {
   
    setDiscountAmount(event.target.value)
  };

  return (
    <>
<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} ml={5}>
       
        <Typography variant="h4" gutterBottom>
        Generate Bill For Client
        </Typography>    
</Stack>

     
  <Card sx={{ width: '95%', p: 2, margin: 'auto' }}>
  <CardContent>
    <Grid container spacing={3}>
      
      <Grid item xs={3}>
        <FormControl fullWidth>
        <Autocomplete
          options={clientInfo.map((client, clientIndex) => ({
            ...client,
            customKey: clientIndex, // Assuming clientIndex is a unique identifier
          }))}
          isOptionEqualToValue={(option, value) =>
            option.customKey === value.customKey
          }
          getOptionLabel={(option) => `${option.displayname}`}
          onChange={(event, value) => setSelectedClient(value)}
          value={selectedClient}
          renderInput={(params) => (
            <TextField
              {...params}
              id="select-cameras"
              label="Select client"
              variant="outlined"
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.customKey}>
              {option.displayname}
            </li>
          )}
        />

          
           </FormControl>
          {errorClientMessage && <Typography color="error" variant="body2" align="left">{errorClientMessage}</Typography>}
      </Grid>
           
      <Grid item xs={3}>
        <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}  >
                  <DesktopDatePicker
                    label="Date"
                    inputFormat="DD/MM/YYYY"
                    value={fromDate}
                    onChange={(e) => handleFromDateChange(e)}
                    name="reportDate"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
            <FormControl fullWidth>
            <TextField
              id="discount-amount"
              variant="outlined"
              label="Discount Amount"
              value={discountAmount}
              onChange={(e)=>handlediscountAmount(e,"add")}
              InputProps={{
                inputProps: {
                  pattern: "[0-9]*", // Only accept numbers
                }
              }}
            />

            </FormControl>
      </Grid>


      <Grid item xs={2}>
        <Button variant="outlined" onClick={handleSave} sx={{ mt: 2 ,marginRight:"2px"}}>
          Get Invoice
        </Button>
       {/* </Grid> 

      <Grid item xs={2}> */}
      <Button variant="outlined" onClick={handleDownloadPDF} sx={{ mt: 2 }}>
        Download PDF
        </Button>
    </Grid>

    
    </Grid>
  </CardContent>

        <TableContainer>
        <Table>
        <TableHead sx={{ background: '#f3f3f3' }}>
        <TableRow>
          <TableCell sx={{ background: '#f3f3f3' }}>S.No</TableCell>
          <TableCell sx={{ background: '#f3f3f3' }}>Client Name</TableCell>
          <TableCell sx={{ background: '#f3f3f3' }}>Camera Name</TableCell>
          <TableCell sx={{ background: '#f3f3f3' }}>Plan Name</TableCell>
          <TableCell sx={{ background: '#f3f3f3' }}>Price</TableCell>
          <TableCell sx={{ background: '#f3f3f3' }}></TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {clientData.map((row, index) => (
        <TableRow key={index}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{row.displayname}</TableCell>
        <TableCell>{row.devicename}</TableCell>
        <TableCell>{row.PlanName}</TableCell>
        <TableCell>{row.Pricing}  {unitsPrice}</TableCell>      
        </TableRow>
        ))}

        {clientData.length > 0 && discountAmount &&
        <TableRow >
          <TableCell align="right" colSpan={4} style={{ fontSize: "1.0rem",fontWeight: 'bold' }}>Actual Amount </TableCell>
          <TableCell align="left"   style={{ fontSize: "1.0rem",fontWeight: 'bold' }}>
            {clientData.reduce((total, row) => total + row.Pricing, 0)}  {unitsPrice}
          </TableCell>
        </TableRow>
      }

        {discountAmount &&
        <TableRow >
          <TableCell align="right" colSpan={4} style={{ fontSize: "1.0rem",fontWeight: 'bold' }}>Discount Amount</TableCell>
          <TableCell align="left"   style={{ fontSize: "1.0rem",fontWeight: 'bold' }}>
            {discountAmount ? discountAmount : 0}   {unitsPrice}
          </TableCell>
        </TableRow>
            }
          {clientData.length > 0 &&
                  <TableRow>
                    <TableCell align="right" colSpan={4} style={{ fontSize: "1.0rem", fontWeight: 'bold' }}>Final Amount</TableCell>
                    <TableCell align="left" style={{ fontSize: "1.0rem", fontWeight: 'bold' }}>
                      {clientData.reduce((total, row) => total + row.Pricing, 0) - discountAmount} {unitsPrice}
                    </TableCell>
                  </TableRow>
          }
        </TableBody>

        </Table>
        </TableContainer>

      </Card>
</>
  );
};

export default ClientBilling;
