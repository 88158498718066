import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Link,
} from '@mui/material';
import Iconify from '../../src/components/Iconify.js';
import { useEffect, useState } from 'react';
import axios from "axios";
import { UserListHead, UserListToolbar } from '../../src/sections/@dashboard/user';
import Label from '../../src/components/Label.js';
import ShiftTimeMoreMenu from 'src/sections/shifttime/ShiftTimeMoreMenu.js';
import ShiftTimeAdd from 'src/sections/shifttime/shiftTimeAdd.js';
import ShiftTimeEdit from 'src/sections/shifttime/shiftTimeEdit.js';

// mock
import { ListItemText } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import React from 'react';
import { id } from 'date-fns/locale';




const TableHeaders = ({ headers }) => (
  <TableRow>
    {headers.map((header) => (
      <TableCell key={header.id} align="left">
        <Typography variant="subtitle1" fontWeight="bold">
          {header.label}
        </Typography>
      </TableCell>
    ))}
   
  </TableRow>
);

export default function ShiftTme() {

  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [clients, setClient] = useState('');


  const TABLE_HEAD = [
    { id: 'Id', label: 'S.No' },
    { id: 'LocationName', label: 'LocationName' },
    { id: 'ShiftName', label: 'ShiftName' },
    { id: 'FromTime', label: 'FromTime' },
    { id: 'ToTime', label: 'ToTime' },
    { id: 'Status', label: 'IsActive' },
  ];
  const [data, setData] = useState([]);


  // const [dialog, setOpen] = useState({ isOpen: false, id: null });

  // function handleDialog(id=null) {
  //   console.log('dialog', dialog)
  //   setOpen({...dialog, isOpen: true, id: id})
  // }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };





  const filteredData = data.filter((row) =>
  Object.values(row).some(
    (value) =>
      value &&
      value.toString().toLowerCase().includes(filterName.toLowerCase())
  )
);



useEffect(() => {
  const fetchData = async () => {
    const apiUrl = 'http://192.168.30.68:8080/employee-shifts-info?status=1';
    const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
    // console.log('AUTH_TOKEN',AUTH_TOKEN);
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `${AUTH_TOKEN}`,
        },
      });
      // console.log('shift',response);
      setData(response.data);
      const sortedData = response.data.sort((a, b) => a.Id - b.Id);
      setData(sortedData);
    } catch (error) {
      // Handle error
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);

  const handleUpdateData = async () => {


    const apiUrl = 'http://192.168.30.68:8080/employee-shifts-info?status=1';
    const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
    // console.log('AUTH_TOKEN',AUTH_TOKEN);
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `${AUTH_TOKEN}`,
        },
      });

      // Handle successful response
      setData(response.data);
      const sortedData = response.data.sort((a, b) => a.Id - b.Id);
      setData(sortedData);
      // console.log("Token get api", response.data);
    } catch (error) {
      // Handle error
      console.error('Error:', error);
    }
  };







  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [editData, setEditData] = useState({}); // State to store the data for editing
  const [isShiftTimeAddVisible, setShiftTimeAddVisible] = useState(false);

  const addTimelaps = () => {

    setShiftTimeAddVisible((prevVisibility) => !prevVisibility);
  };
  const handleAddTimelaps = (timelapsValue) => {
    // Handle the logic for adding timelaps here
    // console.log('Adding Timelaps:', timelapsValue);
  };



// const handleAlertOpen = (id, status) => {
//     console.log(id)
//       if (status === 'status' && Number(id.Status) === 0)
//         handleStatus(id.ID, 1)
//       else {
//         // let title = status === 'status' ? 'Are you sure?' : 'Are you sure?'
//         let content = status === 'status' ? 'Are you sure want to inactive this record?': 'Are you sure want to delete this record permanently?'
//         let btn = status === 'status' ? "Yes, inactive record!": "Yes, delete record!"
//         let upStatus = status === 'delete' ? 2 : 0
//         setAlert({
//             title: btn,
//             content: content,
//             open: true,
//             id: id.ID,
//             status: upStatus
//         })
//       }        
//   }
 
  // handleStatus(id.ID, 1){

  // }


  
  const handleEditClick = (row) => {
    setCurrentId(row.Id);
    setEditData(row);
    setIsDialogOpen(true);
  };

    const handleCloseDialog = () => {
      setIsDialogOpen(false);
      setCurrentId(null);
    };
  
  return (
<>
<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Shift Timings
        </Typography>
        <Button variant="contained" to="#" onClick={() => addTimelaps()} startIcon={<Iconify icon="eva:plus-fill" />}>
          Add  Shift Time
        </Button>

      </Stack>
<UserListToolbar sx={{ backgroundColor: "#f3f3f3" }}   numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName}/>

<Card>
      <TableContainer sx={{ width: '100%' }}>
        <Table>
      
          <React.Fragment>
            <UserListHead 
            headers={TABLE_HEAD} 
            onRequestSort={handleRequestSort}
          
            headLabel={TABLE_HEAD}
            rowCount={clients.length}
            numSelected={selected.length}
            />

            {/* Render rows with dummy data */}
            <TableBody>
            {filteredData.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="left">{row.Id}</TableCell>
                <TableCell align="left">{row.LocationName}</TableCell>
                <TableCell align="left">{row.ShiftName}</TableCell>
                <TableCell align="left">{row.FromTime}</TableCell>
                <TableCell align="left">{row.ToTime}</TableCell>
                <TableCell align="left">   
                    <Label variant="ghost" color={row.Status === 1 ? 'success': 'error'}>
                       {row.Status === 1 ? 'Active': 'In Active'}
                   </Label>              
           </TableCell>
           {/* <TableCell align="right">
                    <ShiftTimeMoreMenu   
                     row={row.Id}
                     handleDialog={handleDialog}
                      // handleAlertOpen={handleAlertOpen}
                  />
                </TableCell> */}
             
                <TableCell align="right">
                  <Button onClick={() => handleEditClick(row)} >
                     <Iconify icon="eva:edit-fill" width={24} height={24} />
                     <ListItemText primary="Edit"/>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
            
            {/* Your other components and logic go here */}
          </React.Fragment>
        </Table>
      </TableContainer>
    </Card>
   
    <ShiftTimeAdd
        isOpen={isShiftTimeAddVisible}
        onClose={() => setShiftTimeAddVisible(false)}
        onAddTimelaps={handleAddTimelaps}
        
      />

    <ShiftTimeEdit isOpen={isDialogOpen} onClose={handleCloseDialog} currentId={currentId} rowData={editData} updateDataCallback={handleUpdateData} />


</>
 
  );
}

