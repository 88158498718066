import { 
    Card,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    Paper,
    CardHeader
} from "@mui/material";

export default function ReportTable ({ title, tableData}) {
    return (
        <Card sx={{p: 3, my: 2}}>
            <Typography variant="h6">{title}</Typography>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>S No</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Entry Count</TableCell>
                        <TableCell>Exit Count</TableCell>
                        <TableCell>Wear No Safety Boots People Count</TableCell>
                        <TableCell>Wear No Mask People Count</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(tableData).length > 0 ? Object.keys(tableData).map((key, main_index) => {
                        
                        return tableData[key].details.map((details, index) => { 
                            // console.log(details)
                            return (
                            <TableRow key={`${main_index}_${index}`}>
                                <TableCell>{main_index+1}</TableCell>
                                <TableCell>{key}</TableCell>
                                <TableCell>{details.entry_count}</TableCell>
                                <TableCell>{details.exit_count}</TableCell>
                                <TableCell>{details.no_boots_count}</TableCell>
                                <TableCell>{details.no_mask_count}</TableCell>
                            </TableRow>
                        )})
                    }) : (
                        <TableRow>
                            <TableCell colSpan={6} align="center">--No Data--</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Card>
    )
}