

import React, { useState, useEffect } from 'react';
import { filter } from 'lodash';
import Iconify from 'src/components/Iconify';
import {
  InputAdornment,
  Grid,
  Box,
  Autocomplete,
  Button,
  TextField,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableContainer, 
  TablePagination, 
  Toolbar, 
  OutlinedInput, createFilterOptions,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText
} from '@mui/material';
import PropTypes from 'prop-types';
import Scrollbar from 'src/components/Scrollbar';
import { styled } from '@mui/material/styles';
import { getClientList, trueEventsReport } from 'src/api/EventReports/EventReports';
import SearchNotFound from 'src/components/SearchNotFound';
import * as XLSX from 'xlsx';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';


const iconClient = <CheckBoxOutlineBlankIcon fontSize="small" />;

const checkedIconClient = <CheckBoxIcon fontSize="small" />;


const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  marginLeft: 15,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const months = [
  { name: 'January', value: '1' },
  { name: 'February', value: '2' },
  { name: 'March', value: '3' },
  { name: 'April', value: '4' },
  { name: 'May', value: '5' },
  { name: 'June', value: '6' },
  { name: 'July', value: '7' },
  { name: 'August', value: '8' },
  { name: 'September', value: '9' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' }
];

const TotalEvents = () => {
  const [filterName, setFilterName] = useState('');
  const [value1, setValue1] = useState(null);
  const [value2, setValue2] = useState(null);
  const [selectedClients, setSelectedClients] = useState([]);
  const currentYear = new Date().getFullYear();
  const years = [(currentYear - 1).toString(), currentYear.toString(), (currentYear + 1).toString()];

  const [error, setError] = useState(false);
  const [isMonthSelected, setIsMonthSelected] = useState(false);
  const [isYearSelected, setIsYearSelected] = useState(false);
  const [isClientSelected, setIsClientSelected] = useState(false);
  const [data, setData] = useState([]);
  const [page, pageChange] = useState(0);
  const [rowPerPage, rowPerPageChange] = useState(100);
  const [clientInfo, setClientInfo] = useState([]);
  

  const [checkValue,setCheckValue]=useState(false)
  const [checkClientValue,setCheckClientValue]=useState(false)
  const [inputClientValue, setInputClientValue] = useState([]);
  const [activeClients, setActiveClients] = useState([])
  const [openClientAuto,setOpenClientAuto]=useState(false)
  const [isClientList, setisClientList] = useState([]);


  useEffect(() => {
    getClientList((response) => {
      if (response.status === 200) {
        setClientInfo(response.data);
      }
    });
  }, []);

  const handleButtonClick = () => {
    setError(false);
    setIsMonthSelected(false);
    setIsYearSelected(false);
    setIsClientSelected(false);

    if (!value1) {
      setError(true);
      setIsMonthSelected(true);
    }

    if (!value2) {
      setError(true);
      setIsYearSelected(true);
    }

    // if (selectedClients.length === 0) {
    //   setError(true);
    //   setIsClientSelected(true);
    // }

    if (value1 && value2) {
      const month = value1.value;
      const year = value2;
      const clientIds = activeClients ? `${activeClients}` : "";
      // const requestData = { month, year,clientIds};
      const body ={
        month : month,
        year  : year,
        client_id : clientIds ? clientIds : ""
      }

// debugger
      trueEventsReport(body, (result) => {
        if (result.status === 200) {
          setData(result.data);
          // console.log("result data", result.data);
        }
      });
    }
  };

  const columns = [
    { id: 'Sno', name: 'Sno' },
    { id: 'displayname', name: 'Client Name' },
    { id: 'total_event_count', name: 'Total Events Count' },
    { id: 'true_event_count', name: 'True Events Count' },
    { id: 'false_event_count', name: 'False Events Count' },
    { id: 'escalated_event_count', name: 'Escalated Events Count' },
  ];

  const handleChangePage = (event, newPage) => {
    pageChange(newPage);
  };

  const handleRowsPerPage = (event) => {
    rowPerPageChange(event.target.value);
    pageChange(0);
  };



  const exportToExcel = () => {


    if (filteredData.length) {
      const newColumnOrder = [
        'S No',
        'Client Name',
        'Total Events Count',
        'True Events Count',
        'False Events Count',    
        'Escalated Events Count',
        // 'Agent', 
      ];
      
      const headers = newColumnOrder;
      
      const formatEventRaised = (eventRaised) => {
        try {
          const date = new Date(eventRaised);
          if (isNaN(date.getTime())) {
            throw new Error('Invalid date');
          }
          return date.toISOString().slice(0, 19).replace('T', ' ');
        } catch (error) {
          console.error('Error parsing date:', error);
          return ''; // Or handle the error according to your application's needs
        }
      };
      
      const formatLatency = (latency) => {
        const hours = Math.floor(latency / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((latency % 3600) / 60).toString().padStart(2, '0');
        const seconds = (latency % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
      };
  
      
      const sortedData = filteredData.sort((a, b) => {
        return a.event_hour - b.event_hour;
    });
    
    // Then, construct the data array
    const data = [headers, ...sortedData.map((item, index) =>
        [index + 1, ...headers.slice(1).map(key => {
          if (key === 'Client Name') return item['displayname'];
          if (key === 'Total Events Count') return item['total_event_count'];
          if (key === 'True Events Count') return item['true_event_count'];
          if (key === 'False Events Count') return item['false_event_count'];
          if (key === 'Escalated Events Count') return item['escalated_event_count'];  
          return item[key];
        })]
    )];
      
      // setHeaders(headers);
      // setData(data);



      const sheetName = "Total Events";
      // const headers = Object.keys(filteredData[0]);
      // const data = [
      //   headers,
      //   ...filteredData.map((item) => headers.map((key) => item[key])),
      // ];

      const ws = XLSX.utils.aoa_to_sheet(data);
      const filename = sheetName + ".xlsx";

      const maxColumnWidths = {};
      headers.forEach((header) => {
        maxColumnWidths[header] = Math.max(
          20,
          ...data.map((row) => (row[header] || "").toString().length)
        );
      });
      const columnWidths = headers.map((header) => ({
        wch: maxColumnWidths[header],
      }));

      ws["!cols"] = columnWidths;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName || "Sheet 1");

      XLSX.writeFile(wb, filename);
    } else {
      alert("No data to Export.");
    }
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value.trim());
  };

  const filteredData = data.filter((item) => {
    return item.displayname && item.displayname.toLowerCase().includes(filterName.toLowerCase());
  });

  const isDataNotFound = filteredData.length === 0;

  TotalEvents.propTypes = {
    numSelected: PropTypes.number,
    filterName: PropTypes.string,
    onFilterName: PropTypes.func,
  };



  // const handleClientChange = (event, value) =>{
  //   debugger

  //   // setClientMatchedCameras([])
  //   setCheckValue(false)
  //   // setActiveCameras([])
  //   if (value.some(obj => obj.hasOwnProperty('selectall'))) {
  //     if(value[0].selectall){
  //       setOpenClientAuto(true);
  //     }
  //     else{
  //       setOpenClientAuto(false);
  //     }
  //   } else {
  //     setOpenClientAuto(true);
  //   }
  
  //   if (value.length > 1 && value.every((entry, index, arr) => JSON.stringify(entry) === JSON.stringify(arr[0]))) {
  //     value = [];
  //   }
    
  //   const containsAllProperty = value.some(obj => obj.hasOwnProperty('selectall'));
  
  //   if (value.length === 0 || !containsAllProperty) {
  //     setCheckClientValue(false);
  //   }
  
  //   let actClients = [];
  //   //setDeviceErrorMessage('');
  
  //   if (value.length > 0 && !containsAllProperty) {
  //     value.forEach((row) => {
  //       actClients.push(row.ID);
  //     });
  
  //     // if (clientMatchedCameras.length === actClients.length) {
  //     //   setCheckClientValue(true);
  //     //   setOpenClientAuto(false);
  //     // }
  
  //     setActiveClients(actClients);
  //     setInputClientValue([...value]);
  //   }
  
  //   if (value.length > 0 && containsAllProperty) {
  //     let Clients = clientInfo;
  
  //     actClients = [];
  
  //     if (activeClients && activeClients.length === isClientList.length) {
  //       setCheckClientValue(false);
  //       // const selectedDisplayName = value.filter(obj => obj.hasOwnProperty('selectall'))[0].displayname;
  //       // const selectedClients = Clients.filter(client => client.displayname === selectedDisplayName);
  //       const Ids = value.filter(obj => !obj.hasOwnProperty('selectall')).map(obj => obj.ID);
        
  //       Clients = Clients.filter(item => !Ids.includes(item.ID));
  //       Clients.forEach((row) => {
  //         actClients.push(row.ID);
  //       });
  //       setActiveClients([...actClients]);
  //       setInputClientValue(isClientList);

  //       // selectedClients.forEach((row) => {
  //       //   actClients.push(row.ID);
  //       // });
  //       // setActiveClients(actClients);
  //       // setInputClientValue(isClientList);
  //       return;

  //       //return;
  //     }
  //     if (!checkClientValue) {
  //       Clients.forEach((row) => {
  //         actClients.push(row.ID);
  //       });
  //       setActiveClients(actClients);
  //       setInputClientValue(isClientList);
  //       setCheckClientValue(true);
  //     } else {
  //       setActiveClients([]);
  //       setInputClientValue([]);
  //     }
  //   } else if (value.length === 0) {
  //     setActiveClients([]);
  //     setCheckClientValue(false);
  //     setInputClientValue([]);
  //   }
  // }

  const handleClientChange = (event,value) =>{
    // debugger
    let actClients=[];
    if (value.some(obj => obj.hasOwnProperty('selectall'))){
      if(activeClients.length===clientInfo.length){
        setActiveClients([])
        setCheckClientValue(false)
      }
      else{
        clientInfo.forEach(item=>{
          actClients.push(item.ID)
        })
        setActiveClients(actClients)
        setCheckClientValue(true)
      }
    }
    else{
      let id=value[0].ID;
      if(activeClients.includes(id)){
        let filterArr = activeClients.filter(client => client !== id);
        // console.log(typeof activeClients[0]);
        setActiveClients(filterArr);
        if(checkClientValue){
          setCheckClientValue(false)
        }
      }
      else{
        setActiveClients((prv)=>[...prv,id])
      }
    }
  }

  useEffect(()=>{
    //debugger
    if(activeClients.length===clientInfo.length && activeClients.length>0){
      setCheckClientValue(true)
    }
  },[activeClients])

  const handleFilterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    
    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeClients.includes(option.ID));
    const unselectedOptions = filtered.filter(option => !activeClients.includes(option.ID));
    
    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];
  
    return [{ title: 'Select All', selectall: true }, ...sortedOptions];
  };

  return (
    <Grid sx={{ marginLeft: '1rem' }}>
      <Grid container spacing={2} alignItems="center" sx={{ marginTop: '0.2rem' }}>
        <Grid item xs={3}>

          <Autocomplete
            value={value1}
            onChange={(event, newValue) => {
              setIsMonthSelected(false);
              setValue1(newValue);
            }}
            options={months}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Month"
                variant="outlined"
                error={error && isMonthSelected}
              />
            )}
          />
          {error && isMonthSelected && <Typography variant="body2" color="error">Select Month</Typography>}
        </Grid>

        <Grid item xs={3}>

          <Autocomplete
            value={value2}
            onChange={(event, newValue) => {
              setIsYearSelected(false);
              setValue2(newValue);
            }}
            options={years}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Year"
                variant="outlined"
                error={error && isYearSelected}
              />
            )}
          />
          {error && isYearSelected && <Typography variant="body2" color="error">Select Year</Typography>}
        </Grid>

        <Grid item xs={3}>

          <FormControl fullWidth>
            {/* <InputLabel id="client-label">Client</InputLabel> */}
            {/* <Select
              labelId="client-label"
              multiple
              value={selectedClients}
              onChange={(event) => {
                const value = event.target.value;
                if (value.includes('all')) {
                  setSelectedClients(selectedClients.length === clientInfo.length ? [] : clientInfo.map(client => client.ID));
                } else {
                  setSelectedClients(value);
                }
                setIsClientSelected(false);
              }}
              renderValue={(selected) => selected.map((id) => clientInfo.find(client => client.ID === id)?.displayname).join(', ')}
              label="Client"
            >
              <MenuItem value="all">
                <Checkbox
                  checked={selectedClients.length === clientInfo.length}
                  indeterminate={selectedClients.length > 0 && selectedClients.length < clientInfo.length}
                />
                <ListItemText primary="Select All" />
              </MenuItem>
              {clientInfo.map((client) => (
                <MenuItem key={client.ID} value={client.ID}>
                  <Checkbox checked={selectedClients.indexOf(client.ID) > -1} />
                  <ListItemText primary={client.displayname} />
                </MenuItem>
              ))}
            </Select> */}

            <Autocomplete
              multiple
              fullWidth
              id="client"
              options={clientInfo}
              //value={(checkClientValue) ? [{ title: 'Select All', selectall: true }] : inputClientValue.filter((client) => activeClients.includes(client.ID))}
              value={[]}

              getOptionLabel={(option) => {
                return (option.selectall && clientInfo.length === activeClients.length) ? 'Select All' : `${option.displayname}`;
              }}
              onChange={handleClientChange}
              disableCloseOnSelect={openClientAuto}
              open={openClientAuto}
              onOpen={() => setOpenClientAuto(true)}
              onClose={() => setOpenClientAuto(false)}
              
              
              // filterOptions={(options, params) => {
              //   const filter = createFilterOptions();
              //   const filtered = filter(options, params);
              //   //console.log('test',options)
              //   return [{ title: 'Select All', selectall: true }, ...filtered];
              // }}

              filterOptions={handleFilterOptions}


              renderOption={(props, option, { selected }) => {
                const key = `camid-${option.ID}`
                return (<li {...props} key={key}>
                  
                  {option.selectall ? (
                    <>
                      <Checkbox
                        icon={iconClient}
                        checkedIcon={checkedIconClient}
                        style={{ marginRight: 8 }}
                        checked={checkClientValue}
                        //onChange={() => setCheckClientValue(!checkClientValue)}
                      />
                      {option.title}
                    </>
                  ) : (
                    <>
                      <Checkbox
                        icon={iconClient}
                        checkedIcon={checkedIconClient}
                        style={{ marginRight: 8 }}
                        checked={activeClients.includes(option.ID)}
                        onChange={() => {
                          const isChecked = activeClients.includes(option.ID);
                          const newActiveClients = isChecked
                            ? activeClients.filter((clientId) => clientId !== option.ID)
                            : [...activeClients, option.ID];
                          setActiveClients(newActiveClients);
                        }}
                      />
                      {`${option.displayname}`}
                    </>
                  )}
                
                </li>)
              }
              }
              renderInput=
              {(params) => 
                <TextField {...params} label="Client" onFocus={() => setOpenClientAuto(true)}/>
              }
            />
          </FormControl>
          {error && isClientSelected && <Typography variant="body2" color="error">Select Client</Typography>}
        </Grid>


        <Grid item xs={3} sx={{ marginTop: 2 }}>
          <Button variant="contained" onClick={handleButtonClick}>
            Submit
          </Button>
          <Button variant="contained" sx={{ marginLeft: 1 }} onClick={exportToExcel}>
          <InsertDriveFileIcon sx={{paddingRight:'5px'}}/> Export <SaveAltIcon sx={{paddingLeft:'5px'}}/>
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <SearchStyle
          value={filterName}
          onChange={handleFilterByName}
          placeholder="Search"
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      </Grid>

      <Grid container spacing={2} alignItems="center" sx={{ marginTop: '1rem' }}>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#f2f2f2" }}>
                  {columns.map((item) => {
                    return <TableCell key={item.id}>{item.name}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.slice(page * rowPerPage, page * rowPerPage + rowPerPage).map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{page * rowPerPage + index + 1}</TableCell>
                    <TableCell align="left">{row.displayname}</TableCell>
                    <TableCell align="left">{row.total_event_count}</TableCell>
                    <TableCell align="left">{row.true_event_count}</TableCell>
                    <TableCell align="left">{row.false_event_count}</TableCell>
                    <TableCell align="left">{row.escalated_event_count}</TableCell>
 
                  </TableRow>
                ))}
              </TableBody>

              {(isDataNotFound && filterName) ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={11}>
                          <Box sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ):null}
              {(filteredData.length === 0 && !filterName) && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={11}>
                        <Box sx={{ py: 3 }}>
                        <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                            No Data
                          </Typography>
                          </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} 
            </Table>
          </TableContainer>
        </Scrollbar>
      </Grid>

      <Grid>
        <TablePagination
          rowsPerPageOptions={[100, 300, 500]}
          page={page}
          count={filteredData.length}
          rowsPerPage={rowPerPage}
          component="div"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleRowsPerPage}
          sx={{ backgroundColor: '#f2f2f2' }}
        />
      </Grid>
    </Grid>
  );
};

export default TotalEvents;