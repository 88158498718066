import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Checkbox,
  TableContainer,InputLabel,Select,MenuItem,
  CardContent,
  FormControl,
  Grid,
  TableHead,
  TextField,  Alert,
  Autocomplete,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getClientList, postEmailTime, getEmailClients } from "src/api/analytics";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DailyEmailReports = () => {
  const [clientInfo, setClientInfo] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [time, setTime] = useState('00:00');
  const [openAuto, setOpenAuto] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [status, setStatusBase] = useState(false);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(false)


  useEffect(() => {
    setErrorMessage(false);
    console.log('selected clients:', selectedClients);
    if (selectedClients.length === 1){
      console.log('selected clients:', selectedClients[0].ID);

      const data = {
        client_id: selectedClients[0].ID,
      };
      getEmailClients(data, (response) => {
        if (response.status === 200) {
          console.log('selected clients for one client :',response.data[0].Time);
          setTime(`${response.data[0].Time}`)
        }
      });
    }
    else {
      setTime('00:00');
    }
  },[selectedClients]);

  useEffect(() => {
    setStatusBase(false);
    getClientList((response) => {
      if (response.status === 200) {
        const usClients = response.data.filter(client => client.ClientType === "US");
        // setClientInfo(usClients);
        setClientInfo(response.data);
      }
    });

    const data = {
      client_id: '',
    };
    getEmailClients(data, (response) => {
      if (response.status === 200) {
        setClientData(response.data);
      }
    });
  }, []);

  const handleClientChange = (event, value) => {
    if (value.some(client => client.selectAll)) {
      if (selectAll) {
        setSelectedClients([]);
        setSelectAll(false);
      } else {
        setSelectedClients(clientInfo);
        setSelectAll(true);
      }
    } else {
      setSelectedClients(value);
      setSelectAll(value.length === clientInfo.length);
    }
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleSave = () => {
    console.log("selctedClient",selectedClients)
    if (selectedClients.length === 0 ){
      setErrorMessage(true);
    }
    else {
      setErrorMessage(false);
    
    const data = selectedClients.map(client => ({
      ClientId: client.ID,
      Time: time
    }));

    console.log("Data to send:", data);
    postEmailTime(data, (response) => {
      if (response.status === 200) {
        console.log("Email Time Updated Successfully", response.data);
        const data = {
          client_id: '',
        };
        getEmailClients(data, (response) => {
          if (response.status === 200) {
            setClientData(response.data);
            setStatusBase(true);
            setMessage("Timings Added successfully");
            setTime('00:00');
            setSelectedClients([]);
            setTimeout(() => {
              setStatusBase(false);
            }, 1000);

          }
        });
      }
    });
  }
  };

  const handleFilterOptions = (options, params) => {
    const { inputValue } = params;
    const filtered = options.filter(option => option.displayname.toLowerCase().includes(inputValue.toLowerCase()));
    return [{ displayname: 'Select All', selectAll: true }, ...filtered];
  };

  return (
    <>
      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} ml={5}>
        <Typography variant="h4" gutterBottom>Daily Email Reports</Typography>
      </Stack> */}

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  fullWidth
                  id="clients-autocomplete"
                  options={clientInfo}
                  value={selectedClients}
                  getOptionLabel={(option) => (option.selectAll && selectedClients.length === clientInfo.length) ? 'Select All' : `${option.displayname}`}
                  onChange={handleClientChange}
                  disableCloseOnSelect
                  open={openAuto}
                  onOpen={() => setOpenAuto(true)}
                  onClose={() => setOpenAuto(false)}
                  filterOptions={handleFilterOptions}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.ID}>
                      {option.selectAll ? (
                        <>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selectAll}
                          />
                          {option.displayname}
                        </>
                      ) : (
                        <>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selectedClients.some(client => client.ID === option.ID)}
                          />
                          {`${option.displayname}`}
                        </>
                      )}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Clients" onFocus={() => setOpenAuto(true)} />
                  )}
                />
              </FormControl>
              {errorMessage && <Typography color="error" variant="body2" align="left" marginTop={1}>Please Select The Client</Typography>}
            </Grid>

            <Grid item xs={3}>
            <FormControl fullWidth>
                  <InputLabel>Time</InputLabel>
                  <Select
                    value={time}
                    onChange={handleTimeChange}
                    fullWidth
                    label="Time"
                  >
                    {Array.from({ length: 24 * 2 }, (_, index) => {
                      const hours = Math.floor(index / 2).toString().padStart(2, '0');
                      const minutes = index % 2 === 0 ? '00' : '30';
                      const value = `${hours}:${minutes}`;
                      return (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

            </Grid>

            <Grid item xs={3}>
              <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>Save</Button>
            </Grid>
          </Grid>
          {(status && message) && (
            <Stack direction="row" sx={{ paddingTop: 2 }}>
              <Alert severity="success">{message}</Alert>
            </Stack>
          )}
        </CardContent>
     
        <TableContainer>
          <Table>
            <TableHead sx={{ background: "#f3f3f3" }}>
              <TableRow>
                <TableCell sx={{ background: "#f3f3f3" }}>S.No</TableCell>
                <TableCell sx={{ background: "#f3f3f3" }}>Client Name</TableCell>
                <TableCell sx={{ background: "#f3f3f3" }}>Time Zone</TableCell>
                <TableCell sx={{ background: "#f3f3f3" }}>Email Sent Time</TableCell>
                <TableCell sx={{ background: "#f3f3f3" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientData.map((row, index) => (
                <TableRow key={index + 1}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.displayname}</TableCell>
                  <TableCell>{row.TimeZone === null ? "No Time Zone" : row.TimeZone}</TableCell>
                  <TableCell>{row.Time}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </>
  );
};

export default DailyEmailReports;
