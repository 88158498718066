import React, { useState, useEffect } from "react";
import {
  InputAdornment,
  Grid,
  Box,
  Autocomplete,
  Button,
  TextField,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  MenuItem,
  Select,createFilterOptions,Checkbox,
  InputLabel,
  FormControl,
  TableContainer,
  TablePagination,
  Toolbar,
  OutlinedInput,
  Stack,
  Dialog,
  DialogContent,
} from "@mui/material";
import PropTypes from "prop-types";
import Scrollbar from "src/components/Scrollbar";
import Iconify from "src/components/Iconify";
import SearchNotFound from "src/components/SearchNotFound";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { styled } from "@mui/material/styles";
import { getClientList, getIndividualEevnts } from "src/api/EventReports/EventReports";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import * as XLSX from 'xlsx';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const iconClient = <CheckBoxOutlineBlankIcon fontSize="small" />;

const checkedIconClient = <CheckBoxIcon fontSize="small" />;

const IndividualEvents = ({ clientList }) => {
  const options3 = clientList;

  const [filterName, setFilterName] = useState("");

  const [value1, setValue1] = useState(null);

  const [value3, setValue3] = useState(null);

  const [value2, setValue2] = useState(null);
  const currentYear = new Date().getFullYear();
  const years = [
    (currentYear - 1).toString(),
    currentYear.toString(),
    (currentYear + 1).toString(),
  ];

  const [selectedClient, setSelectedClient] = useState("");
  const [clientInfo, setClientInfo] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [client, setClient] = useState("");

  const [error, setError] = useState(false);
  const [isMonthSelected, setIsMonthSelected] = useState(false);
  const [isYearSelected, setIsYearSelected] = useState(false);
  const [isClientSelected, setIsClientSelected] = useState(false);

  const [data, setData] = useState([]);
  const [row, rowChange] = useState([]);
  const [page, pageChange] = useState(0);
  const [rowPerPage, rowPerPageChange] = useState(100);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isValidFrom, setIsValidFrom] = useState(false);
  const [isValidTo, setIsValidTo] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [openDialogImage, setOpenDialogImage] = useState(false);



  const [checkValue,setCheckValue]=useState(false)
  const [checkClientValue,setCheckClientValue]=useState(false)
  const [inputClientValue, setInputClientValue] = useState([]);
  const [activeClients, setActiveClients] = useState([])
  const [openClientAuto,setOpenClientAuto]=useState(false)
  const [isClientList, setisClientList] = useState([]);

  const columns = [
    { id: "Sno", name: "Sno" },
    { id: "Client_Name", name: "Client" },
    { id: "Camera_Name", name: "Camera Name" },
    { id: "Total_True_Events", name: "True Events" },
    { id: "Total_False_Events", name: "False Events" },
    { id: "Total_Escalated_Events", name: "Escalated events" },
    { id: "Total_Events_Generated", name: "Total Events Count" },
  ];

  const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: "flex",
    padding: theme.spacing(0, 1, 0, 3),
  }));

  const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    marginLeft: 15,
    transition: theme.transitions.create(["box-shadow", "width"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
    "& fieldset": {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  }));

  const handleChangePage = (event, newpage) => {
    if (newpage === 0) {
      pageChange(0);
    } else {
      pageChange(newpage);
    }
  };

  const handleButtonClick = () => {
    if(!fromDate || !toDate){
      setError(true);
    }
    const fromDatefor = fromDate;
    const toDatefor = toDate;
  
    const isValidDate = (value) => {
      const date = new Date(value);
      return !isNaN(date.getTime());
    };
  
    const parseDate = (dateString) => {
      if (!isValidDate(dateString)) {
        console.error("Invalid date:", dateString);
        return null;
      }
      return new Date(dateString);
    };
  
    const formatDateTime = (date) => {
      return dayjs(date).format("YYYY-MM-DD");
    };
  
    const fromDateObj = parseDate(fromDatefor);
    const toDateObj = parseDate(toDatefor);
  
    if (fromDateObj && toDateObj) {
      const formattedFromDate = formatDateTime(fromDateObj);
      const formattedToDate = formatDateTime(toDateObj);
      // console.log("Formatted fromDate:", formattedFromDate, formattedToDate);
  
      const clientId = activeClients ? `${activeClients}` : '';
  
      const body = {
        from_date: formattedFromDate,
        to_date: formattedToDate,
        client_id: clientId,
      };
      getIndividualEevnts(body, (response) => {
        if (response.status === 200) {
          setData(response.data);
          // console.log("messages ", response.data);
        }
      });
    }
  };
  

  const handleRowsPerPage = (e) => {
    rowPerPageChange(e.target.value);
    pageChange(0);
  };


  // const handleClientChange = (event, value) =>{
  //   debugger

  //   // setClientMatchedCameras([])
  //   setCheckValue(false)
  //   // setActiveCameras([])
  //   if (value.some(obj => obj.hasOwnProperty('selectall'))) {
  //     if(value[0].selectall){
  //       setOpenClientAuto(true);
  //     }
  //     else{
  //       setOpenClientAuto(false);
  //     }
  //   } else {
  //     setOpenClientAuto(true);
  //   }
  
  //   if (value.length > 1 && value.every((entry, index, arr) => JSON.stringify(entry) === JSON.stringify(arr[0]))) {
  //     value = [];
  //   }
    
  //   const containsAllProperty = value.some(obj => obj.hasOwnProperty('selectall'));
  
  //   if (value.length === 0 || !containsAllProperty) {
  //     setCheckClientValue(false);
  //   }
  
  //   let actClients = [];
  //   //setDeviceErrorMessage('');
  
  //   if (value.length > 0 && !containsAllProperty) {
  //     value.forEach((row) => {
  //       actClients.push(row.ID);
  //     });
  
  //     // if (clientMatchedCameras.length === actClients.length) {
  //     //   setCheckClientValue(true);
  //     //   setOpenClientAuto(false);
  //     // }
  
  //     setActiveClients(actClients);
  //     setInputClientValue([...value]);
  //   }
  
  //   if (value.length > 0 && containsAllProperty) {
  //     let Clients = clientInfo;
  
  //     actClients = [];
  
  //     if (activeClients && activeClients.length === isClientList.length) {
  //       setCheckClientValue(false);
  //       // const selectedDisplayName = value.filter(obj => obj.hasOwnProperty('selectall'))[0].displayname;
  //       // const selectedClients = Clients.filter(client => client.displayname === selectedDisplayName);
  //       const Ids = value.filter(obj => !obj.hasOwnProperty('selectall')).map(obj => obj.ID);
        
  //       Clients = Clients.filter(item => !Ids.includes(item.ID));
  //       Clients.forEach((row) => {
  //         actClients.push(row.ID);
  //       });
  //       setActiveClients([...actClients]);
  //       setInputClientValue(isClientList);

  //       // selectedClients.forEach((row) => {
  //       //   actClients.push(row.ID);
  //       // });
  //       // setActiveClients(actClients);
  //       // setInputClientValue(isClientList);
  //       return;

  //       //return;
  //     }
  //     if (!checkClientValue) {
  //       Clients.forEach((row) => {
  //         actClients.push(row.ID);
  //       });
  //       setActiveClients(actClients);
  //       setInputClientValue(isClientList);
  //       setCheckClientValue(true);
  //     } else {
  //       setActiveClients([]);
  //       setInputClientValue([]);
  //     }
  //   } else if (value.length === 0) {
  //     setActiveClients([]);
  //     setCheckClientValue(false);
  //     setInputClientValue([]);
  //   }
  // }

  const handleClientChange = (event,value) =>{
    let actClients=[];
    if (value.some(obj => obj.hasOwnProperty('selectall'))){
      if(activeClients.length===clientInfo.length){
        setActiveClients([])
        setCheckClientValue(false)
      }
      else{
        clientInfo.forEach(item=>{
          actClients.push(item.ID)
        })
        setActiveClients(actClients)
        setCheckClientValue(true)
      }
    }
    else{
      let id=value[0].ID;
      if(activeClients.includes(id)){
        let filterArr = activeClients.filter(client => client !== id);
        // console.log(typeof activeClients[0]);
        setActiveClients(filterArr);
        if(checkClientValue){
          setCheckClientValue(false)
        }
      }
      else{
        setActiveClients((prv)=>[...prv,id])
      }
    }
  }

  useEffect(()=>{
    if(activeClients.length===clientInfo.length && activeClients.length>0){
      setCheckClientValue(true)
    }
  },[activeClients])


  const handleFilterByName = (event) => {
    const pattern = event.target.value.trim();
    setFilterName(pattern);
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
    setIsValidFrom(!!date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    setIsValidTo(!!date);
  };

  const filteredData = data.filter((item) => {
    return (
      (item.Client_Name &&
        item.Client_Name.toLowerCase().includes(filterName.toLowerCase())) 
     
    );
  });


  const exportToExcel1 = () => {
    if (filteredData.length) {
      const sheetName = "Individual Events Report";
      const headers = Object.keys(filteredData[0]);
      const data = [headers, ...filteredData.map(item => headers.map(key => item[key]))];

      const ws = XLSX.utils.aoa_to_sheet(data);
      const filename = sheetName + '.xlsx';

      const maxColumnWidths = {};
      headers.forEach(header => {
        maxColumnWidths[header] = Math.max(
          20,
          ...data.map(row => (row[header] || '').toString().length)
        );
      });
      const columnWidths = headers.map(header => ({
        wch: maxColumnWidths[header]
      }));

      ws['!cols'] = columnWidths;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName || 'Sheet 1');

      XLSX.writeFile(wb, filename);
    } else {
      alert('No data to Export.');
    }
  };



  const exportToExcel = () => {


    if (filteredData.length) {
      const newColumnOrder = [
        'S No',
        'Client',
        'Camera Name',
        'True Events',
        'False Events',    
        'Escalated events',
        'Total Events Count'
        // 'Agent', 
      ];
      
      const headers = newColumnOrder;
      
      const formatEventRaised = (eventRaised) => {
        try {
          const date = new Date(eventRaised);
          if (isNaN(date.getTime())) {
            throw new Error('Invalid date');
          }
          return date.toISOString().slice(0, 19).replace('T', ' ');
        } catch (error) {
          console.error('Error parsing date:', error);
          return ''; // Or handle the error according to your application's needs
        }
      };
      
      const formatLatency = (latency) => {
        const hours = Math.floor(latency / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((latency % 3600) / 60).toString().padStart(2, '0');
        const seconds = (latency % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
      };
  
      
      const sortedData = filteredData.sort((a, b) => {
        return a.event_hour - b.event_hour;
    });
    
    // Then, construct the data array
    const data = [headers, ...sortedData.map((item, index) =>
        [index + 1, ...headers.slice(1).map(key => {
          if (key === 'Client') return item['Client_Name'];
          if (key === 'Camera Name') return item['Camera_Name'];
          if (key === 'True Events') return item['Total_True_Events'];
          if (key === 'False Events') return item['Total_False_Events'];
          if (key === 'Escalated events') return item['Total_Escalated_Events'];  
          if (key === 'Total Events Count') return item['Total_Events_Generated'];  

          return item[key];
        })]
    )];
      
      // setHeaders(headers);
      // setData(data);



      const sheetName = "Individual Events";
      // const headers = Object.keys(filteredData[0]);
      // const data = [
      //   headers,
      //   ...filteredData.map((item) => headers.map((key) => item[key])),
      // ];

      const ws = XLSX.utils.aoa_to_sheet(data);
      const filename = sheetName + ".xlsx";

      const maxColumnWidths = {};
      headers.forEach((header) => {
        maxColumnWidths[header] = Math.max(
          20,
          ...data.map((row) => (row[header] || "").toString().length)
        );
      });
      const columnWidths = headers.map((header) => ({
        wch: maxColumnWidths[header],
      }));

      ws["!cols"] = columnWidths;

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName || "Sheet 1");

      XLSX.writeFile(wb, filename);
    } else {
      alert("No data to Export.");
    }
  };

  const isDataNotFound = filteredData.length === 0;
  IndividualEvents.prototype = {
    numSelected: PropTypes.number,
    filterName: PropTypes.string,
    onFilterName: PropTypes.func,
  };

  
  const handlePlayImage = (item) => {
    setImageURL(item.snapshot_url);
    setOpenDialogImage(true);
  };

  useEffect(() => {
    getClientList((response) => {
      if (response.status === 200) {
        // console.log("response data", response.data);
        setClientInfo(response.data);
      }
    });
  }, []);

  const handleFilterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    
    // Separate selected and unselected options
    const selectedOptions = filtered.filter(option => activeClients.includes(option.ID));
    const unselectedOptions = filtered.filter(option => !activeClients.includes(option.ID));
    
    // Sort options: selected options first, then unselected
    const sortedOptions = [...selectedOptions, ...unselectedOptions];
  
    return [{ title: 'Select All', selectall: true }, ...sortedOptions];
  };

  return (
    <>
      <Grid sx={{ marginLeft: "1rem" }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ marginTop: "0.2rem" }}
        >
          <Grid item xs={3} sx={{ height: "100%" }}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="From Date"
                  id="from-date"
                  
                  format="YYYY-MM-DD"
                  value={fromDate}
                  required={true}
                  onChange={handleFromDateChange}
                  sx={{ border: "none" }}
                  maxDate={dayjs()}
                  />
              </LocalizationProvider>
            </FormControl>
            {error && !fromDate && <Typography variant="body2" color="error">Select From Date</Typography>}

          </Grid>

          <Grid item xs={3} sx={{ height: "100%" }}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="To Date"
                  id="to-date"
                  required
                  
                  format="YYYY-MM-DD"
                  value={toDate}
                  onChange={handleToDateChange}
                  sx={{ border: "none" }}
                  minDate={fromDate}
                  maxDate={dayjs()}
                />
              </LocalizationProvider>
            </FormControl>
            {error && !toDate && <Typography variant="body2" color="error">Select To Date</Typography>}

          </Grid>

          <Grid item xs={3} sx={{ height: "100%" }}>
            <FormControl fullWidth>

            <Autocomplete
              multiple
              fullWidth
              id="client"
              options={clientInfo}
              
              //value={(checkClientValue) ? [{ title: 'Select All', selectall: true }] : inputClientValue.filter((client) => activeClients.includes(client.ID))}
              value={[]}

              getOptionLabel={(option) => {
                return (option.selectall && clientInfo.length === activeClients.length) ? 'Select All' : `${option.displayname}`;
              }}
              onChange={handleClientChange}
              disableCloseOnSelect={openClientAuto}
              open={openClientAuto}
              onOpen={() => setOpenClientAuto(true)}
              onClose={() => setOpenClientAuto(false)}
              
              
              // filterOptions={(options, params) => {
              //   const filter = createFilterOptions();
              //   const filtered = filter(options, params);
              //   //console.log('test',options)
              //   return [{ title: 'Select All', selectall: true }, ...filtered];
              // }}

              filterOptions={handleFilterOptions}


              renderOption={(props, option, { selected }) => {
                const key = `camid-${option.ID}`
                return (<li {...props} key={key}>
                  
                  {option.selectall ? (
                    <>
                      <Checkbox
                        icon={iconClient}
                        checkedIcon={checkedIconClient}
                        style={{ marginRight: 8 }}
                        checked={checkClientValue}
                        //onChange={() => setCheckClientValue(!checkClientValue)}
                      />
                      {option.title}
                    </>
                  ) : (
                    <>
                      <Checkbox
                        icon={iconClient}
                        checkedIcon={checkedIconClient}
                        style={{ marginRight: 8 }}
                        checked={activeClients.includes(option.ID)}
                        onChange={() => {
                          const isChecked = activeClients.includes(option.ID);
                          const newActiveClients = isChecked
                            ? activeClients.filter((clientId) => clientId !== option.ID)
                            : [...activeClients, option.ID];
                          // setActiveCameras(newActiveClients);
                        }}
                      />
                      {`${option.displayname}`}
                    </>
                  )}
                
                </li>)
              }
              }
              renderInput=
              {(params) => 
                <TextField {...params} label="Client" onFocus={() => setOpenClientAuto(true)}/>
              }
            />

            </FormControl>
          </Grid>

          <Grid item xs={3} sx={{ height: "100%", marginTop: 2 }}>
            <Button variant="contained" onClick={handleButtonClick}>
              Submit
            </Button>
            <Button variant="contained" sx={{ marginLeft: 1 }} onClick={exportToExcel}>
              <InsertDriveFileIcon sx={{marginRight:'5px'}}/> Export <SaveAltIcon sx={{paddingLeft:'5px'}}/>
            </Button>
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: 2 , marginLeft: 1 }} >

        <TextField

          variant="outlined"

          placeholder="Search..."

          value={filterName}

          onChange={handleFilterByName}

          InputProps={{

            startAdornment: (

              <InputAdornment position="start">

                <Iconify

                  icon="eva:search-fill"

                  sx={{ color: "text.disabled", width: 20, height: 20 }}

                />

              </InputAdornment>

            ),

          }}

        />

        </Grid>

        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ marginTop: "1rem" }}
        >
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f2f2f2" }}>
                    {columns.map((item) => {
                      return <TableCell key={item.id}>{item.name}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                {filteredData.slice(page * rowPerPage, page * rowPerPage + rowPerPage).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{index + 1}</TableCell>
                     
                      <TableCell align="left">{row.Client_Name}</TableCell>
                      <TableCell align="left">{row.Camera_Name}</TableCell>
                      <TableCell align="left">{row.Total_True_Events}</TableCell>
                      <TableCell align="left">{row.Total_False_Events}</TableCell>
                      <TableCell align="left">
                        {row.Total_Escalated_Events}
                      </TableCell>
                      <TableCell align="left">
                        {row.Total_Events_Generated}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                {(isDataNotFound && filterName) ? (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={11}>
                          <Box sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ):null}
              {(filteredData.length === 0 && !filterName) && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={11}>
                        <Box sx={{ py: 3 }}>
                        <Typography variant="body1" align="center" style={{ fontWeight: 'bold' }}>
                            No Data
                          </Typography>
                          </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} 
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>

        <Grid>
          <TablePagination
            rowsPerPageOptions={[100, 300, 500]}
            page={page}
            count={filteredData.length}
            rowsPerPage={rowPerPage}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPage}
            sx={{ backgroundColor: "#f2f2f2" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default IndividualEvents;