import { useState, useEffect } from 'react';

const useVideoPlayer = (videoElement) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    speed: 3,
    isMuted: false,
    playStatus: 'Not Ready'
  });

  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying
    });
  };

  useEffect(() => {
    if (videoElement.current) {
        playerState.isPlaying ? videoElement.current.play() : videoElement.current.pause();
        if (playerState.isPlaying)     
            videoElement.current.playbackRate = playerState.speed;  
    }
    
  }, [playerState.isPlaying, videoElement]);

  const handlePause = () => {
    const isPlaying = false;
    const playStatus = isPlaying ? 'Playing...' : 'Pause'
    setPlayerState({
      ...playerState,
      isPlaying,
    //   playStatus
    });
  };

  const handlePlay = () => {
    const isPlaying = true;
    const playStatus = isPlaying ? 'Playing...' : 'Pause'
    setPlayerState({
      ...playerState,
      isPlaying,
      playStatus
    });
  };

  useEffect(()=>{
    
    if(playerState.playStatus==='Closed'){
      //debugger
      
      const speed = 3;
      videoElement.current.playbackRate = speed;
      setPlayerState({
        ...playerState,
        speed
      });
    }
  },[playerState.playStatus])

  const handleClosePlayer = () => {
    const isPlaying = false;
    const playStatus = 'Closed'
    setPlayerState({
      ...playerState,
      isPlaying,
      playStatus
    });
  };

  const handleInitPlay = () => {
    handlePlay()
    // videoElement.current.currentTime = videoElement.current.duration-70
  };

  const handleOnTimeUpdate = () => {
    const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;
    const playStatus = videoElement.current.currentTime+2 >= videoElement.current.duration ? 'Completed' : 'Playing...'
    // console.log(playStatus, videoElement.current.currentTime, videoElement.current.duration)
    if(videoElement.current.duration-70 > videoElement.current.currentTime)
        videoElement.current.currentTime = videoElement.current.duration-70
    setPlayerState({
      ...playerState,
      progress,
      playStatus
    });
  };

  const handleVideoProgress = (event) => {
    const manualChange = Number(event.target.value);
    videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
    setPlayerState({
      ...playerState,
      progress: manualChange
    });
  };

  const handleVideoSpeed = (event) => {
    const speed = Number(event.target.value);
    videoElement.current.playbackRate = speed;
    setPlayerState({
      ...playerState,
      speed
    });
  };

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted
    });
  };

  useEffect(() => {
    if (videoElement.current) {
        playerState.isMuted
      ? (videoElement.current.muted = true)
      : (videoElement.current.muted = false);
    }    
  }, [playerState.isMuted, videoElement]);

  return {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
    handlePause,
    handlePlay,
    handleInitPlay,
    handleClosePlayer
  };
};

export default useVideoPlayer;