import { Card, Container, Tab, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useEffect, useState } from 'react';
import ReportTable from 'src/sections/productivity/ReportTable';
import ProGraph from 'src/sections/productivity/ProGraph';
import { getProductivityReport } from 'src/api/productivity';
import LocReportTable from 'src/sections/productivity/LocReportTable';
import dayjs from 'dayjs';
import { format } from "date-fns";

// ----------------------------------------------------------------------



export default function EmpProductivity() {

  const urlParams = new URLSearchParams(window.location.search);
  let paramDate = urlParams.get('date');
  let paramShift = urlParams.get('shift');
  let activeTab = urlParams.get('type');
  // console.log(paramDate, paramShift)
  paramDate = paramDate ? new Date(paramDate) : new Date()
  paramShift = paramShift ? paramShift : 'night'
  // console.log(paramDate, paramShift)


  const [reportdate, setReportDate] = useState(dayjs(paramDate))

  const [filterData, setFilter] = useState({ mode: activeTab || 'employee', type: 'all' })

  const [reportShift, setShift] = useState(paramShift);

    

  const handleCategory = (e, value) => setFilter({ ...filterData, mode: value })
  const handleType = (value) => setFilter({ ...filterData, type: value })

  const [report, setReport] = useState({
    employee: {},
    location: {},
    camera: {},
    locationHigh: 0,
    locationMedium: 0,
    locationLow: 0,
    empHigh: 0,
    empMedium: 0,
    empLow: 0,
    camHigh: 0,
    camMedium: 0,
    camLow: 0
  })

  useEffect(() => {
    const date = new Date(reportdate)
    const wd = date.getDay()

    
    
    let ftime = '08:00:00'
    let ttime = '08:00:00'
    if (reportShift === 'day') {
        ftime = '08:00:00'
        ttime = '20:00:00'
    } else if (reportShift === 'night') {
        ftime = '20:00:00'
        ttime = '08:00:00'
    }

    // const ftime = wd === 0 || wd === 6 ? '08:00:00' : "20:00:00"
    // const ttime = wd === 0 || wd === 6 ? '08:00:00' : "08:00:00"
    const fromdate = `${format(date, 'yyyy-MM-dd')} ${ftime}`
    if (reportShift !== 'day')
      date.setDate(date.getDate() + 1);
    const todate = `${format(date, 'yyyy-MM-dd')} ${ttime}`
    const nonMonDays = [1, 2, 3, 4, 5]
    if (nonMonDays.includes(wd) && reportShift === 'day') {
      // console.log("Non-Moinitor Timings")
      setReport({
        ...report,
        employee: {},
        location: {},
        camera: {},
        locationHigh: 0,
        locationMedium: 0,
        locationLow: 0,
        empHigh: 0,
        empMedium: 0,
        empLow: 0,
        camHigh: 0,
        camMedium: 0,
        camLow: 0
      })
    } else {
      getProductivityReport({ starttime: fromdate, endtime: todate }, (response) => {

        if (Object.keys(response.data).includes('employeewise') && Object.keys(response.data).includes('locationwise')) {
          setReport({
            ...report,
            employee: response.data.employeewise,
            location: response.data.locationwise,
            camera: response.data.camerawise,
            locationHigh: Object.keys(response.data).includes('location_details') && Object.keys(response.data.location_details).includes('high') ? response.data.location_details.high : 0,
            locationMedium: Object.keys(response.data).includes('location_details') && Object.keys(response.data.location_details).includes('medium') ? response.data.location_details.medium : 0,
            locationLow: Object.keys(response.data).includes('location_details') && Object.keys(response.data.location_details).includes('low') ? response.data.location_details.low : 0,
            empHigh: Object.keys(response.data).includes('security_details') && Object.keys(response.data.security_details).includes('high') ? response.data.security_details.high : 0,
            empMedium: Object.keys(response.data).includes('security_details') && Object.keys(response.data.security_details).includes('medium') ? response.data.security_details.medium : 0,
            empLow: Object.keys(response.data).includes('security_details') && Object.keys(response.data.security_details).includes('low') ? response.data.security_details.low : 0,
            camHigh: Object.keys(response.data).includes('camera_details') && Object.keys(response.data.camera_details).includes('high') ? response.data.camera_details.high : 0,
            camMedium: Object.keys(response.data).includes('camera_details') && Object.keys(response.data.camera_details).includes('medium') ? response.data.camera_details.medium : 0,
            camLow: Object.keys(response.data).includes('camera_details') && Object.keys(response.data.camera_details).includes('low') ? response.data.camera_details.low : 0
          })
        } else {
          setReport({
            ...report,
            employee: {},
            location: {},
            camera: {},
            locationHigh: 0,
            locationMedium: 0,
            locationLow: 0,
            empHigh: 0,
            empMedium: 0,
            empLow: 0,
            camHigh: 0,
            camMedium: 0,
            camLow: 0
          })
        }
      })
    }    
  }, [reportdate, reportShift])

  const handleDate = (date) => {
    setReportDate(date)
  }
  // console.log('filterData', report)

  return (
    // <Page title="Dashboard">
    //   <Container maxWidth="xl">
    //     <Typography variant='h4' sx={{ pb: 4 }}>Employee Productivity</Typography>
        <Card>
          <TabContext value={filterData.mode}>
            <TabList
              onChange={handleCategory}
              sx={{ borderBottom: "1px solid #ebebeb", backgroundColor: "#f3f3f3" }}
            >
              <Tab label="Employee wise" value="employee" />
              <Tab label="Location wise" value="location" />
              <Tab label="Camera wise" value="camera" />
            </TabList>
            <TabPanel value="employee" index={0} sx={{ p: 0 }}>
              <ProGraph
                setFilter={handleType}
                reportdate={reportdate}
                handleDate={handleDate}
                high={report.empHigh}
                medium={report.empMedium}
                low={report.empLow}
                reportShift={reportShift}
                setShift={setShift}
              />
              <ReportTable filterData={filterData} reportData={Object.values(report.employee)} />
            </TabPanel>
            <TabPanel value="location" index={1} sx={{ p: 0 }}>
              <ProGraph setFilter={handleType} reportdate={reportdate} handleDate={handleDate}
                high={report.locationHigh}
                medium={report.locationMedium}
                low={report.locationLow}
                reportShift={reportShift}
                setShift={setShift}
              />
              <LocReportTable filterData={filterData} reportData={Object.values(report.location)} dataType="location" />
            </TabPanel>
            <TabPanel value="camera" index={1} sx={{ p: 0 }}>
              <ProGraph setFilter={handleType} reportdate={reportdate} handleDate={handleDate}
                high={report.camHigh}
                medium={report.camMedium}
                low={report.camLow}
                reportShift={reportShift}
                setShift={setShift}
              />
              <LocReportTable filterData={filterData} reportData={Object.values(report.camera)} dataType="camera" />
            </TabPanel>
          </TabContext>
        </Card>
    //   </Container>
    // </Page>
  );
}
