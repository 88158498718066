    import { Card, Container, TextField, Box, Grid, Typography, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// components
import Page from '../components/Page';

import {
  ReportPieChart,
  ReportBarChart,
  ReportDetailsTable
} from '../sections/jfwReport';

import { useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useEffect, useRef, useState } from 'react';
import { getReportData } from '../api/jfwReportsAPI'
import dayjs from 'dayjs';
import { format } from "date-fns";


// ----------------------------------------------------------------------


const urlParams = new URLSearchParams(window.location.search);

let paramDate = urlParams.get('date');
if (paramDate) {
  paramDate = new Date(paramDate)
} else {
  let d = new Date();
  d.setDate(d.getDate() - 1);
  paramDate = d
  
}
// paramDate = paramDate ? new Date(paramDate) : new Date()

let activeCat = urlParams.get('category') || "";
let activeSubCat = urlParams.get('sub-category')  || "";
let slot = urlParams.get('slot') || "fullday";



export default function JFWReport() {



  const theme = useTheme();

  const [reportDate, setDate] = useState(dayjs(paramDate))

  const [data, setData] = useState({})

  const [activeCategory, setCategory] = useState(activeCat)

  const [activeSub, setActiveSub] = useState(activeSubCat)

  const [timeSlot, setTimeSlot] = useState(slot)

  const tableRef = useRef(null)
  

  const handleTimeSlot = (event) => {
    setTimeSlot(event.target.value)
  }

  const getReportDate = () => {
    if (timeSlot === "day") {
      const dateString = format(new Date(reportDate), 'yyyy-MM-dd')
      return [`${dateString} 06:00:00`, `${dateString} 18:00:00`]
    } else if (timeSlot === "night") {
      let d = new Date(reportDate);
      d.setDate(d.getDate() + 1);
      const nxt_Date = format(new Date(d), 'yyyy-MM-dd')
      const rDate = format(new Date(reportDate), 'yyyy-MM-dd')
      return [`${rDate} 18:00:00`, `${nxt_Date} 06:00:00`]
    } else {
      let ct = new Date(reportDate);
      ct.setDate(ct.getDate() - 1);
      const prev_Date = format(new Date(ct), 'yyyy-MM-dd')
      const dateString = format(new Date(reportDate), 'yyyy-MM-dd')
      return [`${prev_Date} 06:00:00`, `${dateString} 06:00:00`]
    }
  }


  useEffect(() => {

    if (timeSlot && reportDate) {
      const [fromTime, toTime] = getReportDate()
      // console.log('FTIME', fromTime, toTime)

      getReportData((response) => {

        if (response.status === 200) {
          setData(response.data)
          // setCategory(Object.keys(response.data).length > 0 ? Object.keys(response.data)[0] : "")
        }

      }, fromTime, toTime)
    }    

  }, [timeSlot, reportDate])

  function handleCatChange (value) {
    setCategory(value)
    setActiveSub("")
  }

  const handleSubCategory = (value) => {
    setActiveSub(value)
    tableRef.current.scrollIntoView()
   }

  // 

// console.log((activeCategory && activeSub && data[activeCategory].hasOwnProperty('types') && data[activeCategory]["types"].hasOwnProperty(activeSub) && data[activeCategory]["types"][activeSub].hasOwnProperty('data') ) ? data[activeCategory]["types"][activeSub].data : [])



  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>Events Report</Typography>
        <Card sx={{ p: 3 }}>
          <Stack direction="row"
            justifyContent="right"
            alignItems="center"
            spacing={2}>
            {/* <Typography variant='h6'>DayWise Report</Typography> */}
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DesktopDatePicker
                label="Report Date"
                inputFormat="DD/MM/YYYY"
                value={reportDate}
                onChange={(e) => setDate(e)}
                name="reportDate"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <FormControl>
              <InputLabel id="select-time-period">Select Time Period</InputLabel>
              <Select
              labelId='select-time-period'
              value={timeSlot}
              label="Select Time Period"
              onChange={handleTimeSlot}
              >
                <MenuItem value="fullday">Full Day</MenuItem>
                <MenuItem value="day">06:00 AM to 06:00 PM</MenuItem>
                <MenuItem value="night">06:00 PM to 06:00 AM</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={12} md={5} lg={5}>
              <ReportPieChart
                title="All Deviation Categories Analysis"
                chartData={Object.keys(data).map((label) => ({ label: label, value: data[label].hasOwnProperty('category_count') && data[label].category_count }))}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.chart.blue[0],
                  theme.palette.chart.violet[0],
                  theme.palette.chart.yellow[0],
                ]}
                handleCategory={handleCatChange}
              />
            </Grid>
            <Grid item xs={12} md={7} lg={7}>
              <ReportBarChart                
                title="Detailed Deviation type report of Category"
                subheader={activeCategory}
                chartLabels={(activeCategory && data.hasOwnProperty(activeCategory) && data[activeCategory].hasOwnProperty('types')) ? Object.keys(data[activeCategory].types): []}
                chartData={[
                  {
                    name: 'No Of Events',
                    type: 'column',
                    fill: 'solid',
                    data: (activeCategory && data.hasOwnProperty(activeCategory) && data[activeCategory].hasOwnProperty('types')) ? Object.keys(data[activeCategory].types).map((cat) => data[activeCategory]['types'][cat].detected_count): [],
                  }
                ]}
                handleSubCategory={handleSubCategory}
              />
            </Grid>
            <Grid ref={tableRef} item sm={12}>
              <ReportDetailsTable title={`Violation Details - ${activeSub && activeSub !== 'null' ? activeSub : ""}`} rows={(activeCategory && activeSub && data.hasOwnProperty(activeCategory) && data[activeCategory].hasOwnProperty('types') && data[activeCategory]["types"].hasOwnProperty(activeSub) && data[activeCategory]["types"][activeSub].hasOwnProperty('data') ) ? data[activeCategory]["types"][activeSub].data : []}/>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
}
