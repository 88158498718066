import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Grid,
  Switch,
  Box,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { LoadingButton } from "@mui/lab";
import axios from "src/axios";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import qs from "qs";
import * as Yup from "yup";
import { addShift ,getLocations } from "src/api/ShiftMaster";
import dayjs from "dayjs";

export default function ShowTimeAdd({ isOpen, onClose, updateDataCallback }) {
  const [locationNames, setLocationNames] = useState([]); // Initialize as an empty array
  // const [selectedLocationId, setSelectedLocationId] = useState(null); // New state to stor
  const [apiData, setApiData] = useState(null); // New state for storing API data

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [shiftName, setShiftName] = useState("");
  //const [entryFromTime, setEntryFromTime] = useState(null);
  // const [entryToTime, setEntryToTime] = useState(null);
  const [exitFromTime, setExitFromTime] = useState(null);
  const [exitToTime, setExitToTime] = useState(null);

  const [loading, setLoading] = useState(false);
  const [errorMessage,setErrorMessage] = useState(false);
  const [timeError,SetTimeError] = useState(false);


  useEffect(() => {
      getLocations((response)=> {
        if (response.status === 200) {
          const extractedLocationNames = response.data.map(
            (item) => item.LocationName
          );
          setLocationNames(extractedLocationNames);  
          setApiData(response.data);
        }
      })
  }, []);

  const formatTime = (time) => {
    return time ? `${time}:00` : ""; // Append ":00" to the time if it's not null or empty
  };

  const handleAddNewShift1 = async () => {
    setLoading(true);
    if(!selectedLocation || !shiftName || !exitFromTime ||!exitToTime) {
      console.log("fields are mandatory");
      setErrorMessage(true)
      return false
      
      }


    const enft = new Date(formatTime(exitFromTime));
    const formattedEntryFromTime = enft.toLocaleTimeString("en-US", {
      hour12: false,
    });

    const extt = new Date(formatTime(exitToTime));
    const formattedExitToTime = extt.toLocaleTimeString("en-US", {
      hour12: false,
    });

    const locationIDtest = apiData.find(
      (item) => item.LocationName === selectedLocation
    );

    // console.log("data", locationIDtest.Id);


    const data = {
      LocationId: locationIDtest.Id,
      ShiftName: shiftName,
      ExitFromTime: formattedEntryFromTime,
      ExitToTime: formattedExitToTime,
    };

    console.log("data to add in show ", data);
    addShift(data, (response) => {
      updateDataCallback();
      onClose();
      setLoading(false);
    })
    setSelectedLocation("");
    setShiftName("");
    setExitFromTime(null);
    setExitToTime(null);
    onClose();
  };

  const handleAddNewShift = async () => {
    setLoading(true);
    if (!selectedLocation || !shiftName || !exitFromTime || !exitToTime) {
      console.log("fields are mandatory");
      setErrorMessage(true);
      setLoading(false);
      return false;
    }
  
    const enft = new Date(formatTime(exitFromTime));
    const formattedEntryFromTime = enft.toLocaleTimeString("en-US", {
      hour12: false,
    });
  
    const extt = new Date(formatTime(exitToTime));
    const formattedExitToTime = extt.toLocaleTimeString("en-US", {
      hour12: false,
    });
  
    // Check if exitToTime is after exitFromTime
    if (extt <= enft) {
      console.log("exitToTime should be after exitFromTime");
      setErrorMessage(true);
      setLoading(false);
      SetTimeError(true);
      return false;
    }
  
    const locationIDtest = apiData.find(
      (item) => item.LocationName === selectedLocation
    );
  
    const data = {
      LocationId: locationIDtest.Id,
      ShiftName: shiftName,
      ExitFromTime: formattedEntryFromTime,
      ExitToTime: formattedExitToTime,
    };
  
    console.log("data to add in show", data);
    addShift(data, (response) => {
      updateDataCallback();
      onClose();
      setLoading(false);
    });
    setSelectedLocation("");
    setShiftName("");
    setExitFromTime(null);
    setExitToTime(null);
    onClose();
  };
  
  const handleClose = () => {
    setErrorMessage(false);
    SetTimeError(false);
    setSelectedLocation(null);
    setShiftName("");
    setExitFromTime(null);
    setExitToTime(null);
    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Add New Show </DialogTitle>
      <DialogContent>
        <Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={locationNames}
              getOptionLabel={(option) => option}
              value={selectedLocation}
              onChange={(event, newValue) => setSelectedLocation(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Screen"
                  fullWidth
                  margin="normal"
                    // error={!!errors.selectedLocation}
                    // helperText={errors.selectedLocation}
                />
              )}
            />
          {  errorMessage && !selectedLocation &&  <Typography color="error" variant="body2" align="left" marginTop={1}>Please Select the screen</Typography>}

          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Show Name"
              value={shiftName}
              onChange={(e) => setShiftName(e.target.value)}
              fullWidth
              margin="normal"
              // error={!!errors.shiftName}
              // helperText={errors.shiftName}
            />
         {  errorMessage && !shiftName &&  <Typography color="error" variant="body2" align="left" marginTop={1}>Please Enter the Show Name </Typography>}

          </Grid>
          <Box sx={{ my: 3 }}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Break Time
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item md={6}>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    label="Start Time"
                    value={exitFromTime}
                    onChange={(newValue) => setExitFromTime(newValue)}
                    fullWidth
                  />

                </DemoContainer>
                {  errorMessage && !exitFromTime &&  <Typography color="error" variant="body2" align="left" marginTop={1}>Please select Start time  </Typography>}
              </LocalizationProvider> */}

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    label="Start Time"
                    value={exitFromTime}
                    onChange={(newValue) => {
                      setExitFromTime(newValue);
                      setErrorMessage("");
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </DemoContainer>
                {errorMessage && !exitFromTime && (
                  <Typography color="error" variant="body2" align="left" marginTop={1}>
                    Please select Start time
                  </Typography>
                )}
              </LocalizationProvider>

            </Grid>
            <Grid item md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    label="End Time"
                    value={exitToTime}
                    onChange={(newValue) => setExitToTime(newValue)}
                    fullWidth
                  />

                </DemoContainer>
                {  errorMessage && !exitToTime &&  <Typography color="error" variant="body2" align="left" marginTop={1}>Please select End time  </Typography>}
                {  errorMessage && timeError &&  <Typography color="error" variant="body2" align="left" marginTop={1}>End Time Must be after Start Time</Typography>}

              </LocalizationProvider>
            </Grid>

            
            
            {/* <Grid item md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    label="Entry To Time"
                    value={entryToTime}
                    onChange={(newValue) => setEntryToTime(newValue)}
                    fullWidth
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid> */}
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          // entryFromTime
          onClick={handleAddNewShift}
          // loading={loading}
        >
          Add Show
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
