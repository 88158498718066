// import axios from 'src/axios'
import { getURL, jsonToParam ,jsonToFormData} from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';
import axios from 'src/axios'


const addClient = (body ,callback) => {
    axios({
        method: 'post',
        url: getURL('client/add'),
        data: jsonToFormData(body),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getDownalodVideo = (body, callback) => {
    const { url} = body; 
    console.log("url...",url);
    axios({
        method: 'get',
        url: `${getURL(`/recordings/download?url=${encodeURIComponent(url)}`)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}



const getTimeZonesRegister = (callback) => {
    axios({
        method: 'get',
        url: getURL('/timezone?status=1'),
        validateStatus: (status) => status >= 200 && status < 300
    })
    .then(response => {
        callback(null, response);
    })
    .catch(error => {
        callback(error, null);
    });
};

const getClients = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('client')}`,
        // validateStatus: (status) => handleResponse(status)
    }).then((response) => {
        callback(response);
    }).catch((error) => {
        console.error("Error fetching clients:", error);
    });
};

export { addClient ,getTimeZonesRegister ,getDownalodVideo ,getClients} 

