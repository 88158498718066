import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Alert,
    AlertTitle,
    Stack,
    Typography
} from '@mui/material';
import Iconify from 'src/components/Iconify';


export default function DeviceAlert({
    alert,
    handleAlertClose,
    title,
    content,
    id,
    status,
    handleStatus }) {
    return (
        <Dialog
            open={alert}
            onClose={handleAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {/* <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle> */}
            <DialogContent>
                <Stack
                //  direction="row"
                 justifyContent="center"
                 alignItems="center"
                 sx={{ maxWidth: 350, textAlign: "center" }}
                >
                    <Iconify 
                    icon={status === 2 ? "eva:alert-circle-outline" : "eva:alert-triangle-outline"} 
                    sx={{ fontSize: 92, color: status === 2 ? '#ffb2a3' : '#ffdba3' }}/>
                    <Typography sx={{ color: "gray"}} variant='h5'>Are you sure?</Typography>
                    <Typography sx={{ py:2 }}>{content}</Typography>
                    <DialogActions sx={{p:0, pt: 1}}>
                        <Button color='success' variant='outlined' onClick={handleAlertClose}>cancel</Button>
                        <Button color='error' variant='outlined' onClick={() => handleStatus(id, status)} autoFocus>
                            {title}
                        </Button>
                    </DialogActions>
                </Stack>
                
                {/* <DialogContentText id="alert-dialog-description"> */}
                {/* <Alert severity="error" sx={{ bgcolor: 'white', p:0 }}>
                    <AlertTitle>Delete Warning</AlertTitle>
                </Alert> */}
                
                    {/* </DialogContentText> */}
            </DialogContent>
            
        </Dialog>
    )
}