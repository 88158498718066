import * as Yup from 'yup';
import qs from 'qs';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
// material
import {
  Grid,
  TextField,
  Alert,Table,styled,Paper,TableCell,TableHead,TableRow,TableBody,TableContainer,CardContent,
  Typography,
  Switch,
  Stack,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Autocomplete,
  FormHelperText,
  FormControlLabel,
  Card,
  colors,
  Icon
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, {Fragment } from 'react';

// component
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import client from 'src/api/clients';
import { getClientList , setAddDevice ,getServerList , getArchiveTimings, getMonitoringTimings, getTimezoneList } from 'src/api/analytics';
import { getProtocolsInfo } from 'src/api/protocol';
import axios from 'axios';
import { DeviceUnknown } from '@mui/icons-material';
import { getClientInfo } from 'src/api/protocol';

export default function ClientDetails({ dialogData, setOpen}) {
 
     const [fullWidth, setFullWidth] = useState(true);

     const [clientInfo,setClientInfo] = useState([]); 
     const [policeNumber,setPoliceNumber] = useState('');
     const [fireNumber,setFireNumber] = useState('')
     const [contactNumber,setContactNumber] = useState('')
     const [emailList,setEmail] = useState('');
     const [clientAddress,setAddress] = useState(''); 
     const [timeZone,setTimeZone] = useState('')

     useEffect(() => {

    //  console.log("data",dialogData.id)
     if (dialogData.isOpen && dialogData.id) {
               getClientInfo(dialogData.id.ID,(response) => {
                 if (response.status === 200) {
                   setClientInfo(response.data)
                 }
               })
               getTimezoneList((response) => {
                    if(response.status === 200){
                        //  console.log("timeZone",response.data);
                        //  console.log("Protocols Data",dialogData.id.TimezoneId);
                         const timezone = response.data.find(tz => tz.Id === dialogData.id.TimezoneId);
                         if (timezone) {
                            //  console.log(timezone.TimeZone);
                             setTimeZone(timezone.TimeZone)

                         } else {
                            //  console.log("Timezone not found for the given Id.");
                         }


                    }
               })

     }

     }, [dialogData.isOpen, dialogData.id]);


     useEffect(() => {

             if(clientInfo.length == 1){
               //console.log("clientInfo Length" ,clientInfo)
         
               setPoliceNumber(clientInfo[0].PoliceNo)
               setContactNumber(clientInfo[0].MobileNo)
               setAddress(clientInfo[0].Address)
               setFireNumber(clientInfo[0].FireNo)
               setEmail(clientInfo[0].Email)
             }
             else{
               // setFianlClientData([]);
         
               setPoliceNumber('')
               setContactNumber('')
               setAddress('')
               setFireNumber('')
               setEmail('')
             }
         
           },[clientInfo])

     const onClose = () => {
          setOpen({ isOpen: false, id: null });  
     }


  return (
<Dialog  fullWidth={fullWidth}  maxWidth="md"    open={dialogData.isOpen} onClose={onClose}>
  <DialogContent >
   <Typography variant="h6" style={{ fontWeight: 'bold' }}>
   {/* {selectedCamId}  */}
    Client Details       </Typography>
               <Grid container spacing={2}>
                              <Grid item md={8}>
                              </Grid>
               </Grid>
                              

                        <Grid container spacing={2}>                           
                                      <Grid item md={6}>
                                       <InputLabel>Police Number</InputLabel> 
                                      <TextField 
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        value={policeNumber || ''}
                                        />
                                        {/* if clientInfo is true then set the value else set to '' */}
                                       </Grid>
                                       <Grid item md={6}>
                                      <InputLabel>Fire Number</InputLabel> 
                                      <TextField 
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        value={fireNumber || ''}
                                        />

                                       </Grid>
                         </Grid>

                         <Grid container spacing={2} marginTop={1}>                           
                                      <Grid item md={6}>
                                       <InputLabel>Contact Number</InputLabel> 
                                      <TextField 
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        value={contactNumber || ''}
                                        />

                                       </Grid>
                                       <Grid item md={6}>
                                      <InputLabel>Time Zone</InputLabel> 
                                      <TextField 
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        value={timeZone || ''}
                                        />

                                       </Grid>
                         </Grid>

                         <Grid container spacing={2} marginTop={1}>                           
                                      <Grid item md={6}>
                                       <InputLabel>Email</InputLabel> 
                                      <TextField 
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        value={emailList || ''}
                                        />

                                       </Grid>
                                       <Grid item md={6}>
                                      <InputLabel>Address</InputLabel> 
                                      <TextField 
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        value={clientAddress || ''}
                                        // value={footage || ''}
                                        />

                                       </Grid>
                         </Grid>
                      
                         <DialogActions>
                          <Button color='error' variant='outlined' onClick={onClose} >Close</Button>
                        </DialogActions>    
  </DialogContent>
</Dialog>

  )
}
ClientDetails.propTypes = {
  setOpen: PropTypes.func.isRequired
} 