import React, { useEffect, useState } from 'react';
import {
  Card,
  Stack,
  Container,
  Typography,
  CardContent,
  TextField,
  Button,
  Box,
  Divider,
  Grid,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Page from '../components/Page';
import dayjs from "dayjs";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { getArchiveTimings ,postArchiveTimings } from 'src/api/analytics';
// const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const cardColors = [
  '#FFE0E0', // Light Red
  '#E0F5FF', // Light Blue
  '#E0FFE0', // Light Green
  '#FFF5E0', // Light Orange
  '#F0E0FF', // Light Purple
  '#E0E8FF', // Light Grayish Blue
  '#FFE0F0'  // Light Pink
];

export default function ArchiveTimings({DeviceInfo ,close }) {
 
  const [main, setOpen] = useState({
    open: false,
    activeColoum: [],
    AnalyticName: '',
    activeCamIds: []
  });

  
  // State variables to hold timings for each day
  const [timings, setTimings] = useState(daysOfWeek.map(() => ({ fromTime: '', toTime: '' })));
  const [allDaysFromTime, setAllDaysFromTime] = useState(dayjs().startOf('day'));
  const [allDaysToTime, setAllDaysToTime] = useState(dayjs().endOf('day'));
  
  //const [devicemonTimings,setDeviceMonTimings] = useState([]);

  const [status, setStatusBase] = useState(false);
  const [message,setMessage]=useState('')
  const [messageError, setErrorMessage] = useState('');
  const [messageErrorDate, setErrorMessageDate] = useState('');
const formatTime = (time) => {
  // Add leading zero if hour is less than 10
  // return hour < 10 ? `0${hour}:00` : `${hour}:00`;
  return dayjs(time).format('HH:mm:ss');
};


useEffect(()=>{
  debugger
  if(messageError.length>0){
    const unqArr=[...new Set(messageError)]
    setErrorMessage(unqArr)
    // console.log(messageError)
    // console.log(unqArr)
  }
},[messageError])

  useEffect(() =>{
    const selectedDevices = DeviceInfo;
    selectedDevices.forEach(value => {
      // console.log(value);
  
      getArchiveTimings(value, (response) => {
        if (response.status === 200) {
         
          const fetchedTimings = response.data;
  
         
          fetchedTimings.forEach(timing => {
            // Extract Dayno and corresponding timing object index
            const { Dayno } = timing;
            const timingIndex = Dayno ; // Dayno is 1-based, array index is 0-based
            // Update timings state with fetched values
            if (timingIndex >= 0 && timingIndex < daysOfWeek.length) {
              // Set fromTime and toTime for the corresponding day
              setTimings(prevTimings => {
                const updatedTimings = [...prevTimings];
                updatedTimings[timingIndex] = {
                  // fromTime: formatTime(timing.FromHour),
                  // toTime: formatTime(timing.ToHour)
                  fromTime: dayjs(timing.FromHour, 'HH:mm:ss'),
                  toTime: dayjs(timing.ToHour, 'HH:mm:ss')
                };
                return updatedTimings;
              });
            }
          });
        }
      });
  
    });
  },[]);
  

  const handleClose = () => {
    //debugger
    close()
    };

  const handleOpen = () => {
    setOpen({ ...main, open: true });
  };

  // useEffect(()=>{
  //   debugger
  //   console.log(messageError)
  // },[messageError])


  const handleSaveTime = () => {
   // debugger
    const resultList = [];
    const selectedDevices = DeviceInfo;
    let previousTime = '';
    let previousFromTime = '';
    let extrahourIndex = '';
    let overlapDetected = false;

    // console.log(selectedDevices)
    // console.log(timings)

    setMessage('');
    setErrorMessage([]);
    
    //console.log(messageError)
    
    // selectedDevices.forEach(value => {
    //   previousTime='';

    //   //console.log("timings",timings)
    //   timings.forEach((timing, index) => {
        
        
    //     const fromTimeformate = formatTime(timing.fromTime)
    //     const trimmedFromTime = fromTimeformate.split(":").slice(0, 2).join(":"); // Splitting by ":" and taking only the first two parts
    //     const toTimeformate = formatTime(timing.toTime)
    //     const trimmedtoTime = toTimeformate.split(":").slice(0, 2).join(":"); // Splitting by ":" and taking only the first two parts

    //     const currentDate = new Date()
    //     const currentDay = currentDate.getDay(); 

    //     const daysToAdd = index - currentDay;
    //     currentDate.setDate((currentDate.getDate() + daysToAdd)+1);

    //     const fromDateTime = new Date(currentDate.toDateString() + ' ' + timing.fromTime);
    //     const toDateTime = new Date(currentDate.toDateString() + ' ' + timing.toTime);

    //     if (toDateTime < fromDateTime) {
    //       toDateTime.setDate(toDateTime.getDate() + 1);
    //     }

    //     console.log(fromDateTime,toDateTime)



    //     // if(fromDateTime && toDateTime){
    //     //   if(previousTime){ 
    //     //       if(previousTime > fromDateTime){

    //     //         setErrorMessage((prevErrorMessage) => [
    //     //           ...prevErrorMessage,
    //     //           `Overlapping time range detected between ${new Date(fromDateTime.getTime() - (24 * 60 * 60 * 1000)).toLocaleDateString('en-US', { weekday: 'long' })} and ${fromDateTime.toLocaleDateString('en-US', { weekday: 'long' })}`
    //     //       ]);

    //     //         setStatusBase(true);
    //     //         overlapDetected = true;
    //     //       }
    //     //   }
    //     //   else{  // if monday need to check for sunday time
    //     //     //debugger
    //     //     // const sundayDate = new Date(new Date().setDate(new Date().getDate() + (7 - new Date().getDay())),...time.split(':').map(Number));
    //     //     // const mondayDate = new Date(new Date().setDate(new Date().getDate() + (1 + 7 - new Date().getDay()) % 7 + new Date(new Date().setHours(...time.split(':').map(Number))).getDay()));
    //     //     if (Date.parse('01/01/1970 ' + timings[timings.length-1].toTime) < Date.parse('01/01/1970 ' + timings[timings.length-1].fromTime)) {
    //     //       let sundayTime=timings[timings.length-1].toTime;
    //     //       let timeDiff=Date.parse('01/01/1970 ' + sundayTime) > Date.parse('01/01/1970 ' + fromTimeformate);
    //     //       if(timeDiff){
    //     //         setErrorMessage((prevErrorMessage) => [
    //     //           ...prevErrorMessage,
    //     //           `Overlapping time range detected between Sunday and Monday`
    //     //       ]);
  
    //     //         setStatusBase(true);
    //     //         overlapDetected = true;
    //     //       }
    //     //     }
    //     //   }
    //     // }

    //     // previousTime=toDateTime
    //     // previousFromTime=fromDateTime

    //     if(overlapDetected === false){
    //       resultList.push({
    //         CameraId: value,
    //         Dayno: index,
    //         FromHour: trimmedFromTime,
    //         ToHour: trimmedtoTime
    //     });
    //     }
      
      
    //   });


    // })

    selectedDevices.forEach(value => {
      previousTime = '';
      timings.forEach((timing, index) => {
          const fromTimeformate = formatTime(timing.fromTime);
          const trimmedFromTime = fromTimeformate.split(":").slice(0, 3).join(":");
          const toTimeformate = formatTime(timing.toTime);
          const trimmedtoTime = toTimeformate.split(":").slice(0, 3).join(":");

          const currentDate = new Date();
          const currentDay = currentDate.getDay();
          const daysToAdd = index - currentDay;
          currentDate.setDate((currentDate.getDate() + daysToAdd) + 1);

          resultList.push({
              CameraId: value,
              Dayno: index,
              FromHour: trimmedFromTime,
              ToHour: trimmedtoTime
          });
      });
  });



    console.log("result list Archive Timings ",resultList)
      
    postArchiveTimings(resultList,(response)=>{
      if(response.data){
        setErrorMessage([])
        setStatusBase(true)
        setMessage('Archives Timings Inserted Successfully!!')
        }
    })

      // if(overlapDetected === false){
      //   postArchiveTimings(resultList,(response)=>{
      //     if(response.data){
      //       setErrorMessage([])
      //       setStatusBase(true)
      //       setMessage('Archives Timings Inserted Successfully!!')
      //       }
      //   })
      // }



  }; 
 


  const handleTimingChange = (index, field, value) => {
    setTimings(prevTimings => {
      const updatedTimings = [...prevTimings];
      updatedTimings[index][field] = value;
      return updatedTimings;
    });
  };

  const handleSetAllDaysTime = () => {
    setTimings(prevTimings => prevTimings.map(() => ({ fromTime: allDaysFromTime, toTime: allDaysToTime })));
  };

//  console.log("close",close)

  return (
    <Page title="Archives Times">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>Archives Times</Typography>
        </Stack>



        <Card sx={{ padding: 3 }}>
          <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
            <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
              <Stack direction="row" spacing={2}>
                <Stack >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      views={['hours', 'minutes', 'seconds']}
                      label="From Time (All Days)"
                      value={allDaysFromTime}
                      onChange={(newValue) => setAllDaysFromTime(newValue)}
                      format="HH:mm:ss"
                      ampm={false}
                    />
                  </LocalizationProvider>
                  
                </Stack>
                <Stack>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      views={['hours', 'minutes', 'seconds']}
                      label="To Time (All Days)"
                      value={allDaysToTime}
                      onChange={(newValue) => setAllDaysToTime(newValue)}
                      format="HH:mm:ss"
                      ampm={false}
                      minutesStep={1}   // Set minutes step to 1 for every minute
                      secondsStep={1}   // Set seconds step to 1 for every second
                      getMinutesAriaText={(value) => `${value} minutes`}  // Optional: Aria text for minutes
                      getSecondsAriaText={(value) => `${value} seconds`}  // Optional: Aria text for seconds
                    />
                  </LocalizationProvider>
                </Stack>
                <LoadingButton variant="contained" onClick={handleSetAllDaysTime}>Apply to All Days</LoadingButton>
              </Stack>
            </Stack>

            <Grid container spacing={3}>
              {daysOfWeek.map((day, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card sx={{ py: 2, boxShadow: 0, textAlign: 'center', bgcolor: cardColors[index] }}>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {day}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        views={['hours', 'minutes', 'seconds']}
                        label="From Time"
                        value={timings[index].fromTime}
                        onChange={(newValue) => handleTimingChange(index, 'fromTime', newValue)}
                        format="HH:mm:ss"
                        ampm={false}
                      />
                    </LocalizationProvider>
                    <Box m={2} /> {/* Adjust margin as needed */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        views={['hours', 'minutes', 'seconds']}
                        label="To Time"
                        value={timings[index].toTime}
                        onChange={(newValue) => handleTimingChange(index, 'toTime', newValue)}
                        format="HH:mm:ss"
                        ampm={false}
                      />
                    </LocalizationProvider>
                  </CardContent>

                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Divider />

          <Stack direction="row" spacing={1} sx={{ paddingTop: 2 }}>
            <LoadingButton variant="contained" color='error' onClick={handleClose}>Close</LoadingButton>
            <LoadingButton variant="contained" onClick={handleSaveTime}>Save</LoadingButton>
          </Stack>

          {(status && message) && (
            <Stack direction="row" sx={{ paddingTop: 2 }}>
              <Alert severity="success">{message}</Alert>
            </Stack>
          )}

     {(status && messageErrorDate) && (
            <Stack direction="row" sx={{ paddingTop: 2 }}>
              <Alert severity="error">{messageErrorDate}</Alert>
            </Stack>
          )}

          {status && messageError.length > 0 && (
            <Stack direction="column-reverse" sx={{ paddingTop: 2, paddingBottom: 2 }}>
              {/* {messageError.map((error, index) => ( */}
                <Alert severity="error">{messageError}</Alert>
              {/* ))} */}
            </Stack>
          )}
        </Card>

      </Container>
    </Page>
  );
}