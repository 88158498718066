import axios from '../axios'
import { getURL, jsonToFormData } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';
import { jsonToParam } from 'src/utils/config';

const getAllPlanDetails = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/plan/get-plan-settings')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


const getPlanDetails = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/plan/get-plans')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}
const AddPlanCam = (body,callback) => {
    axios({
        method: 'post',
        url: `${getURL('plan/add-client-plan')}`,
        data:body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch((error)=>{
        console.log(error)
    })
}

const AddclientInvoice = (body,callback) => {
    axios({
        method: 'post',
        url: `${getURL('plan/add-client-plan')}`,
        data:body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch((error)=>{
        console.log(error)
    })
}

const getClientPlanInfo= (body,callback) => {
    axios({
        method: 'get',
        url: `${getURL('/plan/get-plans-by-client')}?${jsonToParam(body)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch((error)=>{
        console.log(error)
    })
}

const AddNewPlan = (body,callback) => {

    axios({
        method: 'post',
        url: `${getURL('/plan/add')}`,
        data:body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch((error)=>{
        console.log(error)
    })
}

// const UpdateClientPlan = (body,callback) => {

//     axios({
//         method: 'put',
//         url: `${getURL('camera-groups/update')}`,
//         data:body,
//         validateStatus: (status) => handleResponse(status)
//     }).then((response) => callback(response)).catch((error)=>{
//         console.log(error)
//     })
// }




export { getAllPlanDetails, getPlanDetails ,AddPlanCam ,getClientPlanInfo ,AddNewPlan, AddclientInvoice}