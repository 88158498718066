// material
import { useParams } from 'react-router-dom'
import { Container, Grid } from '@mui/material';
import { AuditLiveGrid } from 'src/sections/@dashboard/audit';
import { useEffect } from 'react';
// components
import Page from '../components/Page';

export default function Live () {
    // console.log('Live: ', loadCameras)
    const { gridview } = useParams()

    useEffect(() => {
        let g = parseInt(gridview)/2 > 3 ? 12/3 : 12/2
        // console.log('Params', g)
    }, [gridview])

    return (
        <Page title="Live Audits">
            <Container maxWidth="xxl" style={{padding:0}}>
                <Grid container spacing={0} >
                    <Grid item xs={12} sm={12} md={12}>
                        <AuditLiveGrid gridview={gridview} />
                    </Grid>
                </Grid>        
            </Container>
        </Page>
    )    
}