import React from 'react'
import { useState } from 'react';
import {Dialog,DialogContent,DialogTitle} from '@mui/material';
import { Box,Typography,IconButton,Switch,Grid,TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

export default function ModalTemplate({open, onClose,deviceInfo}) {
    
    //console.log(deviceInfo)
    const {devicename,deviceid,displayname,Status,archive,CameraType} = deviceInfo
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

      
  return (
    <Dialog open={open} sx={{ maxWidth: '90vw', width: '90vw' }}>
    <DialogTitle sx={{ textAlign: 'center' }}>Device Information</DialogTitle>
    <DialogContent sx={{ display: 'flex', flexDirection: 'column'}}>
        {/* <Typography>Camera Name : {agent_devicename}</Typography>
        <Typography>Camera ID : {deviceid}</Typography>
        <Typography>Client Name : {displayname}</Typography>
        <Typography>Status : {Status === 1 ? 'Active' : 'Inactive'}</Typography>
        <Typography>Archive :  {archive ? <Switch checked={archive} disabled/>:<Switch checked={archive} disabled/>}
        </Typography> */}
        <Grid container spacing={3} sx={{ my: 2 }}>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    autoComplete="Camera Name"
                    type="text"                                                                                               
                    label="Camera Name"
                    value={devicename}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    autoComplete="Camera ID"
                    type="text"                                                                                               
                    label="Camera ID"
                    value={deviceid}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    autoComplete="Client Name"
                    type="text"                                                                                               
                    label="Client Name"
                    value={displayname}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    autoComplete="Status"
                    type="text"                                                                                               
                    label="Status"
                    value={Status === 1 ? 'Active' : 'Inactive'}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    autoComplete="CameraType"
                    type="text"                                                                                               
                    label="CameraType"
                    value= {CameraType ?CameraType:'Not Available'}
                  />
                </Grid>
        </Grid>

        <Grid container spacing={2} sx={{my:2,display:'flex',flexDirection:'column'}}>
                <Grid item md={12}>
                        <Typography>Is Need Archive :  {archive ? <Switch checked={archive} disabled/>:<Switch checked={archive} disabled/>}
                        </Typography>
                </Grid>
        </Grid>
              
      <LoadingButton
       sx={{ mt: 2, ml: 'auto' }}
        type="submit"
        color='error' 
        variant='outlined'      
        onClick={onClose}
      >
        Close
      </LoadingButton>
    </DialogContent>
  </Dialog>
  )
}