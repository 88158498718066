import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  FormControl,
  InputLabel,
  locationError,
  Select,
  CircularProgress,
  Container,
  Typography,
  TableContainer,
  IconButton,
  Grid,
  MenuItem,
  TablePagination,
  Link,
  Icon,
  colors,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

import { useEffect, useState } from "react";
import ShiftTimeEdit from "src/sections/shifttime/shiftTimeEdit.js";
import ShiftTimeAdd from "src/sections/shifttime/shiftTimeAdd.js";
import TimelapsAdd from "src/sections/timelaps/TimelapsAdd.js";
import axios from "src/axios";
import { ListItemText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Delete } from "@mui/icons-material";
import { deleteShiftById ,getAllShifts } from "src/api/ShiftMaster";
import Iconify from "src/components/Iconify";
import { UserListToolbar } from "../@dashboard/audit";
import { UserListHead } from "../@dashboard/user";
import Label from "src/components/Label.js";
import TimeLapsEdit from "../timelaps/TimeLapsEdit.js";
import ShowTimeAdd from "./ShowTimeAdd";
import EditShow from "./EditShow";
import React from "react";

export default function AddShow() {
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [locationData, setLocationData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isShowtimeAdd, setShowTimeAdd] = useState(false);
  const [data, setData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [editData, setEditData] = useState({});
  const [rowToDelete, setRowToDelete] = useState(null); // State to store the row to be deleted

  const TABLE_HEAD = [
    { id: "Id", label: "S.No" },
    { id: "locationname", label: "Screen Name" },
    { id: "Shiftname", label: "Show Name" },
    { id: "ExitFromtime", label: "Break Start Time" },
    { id: "Exittotime", label: "Break End Time" },
    { id: "Action", label: "Action" },
  ];

  const handleGetShifts = () => {
    setLoading(true);
    getAllShifts((response) => {
      setLoading(false);
      if (response.status === 200) {
        setLocationData(response.data);
      }
    });
  };

  useEffect(() => handleGetShifts(), []);

  const handleDetectedType = (event, value) => {
    setSelectedLocation(value);
    const selectedLocationObject = apiData.find(
      (location) => location.LocationName === value
    );
    setSelectedLocationId(
      selectedLocationObject ? selectedLocationObject.Id : null
    );
  };

  const filteredData = locationData.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterName.toLowerCase())
    )
  );

  const handleUpdateData = async () => {
    try {
      handleGetShifts();
      console.log("yes ");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleEditClick = (row) => {
    setCurrentId(row.Id);
    setEditData(row);
    setIsDialogOpen(true);
  };

  const handleDeleteClick = (row) => {
    setRowToDelete(row); // Store the row to be deleted
    setOpenDelete(true); // Open the delete confirmation dialog
  };

  const handleClose = () => {
    setOpenDelete(false); // Close the dialog
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setCurrentId(null);
  };

  const deleteShift = () => {
    deleteShiftById({ Id: rowToDelete.Id }, (response) => {
      setOpenDelete(false);
      if ([200, 201].includes(response.status)) handleGetShifts();
    });
  };

  const handleAddNewShow = () => {
    setShowTimeAdd(true);
  };

  const handleCloseShowtimeAdd = () => {
    setShowTimeAdd(false);
    setIsDialogOpen(false);
  };

  function formatTimeTo12Hour(time) {
    const [hour, minute] = time.split(':');
    const date = new Date();
    date.setHours(hour);
    date.setMinutes(minute);

    let formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

    if (formattedTime.startsWith('00:')) {
        formattedTime = formattedTime.replace('00:', '12:');
    }

    return formattedTime.replace(' am', ' AM').replace(' pm', ' PM');
}


  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Shows
        </Typography>
        <Button
          variant="contained"
          to="#"
          onClick={() => handleAddNewShow()}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Add New Show
        </Button>
      </Stack>
      <UserListToolbar
        sx={{ backgroundColor: "#f3f3f3" }}
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
      />

      <Card>
        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <React.Fragment>
              <UserListHead
                headers={TABLE_HEAD}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                numSelected={selected.length}
              />

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={TABLE_HEAD.length} align="center">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "20vh",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">{row.LocationName}</TableCell>
                      <TableCell align="left">{row.ShiftName}</TableCell>
                      <TableCell align="left">{formatTimeTo12Hour(row.ExitFromTime)}</TableCell>
                      <TableCell align="left">{formatTimeTo12Hour(row.ExitToTime)}</TableCell>

                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleEditClick(row)}
                          sx={{ marginRight: 1 }} // Add margin to the right
                        >
                          Edit
                        </Button>

                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDeleteClick(row)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </React.Fragment>
          </Table>
        </TableContainer>
      </Card>

      <EditShow
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        rowData={editData}
        updateDataCallback={handleUpdateData}
      />

      <ShowTimeAdd
        isOpen={isShowtimeAdd}
        onClose={() => handleCloseShowtimeAdd(false)}
        updateDataCallback={handleUpdateData}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDelete} onClose={handleClose}>
      <DialogContent>
          <Stack
            //  direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ maxWidth: 350, textAlign: "center" }}
          >
            <Iconify
              icon={"eva:alert-triangle-outline"}
              sx={{ fontSize: 92, color: "#ffb2a3" }}
            />
            <Typography sx={{ color: "gray" }} variant="h5">
              {" "}
              Are you sure you want Delete the Show ?
            </Typography>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="error">
            No
          </Button>
          <Button variant="contained"  onClick={() => {
              deleteShift();
              handleClose();
            }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
