import axios from '../axios'
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';


// get cameras data
const getEmployees = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/activity-tracker/get-employees-list')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getLocations = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/activity-tracker/get-locations-list')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getCameras = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/activity-tracker/get-cameras-list')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const getReportData = (filters, callback) => { 
    const params = filters ? `?${jsonToParam(filters)}`: "";
    axios({
        method: 'get',
        url: `${getURL(`/activity-tracker/detail-info${params}`)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

export { getEmployees, getLocations, getCameras, getReportData }