import React from 'react';
import { Bar } from 'react-chartjs-2';

const MohiniBarGraph = ({ data }) => {
  // Assuming data has an additional 'screen' field indicating 'Screen 1' or 'Screen 2'
  // const screenCategories = ['Screen 1', 'Screen 2'];
  console.log("data",data);
  const screenCategories = Array.from(new Set(data.map(item => item.LocationName)));

  // Aggregate counts for each screen category
  const aggregatedData = screenCategories.reduce((acc, LocationName) => {
    acc[LocationName] = {
      popcorn: 0,
      cooldrink: 0
      // sweetcorn: 0
    };
    data.forEach(item => {
      if (item.LocationName === LocationName) {
        acc[LocationName].popcorn += item.pop_corn_count;
        acc[LocationName].cooldrink += item.cool_drink_count;
        // acc[screen].sweetcorn += item.sweetcorn_count;
      }
    });
    return acc;
  }, {});

  // Separate data for each category
  const popcornCounts = screenCategories.map(LocationName => aggregatedData[LocationName].popcorn);
  const cooldrinkCounts = screenCategories.map(LocationName => aggregatedData[LocationName].cooldrink);
  // const sweetcornCounts = screenCategories.map(screen => aggregatedData[screen].sweetcorn);

  // Find the maximum value among all counts
  const maxCount = Math.max(
    ...popcornCounts,
    ...cooldrinkCounts,
    // ...sweetcornCounts
  );
  const nearestMultipleOf50 = Math.ceil(maxCount / 50) * 50;

  const chartData = {
    labels: screenCategories,
    datasets: [
      {
        label: 'Popcorn',
        backgroundColor: 'rgba(250, 218, 95, 1)',
        data: popcornCounts
      },
      {
        label: 'Cooldrink',
        backgroundColor: 'rgba(226, 133, 129, 1)',
        data: cooldrinkCounts
      }
    ]
  };

  const options = {
    indexAxis: 'x',
    scales: {
      y: {
        beginAtZero: true,
        max: nearestMultipleOf50,
        title: {
          display: true,
          text: 'Count'
        }
      },
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Screens'
        }
      }
    }
  };

  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default MohiniBarGraph;
