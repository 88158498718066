import {
    ToggleButton,
    ToggleButtonGroup,
    Paper
 } from '@mui/material'

 import { styled } from '@mui/material/styles';
 import { DeleteOutline, PolylineOutlined, RectangleOutlined } from '@mui/icons-material';
 import PropTypes from 'prop-types';

 const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
        border: 0,
        },
        '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
        },
    },
}));

DrawToolBar.propTypes = {
    tool: PropTypes.string.isRequired,
    handleTool: PropTypes.func.isRequired
}

export default function DrawToolBar ({tool, handleTool}) {

    return (
        <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
          m: 2,
          maxWidth: 180
        }}
      >
       
        <StyledToggleButtonGroup
          size="small"
          value={tool}
          exclusive
          onChange={handleTool}
          aria-label="text alignment"
        >
          <ToggleButton value="Polygon" aria-label="polygon">
            <PolylineOutlined />
          </ToggleButton>
          <ToggleButton value="Rectangle" aria-label="rectangle">
            <RectangleOutlined />
          </ToggleButton>
          <ToggleButton value="Delete" aria-label="delete" color="error">
            <DeleteOutline />
          </ToggleButton>

        </StyledToggleButtonGroup>
      </Paper>
    )
}