import axios from '../axios'
import { getURL, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';


// get cameras data
const getLicensePlateData = (callback) => {
    // let params = jsonToParam(body)
    // params = params ? `?${params}`: ''
    axios({
        method: 'get',
        url: `${getURL('/license-plate-information')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

export { getLicensePlateData }