import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  TextField,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


// ----------------------------------------------------------------------

export default function CameraForm({ formDialog, setFromDialog, alldata, addCamera, isLoadForm, isReloadData }) {

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');

  // const handleClickOpen = () => {
  //     setOpen(true);
  // };

  const handleClose = () => setFromDialog({ ...formDialog, open: false });
  

  const [isError, setErrorMsg] = useState({ status: false, msg: "" })
  // const {
  //   locations,
  //   addLocation,
  //   isLoadForm,
  //   isReloadData
  // } = useLocations()

  const FormSchema = Yup.object().shape({
    devicename: Yup.string().required('Camera Name is required'),
    substream: Yup.string().required('Stream URL is required')
  });

  const formik = useFormik({
    initialValues: {  
      devicename: '',
      substream: ''
    },
    validationSchema: FormSchema,
    onSubmit: () => {
      setErrorMsg({...isError, status: false})
      // console.log(formDialog.id)
      if (formDialog.id != null)
        values.uid = formDialog.id
      // console.log('values', values)
      // const fdata = jsonToFormData(values)
      addCamera(values)
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setSubmitting, setFieldValue } = formik;

  useEffect(() => {
    if (alldata.formResponse.isLoad) {
      setSubmitting(false)
      if (alldata.formResponse.status === true) {
        formik.resetForm()
        setFromDialog({
          id: null,
          open: false
        })
        isLoadForm(true)
        isReloadData(true)
      } else {
        setErrorMsg({ status: true, msg: 'Somthing went wrong please try again.' })        
      }
    }
      setFieldValue('devicename', formDialog.devicename || '')
      setFieldValue('substream', formDialog.substream || '')

  }, [alldata.formResponse.isLoad, formDialog.devicename, formDialog.substream])

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={formDialog.open}
      onClose={handleClose}
    >
      <DialogTitle>Add Camera</DialogTitle>
      <DialogContent>
        {/* <DialogContentText>
          You can set my maximum width and whether to adapt or not.
        </DialogContentText> */}      



          <FormikProvider value={formik} sx={{ pt:2 }}>
            {(isError.status) && (
              <Alert severity="error" sx={{ mb: 3 }}>{isError.msg}</Alert>
            )}
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3} sx={{ mb: 3, pt:2 }}>
                <TextField
                  fullWidth
                  autoComplete="devicename"
                  type="text"
                  label="Camera Name"
                  
                  {...getFieldProps('devicename')}
                  error={Boolean(touched.devicename && errors.devicename)}
                  helperText={touched.devicename && errors.devicename}
                />

                <TextField
                  fullWidth
                  autoComplete="substream"
                  type="text"
                  label="Stream URL"
                  
                  {...getFieldProps('substream')}
                  error={Boolean(touched.substream && errors.substream)}
                  helperText={touched.substream && errors.substream}
                />

                
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Form>
          </FormikProvider>


    
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>





  );
}

CameraForm.propTypes = {
  formDialog: PropTypes.object.isRequired,
  setFromDialog: PropTypes.func.isRequired,
  alldata: PropTypes.object.isRequired,
  addCamera: PropTypes.func.isRequired,
  isLoadForm: PropTypes.func.isRequired,
  isReloadData: PropTypes.func.isRequired
}