import {
    Card,
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Link,
  } from '@mui/material';
  import Iconify from '../../src/components/Iconify.js';
  import { useEffect, useState } from 'react';
  import axios from "axios";
  import { UserListHead, UserListToolbar } from '../../src/sections/@dashboard/user';
  import Label from '../../src/components/Label.js';

  import TimeLapsEdit from '../sections/timelaps/TimeLapsEdit.js'; 
  import TimelapsAdd from 'src/sections/timelaps/TimelapsAdd.js'; 

  // mock
  import { ListItemText } from '@mui/material';
  import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
  import React from 'react';

  
  const TableHeaders = ({ headers }) => (
    <TableRow>
      {headers.map((header) => (
        <TableCell key={header.id} align="left">
          <Typography variant="subtitle1" fontWeight="bold">
            {header.label}
          </Typography>
        </TableCell>
      ))}
     
    </TableRow>
  );
  
  export default function TimeLaps() {

    const [selected, setSelected] = useState([]);
    const [filterName, setFilterName] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [clients, setClient] = useState('')

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleFilterByName = (event) => {
      setFilterName(event.target.value);
    };

    const TABLE_HEAD = [
      { id: 'Id', label: 'S.No' },
      { id: 'CameraId', label: 'CameraId' },
      { id: 'devicename', label: 'DeviceName' },
      { id: 'ProcessFps', label: 'ProcessFps' },
      { id: 'videoFps', label: 'VideoFps' },
      { id: 'segmentTime', label: 'SegmentTime' },
      { id: 'status', label: 'IsActive' },
    ];
    const [data, setData] = useState([]);

    const filteredData = data.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterName.toLowerCase())
    )
  );





 

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('http://192.168.30.68:8080/time-laps/get-time-laps-settings'); 
          setData(response.data);
          const sortedData = response.data.sort((a, b) => a.Id - b.Id);
          setData(sortedData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []); // Empty dependency array to run once on mount
   

    const handleUpdateData = async () => {
      try {
        const response = await axios.get('http://192.168.30.68:8080/time-laps/get-time-laps-settings');
        setData(response.data);
        const sortedData = response.data.sort((a, b) => a.Id - b.Id);
        setData(sortedData);
      
      } catch (error) {
        console.error('Error updating data:', error);
      }
    };
  
    // const handleUpdateData = async () => {
    //  console.log("data updated after saving");
    // };



    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [editData, setEditData] = useState({}); // State to store the data for editing
    const [isTimelapsAddVisible, setTimelapsAddVisible] = useState(false);

    const addTimelaps = () => {

      setTimelapsAddVisible((prevVisibility) => !prevVisibility);
    };
    const handleAddTimelaps = (timelapsValue) => {
      // Handle the logic for adding timelaps here
      // console.log('Adding Timelaps:', timelapsValue);
    };



  const handleEditClick = (row) => {
    setCurrentId(row.Id);
    setEditData(row);
    setIsDialogOpen(true);
  };

    const handleCloseDialog = () => {
      setIsDialogOpen(false);
      setCurrentId(null);
    };
    
  
    // const handleEditClick = (id) => {
    //   alert(`Edit icon clicked for ID: ${id}`);
    // };   
    
    
    return (
<>
<Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            TimeLaps 
          </Typography>
          <Button variant="contained" to="#" onClick={() => addTimelaps()} startIcon={<Iconify icon="eva:plus-fill" />}>
            Add TimeLaps
          </Button>

        </Stack>
<UserListToolbar sx={{ backgroundColor: "#f3f3f3" }}   numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName}/>

<Card>
        <TableContainer sx={{ width: '100%' }}>
          <Table>
        
            <React.Fragment>
              <UserListHead 
              headers={TABLE_HEAD} 
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={clients.length}
              numSelected={selected.length}
              />
  
              {/* Render rows with dummy data */}
              <TableBody>
              {filteredData.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="left">{row.Id}</TableCell>
                  <TableCell align="left">{row.CameraId}</TableCell>
                  <TableCell align="left">{row.devicename}</TableCell>
                  <TableCell align="left">{row.ProcessFps}</TableCell>
                  <TableCell align="left">{row.VideoFps}</TableCell>
                  <TableCell align="left">{row.SegmentTime}</TableCell>
                  <TableCell align="left">   
                      <Label variant="ghost" color={row.IsActive === 1 ? 'success': 'error'}>
                         {row.IsActive === 1 ? 'Active': 'In Active'}
                      </Label>              
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => handleEditClick(row)} >
                       <Iconify icon="eva:edit-fill" width={24} height={24} />
                       <ListItemText primary="Edit"/>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
              
              {/* Your other components and logic go here */}
            </React.Fragment>
          </Table>
        </TableContainer>
      </Card>
     
      <TimeLapsEdit isOpen={isDialogOpen} onClose={handleCloseDialog} currentId={currentId} rowData={editData} updateDataCallback={handleUpdateData} />
      <TimelapsAdd
        isOpen={isTimelapsAddVisible}
        onClose={() => setTimelapsAddVisible(false)}
        onAddTimelaps={handleAddTimelaps}
        updateDataCallback={handleUpdateData} 
      />


</>
   
    );
  }

