import { useEffect, useState } from "react"


const useCanvas = (canvas, imageURL) => {

    const [plot, setPlot] = useState([])
    const [plots, setPlots] = useState([])
    const [rectangles, setRectAnagles] = useState([])
    const [rectangle, setRectAnagle] = useState([])
    const [context, setContext] = useState(null)
    const [imgObj, setImage] = useState(new Image())
    const [tool, setTool] = useState("Polygon");

    const handleToolBar = (event, newTool) => {setTool(newTool)}
    
    const setImagesToCanvas = () => {
        const imgObj = new Image()
        imgObj.src = imageURL
        // console.log(canvas)
        imgObj.onload = (event) => {
            const context = canvas.current.getContext('2d')
            setContext(context)
            context.canvas.width = imgObj.width
            context.canvas.height = imgObj.height
            context.drawImage(imgObj,0,0)
        }
        setImage(imgObj)
    }

    const resetCanvas = () => {
        context.canvas.width = imgObj.width
        context.canvas.height = imgObj.height
        context.drawImage(imgObj,0,0)
    }

    const isPointInsidePolygon = (point, polygon) => {
        const [x, y] = [point.x, point.y] 
        var inside = false;
        // console.log(point, polygon)
        for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            var xi = polygon[i].x, yi = polygon[i].y;
            var xj = polygon[j].x, yj = polygon[j].y;
            
            var intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        return inside;
    }

    useEffect(() => {
        if (canvas && canvas.current)
            setImagesToCanvas ()
    }, [imageURL, canvas])

    const handleOnMouseMove = (event) => {
        // console.log(event.nativeEvent.offsetX, event.nativeEvent.offsetY)
        // context
        resetCanvas ()
        const x = event.nativeEvent.offsetX
        const y = event.nativeEvent.offsetY      
        const area = {x: x, y: y}
        if (plot.length) {
            const d =5
            if (plot.length >= 3 && plot[0].x-d <= x && plot[0].x+d >= x && plot[0].y-d <= y && plot[0].y+d >= y) {
                const allPlots = [...plots, plot]
                setPlots([...allPlots])
                setPlot([])
                drawPolyLines(allPlots, area)
            } else {
                drawPolyLines(plots, area)
                drawPoint(plot)
                drawLine(plot, area)
            }
        } else if (plots.length) {
            drawPolyLines(plots, area)
        }
    
        if (rectangles.length > 0)
            drawRectangle(rectangles, area)
        if (rectangle.length > 0)        
            drawRectangle([[...rectangle, area]])
        
    } 

    const isConneced = (area, plot, isRect=true) => {
        // console.log('area', area)
        const [x, y] = plot
        if (isRect) {
            const [x1, x2] = [area[0].x, area[1].x].sort()
            const [y1, y2] = [area[0].y, area[1].y].sort()
            if (x1 <= x && x2 >= x && y1 <= y && y2 >= y)
                return true
        } else {
            const xAxies = []
            const yAxies = []
            area.forEach((plot) => {
                xAxies.push(plot.x)
                yAxies.push(plot.y)
            })
            const orderX = xAxies.sort()
            const orderY = yAxies.sort()
            if (orderX[0] <= x && orderX[orderX.length-1] >= x && orderY[0] <= y && orderY[orderY.length-1] >= y)
                return true
        }
        return false
    }

    const handleMouseRelase = (event) => {
        // console.log('rectangles', rectangles)
        const [x, y] = [event.nativeEvent.offsetX, event.nativeEvent.offsetY]
        const latestPlot = {
            x: event.nativeEvent.offsetX,
            y: event.nativeEvent.offsetY
        }
        
    
        if (tool === 'Rectangle') {
            setRectAnagles([...rectangles, [...rectangle, latestPlot]])
            setRectAnagle([])
            // console.log('rectangle', latestPlot)
            drawRectangle([[...rectangle, latestPlot]])
        } else if (tool === 'Polygon') {
            const plts = [...plot, latestPlot]
            setPlot([...plts])
            drawPoint(plts)
        } else if (tool === 'Delete') {
            setRectAnagles((rects) => {
                rects.filter((rect) => {
                    const isC = isConneced(rect, [x, y])
                    // console.log('IsC', isC)
                    return isC
                })
                return rects.filter((rect) => !isConneced(rect, [x, y]))
            })
            setPlots((plots) => plots.filter((plot) => !isPointInsidePolygon(latestPlot, plot)))
        }
        
    }

    const handleClick = (event) => {
        if (tool === 'Rectangle') {
            setRectAnagle([...rectangle, {
                x: event.nativeEvent.offsetX,
                y: event.nativeEvent.offsetY
            }])
        }
    }

    function drawRectangle(rects, selected){
        if (rects) {
            context.strokeStyle = "#df4b26";
            context.lineWidth = 2;        
            
            rects.forEach((rect) => {
                context.beginPath();
                const [x1, y1, x2, y2] = [rect[0].x, rect[0].y, rect[1].x, rect[1].y]
                if (x1 < x2)
                    context.rect(x1, y1, Math.abs(x2-x1), Math.abs(y2-y1))
                else
                    context.rect(x2, y2, Math.abs(x2-x1), Math.abs(y2-y1))
                
                if (selected && tool === 'Delete' && isConneced(rect, [selected.x, selected.y])) {
                    context.fillStyle = 'rgba(100,100,100,0.5)';
                    context.fill();
                }
                context.stroke();
            })
            
        }
    };

    function drawLine(plots, plot2){
        context.strokeStyle = "#df4b26";
        context.lineWidth = 1;        
        context.beginPath();
        context.moveTo(plots[0].x, plot[0].y);
        for(var i=1; i < plots.length; i++) { 
            context.lineTo(plots[i].x, plots[i].y);
        }
        context.lineTo(plot2.x, plot2.y);
        // context.closePath();
        context.stroke();
    };

    const drawPolyLines = (allPlots, selected={}) => {
        allPlots.forEach((plot) => {
            // if (selected && selected[0].x ) {
                
            // }
            
            // context.fillStyle = 'rgba(100,100,100,0.5)';
            context.strokeStyle = "#df4b26";
            context.lineWidth = 1;
            
            context.beginPath();
            context.moveTo(plot[0].x, plot[0].y);
          
            for(var i=1; i < plot.length; i++) { 
       
                context.lineTo(plot[i].x, plot[i].y);
            }
            if (selected && tool === 'Delete' && isPointInsidePolygon(selected, plot)) {
                context.fillStyle = 'rgba(100,100,100,0.5)';
                context.fill();
            }
            context.closePath();
            // context.fill();
            context.stroke();
        })
        
    }

    const drawPoint = (plot) => {
        context.strokeStyle = "#df4b26"; 
        context.lineJoin = "round"; 
        context.lineWidth = 5; 
                    
        for(var i=0; i < plot.length; i++){ 
            context.beginPath(); 
            context.arc(plot[i].x, plot[i].y, 3, 0, 2 * Math.PI, false); 
            context.fillStyle = '#ffffff'; 
            context.fill(); 
            context.lineWidth = 5; 
            context.stroke(); 
        }
    }

    return [
        handleOnMouseMove,
        handleMouseRelase,
        handleClick,
        tool,
        handleToolBar
    ]
}

export default useCanvas