import axios from '../axios'
import { useState } from 'react'
import { getURL } from 'src/utils/config';

const useLicence = () => {
    const [ data, setData ] = useState({
        isLoading: false,
        showAlert: false,
        status: false,
        message: "",
        response: []
    })

    const fileUpload = (body) => {
        setData({...data, isLoading: true})
        axios({
            method: 'post',
            url: getURL('license-key/add'),
            data: body,
            validateStatus: (status) => status <= 400
        }).then((response) => {
            let managedData = {...data, isLoading: false, response: response.data, showAlert: true}
            if (response.status === 200 || response.status === 201) {
                // console.log(Object.keys(response.data))
                if (Object.keys(response.data).includes('status') && Object.keys(response.data).includes('message')) {
                    // console.log(response.data, response.data.status)
                    managedData.status = String(response.data.status).toLocaleLowerCase() === 'success' ? true : false
                    managedData.message = response.data.message
                }
            }
            setData({...managedData})
            if (managedData.status === true) {
                const lastupdate = new Date ()
                localStorage.setItem('LastUpdatedTime', lastupdate)
                localStorage.setItem('isAccess', managedData.status)
                window.location.reload()
            }
        })
    }

    return {
        data,
        fileUpload
    }
}

export default useLicence