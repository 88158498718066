
import axios from '../axios'
import { getURL } from './config'

export function getAccess (token=null) {

  // headers: { Authorization: token},
  const body = {
    method: 'get',
    url: getURL('license-key'),
    validateStatus: (status) =>  status <= 400
  }

  if (token !== null)
    body.headers = { Authorization: token }

  axios(body).then((res) => {
    // console.log('Result', res)
      let status = false
      let lastupdate = new Date()
      if (res.status === 200 || res.status === 201) {
        if ('message' in res.data)
          status = String(res.data.message).toLocaleLowerCase() === "valid" ? true : false
      }
      localStorage.setItem('LastUpdatedTime', lastupdate)
      localStorage.setItem('isAccess', status)
  })
}

export function handleAccess (setAccess, token=null) {

  // headers: { Authorization: token},
  // const body = {
  //   method: 'get',
  //   url: getURL('license-key'),
  //   validateStatus: (status) =>  status <= 400
  // }

  // if (token !== null)
    // body.headers = { Authorization: token }

  // axios(body).then((res) => {
    // console.log('Result', res)
      let status = true
      let lastupdate = new Date()
      // if (res.status === 200 || res.status === 201) {
      //   if ('message' in res.data)
      //     status = String(res.data.message).toLocaleLowerCase() === "valid" ? true : false
      // }
      localStorage.setItem('LastUpdatedTime', lastupdate)
      localStorage.setItem('isAccess', true)
      setAccess(status)
      //window.location.replace('/')
  // })
}

export function isAccess () {
  if (String(localStorage.getItem('isAccess')) === 'true') {
    const precentTime = new Date ()
    const LastUpdatedTime = new Date (localStorage.getItem('LastUpdatedTime'))
    // console.log(((((precentTime-LastUpdatedTime)/1000)/60)/60))
    if (((((precentTime-LastUpdatedTime)/1000)/60)/60) > 24)
      getAccess()
    return true
  }
  return false
}

export function handleResponse(status) { 

  const isaccess = isAccess()
  // console.log('isaccess', isaccess, Boolean(localStorage.getItem('isAccess')))
  if (isaccess === false)
    return false

  if (status === 403) {
    localStorage.clear()
    window.location.reload();
  }
  return status >= 200
}


