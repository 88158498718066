import axios from '../axios'
import { getURL, jsonToFormData, jsonToParam } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';


// get locations
const getLocations = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/employee-shifts-info/location-names?status=1')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch(() => callback({status:400}))
}

// get cameras data
const addShift = (body, callback) => {
    axios({
        method: 'post',
        url: `${getURL('/shift-mon-timings/add')}`,
        data: jsonToFormData(body),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch(() => callback({status:400}))
}

const deleteShiftById = (body, callback) => {
    axios({
        method: 'put',
        url: `${getURL('/shift-mon-timings/delete')}`,
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch(() => callback({status:400}))
}

// get locations
const getAllShifts = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/shift-mon-timings?Status=1')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response)).catch(() => callback({status:400}))
}

export {getLocations, addShift, deleteShiftById, getAllShifts}