import { useState, useEffect } from 'react';
import axios from '../axios'
import { format } from 'date-fns';
import { handleResponse } from 'src/utils/responseHandler';
import { getURL } from 'src/utils/config';


const useDashboard = () => {

    const baseURL = '/dashboard'
    const RUN_FREQ_TIME = 60 //Sec
    const ANALYTICS_ICONS = {
        'person': 'mdi:biathlon',
        'vehicle': 'mdi:bus',
        'fire': 'mdi:fire',
        'smoke': "material-symbols:detector-smoke",
        'accident': 'maki:road-accident',
        'clapping': 'emojione-v1:clapping-hands',
        'cycling': "game-icons:cycling",
        'drinking': 'cil:drink',
        'sleepatwork': 'iconoir:sleeper-chair',
        'eating': 'fluent-mdl2:eat-drink',
        'listening_to_music': 'arcticons:google-music',
        'running': 'cil:running',
        'sitting': 'game-icons:sitting-dog',
        'texting': 'bi:chat-text',
        'using_laptop': 'emojione:laptop-computer',
        'animal': 'game-icons:elephant',
        'cops': 'fluent-emoji:man-police-officer',
        'delivery': 'emojione:delivery-truck',
        'spillage': 'openmoji:oil-spill',
        'firesmoke': 'emojione:fire',
        'social_distance': 'ic:sharp-social-distance',
        'sefty_jocket': 'game-icons:life-jacket',
        'helmet ': 'emojione:rescue-workers-helmet',
        'gloves': 'noto:gloves',
        'dark': 'arcticons:dark',
        'person': 'el:person',
        'vehicle': 'fluent:vehicle-cab-16-regular',
        'mob': 'fluent:people-audience-20-regular',
        'face_recognition': 'emojione:anguished-face',
        'attendence': 'charm:person',
        'vehicle_speed': 'fluent:top-speed-24-regular',
        'license_plate_recognition': 'bx:car',
        'smoking': 'cil:smoking-room'
    }

    const [alldata, seData] = useState({
        isGetData: true,
        response: [],
        data: []
    })

    const [graph, setGraph] = useState({        
        isGetData: true,
        data: {},
        total: 0
    })

    const jsonToParam = (data) => {
        return Object.keys(data).map(function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
        }).join('&')
    }


    useEffect(() => {        
        getAnalyticsCount()     
        getAnalyticsCountByDate()   
        const intervalId = setInterval(() => {
            isReloadData(true)
        }, 1000*RUN_FREQ_TIME)
        return () => clearInterval(intervalId)

    }, [alldata.isGetData])   


    const getAnalyticsCount = (fromdate=null, todate=null) => {
     
        if (!fromdate || !todate) {
            fromdate = format(new Date(), 'yyyy-MM-dd');
            todate = format(new Date(), 'yyyy-MM-dd');
        }    
            
        let bodyContent = jsonToParam({
            "fromdate": fromdate,
            "todate": todate
        });

        let url = `${baseURL}/audits?${bodyContent}`
        if (alldata.isGetData) {
            axios({
                method: 'get',
                url: getURL(url),
                validateStatus: (status) => handleResponse(status)
            }).then((response) => {
                // console.log(response.data)
                if (response.status === 200 || response.status === 201) {
                    seData({
                        ...alldata,
                        response: response.data,
                        data: response.data,
                        isGetData: false                        
                    })
                }
                // getAnalyticsCountByDate()
            })
        }
    }

    const getAnalyticsCountByDate = (fromdate=null, todate=null) => {
     
        if (!fromdate || !todate) {
            fromdate = format(new Date(), 'yyyy-MM-dd');
            todate = format(new Date(), 'yyyy-MM-dd');
        }    
            
        let bodyContent = jsonToParam({
            "fromdate": fromdate,
            "todate": todate
        });

        let url = `${baseURL}/audits-by-date?${bodyContent}`
        if (graph.isGetData) {
            axios({
                method: 'get',
                url: getURL(url),
                validateStatus: (status) => handleResponse(status)
            }).then((response) => {
                // console.log(manageDateWiseData(response.data))
                if (response.status === 200 || response.status === 201) {
                    const [ total, data ] = manageDateWiseData(response.data)
                    setGraph({
                        ...graph,
                        data: data,
                        isGetData: false,
                        total: total                     
                    })
                }
            })
        }
    }

    const jsonToDict = (res) => {
        let data = {}
        for (let a of res)
            data[parseInt(a.hour)] = a.count
        return data
    }

    const manageDateWiseData = (res) => {
        let total = 0
        let data = jsonToDict(res)
        const today = {}
        let last = 0
        let day = ''
        for (let n=3; n<=24; n+=3) {
            day = `${last}-${n}`
            today[day] = 0
            for (let x=last; x<n; x++)
                today[day] += x in data ? data[x]: 0
                total += parseInt(today[day])
            last = n
        }
        return [ total, today ]
    }

    const isReloadData = (status) => {
        seData({ ...alldata, isGetData: status })
        setGraph({ ...graph, isGetData: status })
    }

    const getIcon = (analytic) =>  analytic ? (analytic.toLowerCase() in ANALYTICS_ICONS ? ANALYTICS_ICONS[analytic.toLowerCase()]: 'ant-design:dot-chart-outlined') : 'ant-design:dot-chart-outlined'
    
    return {
        alldata,
        graph,
        getIcon,
        isReloadData
    }
}

export default useDashboard