import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import {InputLabel,Box} from '@mui/material';
import {
  CardContent,
  Card,
  Stack,
  Button,
  Typography,
  Grid,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,DialogTitle,DialogContent
} from '@mui/material';
import {IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { getClientContact , setClientContacts ,postClientContacts ,deleteClientContacts } from 'src/api/clientDetails';
import client from 'src/api/clients';
import { tr } from 'date-fns/locale';

const ClientContacts = () => {
  const [emails, setEmails] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [clientsContactDetails, setClientContactData] = useState([]);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedClient, setEditedClient] = useState(null);
  
  const [emailErrorMessage,setEmailErrorMessage] = useState(null);
  const [mobileErrorMessage,setMobileErrorMessage] = useState(null);

  const [emailEditError,setEmailEditError] = useState(null);
  const [mobileEditError,setMobileEditError] = useState(null);

  const [isDataEdited,setIsDataEdited]=useState(false)

  const [display,setDisplay]=useState(false)
  const [message,setMessage]=useState('')

  const [clientId,setClientId]=useState('')

  

  const handleDisplay = ()=>{
    setDisplay(false)
  }

  useEffect(() => {
    
    getClientContact((response) => {
        if(response.status === 200){
            // console.log("client contacts",response.data);
            setClientContactData(response.data)
        }
    })
  },[]);

//   const checkDuplicates = (email, number) => {
//     debugger
//     let isExisted = { email: false, number: false };


//     for (const item of clientsContactDetails) {
    
//       if(email && item.Email){
//         if (item.Email.toLowerCase() === email.toLowerCase()) {
//           isExisted.email = true;
//         }
//       }
//        if(number && item.MobileNo){
//         if (item.MobileNo === number) {
//           isExisted.number = true;
//         }
//        }
        
//     }

//     return isExisted;
// };

const checkDuplicates = (emails, contactNumbers) => {
  debugger
  let isExisted = { email: false, number: false };

  const emailArray = emails ? emails.split(',') : [];
  const contactNumberArray = contactNumbers ? contactNumbers.split(',') : [];

  emailLoop:
  for (const email of emailArray) {
      const trimmedEmail = email.trim(); // Trim whitespace

      for (const item of clientsContactDetails) {
        
        if(item.Email){
        const emailList = trimmedEmail.split(',');
        const itemList = item.Email.split(',');

        const result = emailList.some(email => itemList.includes(email.trim()));

        isExisted.email = result

        if(result){
          break emailLoop;
        }
      }
        // if (item.Email && item.Email.toLowerCase() === trimmedEmail.toLowerCase()) {
        //       isExisted.email = true;
        //       break;
        //   }
        //   else{
        //    if(!isDataEdited){
        //       let val = item.Email.split(',');
        //       if (val.length > 1) {
        //         val = val.includes(trimmedEmail) ? true : false;
        //       }            
        //       if (val) {
        //         isExisted.email = true;
        //       }
        //    }
        //   }

        
      
      }
  }

  numberLoop:
  for (const contactNumber of contactNumberArray) {
      const trimmedNumber = contactNumber.trim();      
      for (const item of clientsContactDetails) {
          
          if(item.MobileNo){
          const numList = trimmedNumber.split(',');
          const itemList = item.MobileNo.split(',');
  
          const result = numList.some(num => itemList.includes(num.trim()));
  
          isExisted.number = result
  
          if(result){
            break numberLoop;
          }
        }
      }
  }

  return isExisted;
};

  const handleSave = () => {
    debugger
    setEmailErrorMessage(null)
    setMobileErrorMessage(null)

    const moblePattern = new RegExp(/^\d{10}$/);
    let validMobile = true;
    let mobilesList=contactNumber.split(',')
    for (const mobile of mobilesList){
      if(!moblePattern.test(mobile)){
        validMobile=false;
        break;
      }
    }
    //validMobile=moblePattern.test(contactNumber)

    if (!emails && !contactNumber) {
      setEmailErrorMessage("Email is required");
      setMobileErrorMessage("Contact Number is required");
      return;
    }
    
    if(emails){
      const emailPattern =/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i; 
      
      let validEmail = true;
      let emailList=emails.split(',')
      for (const email of emailList){
        if(!emailPattern.test(email)){
          validEmail=false;
          break;
        }
        if(emailPattern.test(email)){
          const dotsAfterAt=validEmail?email.split('@')[1].split('.'):[]
          validEmail=dotsAfterAt.length>2?false:true
        }
      }

       if(!validEmail){
        setEmailErrorMessage("Enter Valid Email");
        if(!validMobile){
          setMobileErrorMessage("Enter Valid Contact Number");
        }
        return;
      }

      // let validEmail = emailPattern.test(emails)
       
      // const dotsAfterAt=validEmail?emails.split('@')[1].split('.'):[]
      // validEmail=dotsAfterAt.length>2?false:true
          
      // if(!validEmail){
      //   setEmailErrorMessage("Enter Valid Email");
      //   return;
      // }
    }
    
    if(contactNumber && !validMobile){
      setMobileErrorMessage("Enter Valid Contact Number");
      return;
    }

    let isExisted=checkDuplicates(emails,contactNumber)
    
    if(isExisted.email && isExisted.number){
      setEmailErrorMessage("Email is Already Existed");
      setMobileErrorMessage("Contact Number is Already Existed");
      return;
    }

    if(isExisted.email || isExisted.number){
      if(isExisted.email){
        setEmailErrorMessage("Email is Already Existed");
        return
      }
      if(isExisted.number){
        setMobileErrorMessage("Contact Number is Already Existed");
        return;
      }
    }


    const data = {
      ContactEmail : emails,
      ContactMobileNo : contactNumber
    }
    
    setClientContacts(data,(response)=>{
        if(response.status === 200){
            // console.log("posted Data ", response.data);
            handleClear()
            setIsDataEdited(false)
            getClientContact((response) => {
                if(response.status === 200){
                    // console.log("client contacts",response.data);
                    setClientContactData(response.data)
                }
            })
        }
    })

  };

  const handleClear = () => {
    //debugger
    setEmails('');
    setContactNumber('');
    setEmailErrorMessage(null);
    setMobileErrorMessage(null);
    setEditedMail('')
    setEditedNumber('')
  };

  const [editedmail,setEditedMail]=useState('')
  const [editedNumber,setEditedNumber]=useState('')

  const handleEdit = (client) => {
    setIsDataEdited(true)
    setEditedClient(client);
    setEditedMail(client.ContactEmail?client.ContactEmail:'')
    setEditedNumber(client.ContactMobileNo?client.ContactMobileNo:'')
    //setEditedMail()
    // setEmails(client.Email);
    // setContactNumber(client.MobileNo);
    
    setEditDialogOpen(true);

    // setEmptyBox(prevState => [
    //       {
    //         ...prevState[0], 
    //         number: true,
    //         email:true 
    //       },
    //       ...prevState.slice(1)
    //     ]);

  };
  
  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setEditedClient(null);
    setEmails('');
    setContactNumber('');
    setEmailErrorMessage(null);
    setMobileErrorMessage(null);
    setIsDataEdited(false)
    setEmailEditError(null)
    setMobileEditError(null)
  };

  const handleSaveEditDialog = () => {
     //setEmailEditError , setMobileEditError
   
  debugger
     // const moblePattern = new RegExp(/^\d{10}$/);
    // const validMobile = moblePattern.test(contactNumber)

        const moblePattern = new RegExp(/^\d{10}$/);
        let validMobile = true;
        let mobilesList=editedNumber.split(',')
        for (const mobile of mobilesList){
          if(!moblePattern.test(mobile)){
            validMobile=false;
            break;
          }
        }


    if (!editedmail && !editedNumber) {
      setEmailEditError("Email is required");
      setMobileEditError("Contact Number is required");
      return;
    }
    
    if(editedmail){
      // const emailPattern =/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i; 
      // let validEmail = emailPattern.test(emails)
       
      // const dotsAfterAt=validEmail?emails.split('@')[1].split('.'):[]
      // validEmail=dotsAfterAt.length>2?false:true
          
      // if(!validEmail){
      //   setEmailEditError("Enter Valid Email");
      //   return;
      // }
      const emailPattern =/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i; 
      
      let validEmail = true;
      let emailList=editedmail.split(',')
      for (const email of emailList){
        if(!emailPattern.test(email)){
          validEmail=false;
          break;
        }
        if(emailPattern.test(email)){
          const dotsAfterAt=validEmail?email.split('@')[1].split('.'):[]
          validEmail=dotsAfterAt.length>2?false:true
        }
      }

       if(!validEmail){
        setEmailEditError("Enter Valid Email");
        if(!validMobile){
          setMobileEditError("Enter Valid Contact Number");
        }
        return;
      }

    }
   
    if(editedNumber && !validMobile){
      setMobileEditError("Enter Valid Contact Number");
      return;
    }
      
    let updatedemail='',updatednumber=''
    if (editedmail !== editedClient.Email || editedNumber !== editedClient.MobileNo) {

      if(editedmail && editedClient.Email){
          
          const listOne=editedmail.split(',')
          const listTwo=editedClient.Email.split(',')
          
          const filtered=listOne.filter(item=>!listTwo.some(value=>item===value))

          updatedemail=filtered.join(',')
      }

      else{
        updatedemail=editedmail
      }

      if(editedNumber && editedClient.MobileNo){
        
        const listOne=editedNumber.split(',')
        const listTwo=editedClient.MobileNo.split(',')
        
        const filtered=listOne.filter(item=>!listTwo.some(value=>item===value))

        updatednumber=filtered.join(',')
      }

      else{
        updatednumber=editedNumber
      }

      const isExisted = checkDuplicates(updatedemail, updatednumber);

      if (isExisted.email && isExisted.number && (editedmail !== editedClient.Email && editedNumber !== editedClient.MobileNo)) {
        setEmailEditError("Email is Already Existed");
        setMobileEditError("Contact Number is Already Existed");
        return;
      }

      if (isExisted.email && editedmail !== editedClient.Email) {
        setEmailEditError("Email is Already Existed");
        return;
      }

      if (isExisted.number && editedNumber !== editedClient.MobileNo) {
        setMobileEditError("Contact Number is Already Existed");
        return;
      }
    }
    

    // if (editedmail && editedClient.Email || editedNumber && editedClient.MobileNo) {
    //   debugger
    //   const isExisted = checkDuplicates(editedmail, editedNumber);
    //   console.log(editedmail,editedClient)
    //   console.log(isExisted)
    //   // if (isExisted.email && isExisted.number && (emails !== editedClient.Email && contactNumber !== editedClient.MobileNo)) {
    //   //   setEmailEditError("Email is Already Existed");
    //   //   setMobileEditError("Contact Number is Already Existed");
    //   //   return;
    //   // }

    //   // if (isExisted.email && emails !== editedClient.Email) {
    //   //   setEmailEditError("Email is Already Existed");
    //   //   return;
    //   // }

    //   // if (isExisted.number && contactNumber !== editedClient.MobileNo) {
    //   //   setMobileEditError("Contact Number is Already Existed");
    //   //   return;
    //   // }
    
    // }
    
    const updatedClient = {
      Id: editedClient.Id,
      ContactEmail: editedmail?editedmail:'',
      ContactMobileNo: editedNumber?editedNumber:'',
    };
   
    postClientContacts(updatedClient, (response) => {
      if (response.status === 200) {
        getClientContact((response) => {
            if(response.status === 200){
                // console.log("client contacts",response.data);
                setClientContactData(response.data)
                setEmailEditError(null)
                setMobileEditError(null)
            }
        })
      }
    });

    handleCloseEditDialog();
  };
  
  
  const handleDelete = (Id) => {
    setDisplay(true) 
    setClientId(Id)
  };

  const delContact = ()=>{
      
    // console.log("deleted ID", clientId);

    const data = {
      Id : clientId
    }
    deleteClientContacts(data,(response) => {
        if(response.status === 200){
            // console.log("client contacts",response.data);
            getClientContact((response) => {
                if(response.status === 200){
                    // console.log("client contacts",response.data);
                    setClientContactData(response.data)
                }
            })
        }
        setDisplay(false)
    })
  }

  const [addEmptyBox,setEmptyBox]=useState([
    {
      email:false,
      number:false
      // closemail:false,
      // closemobile:false
    }
  ])


  const handleBoxClick = (type)=>{

      if(type==='email'){
          
        if(!isDataEdited){
          if(!emails){
            setEmptyBox(prevState => [
              {
                ...prevState[0], 
                email: true 
              },
              ...prevState.slice(1)
            ]);
          }
          else if(emails){
            setEmptyBox(prevState => [
              {
                ...prevState[0], 
                email: false 
              },
              ...prevState.slice(1)
            ]);

            setEmails(emails + ',')
          }
        }
       else{
        setEditedMail(editedmail + ',')
       }   
      
      }
      else if(type==='number'){

        if(!isDataEdited){
          if(!contactNumber){
            setEmptyBox(prevState => [
              {
                ...prevState[0], 
                number: true 
              },
              ...prevState.slice(1)
            ]);
          }
          else if(contactNumber){
            setEmptyBox(prevState => [
              {
                ...prevState[0], 
                number: false 
              },
              ...prevState.slice(1)
            ]);

            setContactNumber(contactNumber + ',')
          }
      }
      else{
        setEditedNumber(editedNumber + ',')
      }
    }
  }

  // const [closeEmail,setCloseEmail]=useState(false)
  // const [closeNumber,setCloseNumber]=useState(false)

  const removeNumber = (e,index,type) => {
    e.stopPropagation()
   if(type==='email'){
    if(!isDataEdited){
      const numbersArray = emails.split(',');
      numbersArray.splice(index, 1); 
      const updatedNumbers = numbersArray.join(',');
      setEmails(updatedNumbers);
    }
    else{
      const numbersArray = editedmail.split(',');
      numbersArray.splice(index, 1); 
      const updatedNumbers = numbersArray.join(',');
      setEditedMail(updatedNumbers);
    }
  }
  else if(type==='number'){

    if(!isDataEdited){
      const numbersArray = contactNumber.split(',');
      numbersArray.splice(index, 1); 
      const updatedNumbers = numbersArray.join(',');
      setContactNumber(updatedNumbers);
    }
    else{
      const numbersArray = editedNumber.split(',');
      numbersArray.splice(index, 1); 
      const updatedNumbers = numbersArray.join(',');
      setEditedNumber(updatedNumbers);
    }
    
  }
  };

  const UpdateEmails = (e) =>{
    setEmails(e.target.value)
  }

  const handleNumberChange = (event, index,type) => {
 
    if(type==='email'){

      if(!isDataEdited){
        const numbersArray = emails.split(',');
        numbersArray[index] = event.target.value;
        const updatedNumbers = numbersArray.join(',');
        setEmails(updatedNumbers);
      }
      else{
        const numbersArray = editedmail.split(',');
        numbersArray[index] = event.target.value;
        const updatedNumbers = numbersArray.join(',');
        setEditedMail(updatedNumbers);
      }
    }
    else if(type==='number'){
      
      if(!isDataEdited){
        let value=event.target.value;
        value=value.replace(/\D/g, '')
        value=value.slice(0,10)
  
        const numbersArray = contactNumber.split(',');
        numbersArray[index] = value;
        const updatedNumbers = numbersArray.join(',');
        setContactNumber(updatedNumbers);
      }
      else{
        let value=event.target.value;
        value=value.replace(/\D/g, '')
        value=value.slice(0,10)
  
        const numbersArray = editedNumber.split(',');
        numbersArray[index] = value;
        const updatedNumbers = numbersArray.join(',');
        setEditedNumber(updatedNumbers);
      }
    }
  };
// console.log("clientsContactDetails",clientsContactDetails.length)
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Contact Details
        </Typography>
      </Stack>

    {clientsContactDetails.length === 0 &&
      <Card style={{ marginBottom: '5px' }}>
        <CardContent style={{ marginBottom: '-16px' }}>
          <Grid container spacing={2}>
            <Grid item md={3}>             
              {/* <TextField
                fullWidth
                label="Email"
                value={!isDataEdited?emails:''}
                onChange={(e) => setEmails(e.target.value)}
              /> */}
                    <InputLabel shrink>
                  Email
                    </InputLabel>
                    <Box
                      sx={{
                        border: '1px solid #b4b4b4',
                        padding: '8px',
                        borderRadius: '8px',
                        minHeight: emails ? 'auto' : '50px', 
                      }}
                      onClick={() => handleBoxClick('email')}
                    >
                    {addEmptyBox[0].email && !emails && (
                      <Box
                      sx={{
                        marginBottom: 1,
                        width:'70%',
                        backgroundColor:'#E8E8E8',
                        position:'relative',
                        borderRadius: '100px',
                        border:'none',
                        borderColor:'none'
                      }}                   
                    >
                          <TextField
                            onClick={(e)=>{
                              e.stopPropagation()
                            }}
                            placeholder='Add Email'
                            onChange={(e) => UpdateEmails(e)}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                border:'none'
                              },
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <IconButton
                              onClick={(e) => removeNumber(e,0,'email')}                            
                            >
                            <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                          </IconButton>
                         </Box>                   
                    </Box>
                    )} 

                    {!addEmptyBox[0].email && emails.split(',').map((email,index)=> (
                      
                      <Box
                      key={index}
                      sx={{
                        marginBottom: 1,
                        width:'70%',
                        backgroundColor:'#E8E8E8',
                        position:'relative',
                        borderRadius: '100px',
                        border:'none',
                        borderColor:'none'
                      }}                   
                    >
                          <TextField
                            //className={classes.root}
                            placeholder='Add Email'
                            value={email}
                            onClick={(e)=>{
                              e.stopPropagation()
                            }}
                            onChange={(e) => handleNumberChange(e, index,'email')}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                //borderRadius: '90px',
                                border:'none'
                              },
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <IconButton
                              onClick={(e) => removeNumber(e,index,'email')}                            
                            >
                            <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                          </IconButton>
                         </Box>                   
                    </Box>
                    
                    ))} 

                    </Box>
                {emailErrorMessage && <Typography color="error" variant="body2" align="left" marginTop={1}>{emailErrorMessage}</Typography>}
            </Grid>
            <Grid item md={3}>
              
              {/* <TextField
                fullWidth
                type='number'
                label="Contact Number"
                value={!isDataEdited?contactNumber:''}
                onChange={(e) => setContactNumber(e.target.value)}
              /> */}

                  <InputLabel shrink>
                  Contact Number
                    </InputLabel>
                    <Box
                      sx={{
                        border: '1px solid #b4b4b4',
                        padding: '8px',
                        borderRadius: '8px',
                        minHeight: contactNumber ? 'auto' : '50px', 
                      }}
                      onClick={() => handleBoxClick('number')}
                    >
                    {addEmptyBox[0].number && !contactNumber && (
                      <Box
                      sx={{
                        marginBottom: 1,
                        width:'70%',
                        backgroundColor:'#E8E8E8',
                        position:'relative',
                        borderRadius: '100px',
                        border:'none',
                        borderColor:'none'
                      }}                   
                    >
                          <TextField
                            onClick={(e)=>{
                              e.stopPropagation()
                            }}
                            type='number'
                            placeholder='Add Contact Number'
                            onChange={(e) => setContactNumber(e.target.value)}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                border:'none'
                              },
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <IconButton
                              onClick={(e) => removeNumber(e,0,'number')}                            
                            >
                            <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                          </IconButton>
                         </Box>                   
                    </Box>
                    )} 

                    {!addEmptyBox[0].number && contactNumber.split(',').map((number,index)=> (
                      
                      <Box
                      key={index}
                      sx={{
                        marginBottom: 1,
                        width:'70%',
                        backgroundColor:'#E8E8E8',
                        position:'relative',
                        borderRadius: '100px',
                        border:'none',
                        borderColor:'none'
                      }}                   
                    >
                          <TextField
                            //className={classes.root}
                            placeholder='Add Contact Number'
                            type='number'
                            value={number}
                            onClick={(e)=>{
                              e.stopPropagation()
                            }}
                            onChange={(e) => handleNumberChange(e, index,'number')}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                //borderRadius: '90px',
                                border:'none'
                              },
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <IconButton
                              onClick={(e) => removeNumber(e,index,'number')}                            
                            >
                            <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                          </IconButton>
                         </Box>                   
                    </Box>
                    
                    ))} 

                    </Box>
              
              {mobileErrorMessage && <Typography color="error" variant="body2" align="left" marginTop={1}>{mobileErrorMessage}</Typography>}
            </Grid>
            <Grid item>
                <LoadingButton type="submit" variant="contained" onClick={handleSave} style={{ marginBottom: '-70px' }}>
                Save
              </LoadingButton>
            </Grid>

            <Grid item>
              <Button color="error" variant="contained" onClick={handleClear} style={{ marginBottom: '-70px' }}>
                Clear
              </Button>
            </Grid>


          </Grid>
        </CardContent>
        {/* <DialogActions>
          <Button color="error" variant="outlined" onClick={handleClear}>
            Clear
          </Button>
          <LoadingButton type="submit" variant="outlined" onClick={handleSave}>
            Save
          </LoadingButton>
        </DialogActions> */}
   
      </Card>
    }
      <Card>
        <CardContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  {/* <TableCell>Client Name</TableCell> */}
                  <TableCell>Email</TableCell>
                  <TableCell>Contact Number</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientsContactDetails.map((client, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    {/* <TableCell>{client.Name}</TableCell> */}
                    <TableCell>{client.ContactEmail}</TableCell>
                    <TableCell>{client.ContactMobileNo}</TableCell>
                    <TableCell>
                      <Button type="submit" variant="outlined" onClick={() => handleEdit(client)}>Edit</Button>
                      <Button color="error" variant="outlined" onClick={() => handleDelete(client.Id)} sx={{marginLeft:1}}>Delete</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Contact</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{marginTop:1}}>
            <Grid item xs={18}>


              {/* <TextField
                fullWidth
                label="Email"
                value={editedmail}
                onChange={(e) => setEditedMail(e.target.value)}
              /> */}
              
              <InputLabel shrink>
                  Email
                    </InputLabel>
                   <Box
                      sx={{
                        border: '1px solid #b4b4b4',
                        padding: '8px',
                        borderRadius: '8px',
                        minHeight: editedmail ? 'auto' : '50px', 
                      }}
                      onClick={() => handleBoxClick('email')}
                    >
                  {editedmail.split(',').map((email,index)=> (                     
                      <Box
                      key={index}
                      sx={{
                        marginBottom: 1,
                        width:'40%',
                        backgroundColor:'#E8E8E8',
                        position:'relative',
                        borderRadius: '100px',
                        border:'none',
                        borderColor:'none'
                      }}                   
                    >
                          <TextField
                            //className={classes.root}
                            placeholder='Add Email'
                            //type='number'
                            value={email}
                            onClick={(e)=>{
                              e.stopPropagation()
                            }}
                            onChange={(e) => handleNumberChange(e, index,'email')}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                //borderRadius: '90px',
                                border:'none'
                              },
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <IconButton
                              onClick={(e) => removeNumber(e,index,'email')}                            
                            >
                            <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                          </IconButton>
                         </Box>                   
                    </Box>
                    
                    ))} 

                </Box>


              {emailEditError && <Typography color="error" variant="body2" align="left" marginTop={1}>{emailEditError}</Typography>}
            </Grid>
            <Grid item xs={18}>

              
              
              {/* <TextField
                fullWidth
                type='number'
                label="Contact Number"
                value={editedNumber}
                onChange={(e) => setEditedNumber(e.target.value)}
              /> */}
                 <InputLabel shrink>
                  Contact Number
                    </InputLabel>
                   <Box
                      sx={{
                        border: '1px solid #b4b4b4',
                        padding: '8px',
                        borderRadius: '8px',
                        minHeight: contactNumber ? 'auto' : '50px', 
                      }}
                      onClick={() => handleBoxClick('number')}
                    >
                  {editedNumber.split(',').map((number,index)=> (                     
                      <Box
                      key={index}
                      sx={{
                        marginBottom: 1,
                        width:'40%',
                        backgroundColor:'#E8E8E8',
                        position:'relative',
                        borderRadius: '100px',
                        border:'none',
                        borderColor:'none'
                      }}                   
                    >
                          <TextField
                            //className={classes.root}
                            placeholder='Add Contact Number'
                            type='number'
                            value={number}
                            onClick={(e)=>{
                              e.stopPropagation()
                            }}
                            onChange={(e) => handleNumberChange(e, index,'number')}
                            fullWidth
                            size="small"
                            sx={{
                              '& input': {
                                height: '14px', 
                                fontSize: '14px',
                              },
                              '& fieldset': {
                                //borderRadius: '90px',
                                border:'none'
                              },
                            }}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '50%',
                              right: '8px',
                              transform: 'translateY(-50%)',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: '#B2BEB5',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <IconButton
                              onClick={(e) => removeNumber(e,index,'number')}                            
                            >
                            <CloseIcon fontSize="small" style={{ fontSize: '14px' }}/>
                          </IconButton>
                         </Box>                   
                    </Box>
                    
                    ))} 

                </Box>

              {mobileEditError && <Typography color="error" variant="body2" align="left" marginTop={1}>{mobileEditError}</Typography>}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" onClick={handleCloseEditDialog}>Cancel</Button>
          <Button onClick={handleSaveEditDialog} color="primary" variant="outlined">Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={display} onClose={handleDisplay}>
        <DialogContent>
        Do You Want to Delete Contact?
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>
          {
            setDisplay(false)
            setClientId('')
          }} color="error" variant="outlined">
              Cancel
          </Button>
          <Button onClick={delContact} color="primary" variant="outlined">Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClientContacts;

// while delete button clicked 