import axios from '../axios'
import { getURL, jsonToParam ,jsonToFormData} from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';

// get cameras data
const getDevices = (body, callback) => {
    axios({
        method: 'get',
        url: `${getURL('devices')}?${jsonToParam(body)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const putExistingDevice = (callback, body) => {
    //console.log(callback)
    axios({
        method: 'put',
        url: getURL('/devices/update'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const setNewDevice = (callback, body) => {
    axios({
        method: 'post',
        url: getURL('/devices/add'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


const getUpdateCategory = (callback, body) => {
    axios({
        method: 'put',
        url: getURL('/devices/update-category'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const updateStatus = (body,callback) => {
    axios({
        method: 'put',
        url: getURL('/devices/update-status'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const setCamlytics = (body,callback) => {
    axios({
        method: 'post',
        url: getURL('/devices/add-camlytics-camera-settings'),
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}



export { getDevices ,putExistingDevice ,setNewDevice ,getUpdateCategory,updateStatus ,setCamlytics }