import {
  Container,
  Typography,
  Grid,
  Stack,
  Button,
  Drawer
} from '@mui/material';

// components
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import { AddEditForm, DetailsBox } from 'src/sections/servers';
import { getServers } from 'src/api/servers';


// ----------------------------------------------------------------------

const anchor = 'right'

export default function Servers() {


  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const [ data, setData ] = useState([])
  const [reloadServer,setReloadServer] = useState(false)

  const loadServersList = ()=>{
  //  debugger
    setReloadServer(!reloadServer)
  }

  useEffect(() => {
  //  debugger
    getServers((response) => {
      if (response.status === 200) {
        setData(response.data);
      }
    })
  }, [reloadServer])


  const toggleDrawer = (anchor, open) => (event) => {
   // console.log('toggleDrawer',event);
   //debugger
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };


  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">


        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          <AddEditForm  onClose={toggleDrawer} Message='Add Server' Action='Add' loadServersList={loadServersList}/>
        </Drawer>


        <Stack 
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pb: 5}}
        >
          <Typography variant='h4'>Servers</Typography>
          <Button variant='contained' onClick={toggleDrawer(anchor, true)}>Add Server</Button>
        </Stack>

        <Grid container spacing={3}>
          {data.map((item, idx) => (
            <Grid item md={3} key={idx}>
              <DetailsBox row={item} loadServersList={loadServersList}/>
            </Grid>    
          ))} 
        </Grid>

      </Container>
    </Page>
  );
}