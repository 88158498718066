import axios from 'src/axios'
import { getURL, jsonToParam ,jsonToFormData} from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';


const getDeviceStats = (body ,callback) => {
    const { fromdate, todate ,month , year , group_id } = body; 
    axios({
        method: 'get',
        url: `${getURL('/dashboard/audits-by-month')}?fromdate=${fromdate}&todate=${todate}&month=${month}&year=${year}&group_id=${group_id}`,
        // url: `${getURL('/dashboard/audits-by-month')}?${jsonToParam(params)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

// http://service.vdointel.ai:9008/timezone?status=1

const getTimeZoneTimes = (callback) => {
    // const { fromdate, todate ,month , year , group_id } = body; 
    axios({
        method: 'get',
        url: `${getURL('/timezone')}?status=1`,
        // url: `${getURL('/dashboard/audits-by-month')}?${jsonToParam(params)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}






export { getDeviceStats ,getTimeZoneTimes} 