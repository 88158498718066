import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Switch, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { LoadingButton } from '@mui/lab';

export default function ShiftTimeAdd({ isOpen, onClose}) {
       
      return (
        <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Add Shift Time</DialogTitle>
        <DialogContent>
  
        <Autocomplete
            
           
            renderInput={(params) => (
              <TextField
                {...params}
                label="CameraId"
                fullWidth
                margin="normal"
              />
            )}
          />
  
          <TextField
            label="ProcessFps"
      
            fullWidth
            margin="normal"
          />
         <TextField
            label="ProcessFps"
      
            fullWidth
            margin="normal"
          />
          <Box>
            <Typography variant='subtitle2'>Active state</Typography>
            <Typography>Disabling this will automatically set to InActive</Typography>
          </Box>
          <Switch
            color="success"
       
          />
        </DialogContent>
        <DialogActions>
          <Button color='error' variant='outlined' onClick={onClose}>
            Close
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"

          >
            Add Shift Time
          </LoadingButton>
        </DialogActions>
      </Dialog>
      );
}
