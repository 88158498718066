import {
  Container,
  Stack,
  Typography,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
  Toolbar,
  Box,
  Card,
  Autocomplete,
  TextField,
  Chip,
  Button,
} from '@mui/material';
import {
  
  Table,
 
 
  TableRow,
  TableBody,
  TableCell,
TableFooter,
  TableContainer,
  TablePagination,
  Link,
} from '@mui/material';
// components
import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';

import Page from 'src/components/Page';
// mock
// import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import * as XLSX from 'xlsx';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//   import { UserListHead  } from '../../src/sections/@dashboard/user';
import { UserListHead } from '../productivity/table';

import IconButton from '@mui/material/IconButton';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// import Loader from 'react-loader-spinner';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import axios from "axios";

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ClearIcon from '@mui/icons-material/Clear';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grade } from '@mui/icons-material';
import { Grid } from 'react-loader-spinner';
import { getURL } from 'src/utils/config';
import { getMohiniReport } from 'src/api/ShiftMaster';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useRef } from 'react';



export default function MohiniReports() {
  const [selectedLocation, setSelectedLocation] = useState([]); // Initial value set to null
  const [selectedLocationId, setSelectedLocationId] = useState(null); // New state to stor
  const [apiData, setApiData] = useState(null); // New state for storing API data
  const [selectedShiftTypes, setSelectedShiftTypes] = useState([]); // Initialize as an empty array
  const [locationNames, setLocationNames] = useState([]); // Initialize as an empty array
  //const [response,setResponse] = useState([])

  const [shiftTypesNames, setShiftTypesNames] = useState([]); // Initialize as an empty array
  // const [fromTime, setFromTime] = useState(null);
  // const [toTime, setToTime] = useState(null);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [responseData, setResponseData] = useState([]);

  const [selectedGetLocation, setSelectedGetLocation] = useState([]); // Initial value set to null
  const [selectedShift, setSelectedGetShift] = useState([]); // New state to stor

  // const [locationError, setLocationError] = useState(false);
  const [displayDate,setDisplayDate] = useState(null);

  const [openVideoPopup, setOpenVideoPopup] = useState(false);
  //const [videoUrl, setVideoUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [openList,setOpenList] = useState(false)
  const [dailogTitle,setDailogTitle] = useState('');
  // Add error states for each field
const [locationError, setLocationError] = useState(false);
const [shiftTypeError, setShiftTypeError] = useState(false);
const [fromDateError, setFromDateError] = useState(false);
// Add more error states if needed
const [setEntryvideo, setEntryvideoURL] = useState(null); // New state to stor
const [setExitvideo, setExitvideoURL] = useState(null); // New state to stor
const [loading, setLoading] = useState(false);
const [dateValidate,setValidate] = useState(false);
const [totalEntryCount, setTotalEntryCount] = useState(0);
const [totalExitCount, setTotalExitCount] = useState(0);
const [entryExitDifference, setEntryExitDifference] = useState(0);
const [errorMessage, setErrorMessage] = useState(false);

const [selectedSpeed, setSelectedSpeed] = useState(1); // Initial playback speed
const [videoList,setVideoList] = useState(false)
const [videoListData,setVideoListData] = useState('')
const videoRef = React.createRef();

let rowSpanApplied = false;
let rowSpanAppliedExit =false;


  const handleFromDateChange = (date) => {
    setFromDate(date);
  };




function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) {
      return obj; // If obj is not an object, return it as is
  }
  let copy = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
          copy[key] = deepCopy(obj[key]); // Recursively copy nested objects/arrays
      }
  }
  return copy;
}


useEffect(()=>{
  debugger
  console.log(videoUrl)
},[videoUrl])

const mergeRows = (data) => {
  const merges = [];
  data.forEach((row, index) => {
    Object.keys(row).forEach((key, col) => {
      if (typeof (row[key]) === "object" && row[key].hasOwnProperty("rowSpam") && row[key]["rowSpam"]) {
      //   console.log(index, col, index + (parseInt(row[key]["rowSpam"])))
        merges.push({
          s: { r: index + 1, c: col },
          e: { r: index + (parseInt(row[key]["rowSpam"])), c: col },
        });
      }
    });
  });
  return merges;
};



const exportToExcel = () => {
  if (!responseData || responseData.length === 0) {
    alert('No data to Export.');
    return;
  }

  console.log("responseData", responseData);
  const sheetName = selectedGetLocation + '_' + selectedShift;
  const headers = ['S.No', 'Date', 'Screen Name', 'Show Name'];

  const formattedHeaders = headers.map(header =>
    header
      .replace(/_/g, ' ')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase())
  );

  const dataItem = responseData[0];

  const formattedData = [{
    'S.No': '1',
    'Date': displayDate,
    'Screen Name': selectedGetLocation,
    'Show Name': selectedShift,
    'CoolDrink Count': dataItem.cool_drink_count,
    'PopCorn Count': dataItem.pop_corn_count,
    'CoolDrink Video': dataItem.cool_drink_video_url,
    'PopCorn Video': dataItem.pop_corn_video_url
  }];

  const ws = XLSX.utils.json_to_sheet(formattedData, {
    header: formattedHeaders,
    cellStyles: true,
  });

  const filename = sheetName + '.xlsx';
  const maxColumnWidths = {};
  headers.forEach(header => {
    maxColumnWidths[header] = Math.max(
      20,
      ...formattedData.map(row => (row[header] || '').toString().length)
    );
  });

  const columnWidths = headers.map(header => ({
    wch: maxColumnWidths[header],
  }));
  ws['!cols'] = columnWidths;

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName || 'Sheet 1');
  XLSX.writeFile(wb, filename);
};


const handleButtonClick = (exportType) => {
  // Set button label and color based on export type
  switch (exportType) {
    case 'excel':
    
      exportToExcel();
      break;
    default:
      break;
  }
};

useEffect(() => {
  if (Array.isArray(responseData) && responseData.length > 0) {
    const newTotalEntryCount = responseData.reduce((sum, entry) => sum + entry.entry, 0);
    const newTotalExitCount = responseData.reduce((sum, entry) => sum + entry.exit, 0);
    const newEntryExitDifference = newTotalEntryCount - newTotalExitCount;

    // Update state values
    setTotalEntryCount(newTotalEntryCount);
    setTotalExitCount(newTotalExitCount);
    setEntryExitDifference(newEntryExitDifference);
  }
}, [responseData]);

  useEffect(() => {
    const fetchData = async () => {
      
      const apiUrl = getURL("employee-shifts-info/location-names?status=1");
      const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
      
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: AUTH_TOKEN,
          },
        });

        const extractedLocationNames = response.data.map(item => item.LocationName);
        setLocationNames(extractedLocationNames);
       
        setApiData(response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };
 

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedLocationId !== null ) {
      //   console.log("selected ID",selectedLocationId);
        const dynamicUrl = getURL(`shift-mon-timings?Status=1&LocationId=${selectedLocationId}`)
        const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
  
        try {
          const response = await axios.get(dynamicUrl, {
            headers: {
              Authorization: AUTH_TOKEN,
            },
          });

          //console.log("data for selected Location ",response.data)
          const extractedShiftTypes = response.data.map(item => ({
            ID: item.Id,
            ShiftName: item.ShiftName,
            EntryFromTime: item.EntryFromTime, // Add the actual property name from your API response
            EntryToTime: item.EntryToTime, 
            ExitFromTime: item.ExitFromTime, // Add the actual property name from your API response
            ExitToTime: item.ExitToTime     // Add the actual property name from your API response
          }));
          // const extractedShiftTypes = response.data.map(item => item.ShiftName);
          setShiftTypesNames(extractedShiftTypes);
          // console.log("location and shifts",extractedShiftTypes)
          // console.log("location and shifts",extractedShiftTypes.toTime)

          setApiData(prevData => {
            // Make sure to merge previous data with the new data
            const newData = [...prevData, ...response.data];
            return newData;
          });

          // setApiData(response.data);  // Update apiData after setShiftTypes
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };
  
    // Use data from the first useEffect
    fetchData();
  }, [selectedLocationId]);
  

  const handlePlay = (url,value) => {
    setVideoUrl(url);
    setDailogTitle(value)
    // console.log(value)
    setOpenVideoPopup(true);
    setSelectedSpeed(1);
  };

  const handleCloseVideoPopup = () => {
   // debugger
    setOpenVideoPopup(false);
    setVideoUrl([]);
    setSelectedSpeed(1);
    setVideoList(false)
  };

  const handleVideoEnded = () => {
    //debugger
    if (currentVideoIndex < videoUrl.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
      setSelectedSpeed(selectedSpeed)
    }
  }

  const handleVideoLoaded = () => {
    if (videoRef.current) {
      videoRef.current.playbackRate = selectedSpeed;
    }
  };

         

  const handleShiftType = (event, value) => {
   // debugger
  //   console.log("shift", value);
    setSelectedShiftTypes(value);    
  
    // const selectedShiftData = apiData.find(item => item.ShiftName === value);
    // console.log("selectedShiftData",selectedShiftData);

    // if (selectedShiftData) {
    //   const selectedShiftFromTime = selectedShiftData.FromTime;
    //   const selectedShiftToTime = selectedShiftData.ToTime;
    // } else {
    //   console.error('Selected shift not found in data.');
    // }

  };


  const handleDetectedType = (event, value) => {
    
    setSelectedLocation(value);
    setSelectedShiftTypes('');
    setFromDate(null);
    setToDate(null)

  const selectedLocationObject = apiData.find((location) => location.LocationName === value);
    setSelectedLocationId(selectedLocationObject ? selectedLocationObject.Id : null);

  };

  // useEffect(()=>{
  // console.log(videoUrl)
  // },[videoUrl])

  useEffect(()=>{
    console.log(responseData)
  },[responseData])

  // const videoRef = useRef(null); 
  // useEffect(() => {
  //   if (videoRef.current) {
  //     videoRef.current.src = videoListData; 
  //    // videoRef.current.load();
  //     //videoRef.current.play();
  //   }
  // }, [videoListData]);

  const handleSubmit = () => {
    debugger
    setLoading(true);
    setResponseData([]);
    setLocationError(false);
    setShiftTypeError(false);
    setFromDateError(false);
    setExitvideoURL('');
    setEntryvideoURL('');
    setTotalEntryCount(0);
    setTotalExitCount(0);
    setEntryExitDifference(0);
    if (selectedLocation.length === 0 || selectedShiftTypes.length === 0 || fromDate === null) {
      setLoading(false);
      setErrorMessage(true);
      return;
    }

    setErrorMessage(false);

    setSelectedGetLocation(selectedLocation);
    const combinedFromDateTime =  dayjs(fromDate).format('YYYY-MM-DD');
    const displayDateFormate =  dayjs(fromDate).format('MM-DD-YYYY');

    const currentSystemTime = dayjs();

    const fromDateTime = dayjs(combinedFromDateTime);

    const isCombinedDateTimeGreaterThanSystemTime = fromDateTime.isAfter(currentSystemTime);

    const fromTime = selectedShiftTypes.FromTime;

    const currentDate = currentSystemTime.format('YYYY-MM-DD');

    const combinedDateTime = dayjs(currentDate + ' ' + fromTime);

    if ((combinedDateTime.isAfter(currentSystemTime) ) && (fromDateTime.format('YYYY-MM-DD') === currentSystemTime.format('YYYY-MM-DD'))){
      setValidate(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } else if (isCombinedDateTimeGreaterThanSystemTime) {
      setValidate(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } 
    else {
      setValidate(false);
      setDisplayDate(displayDateFormate);

   // console.log("Selected Shift",selectedShiftTypes)
    
    let data = {'show_id':selectedShiftTypes.ID,'screen_id':selectedLocationId,'date':combinedFromDateTime}
    getMohiniReport(data,(response)=>{
     // debugger
     // console.log(response)
      if(response.status===200){
        setLoading(false);
        if(response.data.length>0){
          console.log(response.data)           
          setResponseData(response.data)             
        }
        // else{
        //   setResponseData([
        //     {
        //       "devicename": "Cool Drink Screen2",
        //       "count": 0,
        //       "VideoUrl": [
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9503_Cool_Drink_Screen2/9503_2024_09_04_12_56_56_359030.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9503_Cool_Drink_Screen2/9503_2024_09_04_12_40_00_111535.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9503_Cool_Drink_Screen2/9503_2024_09_04_13_02_37_476258.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_12_40_00_110276.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_12_56_56_189134.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_13_02_38_220862.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_12_40_00_110276.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_12_56_56_189134.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_13_02_38_220862.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_12_40_00_110276.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_12_56_56_189134.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_13_02_38_220862.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_12_40_00_110276.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_12_56_56_189134.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_13_02_38_220862.mp4"
        //         }
        //       ]
        //     },
        //     {
        //       "devicename": "Flavoured_Pop_Corn_sc2",
        //       "count": 0,
        //       "VideoUrl": [
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9501_Flavoured_Pop_Corn_sc2/9501_2024_09_04_12_40_00_111360.mp4"
        //         }
        //       ]
        //     },
        //     {
        //       "devicename": "French_Fries_sc2",
        //       "count": 0,
        //       "VideoUrl": [
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_12_40_00_110276.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_12_56_56_189134.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9498_French_Fries_sc2/9498_2024_09_04_13_02_38_220862.mp4"
        //         }
        //       ]
        //     },
        //     {
        //       "devicename": "Salted_Pop_Corn_sc2",
        //       "count": 0,
        //       "VideoUrl": [
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9500_Salted_Pop_Corn_sc2/9500_2024_09_04_12_56_56_262461.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9500_Salted_Pop_Corn_sc2/9500_2024_09_04_13_02_38_256004.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9500_Salted_Pop_Corn_sc2/9500_2024_09_04_12_40_00_082853.mp4"
        //         }
        //       ]
        //     },
        //     {
        //       "devicename": "Sweet_Corn_sc2",
        //       "count": 0,
        //       "VideoUrl": [
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9499_Sweet_Corn_sc2/9499_2024_09_04_13_02_38_136652.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9499_Sweet_Corn_sc2/9499_2024_09_04_12_56_56_442555.mp4"
        //         },
        //         {
        //           "VideoUrl": "http://14.97.205.250:9012/storage/Videos/9499_Sweet_Corn_sc2/9499_2024_09_04_12_40_00_113993.mp4"
        //         }
        //       ]
        //     }
        //   ])  
        // }
      }
    })
    
    // const apiUrl = getURL(`/employee-shifts-info/mohini-daily-report?` +
    //   `show_id=${encodeURIComponent(String(selectedShiftTypes.ID))}&` +
    //   `screen_id=${encodeURIComponent( String(selectedLocationId))}&` +
    //   `date=${encodeURIComponent( String(combinedFromDateTime))}`);

    // const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
    // axios.get(apiUrl, {
    //   headers: {
    //     Authorization: AUTH_TOKEN,
    //   },
    // })
    // .then(response => {
    //   setResponseData(response.data);        
    //   setSelectedGetShift(selectedShiftTypes.ShiftName);   
    //   response.data.map((item, index) => {
    //     console.log("response data item",item);
    //     if(item.cool_drink_video_url){
    //         setEntryvideoURL(item.cool_drink_video_url)
    //     }
    //     if(item.pop_corn_video_url){
    //         setExitvideoURL(item.pop_corn_video_url)
    //     }
    //   });
    //   setLoading(false);
    // })
    // .catch(error => {
    //   console.error('API Error:', error);
    //   setLoading(false);
      
    // });
  }
};  


  /* 
  const handleSubmit = () => {
    debugger
    setLoading(true);
    // getTotalCount();

    setResponseData([]);
    setLocationError(false);
    setShiftTypeError(false);
    setFromDateError(false);
    setExitvideoURL('');
    setEntryvideoURL('');
    setTotalEntryCount(0);
    setTotalExitCount(0);
    setEntryExitDifference(0);
    if (selectedLocation.length === 0 || selectedShiftTypes.length === 0 || fromDate === null) {
      // Set error states based on the empty fields
    //   if (selectedLocation.length === 0) {
    //     setLocationError(true);
    //   }
    //   if (selectedShiftTypes.length === 0) {
    //     setShiftTypeError(true);
    //   }
    //   if (fromDate === null) {
    //     setFromDateError(true);
    //   }
      setLoading(false);
      setErrorMessage(true);
      //  alert('Please fill in all mandatory fields');
      return;
    }
    // console.log('selectedLocation:', selectedLocation);
    // console.log('selectedShiftTypes:', selectedShiftTypes.ShiftName);
    // console.log('selectedFromDate:', fromDate);
    // console.log('selectedToDate:', toDate);
    // if (selectedLocation.length === 0 || selectedShiftTypes.length === 0 || fromDate === null) {
    //   // setErrorMessage('Please fill in all mandatory fields');
    //   alert('Please fill in all mandatory fields');
    //   return;
    // }
    // setErrorMessage('');

    // // Check for empty values in mandatory fields
    // if (!selectedLocation || !selectedSsetEntrycllip

    // Extract the FromTime
    setErrorMessage(false);



    setSelectedGetLocation(selectedLocation);
    const combinedFromDateTime =  dayjs(fromDate).format('YYYY-MM-DD');
    const displayDateFormate =  dayjs(fromDate).format('MM-DD-YYYY');
    // const entry_video = 'http://54.69.173.158:8070/storage/Videos/6149_SOFTWARE/6149_2024_02_14_19_22_32_195067.mp4';
    // const exit_video = 'http://54.69.173.158:8070/storage/Videos/6149_SOFTWARE/6149_2024_02_14_19_22_32_195067.mp4';

    // console.log("From Time",combinedFromDateTime);
          // Get the current system time
    const currentSystemTime = dayjs();

    // Convert combinedFromDateTime to a dayjs object for comparison
    const fromDateTime = dayjs(combinedFromDateTime);

    
    // Check if combinedFromDateTime is greater than the system time
    const isCombinedDateTimeGreaterThanSystemTime = fromDateTime.isAfter(currentSystemTime);

    const fromTime = selectedShiftTypes.FromTime;

    // console.log('From Time validator:', fromTime);
  
    // const combinedSystemTime = dayjs(currentSystemTime.format('YYYY-MM-DD') + ' ' + fromTime);

    //i want to conditions work supparately for time and date

    // 1. from time grater then system time its allowing  
    // 2. from time lessthen system time and date is grater then current date No Date
    // if from time is grater then system time but dat eis less then current date then show data 
    const currentDate = currentSystemTime.format('YYYY-MM-DD');
    // console.log("marking date ",currentSystemTime);

    const combinedDateTime = dayjs(currentDate + ' ' + fromTime);
    
    // console.log("marking date time ",fromDateTime.format('YYYY-MM-DD'));

    if ((combinedDateTime.isAfter(currentSystemTime) ) && (fromDateTime.format('YYYY-MM-DD') === currentSystemTime.format('YYYY-MM-DD'))){
      // setLoading(true);

      setValidate(true);
      // Fetch details or perform other actions here
    //   console.log('Fetching details...');
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      // setLoading(false);
    } else if (isCombinedDateTimeGreaterThanSystemTime) {
      // setLoading(true);

      setValidate(true);
      // Fetch details or perform other actions here
    //   console.log('Fetching details...');
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } 
    else {
      setValidate(false);

                        // if combinedFromDateTime value is grater then system time then  show this table 
    setDisplayDate(displayDateFormate);
    // setEntrycllip(entry_video);
    // setExitclip(entry_video);
    // const combinedToDateTime = dayjs(toDate).format('YYYY-MM-DD');
    // console.log("from date",combinedFromDateTime)
    // console.log('combined date', combinedFromDateTime ,combinedToDateTime);

    console.log("Selected Shift",selectedShiftTypes)
    const apiUrl = getURL(`/employee-shifts-info/mohini-daily-report?` +
      `show_id=${encodeURIComponent(String(selectedShiftTypes.ID))}&` +
      `screen_id=${encodeURIComponent( String(selectedLocationId))}&` +
      // `date=${encodeURIComponent( String(combinedFromDateTime))}&` +
      `date=${encodeURIComponent( String(combinedFromDateTime))}`);
  
    // Get the authentication token from local storage
    const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
    // console.log('AUTH_TOKEN', AUTH_TOKEN);
  
    // Make a GET request to the API endpoint with the Authorization header
    axios.get(apiUrl, {
      headers: {
        Authorization: AUTH_TOKEN,
      },
    })
    .then(response => {
      setResponseData(response.data);        
      setSelectedGetShift(selectedShiftTypes.ShiftName);   
      response.data.map((item, index) => {
        console.log("response data item",item);
        // Print entry_video values to the console
        // console.log(`Entry Video for item ${index + 1}:`, item.entry_video);
        if(item.cool_drink_video_url){
            setEntryvideoURL(item.cool_drink_video_url)
            // console.log("value",setEntryvideo)
        }
        if(item.pop_corn_video_url){
            setExitvideoURL(item.pop_corn_video_url)
            // console.log("value",setExitvideo)
        }
      });
      // Handle the API response

    //   console.log('API Response:', response.data);

      // const modifiedData = response.data.map(item => ({
      //   ...item,
      //   Type: item.Type.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase()),
      // }));
    
      
    //   console.log('data',modifiedData);
      // if (modifiedData == []){
      //   // setResponseData([]);
      //   // console.log('response modified Empty');

      // }
      // else{
      //   setResponseData(modifiedData);        
      //   setSelectedGetShift(selectedShiftTypes.ShiftName);   
      //   // console.log('response modified',modifiedData);

      


      //   modifiedData.map((item, index) => {
      //       // Print entry_video values to the console
      //       // console.log(`Entry Video for item ${index + 1}:`, item.entry_video);
      //       if(item.entry_video){
      //           setEntryvideoURL(item.entry_video)
      //           // console.log("value",setEntryvideo)
      //       }
      //       if(item.exit_video){
      //           setExitvideoURL(item.exit_video)
      //           // console.log("value",setExitvideo)
      //       }
      //     });
          
      //   //   const newTotalEntryCount = responseData.reduce((sum, entry) => sum + entry.entry, 0);
      //   //   const newTotalExitCount = responseData.reduce((sum, entry) => sum + entry.exit, 0);
      //   //   const newEntryExitDifference = newTotalEntryCount - newTotalExitCount;
      //   //   setTotalEntryCount(newTotalEntryCount);
      //   //   setTotalExitCount(newTotalExitCount);
      //   //   setEntryExitDifference(newEntryExitDifference);
      //   //   console.log("TotalEntry Count",newTotalEntryCount,newTotalExitCount,newEntryExitDifference);

      // }
      setLoading(false);
    })
    .catch(error => {
      // Handle API request errors
      console.error('API Error:', error);
      setLoading(false);
      
    });

    }


    

  };  
  */
    const handleSpeedChange = (event) => {
      //debugger
      setSelectedSpeed(event.target.value);
      const video = document.getElementById('videoPlayer');
      if (video) {
        video.playbackRate = event.target.value;
      }
    };



    const TABLE_HEAD = [
      { id: 'Id', label: 'S.No' },
      { id: 'Date', label: 'Date' },
      { id: 'ScreenName', label: 'Screen Name' },
      { id: 'ShowName', label: 'Show Name' },
      { id: 'DeviceName', label: 'Device Name' },
      { id: 'Count', label: 'Count' },
      { id: 'Video', label: 'Video' },
      // { id: 'coolCount', label: 'CoolDrink Count' },
      // { id: 'popCount', label: 'PopCorn Count' },
      // { id: 'coolDrinkVideo', label: 'CoolDrink Video' },
      // { id: 'popCornVideo', label: 'PopCorn Video' },
    ];

    const handlePlayVideo = (videoUrls) =>{
      debugger
      console.log(videoUrls)
      setOpenList(false)
      setOpenVideoPopup(true)
      setVideoListData('')
      
      if(videoUrls.length==1){
        //let data=videoUrls[0].VideoUrl
        setVideoUrl(videoUrls)
        setOpenList(false)
        setCurrentVideoIndex(0)
      }
      else if(videoUrls.length>1){
        setOpenList(true)
        setVideoUrl(videoUrls)
        setCurrentVideoIndex(0)
      }
     
    }

  return (
    <Page title="Mohini Cenimas">
      <Container maxWidth="xl">      
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                      Day Wise Report
                    </Typography>
                    
        </Stack>   
        <Card sx={{ mt: 2}}>

          <Stack direction="row" spacing={2} sx={{ p: 2 }}>
 
      
<FormControl fullWidth sx={{ minWidth: 300 }}>
  <InputLabel id="location-name-label" sx={{ color: locationError ? 'red' : 'gray' }}>Screen Name</InputLabel>
  <Select
    labelId="location-name-label"
    label="Screen Name"
    value={selectedLocation}
    onChange={(e) => handleDetectedType(e, e.target.value)}
    error={locationError}
  >
   {locationNames
.sort((a, b) => a.localeCompare(b))
.map((option) => (
  <MenuItem key={option} value={option}>
    {option}
  </MenuItem>
))}

  </Select>
  { errorMessage && selectedLocation.length === 0 &&  <Typography color="error" variant="body2" align="left" marginTop={1}>Please Select the Screen Name </Typography>}

</FormControl>

<FormControl fullWidth sx={{ minWidth: 200 }}>
<InputLabel id="shift-type-label" sx={{ color: shiftTypeError ? 'red' : 'gray' }}>Show</InputLabel>
<Select
  labelId="shift-type-label"
  label="Show"
  value={selectedShiftTypes}
  onChange={(e) => handleShiftType(e, e.target.value)}
  error={shiftTypeError}
>

{
  shiftTypesNames
  .slice()
  .sort((a, b) => a.ShiftName.localeCompare(b.ShiftName))
  .map((option) => {
      // Convert '00:00' to '12:00' if needed
      const extfromTime = option.ExitFromTime === '00:00:00' ? '12:00:00' : option.ExitFromTime;
      const exttoTime = option.ExitToTime === '00:00:00' ? '12:00:00' : option.ExitToTime;

      // console.log(`FromTime: ${option.FromTime} | ToTime: ${option.ToTime} | Converted FromTime: ${fromTime} | Converted ToTime: ${toTime}`);

      const formatTimes = (time) => {
        const [hours, minutes] = time.split(':');
        const date = new Date(2000, 0, 1, hours, minutes);
        return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    };

      const extfromTimeFormatted = option.ExitFromTime === '00:00:00' ? '12:00 AM' : formatTimes(extfromTime);
      const exttoTimeFormatted = option.ExitToTime === '00:00:00' ? '12:00 AM' : formatTimes(exttoTime);
   
      return (
      <MenuItem key={option.ShiftName} value={option}>
      {`${option.ShiftName} (${extfromTimeFormatted} - ${exttoTimeFormatted})` }
      </MenuItem>
      );
  })
}

        
</Select>
{ errorMessage && selectedShiftTypes.length === 0 &&  <Typography color="error" variant="body2" align="left" marginTop={1}>Please Select the Show</Typography>}

</FormControl>



<LocalizationProvider dateAdapter={AdapterDayjs}>
  <FormControl fullWidth sx={{ width: '75%', '& fieldset': { borderColor: fromDateError ? 'red' : 'initial' } }}>
    <DatePicker
      label="Date"
      value={fromDate}
      onChange={handleFromDateChange}
      maxDate={dayjs()}
      renderInput={(props) => (
        <TextField
          {...props}
          InputProps={{
            style: {
              '&:hover': {
                borderColor: fromDateError ? 'red' : 'initial',
              },
              '&.Mui-focused': {
                borderColor: fromDateError ? 'red' : 'initial',
              },
            },
          }}
        />
      )}
    />
        { errorMessage && !fromDate &&  <Typography color="error" variant="body2" align="left" marginTop={1}>Please Select the Date</Typography>}

  </FormControl>

  </LocalizationProvider>

  
<LoadingButton  sx={{ width: '25%' }}  type="submit" variant="contained" onClick={handleSubmit} loading={loading} >
          Get Reports
        </LoadingButton>
          </Stack>
        </Card>

     

{loading && (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </div>
    )}

    {!loading && (
      <Card sx={{ mt: 4, p: 6 }}>

 
      <Button
        sx={{ position: 'absolute', top: 0, right: 15 ,mt : 1}}
        variant="contained"
        color="primary"
        startIcon={<InsertDriveFileIcon />}
        onClick={() => handleButtonClick('excel')}
      >
        Excel <SaveAltIcon />
      </Button>
      
            <TableContainer sx={{ width: '100%' }}>
            <Table>
      
              <React.Fragment>
              <UserListHead 
                headers={TABLE_HEAD} 
              
                headLabel={TABLE_HEAD}
                // rowCount={clients.length}
                // numSelected={selected.length}
                />
               <TableBody>
                {responseData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <Typography variant="h6" color="textSecondary">
                        No Data
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                responseData.map((item, index) => (
                  <TableRow key={index}>
                   <TableCell>{index+1}</TableCell>
                   <TableCell>{dayjs(fromDate).format('YYYY-MM-DD')}</TableCell>
                   <TableCell>{selectedLocation}</TableCell>
                   <TableCell>{selectedShiftTypes["ShiftName"]}</TableCell>
                   <TableCell>{item.devicename}</TableCell>
                   <TableCell>{item.count}</TableCell>
                   <TableCell>{item.VideoUrl?<PlayCircleIcon onClick={()=>handlePlayVideo(item.VideoUrl)}/>:''}</TableCell>
                  </TableRow>
                ))
              )}
              </TableBody>

              </React.Fragment>
      
      
            </Table>
            </TableContainer>
      
            </Card>
    )}


      </Container>

      <Dialog open={openVideoPopup}  PaperProps={{
          style: {
            width: "35%", // Adjust the width as needed
            maxWidth: "none" // Remove the maximum width limit
          }
        }}>
      <DialogTitle sx={{ textAlign: 'center' }}>{dailogTitle}</DialogTitle>
       
       {/*
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <video controls id="videoPlayer" width="100%" height="auto" style={{ maxWidth: '100%' }} ref={videoRef}>
              {!openList && (<source src={videoUrl} type="video/mp4" />)}
              {openList && videoListData!=='' && (<source src={videoListData} type="video/mp4" />)}
              Your browser does not support the video tag.
            </video>  

            <Box sx={{ display: 'flex',alignItems: 'center', mt: 2, mr: 'auto'}}> 
              {openList && videoUrl.length>0 && videoUrl.map((item, index) => (
                <PlayCircleIcon key={index} onClick={()=>{
                   setVideoListData(item.VideoUrl)
                   setOpenList(true)
                }}/>
              ))}
            </Box>

             <Box sx={{ display: 'flex',alignItems: 'center', mt: 2, ml: 'auto'}}> 

                <InputLabel id="speed-label" sx={{mr:2}}>Speed</InputLabel>               
                <Select
                  label = 'Speed'
                  labelId="speed-label"
                  value={selectedSpeed}
                  onChange={handleSpeedChange}
                  variant="outlined"
                  size="large" 
                  style={{ minWidth: 80 }} 
                >
                  <MenuItem value={1}>1x</MenuItem>
                  <MenuItem value={2}>2x</MenuItem>
                  <MenuItem value={3}>3x</MenuItem>
                  <MenuItem value={5}>5x</MenuItem>
                  <MenuItem value={10}>10x</MenuItem>
                </Select> 
              </Box> 
              
            <LoadingButton
                  sx={{ mt: 2, ml: 'auto' }}
                  type="submit"
                  variant="contained"
                  color="error"
                  onClick={handleCloseVideoPopup}
                >
                  Cancel
            </LoadingButton>
      
      </DialogContent>
      */}
      
      <DialogContent  >
        {videoUrl.length > 0 &&
                      videoUrl[currentVideoIndex] && (
          <div style={{ display: "flex", flexDirection: "column", width : "100%" }}>
            {/* Video Player */}
            <div style={{ position: "relative", paddingBottom: "56.25%", paddingTop: 25, height: 0 }}>
                  <video
                    controls
                    id="videoPlayer"
                    key={currentVideoIndex}
                    ref={videoRef}
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                    
                    //onEnded={handleVideoEnded}
                    onLoadedMetadata={handleVideoLoaded}
                    //autoPlay
                  >
                    <source
                      src={videoUrl[currentVideoIndex].VideoUrl}
                      type="video/mp4"
                    />
                  </video>
            </div>

            {/* <Box sx={{ display: 'flex',alignItems: 'center', mt: 2, mr: 'auto'}}> 
              {videoUrl.length>1 && videoUrl.map((item, index) => (
                <PlayCircleIcon key={index} onClick={()=>{
                  setCurrentVideoIndex(index)
                }}/>
              ))}
            </Box> */}

            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mt: 2, 
              mr: 'auto' ,
              flexWrap: 'wrap',
              overflowY: 'auto',
              gap: '10px',
              }}>
              {videoUrl.length > 1 && videoUrl.map((item, index) => (
                <IconButton
                  key={index}
                  onClick={() => {
                    setCurrentVideoIndex(index);
                  }}
                  sx={{
                    backgroundColor: currentVideoIndex === index ? '#E9967A' : 'grey',  
                    color: 'black',   
                    width: '40px',    
                    height: '40px',
                    fontSize: '16px', 
                    fontWeight: currentVideoIndex === index ? 'bold' : 'normal',  
                    marginRight: '10px',
                    '&:hover': {
                      backgroundColor: currentVideoIndex === index ? '#E9967A' : 'darkgrey',  
                    },
                  }}
                >
                  {index + 1} {/* Display the number */}
                </IconButton>
              ))}
            </Box>

            <Box sx={{ display: 'flex',alignItems: 'center', mt: 2, ml: 'auto'}}> 
             
                  <FormControl style={{ minWidth: "20%",marginRight:'3px' }}>
                  <InputLabel id="speed-label">Speed</InputLabel>
                  <Select
                    labelId="speed-label"
                    value={selectedSpeed}
                    onChange={handleSpeedChange}
                    variant="outlined"
                    size="large"
                    style={{ minWidth: 80 }}
                  >
                    <MenuItem value={1}>1x</MenuItem>
                    <MenuItem value={2}>2x</MenuItem>
                    <MenuItem value={3}>3x</MenuItem>
                    <MenuItem value={5}>5x</MenuItem>
                    <MenuItem value={10}>10x</MenuItem>
                  </Select>
                  </FormControl>
                            
                  <Button color="error" variant="contained" onClick={handleCloseVideoPopup}>Close</Button>
          
            </Box>
          </div>
          )}
      </DialogContent>

      </Dialog>


      {/* move button toward right side*/}
    </Page>
  )
}

