
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import axios from '../axios'
import { Box } from '@mui/system';
import { handleAccess } from 'src/utils/responseHandler';
import { getURL } from 'src/utils/config';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

//----------------------------------------------------------------------

export default function AutoLogin({setToken, setAccess, setUserType, setuserData}) {

  const [isRedirect, setRedirect] = useState(true)
  const urlParams = new URLSearchParams(window.location.search);
  const { navigate } = useNavigate()

  const jsonToFormData = (data) => {
    let formdata = new FormData()
    for (let x in data)
      formdata.append(x, data[x])
    return formdata
  }



  const handleLogin = (body, type='form', redirectURL) => {
    localStorage.clear()
    setToken(null)
    setAccess(false)
    setUserType(null)

    setuserData({
      "access_token": null,
      "token_type": null,      
      "ID": null,
      "IsLicenseBase": null,
      "IsAgentBased": null,
      "name": null,
      "displayname": null,
      "logo_url": null,
      "user_type": null,
      "client_id": null
  }) 



    axios({
      method: 'post',
      url: getURL('token'),
      data: body,
      validateStatus: function (status) {
        return status >= 200 || status === 400;
      }
    }).then(function (response) {
      if (
        (response.status === 200 || response.status === 201) && 
        ('access_token' in response.data) 
        && ![3, 4].includes(parseInt(response.data.user_type))) {    
        
        // console.log(response.data)
        
        const res = response.data
        for (let x in res)
          localStorage.setItem(x, res[x])
        const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
        axios.defaults.headers.common.Authorization = AUTH_TOKEN;   
        // console.log('user_type',localStorage.getItem('user_type'))

        setuserData({
            "access_token": res['access_token'],
            "token_type": res['token_type'],
            "ID": res['ID'],
            "IsLicenseBase": res['IsLicenseBase'],
            "IsAgentBased": res['IsAgentBased'],
            "name": res['name'],
            "displayname": res['displayname'],
            "logo_url": res['logo_url'],
            "user_type": res['user_type'],
            "client_id": res['client_id']
        })
    

        setUserType(parseInt(response.data.user_typ))
	      setToken(localStorage.getItem('access_token'))
	      
        handleAccess (setAccess, AUTH_TOKEN)

        window.location.replace(redirectURL)
          
       
        // navigate(redirectURL, { replace: true })
      }           
    })
  }

  useEffect(() => {

    let username = urlParams.get('username');
    let password = urlParams.get('password');
    let date = urlParams.get('date');
    let shift = urlParams.get('shift');
    let type = urlParams.get('type');
    let category = urlParams.get('category');
    let subCategory = urlParams.get('sub-category');
    let employeeID = urlParams.get('employeeID');
    let locationId = urlParams.get('locationId');
    let cameraId = urlParams.get('cameraId');
    let status = urlParams.get('status');
    let slot = urlParams.get('slot');
    
    let pa = urlParams.get('pa');
    
    let redirectURL = ""

    // http://localhost:3000/jfw-reports?date=2023-09-29&category=Safety%20Violation&sub-category=No%20safety%20helmet
    // http://localhost:3000/auto-login?username=jubilant&pwd=jubilant@123&password=jubilant@123&status=active

    if (username === localStorage.getItem('name')) {
        if (username === "jkorganisation") {
          redirectURL = `/reports?type=${type}&employeeID=${employeeID}&locationId=${locationId}&cameraId=${cameraId}&reportDate=${date}&shift=${shift}&attend=${pa}`
        } else if (username === "jubilant") {
            redirectURL = urlParams.has('status') ? 
            `/devices-report?status=${status}` 
            :`/jfw-reports?date=${date}&category=${category}&sub-category=${subCategory}&slot=${slot}`
        }
        window.location.replace(redirectURL)
        
    } else {

      // console.log(username, password, date, shift, type)

      if (username && password) {
        // console.log('Force login')
        handleLogin(
          jsonToFormData({
            username: username,
            password: password,
            grant_type: 'password'
          }),
          'url',
          redirectURL
        )
      } else 
        setRedirect(false)

    }   

  }, [])

  return (<></>);
}

AutoLogin.propTypes = {
  setToken: PropTypes.func.isRequired
}
