import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid, TextField, Switch,  Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'src/axios';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { top100Films } from './data'; // Import your data
import dayjs from 'dayjs';
import { getLocations, updateShift } from 'src/api/ShiftMaster';

const EditShow = ({ isOpen, onClose, rowData, updateDataCallback }) => {
  const [dataLocationNames, setLocationNames] = useState([]);
  const [shiftname,setShiftName] = useState('');

  const [entryFromTime, setEntryFromTime] = useState(null);
  const [exitToTime, setExitToTime] = useState(null);
  const [locationNames, setLocationNamesAPI] = useState([]); // Initialize as an empty array
  const [apiData, setApiData] = useState(null); // New state for storing API data
  // const [selectedLocation, setSelectedLocation] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  console.log("times",apiData,rowData);

  useEffect(() => {
    getLocations((response)=> {
      if (response.status === 200) {
        const extractedLocationNames = response.data.map(
          (item) => item.LocationName
        );
        setLocationNamesAPI(extractedLocationNames);  
        setApiData(response.data);
      }
    })
}, []);

  
  useEffect(() => {
    console.log("row data ",rowData)
    setLocationNames(rowData.LocationName)
    setShiftName(rowData.ShiftName);
    setEntryFromTime(dayjs(rowData.ExitFromTime, 'HH:mm:ss'));
    setExitToTime(dayjs(rowData.ExitToTime, 'HH:mm:ss'));
  }, [rowData]);

  const handleSubmit = async () => {

    debugger
    try {
 
      // const apiEndpoint = '/shift-mon-timings/update';

      // const headers = {
      //   'Content-Type': 'application/json',
      // };
      const locationObj = apiData.find(location => location.LocationName === dataLocationNames);
      console.log("shiftname",shiftname)

      // Get the Id from the found object or use a default value if not found
      // const LocationID = locationObj ? locationObj.Id : rowData.LocationId;

      // console.log("Location ID: after ",LocationID);

      if(!shiftname){
        setErrorMessage(true);
        return false;
      }

      const formData = {
        Id: rowData.Id,
        LocationId: locationObj.Id,
        ShiftName: shiftname,
        ExitFromTime: entryFromTime ? entryFromTime.format('HH:mm:ss') : rowData.ExitFromTime,
        ExitToTime: exitToTime ? exitToTime.format('HH:mm:ss') : rowData.ExitToTime,
      };
  
      // Make the PUT request
      console.log("put data",formData);
      updateShift(formData,(response) =>{
        if(response.status === 200 && response.data!=null){
          console.log(response.data);
        }
      })
      updateDataCallback();
      onClose();
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error('Error saving data:', error);
    }
  };
  

  const fieldsToShow = ['LocationName','ShiftName','Status'];
  return (

    <Dialog open={isOpen}>
    <DialogTitle>Edit Show </DialogTitle>
    <DialogContent>
      <Grid>
        <Grid item xs={12}>
            <Autocomplete
              options={locationNames}
              getOptionLabel={(option) => option}
              value={dataLocationNames ? dataLocationNames : rowData.LocationName} 
              onChange={(event, newValue) => setLocationNames(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Location"
                  fullWidth
                  margin="normal"

                />
              )}
            />
        {/* {  errorMessage && !selectedLocation &&  <Typography color="error" variant="body2" align="left" marginTop={1}>Please Select the screen</Typography>} */}

        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Show Name"
            value={shiftname}
            onChange={(e) => setShiftName(e.target.value)}
            fullWidth
            margin="normal"
            // error={!!errors.shiftName}
            // helperText={errors.shiftName}
          />
       {  errorMessage && !shiftname &&  <Typography color="error" variant="body2" align="left" marginTop={1}>Please Enter the Show Name </Typography>}

        </Grid>
        <Box sx={{ my: 3 }}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Break Time
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                  <DemoContainer components={['TimePicker']}>
                                                    <TimePicker
                                                      label="Break Start Time"
                                                      value={entryFromTime}
                                                      onChange={(newValue) => setEntryFromTime(newValue)}
                                                      fullWidth
                                                    />

                                                  </DemoContainer>
                      </LocalizationProvider>
          </Grid>
          <Grid item md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                  <DemoContainer components={['TimePicker']}>
                                                    <TimePicker
                                                      label="Break End Time"
                                                      value={exitToTime}
                                                      onChange={(newValue) => setExitToTime(newValue)}
                                                      fullWidth
                                                    />
                                                  </DemoContainer>
                      </LocalizationProvider>
          </Grid>
          
                </Grid>

      </Grid>
    </DialogContent>
    <DialogActions>
      <Button color='error' variant='outlined' onClick={onClose}>
          Close
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleSubmit}
        >
          Save Changes
        </LoadingButton>
    </DialogActions>
  </Dialog>

  );
};

export default EditShow;

