import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Switch,  Box } from '@mui/material';
import axios from "axios";
import { LoadingButton } from '@mui/lab';
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import qs from 'qs';


import {

  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

// import { top100Films } from './data'; // Import your data
const ShiftTimeEdit = ({ isOpen, onClose, currentId, rowData, updateDataCallback }) => {
  const [editedData, setEditedData] = useState({});
  const [data, setData] = useState([]);
  const [dataDevicesId, setDataDevicesId] = useState([]);
  const [dataLocationNames, setLocationNames] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  
  useEffect(() => {
    // Set initial values when rowData changes
    setEditedData({...rowData});
  }, [rowData]);

//   const handleSubmit = async () => {
//    console.log("test",selectedLocation.Id);

//     try {
//       // Make a PUT request to your API endpoint with the edited data
//       const updatedData = {
//         ...rowData,
//         LocationName: selectedLocation.Id || rowData.LocationName,
//         ShiftName: editedData.ShiftName || rowData.ShiftName,
//         // FromTime: editedData.FromTime || rowData.FromTime,
//         // ToTime: editedData.ToTime || rowData.ToTime,
//         Status: editedData.Status || rowData.Status,
//       };

//       await axios.put(`http://192.168.30.68:8080/employee-shifts-info/update`, updatedData);
//       // If successful, you can handle any additional logic or close the dialog
//       updateDataCallback();
//       onClose();
//     } catch (error) {
//       // Handle error, e.g., show an error message
//       console.error('Error saving data:', error);
//     }
//   };
 

  const handleSubmit = async () => {
    // if(location === selectedLocation ){
    //   console.log("test", selectedLocation.Id);
    // }
    
    try {
      // Replace 'your_api_endpoint' with your actual API endpoint
      const apiEndpoint = 'http://192.168.30.68:8080/employee-shifts-info/update';

      const AUTH_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo3NjgsImV4cGlyZXNfaW4iOjE3MzgzODU3MDYsInRva2VuX3R5cGUiOiJCZWFyZXIiLCJpc3N1ZWQiOiJGcmksIDAyIEZlYiAyMDI0IDEwOjI1OjA2IElTVCJ9.BW3_bQ-chtIbrj8Gykuue3B2ZSXCucDa1glAKVxqKJY';
      // const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
      // console.log('AUTH_TOKEN',AUTH_TOKEN);
      // Include the authentication token in the headers
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${AUTH_TOKEN}`,
      };
  
      const formData = qs.stringify({
        // LocationName: selectedLocation || rowData.LocationName,
        ShiftName: editedData.ShiftName || rowData.ShiftName,
        // FromTime: editedData.FromTime || rowData.FromTime,
        // ToTime: editedData.ToTime || rowData.ToTime,
        // Status: editedData.Status || rowData.Status,
      });
  
      // Make the PUT request
      // console.log("put data",formData);
      // console.log("Before PUT request - formData:", formData);
      await axios.put(apiEndpoint, formData, { headers });
      // console.log("After PUT request - Data updated successfully");

    //   // Create the updated data object
    //   const updatedData = {
    //     ...rowData,
    //     LocationName: selectedLocation.Id || rowData.LocationName,
    //     ShiftName: editedData.ShiftName || rowData.ShiftName,
    //     // FromTime: editedData.FromTime || rowData.FromTime,
    //     // ToTime: editedData.ToTime || rowData.ToTime,
    //     Status: editedData.Status || rowData.Status,
    //   };
  
      // Make the PUT request
    //   await axios.put(apiEndpoint, updatedData, { headers });
  
      // If successful, you can handle any additional logic or close the dialog
      updateDataCallback();
      onClose();
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error('Error saving data:', error);
    }
  };
  

  const handleFormChange = (key, value) => {
    // console.log(key, value )
    // if (key == 'LocationName'){
    //   setSelectedLocation(value);
    // }
  //  console.log()
    // const prevData = {...editedData}
    // prevData[key] = value
    // setEditedData({...prevData})
    setEditedData((prevData) => {
      prevData[key] = value
      return {...prevData}
    });
  };

  const fieldsToShow = ['LocationName','ShiftName','Status'];
//   const fieldsToShow = ['LocationName','ShiftName', 'FromTime', 'ToTime', 'Status'];

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = 'http://192.168.30.68:8080/employee-shifts-info/location-names?status=1';
      const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
      // console.log('AUTH_TOKEN',AUTH_TOKEN);
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `${AUTH_TOKEN}`,
          },
        });

        // Handle successful response
        setLocationNames(response.data);
      } catch (error) {
        // Handle error
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  const location = dataLocationNames.map(item => item.LocationName);

  const location_Id = dataLocationNames.map(item => item);


  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = 'http://192.168.30.68:8080/employee-shifts-info/shift-names?status=1';
      const AUTH_TOKEN = `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`;
      // console.log('AUTH_TOKEN',AUTH_TOKEN);
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `${AUTH_TOKEN}`,
          },
        });

        // Handle successful response
        setDataDevicesId(response.data);
      } catch (error) {
        // Handle error
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  
  const temId = dataDevicesId.map(item => item.ShiftName);




  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle  style={{ marginBottom: 10}}>Edit TimeLaps</DialogTitle>
      <DialogContent >
      {fieldsToShow.map((key) => (
        <React.Fragment key={key}>
          {key === 'LocationName' ? (

            <Autocomplete
            options={location}
            getOptionLabel={(option) => option}
            value={selectedLocation||editedData[key] }
            onChange={(_, newValue) => handleFormChange(key, newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="LocationName"
                fullWidth
                margin="normal"
              />
            )}
            />
          ) : key === 'ShiftName' ? (

            <Autocomplete
            options={temId}
            getOptionLabel={(option) => option}
            value={editedData[key] || rowData.ShiftName }
            onChange={(_, newValue) => handleFormChange(key, newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="ShiftName"
                fullWidth
                margin="normal"
              />
            )}
            />
          ) : (
                <>
                <Box>
                  <Typography variant='subtitle2'>Active state</Typography>
                  <Typography>Disabling this will automatically set to InActive</Typography> 
                </Box>  
            
                <Switch
              color="success"
              checked={editedData.Status === 1 }
              onChange={() => {handleFormChange(key, editedData.Status === '0' ? 1 : '0' )}}
              />

                </>
               
            ) 
            }

          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color='error' variant='outlined' onClick={onClose}>
          Close
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleSubmit}
        >
          Save Changes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ShiftTimeEdit;





