import { useState, useEffect } from 'react';
import axios from '../axios'
import { handleResponse } from 'src/utils/responseHandler';
import { jsonToFormData, getURL } from 'src/utils/config';

const useCameras = () => {
    const [alldata, setLocations] = useState({
        isGetData: true,
        data: [],
        formResponse: {
            status: false,
            response: [],
            isLoad: false
        }        
    })
    
    useEffect(() => {
        if (alldata.isGetData){
            axios({
                method: 'get',
                url: getURL('/devices?status=0,1'),
                validateStatus: (status) => handleResponse(status)
              }).then((response) => {
                // console.log(response.data, locations.isGetData)
                if (response.status === 200 || response.status === 201) {

                    if(response.data !== null){
                        setLocations({
                            ...alldata,
                            data: response.data,
                            isGetData: false
                        })
                    }

                }        
            })
        }        
    }, [alldata.isGetData])

    const addOrEditForm = (url, formdata, method) => {
        axios({
            method: method,
            url: getURL(`/devices/${url}`),
            data: formdata,
            validateStatus: (status) => handleResponse(status)
          }).then(function (response) {
            let formResponse = {
                status: false,
                response: [],
                isLoad: true
            }
            if (response.status === 200 || response.status === 201) {
                formResponse.response = response.data
                formResponse.status = true               
            }            
            setLocations({...alldata, formResponse})
        })
    }



    const addCamera = (formdata) => {        
        let url = 'uid' in formdata ? 'update': 'add'
        let method = 'uid' in formdata ? 'put': 'post'
        formdata = jsonToFormData(formdata)
        addOrEditForm(url, formdata, method)
    }

    const updateStatus = (body) => addOrEditForm('updatestatus', body, 'put')

    const deleteRecord = (body) => addOrEditForm('delete', body, 'delete')

    const isLoadForm = (status) => {
        let formResponse = alldata.formResponse
        formResponse.isLoad = status
        setLocations({...alldata, formResponse})
    }

    const isReloadData = (status) => {
        setLocations({...alldata, isGetData: status})
    }

    return {
        alldata,
        addCamera,
        isLoadForm,
        isReloadData,
        updateStatus,
        deleteRecord
    }
}

export default useCameras