import React, { useEffect, useState } from "react";
import axios from "src/axios";
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,Switch,
  TableContainer,
  Checkbox,
  useStepContext,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ListItemText } from "@mui/material";
import Iconify from "src/components/Iconify";
import {
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,Form,
  DialogTitle,
  TableHead,
} from "@mui/material";
import {
  getGroups,
  getClientInfo,
  AddClientGroupName,
  AddClientGroup,
} from "src/api/groups";
import {
  getPlanDetails,
  AddPlanCam,
  getClientPlanInfo,
  AddNewPlan,
} from "src/api/plan";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PlansMore from "./PlansMore";
import { getAllPlanDetails } from "src/api/plan";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ViewPlans = () => {
  const [client, setClient] = useState("");
  const [plan, setPlan] = useState([]);
  const [planName, setPlanName] = useState("");
  const [planPrice, setPlanPrice] = useState("");
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [clientData, setClientData] = useState([]);

  const [message, setMessage] = useState("");
  const [planNameError, setplanNameError] = useState(false);
  const [planPriceError, setplanPriceError] = useState(false);

  const [helperText, setHelperText] = useState("");

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  //Fields
  const [storageType,setStorageType] = useState("");
  const [storageDuration,setStorageDuration] = useState("");
  const [monTime,setMonTime]=useState("");
  const [notifiType,setNotificationType] = useState("");
  const [camView,setCamView] = useState("");

  const [haveSpeaker,setHaveSpeaker] = useState(1);

  const handleGroupReload = (val) => {
    let body = { client_id: client };
    getAllPlanDetails(body, (response) => {
      setClientData(response.data);
      // console.log("plans for Cams", response.data);
    });
  };

  useEffect(() => {

    getAllPlanDetails((response) => {
      setPlan(response.data);
    });
    setHaveSpeaker(0)
  }, []);


  const handleOpen = () => {
    setPlanName("");
    setPlanPrice("");
    setOpen(true);
  };

  useEffect(() => {
    // console.log("selected cameras", selectedCameras);
  }, [selectedCameras]);


  const handleClose = () => {
    setOpen(false);
    setOpenError(false);
    setplanNameError(false);
    setHelperText("");
    setPlanName("");
    setPlanPrice("");
    setStorageType("");
    setStorageDuration("");
    setMonTime("");
    setNotificationType("");
    setCamView("");
    setHaveSpeaker(0);
  };

  const saveGroupName = () => {

    if (planName.trim() === "") {
      setplanNameError(true);
      setMessage("Plan Name cannot be empty.");
    } else if (planPrice.trim() === "") {
      setplanPriceError(true);
      setMessage("Plan Price cannot be empty.");
    } else {
      // Check if the group name already exists
      const isGroupNameExists = plan
        ? plan.some(
            (plan) => plan.PlanName.toLowerCase() === planName.toLowerCase()
          )
        : "";

      if (isGroupNameExists) {
        setplanNameError(true);
        //setErrorMessage()
        setMessage("Plan Name already exists");
      } else {
        let body = {
          PlanName: planName,
          Pricing: planPrice,
          StorageTimings:storageType,
          StorageDuration: storageDuration,
          MonitoringTimings:monTime,
          NotificationType:notifiType,
          CameraType:camView,
          HaveSpeaker:haveSpeaker,
        };

        AddNewPlan(body, (response) => {
          setPlanName("");
          setPlanPrice("");
          setStorageType("");
          setStorageDuration("");
          setMonTime("");
          setNotificationType("");
          setCamView("");
          setHaveSpeaker(0);

          setOpen(false);

          if (response.status === 200) {
            getAllPlanDetails((response) => {
              setPlan(response.data);
              setOpenError(true);
              setMessage("Plan Added Successfully");
            });
          }
        });
        setplanNameError(false);
      }
    }
  };


  const handleStorageType = (event) =>{
    // console.log("client type",event.target.value)
    setStorageType(event.target.value)
  }

  const handleStorageDuration = (event) =>{
    // console.log("client type",event.target.value)
    setStorageDuration(event.target.value)
  }

  const handleMonitoringTime = (event) =>{
    // console.log("client type",event.target.value)
    setMonTime(event.target.value)
  }

  const handleNotificationtype = (event) =>{
    // console.log("client type",event.target.value)
    setNotificationType(event.target.value)
  }

  const handleCamView = (event) =>{
    // console.log("client type",event.target.value)
    setCamView(event.target.value)
  }

  const handleSwitch = (event) => {
    setHaveSpeaker(parseInt(event.target.value) === 0 ? 1 : 0)
    // setFieldValue('IsAgentBased', parseInt(event.target.value) === 0 ? 1 : 0)
  }


  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
        ml={5}
      >
        <Typography variant="h4" gutterBottom>
          View Plans
        </Typography>

        <Button
          variant="contained"
          onClick={() => handleOpen()}
          startIcon={<Iconify icon="eva:plus-fill" />}
          sx={{ marginRight: "3%" }}
        >
          Add Plan
        </Button>

        <Dialog open={open} onClose={handleClose} 
      fullWidth={fullWidth}
      maxWidth={maxWidth}    
          >
          <DialogTitle>Add New Plan </DialogTitle>
          <DialogContent>
              <Grid container spacing={2}>

                <Grid item md={6}>
                <TextField
              margin="dense"
              id="groupName"
              label="Plan Name"
              type="text"
              fullWidth
              value={planName}
              onChange={(e) => {
                const newName = e.target.value;
                setPlanName(newName);
                setplanNameError(false); // Reset error state when typing
                // Check if the new name exists in the list
                // if (groupName.includes(newName)) {
                //     setplanNameError(true);
                //     setHelperText("Group name already exists.");
                // } else
                if (newName.trim() === "") {
                  // Check if the field is empty
                  setplanNameError(true);
                  setHelperText("Plan Name cannot be empty.");
                  setMessage("");
                } else {
                  setplanNameError(false);
                  setHelperText(""); // Clear helper text if no error
                }
              }}
              error={planNameError}
              helperText={helperText}
              required
            />

            {planNameError && (
              <Typography color="red" fontSize={12}>
                {message}
              </Typography>
            )}
                </Grid>

                <Grid item md={6}>
                <TextField
              margin="dense"
              id="planPrice"
              label="Price"
              type="text"
              fullWidth
              value={planPrice}
              onChange={(e) => {
                const newName = e.target.value;
                setPlanPrice(newName);
                setplanNameError(false); // Reset error state when typing
                // Check if the new name exists in the list
                // if (groupName.includes(newName)) {
                //     setplanNameError(true);
                //     setHelperText("Group name already exists.");
                // } else
                if (newName.trim() === "") {
                  // Check if the field is empty
                  setplanNameError(true);
                  setHelperText("Price cannot be empty.");
                  setMessage("");
                } else {
                  setplanNameError(false);
                  setHelperText(""); // Clear helper text if no error
                }
              }}
              error={planPriceError}
              helperText={helperText}
              required
            />
            {planPriceError && (
              <Typography color="red" fontSize={12}>
                {message}
              </Typography>
            )}
                </Grid>

                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-labell-storageType">
                      Storage Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label-storageType"
                      id="demo-simple-select-storageType"
                      value={storageType}
                      label="Storage Type"
                      onChange={handleStorageType}
                    >
                      <MenuItem value="24/7">24/7</MenuItem>
                      <MenuItem value="Week Days">Week Days</MenuItem>
                      <MenuItem value="Monthly">Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-labell-storageDuration">
                    Storage Duration
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label-storageDuration"
                      id="demo-simple-select-storageDuration"
                      value={storageDuration}
                      label="Storage Duration"
                      onChange={handleStorageDuration}
                      // {...getFieldProps("ClientType")}
                      // error={Boolean(touched.ClientType && errors.ClientType)}
                      // helperText={touched.ClientType && errors.ClientType}
                    >
                      <MenuItem value="0">0 Days</MenuItem>
                      <MenuItem value="15">15 Days</MenuItem>
                      <MenuItem value="30">30 Days</MenuItem>
                      <MenuItem value="45">45 Days</MenuItem>

                      {/* <FormHelperText>
                        {touched.timezone && errors.timezone}
                      </FormHelperText> */}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-labell-monTime">
                    Monitoring Timings
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label-monTime"
                      id="demo-simple-select-monTime"
                      value={monTime}
                      label=" Monitoring Timings"
                      onChange={handleMonitoringTime}
                    >
                      <MenuItem value="24/7">24/7</MenuItem>
                      <MenuItem value="8 Hrs">8 Hrs</MenuItem>
                      <MenuItem value="12 Hrs">12 Hrs</MenuItem>
                      <MenuItem value="Week Days">Week Days</MenuItem>


                      {/* <FormHelperText>
                        {touched.timezone && errors.timezone}
                      </FormHelperText> */}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-labell-notifiType">
                    Notification Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label-notifiType"
                      id="demo-simple-select-notifiType"
                      value={notifiType}
                      label="Notification Type"
                      onChange={handleNotificationtype}
                      // {...getFieldProps("ClientType")}
                      // error={Boolean(touched.ClientType && errors.ClientType)}
                      // helperText={touched.ClientType && errors.ClientType}
                    >
                      <MenuItem value="Email">Email</MenuItem>
                      <MenuItem value="SMS">SMS</MenuItem>
                      <MenuItem value="Email/SMS">Email/SMS</MenuItem>

                      {/* <FormHelperText>
                        {touched.timezone && errors.timezone}
                      </FormHelperText> */}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-labell-camView">
                    Camera View
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label-camView"
                      id="demo-simple-select-camView"
                      value={camView}
                      label="Camera View"
                      onChange={handleCamView}
                      // {...getFieldProps("ClientType")}
                      // error={Boolean(touched.ClientType && errors.ClientType)}
                      // helperText={touched.ClientType && errors.ClientType}
                    >
                      <MenuItem value="In Door">In Door</MenuItem>
                      <MenuItem value="Out Door">Out Door</MenuItem>

                      {/* <FormHelperText>
                        {touched.timezone && errors.timezone}
                      </FormHelperText> */}
                    </Select>
                  </FormControl>
                </Grid>
                
              </Grid>
              <Stack direction={"row"} spacing={2} sx={{ my: 2 }}>
                <Box>
                  <Typography variant="subtitle2">Have Speaker ?</Typography>
                  <Typography>
                    {haveSpeaker === 1
                      ? "Enable Speaker"
                      : "Disable Speaker"}
                  </Typography>
                </Box>
                {haveSpeaker === 1 ? (
                  <Switch
                    color="success"
                    value={1}
                    checked={true}
                    onChange={handleSwitch}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                ) : (
                  <Switch
                    value={0}
                    checked={false}
                    onChange={handleSwitch}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                )}
              </Stack>

              <DialogActions>
                  <Button color="error" variant="outlined" onClick={handleClose}>
                    Close
                  </Button>
                  <Button type="submit" onClick={saveGroupName} variant="contained">
                    Save
                  </Button>
                </DialogActions>
          </DialogContent>
        </Dialog>
      </Stack>

      <Card sx={{ width: "95%", p: 2, margin: "auto" }}>
        <TableContainer>
          <Table>
            <TableHead sx={{ background: "#f3f3f3" }}>
              <TableRow>
                <TableCell sx={{ background: "#f3f3f3" }}>S.No</TableCell>
                <TableCell sx={{ background: "#f3f3f3" }}>Plan Name</TableCell>
                <TableCell sx={{ background: "#f3f3f3" }}>Storage Type</TableCell>

                <TableCell sx={{ background: "#f3f3f3" }}>Storage Duration</TableCell>

                <TableCell sx={{ background: "#f3f3f3" }}>Monitoring Timings</TableCell>

                <TableCell sx={{ background: "#f3f3f3" }}>Notification Type</TableCell>

                <TableCell sx={{ background: "#f3f3f3" }}>Camera Type</TableCell>

                <TableCell sx={{ background: "#f3f3f3" }}>Have Speaker</TableCell>
                <TableCell sx={{ background: "#f3f3f3" }}>Pricing</TableCell>


                <TableCell sx={{ background: "#f3f3f3" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plan.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.PlanName}</TableCell>
                  <TableCell>{row.StorageTimings}</TableCell>
                  <TableCell>{row.StorageDuration}</TableCell>
                  <TableCell>{row.MonitoringTimings}</TableCell>
                  <TableCell>{row.NotificationType}</TableCell>
                  <TableCell>{row.CameraType}</TableCell>
                  <TableCell>{row.HaveSpeaker === 1  ? "Yes":"No"}</TableCell>
                  <TableCell>{row.Pricing}</TableCell>

                  {/* <TableCell>
                    <PlansMore
                      row={row}
                      groups={plan}
                      handleGroupReload={handleGroupReload}
                    />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default ViewPlans;
