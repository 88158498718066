import axios from 'src/axios';
import { getURL, jsonToFormData } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';


// const client = new Object()

// get cameras data
const postNewUserData = (formdata, callback) => {
    axios({
        method: 'post',
        url: getURL('client/add'),
        data: jsonToFormData(formdata),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const updateUserData = (formdata, callback) => {
    axios({
        method: 'put',
        url: getURL('client/agent-update'),
        data: jsonToFormData(formdata),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const disableUserData = (formdata, callback) => {
    axios({
        method: 'put',
        url: getURL('client/update-status'),
        data: jsonToFormData(formdata),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}
// const postImageData = () => {

// }
const uploadImagedData = (formdata, callback) => {
    axios({
        method: 'post',
        url : getURL('/file-upload'),
        data : jsonToFormData(formdata),
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


const getUserDetails = (callback) => {
    axios({
        method: 'get',
        url: `${getURL('/user/agents-list')}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}


const getEmailVerification = (formdata, callback) => {
    console.log('API call with data:', formdata);
    axios({
      method: 'put',
      url: getURL('client/update-verification-status'),
      data: jsonToFormData(formdata),
    //   validateStatus: (status) => {
    //     console.log('Response status:', status);
    //     return handleResponse(status);
    //   }
    }).then((response) => {
      console.log('Response received:', response);
      callback(response);
    }).catch((error) => {
      console.error('Axios error:', error);
    });
  };

export { postNewUserData ,uploadImagedData, getUserDetails,updateUserData,disableUserData ,getEmailVerification}