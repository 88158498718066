import {
  Container,
  Typography,
  Stack,
  Tooltip,
  Card,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  IconButton
} from '@mui/material';
// components
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import { getHeadCountInputs, updateHeadCountInput } from 'src/api/headCountAPI';
import { LoadingButton } from '@mui/lab';
import InfoRounded from '@mui/icons-material/InfoRounded';


// ----------------------------------------------------------------------


const INFOS = {
  "Civilian": "Without Uniform and Others",
  "Regular Roll": "Regular Roll and Contractors",
}


export default function HeadCountInput() {

  const [inputs, setInput] = useState([])

  const [isUpdating, setUpdate] = useState(false)

  const getInputsData = () => {
    getHeadCountInputs((response) => {
      if (response.status === 200) {
        setInput(response.data)
      }
    })
  }

  useEffect(() => getInputsData(), [])

  const handleInputs = (key, value) => {
    inputs[key].in_count = value
    setInput([...inputs])
  }

  const handleFormSubmit = () => {
    setUpdate(true)
    updateHeadCountInput((response) => {
      if (response.status === 200 || response.status === 201) {
        setUpdate(false)
        getInputsData()
      }
    }, inputs)
  }

  return (
    <Page title="Head Count Input">
      <Container maxWidth="xl">
        <Typography variant='h4' sx={{py: 2}}>Head Count Input</Typography>
        <Grid container spacing={2}>
          <Grid item sm={6} md={4}>
            <Card sx={{ p: 2 }}>
              <Stack
                spacing={2}
                noValidate
                autoComplete="off"
              >
                {/* <TextField key={index} id="outlined-basic" label={item.type} value={item.in_count} variant="outlined" onChange={(e) => handleInputs(index, e.target.value)} />) */}
                {inputs.map((item, index) => (
                
                <FormControl key={index} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">{item.type}</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    value={item.in_count}
                    onChange={(e) => handleInputs(index, e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip title={INFOS.hasOwnProperty(item.type) ? INFOS[item.type] : item.type} placement="right-start">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <InfoRounded />
                        </IconButton>
                        </Tooltip>                        
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                
                ))}                
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isUpdating}
                  onClick={handleFormSubmit}
                >Update</LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
