import axios from '../axios'
import { getURL } from 'src/utils/config';
import { handleResponse } from 'src/utils/responseHandler';


// get report data
const getCounts = (callback) => {
    axios({
        method: 'get',
        url: `${getURL(`employee-information/uniform-type-info`)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

// get Head count inputs
const getHeadCountInputs = (callback) => {
    axios({
        method: 'get',
        url: `${getURL(`employee-information/uniform-type-data`)}`,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

const updateHeadCountInput = (callback, body) => {
    axios({
        method: 'put',
        url: `${getURL(`employee-information/update-uniform-count-info`)}`,
        data: body,
        validateStatus: (status) => handleResponse(status)
    }).then((response) => callback(response))
}

export { getCounts, getHeadCountInputs, updateHeadCountInput }